/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Navigation } from 'react-minimal-side-navigation';
import { useDispatch } from 'react-redux';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import './Sidebar.css';
import logo from '../../assets/logo.png';
import basicFetch from '../../api/basicFetch';
import authUtility from '../../utils/auth';
import * as API from '../../api/API';
import {
  setIsReseller,
  setDashcamCountRedux,
  setInitRouteGlobal,
} from '../../features/switchCompany/switchCompSlice';

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [dashcamCount, setDashcamCount] = useState(0);
  const [userRole, setUserRole] = useState('');
  const [initialRoute, setInitialRoute] = useState('/Home');
  const [navMenu, setNavMenu] = useState([]);
  const companyInfo = authUtility.getCompanyInfo();
  // const [preventRefresh, setPrevRefresh] = useState(false);

  const getMeCount = () => {
    basicFetch(`${API.DASHCAM_MASTER_ME}`, null, null, 0).then((res) => {
      const resData = res.data || {};
      const resDasCamCount = resData.dashCamCount || 0;
      const menu = resData.menu || [];
      const roleName = resData.contact_Type || '';
      const routePath = resData.routePath || '/Home';
      setDashcamCount(resDasCamCount);
      setUserRole(roleName);
      setNavMenu(menu);
      setInitialRoute(routePath);
      setInitRouteGlobal(routePath);

      dispatch(setIsReseller(resData.is_reseller));
    });
  };

  const [navItems, setNavItems] = useState([]);

  const settingsSubNav =
    userRole === 'BRANCH ADMIN' || userRole === 'BRANCH MANAGER' || userRole === 'USER'
      ? [
          {
            title: (
              <span className="sidebarLink sidebarActiveMenu">
                {userRole === 'USER' ? 'Contact View' : 'Roles'}
              </span>
            ),
            // itemId: '/settingRole',
            itemId: userRole === 'USER' ? '/contactView' : '/settingRole',
            // elemBefore: () => <img src={navItem[]} alt="" />,
            elemBefore: () => <i className="fas fa-cog trailer-icon" />,
          },
          {
            title: <span className="sidebarLink sidebarActiveMenu">Change Password</span>,
            itemId: '/updatePassword',
            elemBefore: () => <i className="fas fa-cog trailer-icon" />,
          },
          {
            title: <span className="sidebarLink sidebarActiveMenu">Request Video</span>,
            itemId: '/requestVideo',
            elemBefore: () => <i className="fas fa-cog trailer-icon" />,
          },
          // {
          //   title: <span className="sidebarLink sidebarActiveMenu">Feature Flag</span>,
          //   itemId: '/featureFlag',
          //   elemBefore: () => <i className="fas fa-cog trailer-icon" />,
          // },
        ]
      : [
          {
            title: <span className="sidebarLink sidebarActiveMenu">Change Password</span>,
            itemId: '/updatePassword',
            elemBefore: () => <i className="fas fa-cog trailer-icon" />,
          },
          // {
          //   title: <span className="sidebarLink sidebarActiveMenu">Request Video</span>,
          //   itemId: '/requestVideo',
          //   elemBefore: () => <i className="fas fa-cog trailer-icon" />,
          // },
          // {
          //   title: <span className="sidebarLink sidebarActiveMenu">Feature Flag</span>,
          //   itemId: '/featureFlag',
          //   elemBefore: () => <i className="fas fa-cog trailer-icon" />,
          // },
        ];

  // const settingsSubNav = [
  //   {
  //     title: <span className="sidebarLink sidebarActiveMenu">Change Password</span>,
  //     itemId: '/updatePassword',
  //     elemBefore: () => <i className="fas fa-cog trailer-icon" />,
  //   },
  // ];

  const settingsNav = {
    title: <span className="sidebarLink sidebarActiveMenu">Settings</span>,
    itemId: '#',
    // elemBefore: () => <img src={navItem[]} alt="" />,
    elemBefore: () => <i className="fas fa-cog trailer-icon" />,
    // subNav: [
    //   {
    //     title: <span className="sidebarLink sidebarActiveMenu">Role</span>,
    //     itemId: '/settingRole',
    //     // elemBefore: () => <img src={navItem[]} alt="" />,
    //     elemBefore: () => <i className="fas fa-cog trailer-icon" />,
    //   },
    //   {
    //     title: <span className="sidebarLink sidebarActiveMenu">Change Password</span>,
    //     itemId: '/updatePassword',
    //     elemBefore: () => <i className="fas fa-cog trailer-icon" />,
    //   },
    // ],
    subNav: settingsSubNav,
  };

  const dynamicNavs = () => {
    const dynamicNavBar = [];

    navMenu.map((navItem) => {
      const child = navItem.child || [];

      const sampleNav = {
        title: <span className="sidebarLink sidebarActiveMenu">{navItem.menuName}</span>,
        itemId: navItem.web_action,
        elemBefore: () => <img src={navItem.png_icon} alt="" />,
        subNav: [],
      };

      // eslint-disable-next-line no-unused-expressions
      child.length > 0 &&
        child.map((childNav) => {
          const item = {
            title: (
              <span className="sidebarLink sidebarActiveMenu d-flex">
                <div className="mr-3">
                  {/* <i className="fas fa-plus-circle" /> */}
                  <img src={childNav.png_icon} alt="" />
                </div>
                {childNav.menuName}
              </span>
            ),
            itemId: childNav.web_action,
          };
          sampleNav.subNav.push(item);

          return null;
        });

      dynamicNavBar.push(sampleNav);

      return null;
    });
    dynamicNavBar.push(settingsNav);
    return dynamicNavBar;
  };

  useEffect(() => {
    dispatch(setDashcamCountRedux(dashcamCount));
  }, [dashcamCount]);

  useEffect(() => {
    setNavItems(dynamicNavs);
  }, [navMenu]);

  // useEffect(() => {
  //   dispatch(setInitRouteGlobal(initialRoute));
  //   if (preventRefresh === false) {
  //     // history.push(initialRoute);
  //     setPrevRefresh(true);
  //   }
  // }, [initialRoute]);

  useEffect(() => {
    getMeCount();
  }, [userRole]);

  useEffect(() => {
    // console.log('sidebarOpen', window.location.pathname);

    if (window.location.pathname === '/Home') {
      if (companyInfo.dashcamCount === 0 && companyInfo.assetCount > 0) {
        history.push('/assets');
      } else {
        setInitialRoute('/Home');
      }
    } else if (window.location.pathname === '/assets') {
      if (companyInfo.dashcamCount > 0 && companyInfo.assetCount === 0) {
        history.push('/Home');
      } else {
        setInitialRoute('/assets');
      }
    } else {
      setInitialRoute(window.location.pathname);
    }
  });

  return (
    <div data-testid="sidebar" className={sidebarOpen ? 'sidebarResponsive' : ''} id="sidebar">
      <div className="sidebarTitle">
        <div className="sidebarImage">
          <img src={logo} alt="ELD Mandate" />
        </div>
        <i
          onClick={() => closeSidebar()}
          className="fa fa-times"
          id="sidebarIcon"
          aria-hidden="true"
        />
      </div>
      <>
        <Navigation
          data-testid="dashcam-home-page"
          activeItemId={initialRoute}
          onSelect={({ itemId }) => {
            history.push(itemId);
            if (typeof itemId !== 'undefined' && !itemId.startsWith('#')) {
              closeSidebar();
            }
          }}
          items={navItems}
        />
      </>
    </div>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool,
  closeSidebar: PropTypes.func,
};
