const asset1047 = {
  dashCamMasterId: '48e823c5-7efa-4d32-849f-d552d51874bb',
  dashcamDeviceId: '4gcc1-362523432431990',
  dashCamTypeId: 1,
  dashCamTypeName: null,
  companyId: 37084,
  branchId: 47096,
  firmwareVersion: null,
  appVersion: '1.2.9',
  createdBy: 1,
  createdByName: null,
  createdDate: '2021-05-06T17:25:03.283',
  createdDateDisplay: null,
  updatedBy: 1,
  updatedByName: null,
  updatedDate: '2021-06-16T23:02:10.117',
  updatedDateDisplay: null,
  idAssetDevice: 1047,
  simCardNo: '89011704278605834243',
  simCardPhoneNumber: '89011704278605834243',
  assetName: 'BH008',
  isActive: true,
  assetDescription: 'BH008',
  speedUnit: 'M',
  polylineType: 0,
  wayPointsCount: 0,
  assetType: 1,
  realTimeTrackingIntervalId: 1,
  idAtAssetCheckIns: 1053,
  movingCheckinId: 5,
  stationaryCheckinId: 13,
};

const asset1050 = {
  dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
  dashcamDeviceId: '4gcc1-362523432434002',
  dashCamTypeId: 1,
  dashCamTypeName: null,
  companyId: 37084,
  branchId: 47096,
  firmwareVersion: null,
  appVersion: '1.2.9',
  createdBy: 1,
  createdByName: null,
  createdDate: '2021-05-06T17:48:42.82',
  createdDateDisplay: null,
  updatedBy: null,
  updatedByName: null,
  updatedDate: '2021-06-16T23:15:49.023',
  updatedDateDisplay: null,
  idAssetDevice: 1050,
  simCardNo: '89011704278605834318',
  simCardPhoneNumber: '89011704278605834318',
  assetName: 'BH005',
  isActive: true,
  assetDescription: 'BH005',
  speedUnit: 'M',
  polylineType: 0,
  wayPointsCount: 0,
  assetType: 1,
  realTimeTrackingIntervalId: 1,
  idAtAssetCheckIns: 1056,
  movingCheckinId: 5,
  stationaryCheckinId: 0,
};

const asset1049 = {
  dashCamMasterId: '139408b8-a63f-488f-9354-971e45ac8661',
  dashcamDeviceId: '4gcc1-362523432428749',
  dashCamTypeId: 1,
  dashCamTypeName: null,
  companyId: 37084,
  branchId: 47096,
  firmwareVersion: null,
  appVersion: '1.2.8',
  createdBy: 1,
  createdByName: null,
  createdDate: '2021-05-06T17:41:39.11',
  createdDateDisplay: null,
  updatedBy: null,
  updatedByName: null,
  updatedDate: '2021-06-16T23:15:42.953',
  updatedDateDisplay: null,
  idAssetDevice: 1049,
  simCardNo: '89011704278605834250',
  simCardPhoneNumber: '89011704278605834250',
  assetName: 'BH004',
  isActive: true,
  assetDescription: 'BH004',
  speedUnit: 'M',
  polylineType: 0,
  wayPointsCount: 0,
  assetType: 1,
  realTimeTrackingIntervalId: 1,
  idAtAssetCheckIns: 1055,
  movingCheckinId: 5,
  stationaryCheckinId: 13,
};

const asset1048 = {
  dashCamMasterId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
  dashcamDeviceId: '4gcc1-362523432431461',
  dashCamTypeId: 1,
  dashCamTypeName: null,
  companyId: 37084,
  branchId: 47096,
  firmwareVersion: null,
  appVersion: '1.2.8',
  createdBy: 1,
  createdByName: null,
  createdDate: '2021-05-06T17:34:28.19',
  createdDateDisplay: null,
  updatedBy: null,
  updatedByName: null,
  updatedDate: '2021-06-10T08:08:56.52',
  updatedDateDisplay: null,
  idAssetDevice: 1048,
  simCardNo: '89011704278605834300',
  simCardPhoneNumber: '89011704278605834300',
  assetName: 'BH006',
  isActive: true,
  assetDescription: 'BH006',
  speedUnit: 'M',
  polylineType: 0,
  wayPointsCount: 0,
  assetType: 1,
  realTimeTrackingIntervalId: 1,
  idAtAssetCheckIns: 1054,
  movingCheckinId: 5,
  stationaryCheckinId: 13,
};

const asset1046 = {
  dashCamMasterId: '07b93190-ec83-405d-8184-7c48dc3fe590',
  dashcamDeviceId: '4gcc1-362523432435678',
  dashCamTypeId: 1,
  dashCamTypeName: null,
  companyId: 37084,
  branchId: 47096,
  firmwareVersion: null,
  appVersion: '1.1.8',
  createdBy: 1,
  createdByName: null,
  createdDate: '2021-05-06T17:07:27.713',
  createdDateDisplay: null,
  updatedBy: null,
  updatedByName: null,
  updatedDate: '2021-05-19T15:54:35.353',
  updatedDateDisplay: null,
  idAssetDevice: 1046,
  simCardNo: '89011704278605834268',
  simCardPhoneNumber: '89011704278605834268',
  assetName: 'BH007',
  isActive: true,
  assetDescription: 'BH007',
  speedUnit: 'M',
  polylineType: null,
  wayPointsCount: null,
  assetType: 1,
  realTimeTrackingIntervalId: 1,
  idAtAssetCheckIns: 1052,
  movingCheckinId: 5,
  stationaryCheckinId: 13,
};

export default {
  asset1046,
  asset1047,
  asset1048,
  asset1049,
  asset1050,
};
