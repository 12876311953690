const data = [
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:53:38',
    Latitude: '35.0066416',
    Longitude: '-117.6946824',
    Speed: '37.90 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '12469 Del Oro St, Boron, CA 93516, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:53:38',
    SlNo: '1',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:53:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:52:17',
    Latitude: '35.0067012',
    Longitude: '-117.7170432',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Boron, CA 93516, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:52:17',
    SlNo: '2',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:52:17 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:51:38',
    Latitude: '35.0067232',
    Longitude: '-117.727087',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Boron, CA 93516, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:51:38',
    SlNo: '3',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:51:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:51:17',
    Latitude: '35.0067308',
    Longitude: '-117.7330728',
    Speed: '53.44 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Boron, CA 93516, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:51:17',
    SlNo: '4',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:51:17 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:49:38',
    Latitude: '35.0037385',
    Longitude: '-117.7641265',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Boron, CA 93516, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:49:38',
    SlNo: '5',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:49:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:48:38',
    Latitude: '35.0027342',
    Longitude: '-117.7827771',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:48:38',
    SlNo: '6',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:48:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:47:38',
    Latitude: '35.0040567',
    Longitude: '-117.8015352',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:47:38',
    SlNo: '7',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:47:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:46:37',
    Latitude: '35.0053379',
    Longitude: '-117.8198748',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17535 CA-58, North Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:46:37',
    SlNo: '8',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:46:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:45:38',
    Latitude: '35.0065705',
    Longitude: '-117.8379916',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:45:38',
    SlNo: '9',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:45:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:44:56',
    Latitude: '35.0074324',
    Longitude: '-117.8503491',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:44:56',
    SlNo: '10',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:44:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:43:38',
    Latitude: '35.0092558',
    Longitude: '-117.8767166',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '14301 Frontage Rd, North Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:43:38',
    SlNo: '11',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:43:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:42:17',
    Latitude: '35.0109766',
    Longitude: '-117.901534',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:42:17',
    SlNo: '12',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:42:17 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:41:38',
    Latitude: '35.0118081',
    Longitude: '-117.9137886',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:41:38',
    SlNo: '13',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:41:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:41:17',
    Latitude: '35.012267',
    Longitude: '-117.9205487',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Edwards, CA 93523, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:41:17',
    SlNo: '14',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:41:17 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:39:37',
    Latitude: '35.0145803',
    Longitude: '-117.9540643',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:39:37',
    SlNo: '15',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:39:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:38:39',
    Latitude: '35.0160735',
    Longitude: '-117.9757738',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7251 Blue Star Memorial Highway, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:38:39',
    SlNo: '16',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:38:39 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:37:38',
    Latitude: '35.0175008',
    Longitude: '-117.9964348',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:37:38',
    SlNo: '17',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:37:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:36:37',
    Latitude: '35.0188052',
    Longitude: '-118.0154121',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:36:37',
    SlNo: '18',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:36:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:35:37',
    Latitude: '35.0206007',
    Longitude: '-118.031696',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:35:37',
    SlNo: '19',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:35:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:34:56',
    Latitude: '35.0223604',
    Longitude: '-118.0431595',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:34:56',
    SlNo: '20',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:34:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:33:37',
    Latitude: '35.0263451',
    Longitude: '-118.0676887',
    Speed: '46.60 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:33:37',
    SlNo: '21',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:33:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:32:18',
    Latitude: '35.0306489',
    Longitude: '-118.0946939',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mojave-Barstow Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:32:18',
    SlNo: '22',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:32:18 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:37:25',
    Latitude: '37.8847616',
    Longitude: '-121.276496',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7623 El Dorado St, French Camp, CA 95231, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:37:25',
    SlNo: '372',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:37:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:36:25',
    Latitude: '37.8859606',
    Longitude: '-121.2765902',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'El Dorado & Hospital Rd NB, French Camp, CA 95231, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:36:25',
    SlNo: '373',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:36:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:35:25',
    Latitude: '37.8862175',
    Longitude: '-121.2765929',
    Speed: '3.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'El Dorado & Hospital Rd NB, French Camp, CA 95231, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:35:25',
    SlNo: '374',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:35:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:34:45',
    Latitude: '37.8881134',
    Longitude: '-121.2765964',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6909 El Dorado St, French Camp, CA 95231, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:34:45',
    SlNo: '375',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:34:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:33:25',
    Latitude: '37.9013456',
    Longitude: '-121.2758243',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4909 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:33:25',
    SlNo: '376',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:33:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:33:05',
    Latitude: '37.9054165',
    Longitude: '-121.2757722',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4004 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:33:05',
    SlNo: '377',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:33:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:31:25',
    Latitude: '37.9097533',
    Longitude: '-121.2758148',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:31:25',
    SlNo: '378',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:31:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:30:25',
    Latitude: '37.9097474',
    Longitude: '-121.2758075',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:30:25',
    SlNo: '379',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:30:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:29:26',
    Latitude: '37.9097471',
    Longitude: '-121.2758097',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:29:26',
    SlNo: '380',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:29:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:29:05',
    Latitude: '37.9097449',
    Longitude: '-121.275809',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:29:05',
    SlNo: '381',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:29:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:27:25',
    Latitude: '37.9097583',
    Longitude: '-121.2757804',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:27:25',
    SlNo: '382',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:27:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:26:25',
    Latitude: '37.9097579',
    Longitude: '-121.2757798',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:26:25',
    SlNo: '383',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:26:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:25:25',
    Latitude: '37.90976',
    Longitude: '-121.2757802',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:25:25',
    SlNo: '384',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:25:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:24:44',
    Latitude: '37.9097589',
    Longitude: '-121.2757805',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:24:44',
    SlNo: '385',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:24:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:23:25',
    Latitude: '37.90976',
    Longitude: '-121.2757809',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:23:25',
    SlNo: '386',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:23:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:23:04',
    Latitude: '37.9097578',
    Longitude: '-121.2757811',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:23:04',
    SlNo: '387',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:23:04 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:21:25',
    Latitude: '37.9097593',
    Longitude: '-121.2757811',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:21:25',
    SlNo: '388',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:21:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:20:25',
    Latitude: '37.9097586',
    Longitude: '-121.2757798',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:20:25',
    SlNo: '389',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:20:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:19:12',
    Latitude: '37.9097588',
    Longitude: '-121.2757799',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:19:12',
    SlNo: '390',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:19:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:18:52',
    Latitude: '37.9097581',
    Longitude: '-121.275781',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:18:52',
    SlNo: '391',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:18:52 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:17:12',
    Latitude: '37.9097584',
    Longitude: '-121.275778',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:17:12',
    SlNo: '392',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:17:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:16:08',
    Latitude: '37.9097588',
    Longitude: '-121.2757795',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:16:08',
    SlNo: '393',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:16:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:15:12',
    Latitude: '37.9097442',
    Longitude: '-121.2757865',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:15:12',
    SlNo: '394',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:15:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:14:45',
    Latitude: '37.9097423',
    Longitude: '-121.2757889',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:14:45',
    SlNo: '395',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:14:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:13:05',
    Latitude: '37.9097747',
    Longitude: '-121.2757719',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:13:05',
    SlNo: '396',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:13:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:11:25',
    Latitude: '37.9097378',
    Longitude: '-121.2757725',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:11:25',
    SlNo: '397',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:11:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:10:52',
    Latitude: '37.9097378',
    Longitude: '-121.2757723',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:10:52',
    SlNo: '398',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:10:52 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:10:25',
    Latitude: '37.9097374',
    Longitude: '-121.2757695',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:10:25',
    SlNo: '399',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:10:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:08:52',
    Latitude: '37.9097396',
    Longitude: '-121.2757692',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:08:52',
    SlNo: '400',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:08:52 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:07:52',
    Latitude: '37.9097275',
    Longitude: '-121.2757755',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:07:52',
    SlNo: '401',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:07:52 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:06:52',
    Latitude: '37.9097747',
    Longitude: '-121.2757686',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:06:52',
    SlNo: '402',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:06:52 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:05:12',
    Latitude: '37.9104331',
    Longitude: '-121.275822',
    Speed: '4.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3845 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:05:12',
    SlNo: '403',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:05:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:05:08',
    Latitude: '37.9125431',
    Longitude: '-121.2761783',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3613 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:05:08',
    SlNo: '404',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:05:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:04:12',
    Latitude: '37.9179257',
    Longitude: '-121.2783112',
    Speed: '22.99 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3147 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:04:12',
    SlNo: '405',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:04:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:02:45',
    Latitude: '37.9285746',
    Longitude: '-121.2826914',
    Speed: '13.67 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2245 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:02:45',
    SlNo: '406',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:02:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:01:26',
    Latitude: '37.9347383',
    Longitude: '-121.2851955',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1725 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:01:26',
    SlNo: '407',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:01:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:00:45',
    Latitude: '37.9359201',
    Longitude: '-121.2860283',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:00:45',
    SlNo: '408',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:00:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:00:25',
    Latitude: '37.9357976',
    Longitude: '-121.2862503',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:00:25',
    SlNo: '409',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:00:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:58:45',
    Latitude: '37.9356077',
    Longitude: '-121.2863561',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:58:45',
    SlNo: '410',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:58:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:57:21',
    Latitude: '37.9356062',
    Longitude: '-121.2863599',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:57:21',
    SlNo: '411',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:57:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:56:34',
    Latitude: '37.9356076',
    Longitude: '-121.2863568',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:56:34',
    SlNo: '412',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:56:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:56:05',
    Latitude: '37.9356076',
    Longitude: '-121.2863567',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:56:05',
    SlNo: '413',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:56:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:54:34',
    Latitude: '37.9356088',
    Longitude: '-121.2863575',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:54:34',
    SlNo: '414',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:54:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:53:35',
    Latitude: '37.9356079',
    Longitude: '-121.2863583',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:53:35',
    SlNo: '415',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:53:35 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:52:34',
    Latitude: '37.9356075',
    Longitude: '-121.2863578',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:52:34',
    SlNo: '416',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:52:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:51:26',
    Latitude: '37.9356066',
    Longitude: '-121.2863562',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:51:26',
    SlNo: '417',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:51:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:50:25',
    Latitude: '37.9356076',
    Longitude: '-121.2863582',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:50:25',
    SlNo: '418',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:50:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:49:54',
    Latitude: '37.9356078',
    Longitude: '-121.2863598',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:49:54',
    SlNo: '419',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:49:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:48:25',
    Latitude: '37.9356084',
    Longitude: '-121.2863566',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:48:25',
    SlNo: '420',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:48:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:47:15',
    Latitude: '37.9356088',
    Longitude: '-121.2863564',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:47:15',
    SlNo: '421',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:47:15 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:46:25',
    Latitude: '37.9356243',
    Longitude: '-121.2863468',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:46:25',
    SlNo: '422',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:46:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:46:05',
    Latitude: '37.9356142',
    Longitude: '-121.2863182',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:46:05',
    SlNo: '423',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:46:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:44:25',
    Latitude: '37.935603',
    Longitude: '-121.286812',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:44:25',
    SlNo: '424',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:44:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:43:25',
    Latitude: '37.9355817',
    Longitude: '-121.2868604',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:43:25',
    SlNo: '425',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:43:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:42:25',
    Latitude: '37.9355811',
    Longitude: '-121.2868612',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:42:25',
    SlNo: '426',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:42:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:41:27',
    Latitude: '37.935579',
    Longitude: '-121.2868615',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:41:27',
    SlNo: '427',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:41:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:40:12',
    Latitude: '37.9355823',
    Longitude: '-121.286862',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:40:12',
    SlNo: '428',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:40:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:40:06',
    Latitude: '37.9355823',
    Longitude: '-121.286862',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:40:06',
    SlNo: '429',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:40:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:38:12',
    Latitude: '37.9355904',
    Longitude: '-121.2868978',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:38:12',
    SlNo: '430',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:38:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:36:52',
    Latitude: '37.9355888',
    Longitude: '-121.2868876',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:36:52',
    SlNo: '431',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:36:52 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:36:12',
    Latitude: '37.9355888',
    Longitude: '-121.2868884',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:36:12',
    SlNo: '432',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:36:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:35:39',
    Latitude: '37.9355916',
    Longitude: '-121.2868929',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:35:39',
    SlNo: '433',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:35:39 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:34:12',
    Latitude: '37.9355681',
    Longitude: '-121.2868728',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:34:12',
    SlNo: '434',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:34:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:32:59',
    Latitude: '37.9355697',
    Longitude: '-121.2869024',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:32:59',
    SlNo: '435',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:32:59 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:31:59',
    Latitude: '37.9355729',
    Longitude: '-121.2869317',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:31:59',
    SlNo: '436',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:31:59 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:31:25',
    Latitude: '37.9355722',
    Longitude: '-121.2869271',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:31:25',
    SlNo: '437',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:31:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:29:59',
    Latitude: '37.9355886',
    Longitude: '-121.2869322',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:29:59',
    SlNo: '438',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:29:59 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:28:45',
    Latitude: '37.9355983',
    Longitude: '-121.2869177',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:28:45',
    SlNo: '439',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:28:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:28:08',
    Latitude: '37.9355988',
    Longitude: '-121.2869283',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:28:08',
    SlNo: '440',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:28:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:27:14',
    Latitude: '37.9355874',
    Longitude: '-121.2868876',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:27:14',
    SlNo: '441',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:27:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:25:59',
    Latitude: '37.9355691',
    Longitude: '-121.286895',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:25:59',
    SlNo: '442',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:25:59 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:25:39',
    Latitude: '37.9355987',
    Longitude: '-121.2869169',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:25:39',
    SlNo: '443',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:25:39 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:24:03',
    Latitude: '37.9356234',
    Longitude: '-121.286925',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:24:03',
    SlNo: '444',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:24:03 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:22:59',
    Latitude: '37.9356254',
    Longitude: '-121.2869243',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:22:59',
    SlNo: '445',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:22:59 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:21:59',
    Latitude: '37.9356092',
    Longitude: '-121.2869223',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:21:59',
    SlNo: '446',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:21:59 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:21:18',
    Latitude: '37.9356452',
    Longitude: '-121.2869556',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:21:18',
    SlNo: '447',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:21:18 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:19:59',
    Latitude: '37.9356452',
    Longitude: '-121.2869584',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:19:59',
    SlNo: '448',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:19:59 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:18:44',
    Latitude: '37.9356075',
    Longitude: '-121.2869076',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:18:44',
    SlNo: '449',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:18:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:17:58',
    Latitude: '37.9356062',
    Longitude: '-121.2869068',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:17:58',
    SlNo: '450',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:17:58 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:17:14',
    Latitude: '37.9355921',
    Longitude: '-121.2868849',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:17:14',
    SlNo: '451',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:17:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:15:58',
    Latitude: '37.9355526',
    Longitude: '-121.2868342',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:15:58',
    SlNo: '452',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:15:58 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:15:32',
    Latitude: '37.9355492',
    Longitude: '-121.2868345',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:15:32',
    SlNo: '453',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:15:32 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:13:58',
    Latitude: '37.9355518',
    Longitude: '-121.2868328',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:13:58',
    SlNo: '454',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:13:58 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:12:34',
    Latitude: '37.9355519',
    Longitude: '-121.2868329',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:12:34',
    SlNo: '455',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:12:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:11:44',
    Latitude: '37.9355527',
    Longitude: '-121.2868335',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:11:44',
    SlNo: '456',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:11:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:11:18',
    Latitude: '37.935564',
    Longitude: '-121.2868454',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:11:18',
    SlNo: '457',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:11:18 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:09:44',
    Latitude: '37.935568',
    Longitude: '-121.2868504',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:09:44',
    SlNo: '458',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:09:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:08:44',
    Latitude: '37.9355997',
    Longitude: '-121.2863083',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:08:44',
    SlNo: '459',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:08:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:07:33',
    Latitude: '37.9355697',
    Longitude: '-121.2863789',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:07:33',
    SlNo: '460',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:07:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:07:14',
    Latitude: '37.9355699',
    Longitude: '-121.2863785',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:07:14',
    SlNo: '461',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:07:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:05:33',
    Latitude: '37.9355642',
    Longitude: '-121.2864178',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:05:33',
    SlNo: '462',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:05:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:04:12',
    Latitude: '37.9355639',
    Longitude: '-121.2864178',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:04:12',
    SlNo: '463',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:04:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:03:35',
    Latitude: '37.9355636',
    Longitude: '-121.2864188',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:03:35',
    SlNo: '464',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:03:35 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:02:34',
    Latitude: '37.9355636',
    Longitude: '-121.2864188',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:02:34',
    SlNo: '465',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:02:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:01:18',
    Latitude: '37.9355626',
    Longitude: '-121.2864202',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:01:18',
    SlNo: '466',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:01:18 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 21:00:52',
    Latitude: '37.9355635',
    Longitude: '-121.286419',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '21:00:52',
    SlNo: '467',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:00:52 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:59:18',
    Latitude: '37.9355636',
    Longitude: '-121.2864192',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:59:18',
    SlNo: '468',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:59:18 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:58:13',
    Latitude: '37.9355636',
    Longitude: '-121.2864196',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:58:13',
    SlNo: '469',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:58:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:57:14',
    Latitude: '37.9355626',
    Longitude: '-121.2864214',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:57:14',
    SlNo: '470',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:57:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:56:56',
    Latitude: '37.9355638',
    Longitude: '-121.2864171',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:56:56',
    SlNo: '471',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:56:56 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:55:14',
    Latitude: '37.9355635',
    Longitude: '-121.286419',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:55:14',
    SlNo: '472',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:55:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:54:12',
    Latitude: '37.9355626',
    Longitude: '-121.2864224',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:54:12',
    SlNo: '473',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:54:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:53:07',
    Latitude: '37.9355652',
    Longitude: '-121.2864184',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:53:07',
    SlNo: '474',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:53:07 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:52:34',
    Latitude: '37.9355645',
    Longitude: '-121.2864186',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:52:34',
    SlNo: '475',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:52:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:51:08',
    Latitude: '37.9355649',
    Longitude: '-121.2864185',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:51:08',
    SlNo: '476',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:51:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:49:54',
    Latitude: '37.9355637',
    Longitude: '-121.2864188',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:49:54',
    SlNo: '477',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:49:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:49:07',
    Latitude: '37.9355646',
    Longitude: '-121.2864184',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:49:07',
    SlNo: '478',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:49:07 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:48:12',
    Latitude: '37.9355637',
    Longitude: '-121.2864187',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:48:12',
    SlNo: '479',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:48:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:46:56',
    Latitude: '37.9355634',
    Longitude: '-121.2864189',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:46:56',
    SlNo: '480',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:46:56 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:45:14',
    Latitude: '37.9355806',
    Longitude: '-121.2864178',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:45:14',
    SlNo: '481',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:45:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:44:56',
    Latitude: '37.9355739',
    Longitude: '-121.2864184',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:44:56',
    SlNo: '482',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:44:56 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:44:12',
    Latitude: '37.9355727',
    Longitude: '-121.2864147',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:44:12',
    SlNo: '483',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:44:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:42:56',
    Latitude: '37.9356357',
    Longitude: '-121.2864238',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:42:56',
    SlNo: '484',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:42:56 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:42:34',
    Latitude: '37.9356334',
    Longitude: '-121.2864253',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:42:34',
    SlNo: '485',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:42:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:40:56',
    Latitude: '37.9356328',
    Longitude: '-121.2864241',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:40:56',
    SlNo: '486',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:40:56 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:40:08',
    Latitude: '37.9356398',
    Longitude: '-121.2864117',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:40:08',
    SlNo: '487',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:40:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:39:54',
    Latitude: '37.9356322',
    Longitude: '-121.2864129',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:39:54',
    SlNo: '488',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:39:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:38:57',
    Latitude: '37.9355599',
    Longitude: '-121.2864434',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:38:57',
    SlNo: '489',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:38:57 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:38:25',
    Latitude: '37.9355615',
    Longitude: '-121.2864447',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:38:25',
    SlNo: '490',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:38:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:36:56',
    Latitude: '37.9355672',
    Longitude: '-121.2864302',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:36:56',
    SlNo: '491',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:36:56 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:35:14',
    Latitude: '37.935574',
    Longitude: '-121.2864052',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:35:14',
    SlNo: '492',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:35:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:34:56',
    Latitude: '37.9355409',
    Longitude: '-121.2864005',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:34:56',
    SlNo: '493',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:34:56 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:34:12',
    Latitude: '37.9355523',
    Longitude: '-121.2863963',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:34:12',
    SlNo: '494',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:34:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:32:54',
    Latitude: '37.9355591',
    Longitude: '-121.2863896',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:32:54',
    SlNo: '495',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:32:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:32:34',
    Latitude: '37.9355587',
    Longitude: '-121.2863889',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:32:34',
    SlNo: '496',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:32:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:30:54',
    Latitude: '37.935557',
    Longitude: '-121.2863662',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:30:54',
    SlNo: '497',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:30:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:29:54',
    Latitude: '37.935544',
    Longitude: '-121.2863703',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:29:54',
    SlNo: '498',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:29:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:28:49',
    Latitude: '37.9355437',
    Longitude: '-121.28637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:28:49',
    SlNo: '499',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:28:49 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:28:12',
    Latitude: '37.9355444',
    Longitude: '-121.2863705',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:28:12',
    SlNo: '500',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:28:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:26:48',
    Latitude: '37.9355424',
    Longitude: '-121.2863699',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:26:48',
    SlNo: '501',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:26:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:26:29',
    Latitude: '37.9355432',
    Longitude: '-121.28637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:26:29',
    SlNo: '502',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:26:29 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:24:48',
    Latitude: '37.9355439',
    Longitude: '-121.2863701',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:24:48',
    SlNo: '503',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:24:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:23:48',
    Latitude: '37.9355441',
    Longitude: '-121.28637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:23:48',
    SlNo: '504',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:23:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:22:34',
    Latitude: '37.935545',
    Longitude: '-121.2863704',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:22:34',
    SlNo: '505',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:22:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:21:08',
    Latitude: '37.935544',
    Longitude: '-121.2863702',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:21:08',
    SlNo: '506',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:21:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:20:29',
    Latitude: '37.9355438',
    Longitude: '-121.2863701',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:20:29',
    SlNo: '507',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:20:29 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:19:54',
    Latitude: '37.9355438',
    Longitude: '-121.2863701',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:19:54',
    SlNo: '508',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:19:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:18:29',
    Latitude: '37.9355451',
    Longitude: '-121.2863701',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:18:29',
    SlNo: '509',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:18:29 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:18:12',
    Latitude: '37.9355442',
    Longitude: '-121.2863701',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:18:12',
    SlNo: '510',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:18:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:16:29',
    Latitude: '37.9355435',
    Longitude: '-121.2863701',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:16:29',
    SlNo: '511',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:16:29 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:15:29',
    Latitude: '37.9355437',
    Longitude: '-121.2863701',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:15:29',
    SlNo: '512',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:15:29 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:14:24',
    Latitude: '37.9355465',
    Longitude: '-121.28637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:14:24',
    SlNo: '513',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:14:24 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:13:49',
    Latitude: '37.9355234',
    Longitude: '-121.2864025',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:13:49',
    SlNo: '514',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:13:49 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:12:12',
    Latitude: '37.9355454',
    Longitude: '-121.2863958',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:12:12',
    SlNo: '515',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:12:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:11:09',
    Latitude: '37.9355469',
    Longitude: '-121.2863966',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:11:09',
    SlNo: '516',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:11:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:10:12',
    Latitude: '37.9355465',
    Longitude: '-121.2863963',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:10:12',
    SlNo: '517',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:10:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:09:54',
    Latitude: '37.9355466',
    Longitude: '-121.2863963',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:09:54',
    SlNo: '518',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:09:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:08:12',
    Latitude: '37.9355476',
    Longitude: '-121.2863954',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:08:12',
    SlNo: '519',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:08:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:06:54',
    Latitude: '37.9355427',
    Longitude: '-121.2863694',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:06:54',
    SlNo: '520',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:06:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:06:12',
    Latitude: '37.9355393',
    Longitude: '-121.2863554',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:06:12',
    SlNo: '521',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:06:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:05:29',
    Latitude: '37.9355233',
    Longitude: '-121.2863776',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:05:29',
    SlNo: '522',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:05:29 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:04:12',
    Latitude: '37.9355222',
    Longitude: '-121.286377',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:04:12',
    SlNo: '523',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:04:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:03:48',
    Latitude: '37.9355017',
    Longitude: '-121.2863724',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:03:48',
    SlNo: '524',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:03:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:02:08',
    Latitude: '37.9355534',
    Longitude: '-121.2863931',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:02:08',
    SlNo: '525',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:02:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 20:01:08',
    Latitude: '37.9355536',
    Longitude: '-121.2863929',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '20:01:08',
    SlNo: '526',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 08:01:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:59:54',
    Latitude: '37.9355584',
    Longitude: '-121.2863942',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:59:54',
    SlNo: '527',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:59:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:59:34',
    Latitude: '37.9355587',
    Longitude: '-121.2863939',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:59:34',
    SlNo: '528',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:59:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:57:54',
    Latitude: '37.9355589',
    Longitude: '-121.2863947',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:57:54',
    SlNo: '529',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:57:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:56:54',
    Latitude: '37.9355592',
    Longitude: '-121.2863949',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:56:54',
    SlNo: '530',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:56:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:55:49',
    Latitude: '37.9355596',
    Longitude: '-121.2863952',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:55:49',
    SlNo: '531',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:55:49 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:55:28',
    Latitude: '37.9355592',
    Longitude: '-121.2863947',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:55:28',
    SlNo: '532',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:55:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:53:48',
    Latitude: '37.9355588',
    Longitude: '-121.2863944',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:53:48',
    SlNo: '533',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:53:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:52:48',
    Latitude: '37.9355581',
    Longitude: '-121.2863942',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:52:48',
    SlNo: '534',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:52:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:51:34',
    Latitude: '37.9355582',
    Longitude: '-121.2863942',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:51:34',
    SlNo: '535',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:51:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:51:08',
    Latitude: '37.9355579',
    Longitude: '-121.2863939',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:51:08',
    SlNo: '536',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:51:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:49:34',
    Latitude: '37.9355596',
    Longitude: '-121.286371',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:49:34',
    SlNo: '537',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:49:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:48:28',
    Latitude: '37.9355597',
    Longitude: '-121.2863704',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:48:28',
    SlNo: '538',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:48:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:47:29',
    Latitude: '37.9355592',
    Longitude: '-121.2863715',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:47:29',
    SlNo: '539',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:47:29 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:46:54',
    Latitude: '37.93556',
    Longitude: '-121.2863733',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:46:54',
    SlNo: '540',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:46:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:45:29',
    Latitude: '37.9355598',
    Longitude: '-121.2863713',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:45:29',
    SlNo: '541',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:45:29 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:44:14',
    Latitude: '37.9355588',
    Longitude: '-121.2863737',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:44:14',
    SlNo: '542',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:44:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:43:28',
    Latitude: '37.9355589',
    Longitude: '-121.2863727',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:43:28',
    SlNo: '543',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:43:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:42:48',
    Latitude: '37.935559',
    Longitude: '-121.2863715',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:42:48',
    SlNo: '544',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:42:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:41:28',
    Latitude: '37.9355589',
    Longitude: '-121.2863721',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:41:28',
    SlNo: '545',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:41:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:41:08',
    Latitude: '37.9355589',
    Longitude: '-121.2863717',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:41:08',
    SlNo: '546',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:41:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:39:28',
    Latitude: '37.9355592',
    Longitude: '-121.2863708',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:39:28',
    SlNo: '547',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:39:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:31:39',
    Latitude: '35.0352886',
    Longitude: '-118.1042657',
    Speed: '7.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:31:39',
    SlNo: '23',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:31:39 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:31:17',
    Latitude: '35.0380129',
    Longitude: '-118.1073351',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'CA-58 BUS, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:31:17',
    SlNo: '24',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:31:17 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:29:37',
    Latitude: '35.0413629',
    Longitude: '-118.1100636',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:29:37',
    SlNo: '25',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:29:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:28:37',
    Latitude: '35.0413498',
    Longitude: '-118.1100585',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:28:37',
    SlNo: '26',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:28:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:27:37',
    Latitude: '35.0413231',
    Longitude: '-118.110045',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:27:37',
    SlNo: '27',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:27:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:26:37',
    Latitude: '35.0413243',
    Longitude: '-118.1100354',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:26:37',
    SlNo: '28',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:26:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:25:37',
    Latitude: '35.0413146',
    Longitude: '-118.1100336',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:25:37',
    SlNo: '29',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:25:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:25:13',
    Latitude: '35.0413293',
    Longitude: '-118.1100475',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:25:13',
    SlNo: '30',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:25:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:23:37',
    Latitude: '35.0413518',
    Longitude: '-118.1100483',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:23:37',
    SlNo: '31',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:23:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:22:17',
    Latitude: '35.0413534',
    Longitude: '-118.1100482',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:22:17',
    SlNo: '32',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:22:17 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:21:38',
    Latitude: '35.0413536',
    Longitude: '-118.1100485',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:21:38',
    SlNo: '33',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:21:38 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:21:17',
    Latitude: '35.0413534',
    Longitude: '-118.1100483',
    Speed: '22.99 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:21:17',
    SlNo: '34',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:21:17 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:19:37',
    Latitude: '35.0521945',
    Longitude: '-118.1131109',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:19:37',
    SlNo: '35',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:19:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:18:37',
    Latitude: '35.0660537',
    Longitude: '-118.1131659',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:18:37',
    SlNo: '36',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:18:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:17:37',
    Latitude: '35.07958',
    Longitude: '-118.1161921',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:17:37',
    SlNo: '37',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:17:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:16:37',
    Latitude: '35.0910216',
    Longitude: '-118.1281552',
    Speed: '32.93 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:16:37',
    SlNo: '38',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:16:37 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:15:31',
    Latitude: '35.0988541',
    Longitude: '-118.1449412',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:15:31',
    SlNo: '39',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:15:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:15:13',
    Latitude: '35.100899',
    Longitude: '-118.1493763',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:15:13',
    SlNo: '40',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:15:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:13:31',
    Latitude: '35.1138053',
    Longitude: '-118.1799334',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:13:31',
    SlNo: '41',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:13:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:12:13',
    Latitude: '35.1167063',
    Longitude: '-118.2015858',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:12:13',
    SlNo: '42',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:12:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:11:31',
    Latitude: '35.1175985',
    Longitude: '-118.2141048',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4533 Rockhouse Rd, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:11:31',
    SlNo: '43',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:11:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:11:13',
    Latitude: '35.1180903',
    Longitude: '-118.2201554',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:11:13',
    SlNo: '44',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:11:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:09:31',
    Latitude: '35.1081573',
    Longitude: '-118.2459885',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:09:31',
    SlNo: '45',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:09:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:08:49',
    Latitude: '35.1050162',
    Longitude: '-118.2624548',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:08:49',
    SlNo: '46',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:08:49 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:07:20',
    Latitude: '35.1017639',
    Longitude: '-118.2828373',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Pacific Crest Trail, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:07:20',
    SlNo: '47',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:07:20 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:06:20',
    Latitude: '35.0975009',
    Longitude: '-118.3003015',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9676 Cameron Canyon Rd, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:06:20',
    SlNo: '48',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:06:20 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:05:13',
    Latitude: '35.1041298',
    Longitude: '-118.3132302',
    Speed: '7.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:05:13',
    SlNo: '49',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:05:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:04:56',
    Latitude: '35.1065124',
    Longitude: '-118.3166938',
    Speed: '39.15 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10836 Tehachapi Blvd, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:04:56',
    SlNo: '50',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:04:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:03:13',
    Latitude: '35.110257',
    Longitude: '-118.3393443',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:03:13',
    SlNo: '51',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:03:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:02:13',
    Latitude: '35.1110191',
    Longitude: '-118.3535572',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:02:13',
    SlNo: '52',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:02:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:01:13',
    Latitude: '35.1149997',
    Longitude: '-118.3706635',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Mojave, CA 93501, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:01:13',
    SlNo: '53',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:01:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:00:12',
    Latitude: '35.1176043',
    Longitude: '-118.3870795',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '04:00:12',
    SlNo: '54',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 04:00:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:59:13',
    Latitude: '35.1218675',
    Longitude: '-118.4031558',
    Speed: '16.78 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:59:13',
    SlNo: '55',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:59:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:58:31',
    Latitude: '35.1267171',
    Longitude: '-118.4111578',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '496400 Steuber Rd, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:58:31',
    SlNo: '56',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:58:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:57:13',
    Latitude: '35.1359743',
    Longitude: '-118.4261445',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1235 Burnett Rd, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:57:13',
    SlNo: '57',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:57:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:56:13',
    Latitude: '35.1396241',
    Longitude: '-118.4409292',
    Speed: '25.48 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:56:13',
    SlNo: '58',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:56:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:55:13',
    Latitude: '35.1396575',
    Longitude: '-118.4557846',
    Speed: '6.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '800 Enterprise Way, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:55:13',
    SlNo: '59',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:55:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:54:56',
    Latitude: '35.1396711',
    Longitude: '-118.459647',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:54:56',
    SlNo: '60',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:54:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:53:15',
    Latitude: '35.1513679',
    Longitude: '-118.4704724',
    Speed: '18.02 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:53:15',
    SlNo: '61',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:53:15 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:52:13',
    Latitude: '35.1595675',
    Longitude: '-118.4739813',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:52:13',
    SlNo: '62',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:52:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:51:13',
    Latitude: '35.1692058',
    Longitude: '-118.4746059',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:51:13',
    SlNo: '63',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:51:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:50:12',
    Latitude: '35.1766034',
    Longitude: '-118.4812328',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:50:12',
    SlNo: '64',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:50:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:49:12',
    Latitude: '35.1814038',
    Longitude: '-118.490495',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:49:12',
    SlNo: '65',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:49:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:48:31',
    Latitude: '35.1841418',
    Longitude: '-118.5001578',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:48:31',
    SlNo: '66',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:48:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:47:12',
    Latitude: '35.1931181',
    Longitude: '-118.5111656',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:47:12',
    SlNo: '67',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:47:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:46:24',
    Latitude: '35.1984825',
    Longitude: '-118.518978',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:46:24',
    SlNo: '68',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:46:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:45:12',
    Latitude: '35.2027813',
    Longitude: '-118.5284305',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:45:12',
    SlNo: '69',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:45:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:44:56',
    Latitude: '35.2042511',
    Longitude: '-118.5311205',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:44:56',
    SlNo: '70',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:44:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:43:13',
    Latitude: '35.2141552',
    Longitude: '-118.5496913',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Tehachapi, CA 93561, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:43:13',
    SlNo: '71',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:43:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:42:12',
    Latitude: '35.2210495',
    Longitude: '-118.5579106',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '29592 Woodford-Tehachapi Rd, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:42:12',
    SlNo: '72',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:42:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:41:12',
    Latitude: '35.2250537',
    Longitude: '-118.5683125',
    Speed: '20.51 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '30356 Woodford-Tehachapi Rd, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:41:12',
    SlNo: '73',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:41:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:40:12',
    Latitude: '35.2335371',
    Longitude: '-118.5746708',
    Speed: '22.99 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:40:12',
    SlNo: '74',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:40:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:39:11',
    Latitude: '35.2403119',
    Longitude: '-118.585259',
    Speed: '15.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:39:11',
    SlNo: '75',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:39:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:38:31',
    Latitude: '35.2451487',
    Longitude: '-118.5921222',
    Speed: '26.72 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:38:31',
    SlNo: '76',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:38:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:37:11',
    Latitude: '35.2524468',
    Longitude: '-118.6051395',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:37:11',
    SlNo: '77',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:37:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:36:11',
    Latitude: '35.2572133',
    Longitude: '-118.6135016',
    Speed: '14.91 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:36:11',
    SlNo: '78',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:36:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:35:11',
    Latitude: '35.261754',
    Longitude: '-118.6204458',
    Speed: '4.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:35:11',
    SlNo: '79',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:35:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:34:56',
    Latitude: '35.2624532',
    Longitude: '-118.6227428',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:34:56',
    SlNo: '80',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:34:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:33:11',
    Latitude: '35.2627092',
    Longitude: '-118.6408825',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:33:11',
    SlNo: '81',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:33:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:31:55',
    Latitude: '35.2642306',
    Longitude: '-118.6590524',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:31:55',
    SlNo: '82',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:31:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:31:11',
    Latitude: '35.2675763',
    Longitude: '-118.6712459',
    Speed: '22.99 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:31:11',
    SlNo: '83',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:31:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:29:17',
    Latitude: '35.2688903',
    Longitude: '-118.6989644',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:29:17',
    SlNo: '84',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:29:17 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:28:34',
    Latitude: '35.2711472',
    Longitude: '-118.7030253',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Keene, CA 93531, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:28:34',
    SlNo: '85',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:28:34 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:27:10',
    Latitude: '35.2779127',
    Longitude: '-118.7176973',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Arvin, CA 93203, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:27:10',
    SlNo: '86',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:27:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:26:00',
    Latitude: '35.2814485',
    Longitude: '-118.7278594',
    Speed: '22.37 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Arvin, CA 93203, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:26:00',
    SlNo: '87',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:26:00 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:24:59',
    Latitude: '35.2870182',
    Longitude: '-118.7392601',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Arvin, CA 93203, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:24:59',
    SlNo: '88',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:24:59 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:24:36',
    Latitude: '35.2887393',
    Longitude: '-118.7436171',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Arvin, CA 93203, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:24:36',
    SlNo: '89',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:24:36 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:22:57',
    Latitude: '35.2986422',
    Longitude: '-118.76764',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Arvin, CA 93203, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:22:57',
    SlNo: '90',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:22:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:21:56',
    Latitude: '35.3094978',
    Longitude: '-118.780508',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17725 Hermosa Rd, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:21:56',
    SlNo: '91',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:21:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:20:57',
    Latitude: '35.3199922',
    Longitude: '-118.7926344',
    Speed: '22.37 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Arvin, CA 93203, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:20:57',
    SlNo: '92',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:20:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:20:12',
    Latitude: '35.3275645',
    Longitude: '-118.8020402',
    Speed: '38.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Arvin, CA 93203, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:20:12',
    SlNo: '93',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:20:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:18:57',
    Latitude: '35.3334001',
    Longitude: '-118.8234417',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2851 Tejon Hwy, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:18:57',
    SlNo: '94',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:18:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:18:32',
    Latitude: '35.3350723',
    Longitude: '-118.8303751',
    Speed: '46.60 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Arvin, CA 93203, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:18:32',
    SlNo: '95',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:18:32 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:16:57',
    Latitude: '35.3414638',
    Longitude: '-118.856845',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:16:57',
    SlNo: '96',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:16:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:15:58',
    Latitude: '35.3447684',
    Longitude: '-118.8742272',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1261 Edison Rd, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:15:58',
    SlNo: '97',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:15:58 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:14:57',
    Latitude: '35.3488915',
    Longitude: '-118.8910933',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Barstow-Bakersfield Hwy, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:14:57',
    SlNo: '98',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:14:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:14:36',
    Latitude: '35.350582',
    Longitude: '-118.8969767',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '355 S Vineland Rd, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:14:36',
    SlNo: '99',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:14:36 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:12:57',
    Latitude: '35.3521621',
    Longitude: '-118.9257245',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7105 E Brundage Ln, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:12:57',
    SlNo: '100',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:12:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:11:56',
    Latitude: '35.3520673',
    Longitude: '-118.9437621',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5412 Caballeros Dr, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:11:56',
    SlNo: '101',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:11:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:10:57',
    Latitude: '35.3526783',
    Longitude: '-118.9616866',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3901 E Brundage Ln, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:10:57',
    SlNo: '102',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:10:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:10:12',
    Latitude: '35.3522904',
    Longitude: '-118.9746629',
    Speed: '37.90 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2603 E Brundage Ln, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:10:12',
    SlNo: '103',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:10:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:08:57',
    Latitude: '35.3522476',
    Longitude: '-118.9971651',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '132c Ohio Dr, Bakersfield, CA 93307, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:08:57',
    SlNo: '104',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:08:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:08:32',
    Latitude: '35.3522963',
    Longitude: '-119.0046571',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '117 E Brundage Ln, Bakersfield, CA 93304, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:08:32',
    SlNo: '105',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:08:32 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:06:57',
    Latitude: '35.3523733',
    Longitude: '-119.0327845',
    Speed: '16.78 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2621 Colton St, Bakersfield, CA 93304, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:06:57',
    SlNo: '106',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:06:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:05:57',
    Latitude: '35.3582343',
    Longitude: '-119.0397757',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3230 Bank St, Bakersfield, CA 93309, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:05:57',
    SlNo: '107',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:05:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:04:57',
    Latitude: '35.3706459',
    Longitude: '-119.0429362',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1400 Easton Dr STE 116, Bakersfield, CA 93309, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:04:57',
    SlNo: '108',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:04:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:04:36',
    Latitude: '35.3744764',
    Longitude: '-119.0442633',
    Speed: '42.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2314 Bahamas Dr, Bakersfield, CA 93309, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:04:36',
    SlNo: '109',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:04:36 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:02:57',
    Latitude: '35.3951764',
    Longitude: '-119.0447657',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3429 Shell St, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:02:57',
    SlNo: '110',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:02:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:01:56',
    Latitude: '35.4076535',
    Longitude: '-119.0497944',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:01:56',
    SlNo: '111',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:01:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:00:57',
    Latitude: '35.417242',
    Longitude: '-119.0599104',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6375 Knudsen Dr, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:00:57',
    SlNo: '112',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:00:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 03:00:12',
    Latitude: '35.4257759',
    Longitude: '-119.0649414',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7156 Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '03:00:12',
    SlNo: '113',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 03:00:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:58:56',
    Latitude: '35.437629',
    Longitude: '-119.0797266',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8450 Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:58:56',
    SlNo: '114',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:58:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:58:32',
    Latitude: '35.4418077',
    Longitude: '-119.0850064',
    Speed: '47.22 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8912 Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:58:32',
    SlNo: '115',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:58:32 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:56:56',
    Latitude: '35.4559568',
    Longitude: '-119.1067044',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:56:56',
    SlNo: '116',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:56:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:55:56',
    Latitude: '35.4657679',
    Longitude: '-119.1196839',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:55:56',
    SlNo: '117',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:55:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:54:56',
    Latitude: '35.4755285',
    Longitude: '-119.1326455',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:54:56',
    SlNo: '118',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:54:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:54:36',
    Latitude: '35.4789629',
    Longitude: '-119.1371487',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:54:36',
    SlNo: '119',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:54:36 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:52:56',
    Latitude: '35.4951375',
    Longitude: '-119.1586082',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93314, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:52:56',
    SlNo: '120',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:52:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:51:56',
    Latitude: '35.5051051',
    Longitude: '-119.1716724',
    Speed: '29.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Shafter, CA 93263, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:51:56',
    SlNo: '121',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:51:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:51:25',
    Latitude: '35.5161767',
    Longitude: '-119.182474',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:51:25',
    SlNo: '122',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:51:25 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:50:13',
    Latitude: '35.5254135',
    Longitude: '-119.1883982',
    Speed: '37.90 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17215 Zachary Rd, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:50:13',
    SlNo: '123',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:50:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:48:56',
    Latitude: '35.543071',
    Longitude: '-119.1946688',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:48:56',
    SlNo: '124',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:48:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:48:32',
    Latitude: '35.5488372',
    Longitude: '-119.1960423',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:48:32',
    SlNo: '125',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:48:32 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:46:56',
    Latitude: '35.5723204',
    Longitude: '-119.2015642',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '16000 Driver Rd, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:46:56',
    SlNo: '126',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:46:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:45:56',
    Latitude: '35.5876209',
    Longitude: '-119.2046957',
    Speed: '29.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Bakersfield, CA 93308, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:45:56',
    SlNo: '127',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:45:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:44:56',
    Latitude: '35.6007332',
    Longitude: '-119.2110999',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '31975 Famoso Rd, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:44:56',
    SlNo: '128',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:44:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:44:36',
    Latitude: '35.6059422',
    Longitude: '-119.2119808',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:44:36',
    SlNo: '129',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:44:36 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:42:56',
    Latitude: '35.6334003',
    Longitude: '-119.2168546',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:42:56',
    SlNo: '130',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:42:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:41:56',
    Latitude: '35.6476899',
    Longitude: '-119.2208538',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:41:56',
    SlNo: '131',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:41:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:40:56',
    Latitude: '35.6530661',
    Longitude: '-119.2215391',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:40:56',
    SlNo: '132',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:40:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:40:12',
    Latitude: '35.653249',
    Longitude: '-119.2216112',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:40:12',
    SlNo: '133',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:40:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:38:56',
    Latitude: '35.6532527',
    Longitude: '-119.2216091',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:38:56',
    SlNo: '134',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:38:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:38:32',
    Latitude: '35.6532113',
    Longitude: '-119.2216136',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:38:32',
    SlNo: '135',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:38:32 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:36:56',
    Latitude: '35.6532112',
    Longitude: '-119.2216142',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:36:56',
    SlNo: '136',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:36:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:35:56',
    Latitude: '35.6532106',
    Longitude: '-119.2216128',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:35:56',
    SlNo: '137',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:35:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:34:56',
    Latitude: '35.6532231',
    Longitude: '-119.221609',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:34:56',
    SlNo: '138',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:34:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:34:36',
    Latitude: '35.6532249',
    Longitude: '-119.2216058',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:34:36',
    SlNo: '139',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:34:36 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:32:56',
    Latitude: '35.6538062',
    Longitude: '-119.2217227',
    Speed: '3.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:32:56',
    SlNo: '140',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:32:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:31:56',
    Latitude: '35.6556632',
    Longitude: '-119.2221289',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:31:56',
    SlNo: '141',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:31:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:30:57',
    Latitude: '35.6689398',
    Longitude: '-119.2251619',
    Speed: '22.37 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '611 Frontage Rd, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:30:57',
    SlNo: '142',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:30:57 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:30:12',
    Latitude: '35.6794458',
    Longitude: '-119.2279244',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '114 Frontage Rd, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:30:12',
    SlNo: '143',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:30:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:28:56',
    Latitude: '35.6984406',
    Longitude: '-119.2320138',
    Speed: '13.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:28:56',
    SlNo: '144',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:28:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:28:33',
    Latitude: '35.7046415',
    Longitude: '-119.2334808',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '12245 Davis Rd, McFarland, CA 93250, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:28:33',
    SlNo: '145',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:28:33 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:26:56',
    Latitude: '35.7273081',
    Longitude: '-119.2387811',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Delano, CA 93215, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:26:56',
    SlNo: '146',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:26:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:25:56',
    Latitude: '35.7410053',
    Longitude: '-119.2419577',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11287 Garzoli Ave, Delano, CA 93215, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:25:56',
    SlNo: '147',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:25:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:24:56',
    Latitude: '35.7550233',
    Longitude: '-119.2473876',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '617 Alderson Ave, Delano, CA 93215, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:24:56',
    SlNo: '148',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:24:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:22:56',
    Latitude: '35.7843106',
    Longitude: '-119.2519711',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2030 High St., Delano, CA 93215, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:22:56',
    SlNo: '149',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:22:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:21:56',
    Latitude: '35.7978684',
    Longitude: '-119.2543825',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Delano, CA 93215, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:21:56',
    SlNo: '150',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:21:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:20:56',
    Latitude: '35.8132082',
    Longitude: '-119.2579331',
    Speed: '22.37 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Earlimart, CA 93219, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:20:56',
    SlNo: '151',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:20:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:20:12',
    Latitude: '35.8240464',
    Longitude: '-119.2587263',
    Speed: '38.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Delano, CA 93215, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:20:12',
    SlNo: '152',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:20:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:19:08',
    Latitude: '35.8420543',
    Longitude: '-119.2643538',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Delano, CA 93215, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:19:08',
    SlNo: '153',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:19:08 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:18:32',
    Latitude: '35.8477019',
    Longitude: '-119.2658773',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Delano, CA 93215, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:18:32',
    SlNo: '154',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:18:32 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:16:56',
    Latitude: '35.8724242',
    Longitude: '-119.2708076',
    Speed: '34.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '218 RR 1, Earlimart, CA 93219, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:16:56',
    SlNo: '155',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:16:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:15:48',
    Latitude: '35.8887159',
    Longitude: '-119.2732537',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '551 N Market Rd, Earlimart, CA 93219, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:15:48',
    SlNo: '156',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:15:48 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:14:56',
    Latitude: '35.900617',
    Longitude: '-119.2779642',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Earlimart, CA 93219, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:14:56',
    SlNo: '157',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:14:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:14:35',
    Latitude: '35.9055763',
    Longitude: '-119.2791111',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Earlimart, CA 93219, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:14:35',
    SlNo: '158',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:14:35 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:12:56',
    Latitude: '35.9308617',
    Longitude: '-119.2831135',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7724 Rd 128, Pixley, CA 93256, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:12:56',
    SlNo: '159',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:12:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:12:00',
    Latitude: '35.9449242',
    Longitude: '-119.2883856',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Pixley, CA 93256, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:12:00',
    SlNo: '160',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:12:00 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:10:56',
    Latitude: '35.9604418',
    Longitude: '-119.2909366',
    Speed: '20.51 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Pixley, CA 93256, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:10:56',
    SlNo: '161',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:10:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:10:11',
    Latitude: '35.9702575',
    Longitude: '-119.292124',
    Speed: '38.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '283 Center St, Pixley, CA 93256, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:10:11',
    SlNo: '162',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:10:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:08:56',
    Latitude: '35.9881659',
    Longitude: '-119.2982651',
    Speed: '13.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2439 N Park Dr, Pixley, CA 93256, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:08:56',
    SlNo: '163',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:08:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:08:31',
    Latitude: '35.9942618',
    Longitude: '-119.2996354',
    Speed: '50.95 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3073 Rd 122, Pixley, CA 93256, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:08:31',
    SlNo: '164',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:08:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:06:55',
    Latitude: '36.0185313',
    Longitude: '-119.305049',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Tipton, CA 93272, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:06:55',
    SlNo: '165',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:06:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:05:48',
    Latitude: '36.0363078',
    Longitude: '-119.3092394',
    Speed: '25.48 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Tipton, CA 93272, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:05:48',
    SlNo: '166',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:05:48 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:04:55',
    Latitude: '36.0484822',
    Longitude: '-119.3114933',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Tipton, CA 93272, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:04:55',
    SlNo: '167',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:04:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:04:36',
    Latitude: '36.0528171',
    Longitude: '-119.3110163',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '836 Burnett Rd, Tipton, CA 93272, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:04:36',
    SlNo: '168',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:04:36 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:02:55',
    Latitude: '36.0723217',
    Longitude: '-119.3171914',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Tipton, CA 93272, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:02:55',
    SlNo: '169',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:02:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:01:55',
    Latitude: '36.0865002',
    Longitude: '-119.320624',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11337 Ave 164, Tipton, CA 93272, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:01:55',
    SlNo: '170',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:01:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:00:48',
    Latitude: '36.1031753',
    Longitude: '-119.3230917',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:00:48',
    SlNo: '171',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:00:48 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 02:00:11',
    Latitude: '36.1121513',
    Longitude: '-119.3239064',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17721 Rd 112, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '02:00:11',
    SlNo: '172',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 02:00:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:58:48',
    Latitude: '36.1325186',
    Longitude: '-119.3257866',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10800 Ave 184, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:58:48',
    SlNo: '173',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:58:48 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:58:31',
    Latitude: '36.1367294',
    Longitude: '-119.3268198',
    Speed: '53.44 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:58:31',
    SlNo: '174',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:58:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:56:48',
    Latitude: '36.1619957',
    Longitude: '-119.3327103',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4870 S K St, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:56:48',
    SlNo: '175',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:56:48 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:55:47',
    Latitude: '36.1765493',
    Longitude: '-119.3294021',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3018 S Blackstone St, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:55:47',
    SlNo: '176',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:55:47 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:54:35',
    Latitude: '36.1941566',
    Longitude: '-119.3286521',
    Speed: '50.95 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1175 Security Ct, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:54:35',
    SlNo: '177',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:54:35 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:52:55',
    Latitude: '36.2188815',
    Longitude: '-119.3307171',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '833 N Lynora St, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:52:55',
    SlNo: '178',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:52:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:52:31',
    Latitude: '36.2236505',
    Longitude: '-119.3335533',
    Speed: '18.02 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '981 E Prosperity Ave, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:52:31',
    SlNo: '179',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:52:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:51:55',
    Latitude: '36.2312288',
    Longitude: '-119.3391623',
    Speed: '41.01 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1820 N Gem St, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:51:55',
    SlNo: '180',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:51:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:50:31',
    Latitude: '36.2482106',
    Longitude: '-119.3515843',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:50:31',
    SlNo: '181',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:50:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:50:11',
    Latitude: '36.2523952',
    Longitude: '-119.3546934',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'North Tulare Public Cemetery, 4572 J St, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:50:11',
    SlNo: '182',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:50:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:48:31',
    Latitude: '36.2732586',
    Longitude: '-119.3690991',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '26487 CA-99, Tulare, CA 93274, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:48:31',
    SlNo: '183',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:48:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:47:31',
    Latitude: '36.2868004',
    Longitude: '-119.378078',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '27446 CA-99, Visalia, CA 93277, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:47:31',
    SlNo: '184',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:47:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:46:31',
    Latitude: '36.300441',
    Longitude: '-119.3866269',
    Speed: '22.99 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Visalia, CA 93277, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:46:31',
    SlNo: '185',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:46:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:45:52',
    Latitude: '36.3101637',
    Longitude: '-119.3932875',
    Speed: '41.01 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Visalia, CA 93277, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:45:52',
    SlNo: '186',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:45:52 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:44:31',
    Latitude: '36.3264698',
    Longitude: '-119.4072801',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:44:31',
    SlNo: '187',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:44:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:42:55',
    Latitude: '36.346143',
    Longitude: '-119.4237133',
    Speed: '13.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '30643 CA-99, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:42:55',
    SlNo: '188',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:42:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:42:31',
    Latitude: '36.3515603',
    Longitude: '-119.427523',
    Speed: '16.78 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6600 Betty Dr, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:42:31',
    SlNo: '189',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:42:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:41:55',
    Latitude: '36.3594689',
    Longitude: '-119.4286794',
    Speed: '33.55 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '31120 West St, Goshen, CA 93227, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:41:55',
    SlNo: '190',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:41:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:40:31',
    Latitude: '36.3743861',
    Longitude: '-119.4367706',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:40:31',
    SlNo: '191',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:40:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:40:11',
    Latitude: '36.3757674',
    Longitude: '-119.4376578',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:40:11',
    SlNo: '192',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:40:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:38:31',
    Latitude: '36.3810432',
    Longitude: '-119.4411673',
    Speed: '7.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '32447 Rd 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:38:31',
    SlNo: '193',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:38:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:37:31',
    Latitude: '36.3841172',
    Longitude: '-119.4431989',
    Speed: '6.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '32403-32401 Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:37:31',
    SlNo: '194',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:37:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:25:06',
    Latitude: '37.9355501',
    Longitude: '-121.2863628',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:25:06',
    SlNo: '724',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:25:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:23:45',
    Latitude: '37.9355501',
    Longitude: '-121.2863627',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:23:45',
    SlNo: '725',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:23:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:23:06',
    Latitude: '37.9355488',
    Longitude: '-121.2863637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:23:06',
    SlNo: '726',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:23:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:22:45',
    Latitude: '37.9355496',
    Longitude: '-121.2863631',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:22:45',
    SlNo: '727',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:22:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:21:08',
    Latitude: '37.9355606',
    Longitude: '-121.286367',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:21:08',
    SlNo: '728',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:21:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:20:09',
    Latitude: '37.935525',
    Longitude: '-121.2863903',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:20:09',
    SlNo: '729',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:20:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:19:06',
    Latitude: '37.9355244',
    Longitude: '-121.2863912',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:19:06',
    SlNo: '730',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:19:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:17:28',
    Latitude: '37.9355223',
    Longitude: '-121.2863909',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:17:28',
    SlNo: '731',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:17:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:17:05',
    Latitude: '37.9355238',
    Longitude: '-121.2863918',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:17:05',
    SlNo: '732',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:17:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:15:06',
    Latitude: '37.9355444',
    Longitude: '-121.2863773',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:15:06',
    SlNo: '733',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:15:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:13:46',
    Latitude: '37.9355381',
    Longitude: '-121.2863822',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:13:46',
    SlNo: '734',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:13:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:13:05',
    Latitude: '37.9355377',
    Longitude: '-121.2863802',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:13:05',
    SlNo: '735',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:13:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:12:46',
    Latitude: '37.9355407',
    Longitude: '-121.2863797',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:12:46',
    SlNo: '736',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:12:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:11:05',
    Latitude: '37.9355374',
    Longitude: '-121.2863669',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:11:05',
    SlNo: '737',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:11:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:09:32',
    Latitude: '37.9355535',
    Longitude: '-121.2863724',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:09:32',
    SlNo: '738',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:09:32 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:08:56',
    Latitude: '37.9355541',
    Longitude: '-121.2863725',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:08:56',
    SlNo: '739',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:08:56 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:08:25',
    Latitude: '37.9355552',
    Longitude: '-121.286374',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:08:25',
    SlNo: '740',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:08:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:06:51',
    Latitude: '37.9355732',
    Longitude: '-121.2863674',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:06:51',
    SlNo: '741',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:06:51 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:05:51',
    Latitude: '37.9355737',
    Longitude: '-121.2863668',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:05:51',
    SlNo: '742',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:05:51 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:04:51',
    Latitude: '37.9355759',
    Longitude: '-121.2863679',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:04:51',
    SlNo: '743',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:04:51 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:03:54',
    Latitude: '37.9355742',
    Longitude: '-121.286367',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:03:54',
    SlNo: '744',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:03:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:02:46',
    Latitude: '37.9355762',
    Longitude: '-121.2863667',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:02:46',
    SlNo: '745',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:02:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:02:10',
    Latitude: '37.935576',
    Longitude: '-121.2863669',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:02:10',
    SlNo: '746',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:02:10 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:00:45',
    Latitude: '37.9355767',
    Longitude: '-121.2863658',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:00:45',
    SlNo: '747',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:00:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:59:25',
    Latitude: '37.9355766',
    Longitude: '-121.2863676',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:59:25',
    SlNo: '748',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:59:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:58:45',
    Latitude: '37.9355763',
    Longitude: '-121.2863672',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:58:45',
    SlNo: '749',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:58:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:58:25',
    Latitude: '37.9355767',
    Longitude: '-121.2863674',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:58:25',
    SlNo: '750',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:58:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:56:45',
    Latitude: '37.9355765',
    Longitude: '-121.2863678',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:56:45',
    SlNo: '751',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:56:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:55:45',
    Latitude: '37.935576',
    Longitude: '-121.2863679',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:55:45',
    SlNo: '752',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:55:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:54:45',
    Latitude: '37.9355757',
    Longitude: '-121.286368',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:54:45',
    SlNo: '753',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:54:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:53:45',
    Latitude: '37.935574',
    Longitude: '-121.2863681',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:53:45',
    SlNo: '754',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:53:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:52:30',
    Latitude: '37.9355733',
    Longitude: '-121.2863685',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:52:30',
    SlNo: '755',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:52:30 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:52:10',
    Latitude: '37.9355748',
    Longitude: '-121.286368',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:52:10',
    SlNo: '756',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:52:10 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:50:30',
    Latitude: '37.9355638',
    Longitude: '-121.286378',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:50:30',
    SlNo: '757',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:50:30 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:49:25',
    Latitude: '37.9355633',
    Longitude: '-121.2863791',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:49:25',
    SlNo: '758',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:49:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:48:31',
    Latitude: '37.9355509',
    Longitude: '-121.2863989',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:48:31',
    SlNo: '759',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:48:31 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:48:12',
    Latitude: '37.9355487',
    Longitude: '-121.2864005',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:48:12',
    SlNo: '760',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:48:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:46:30',
    Latitude: '37.9355474',
    Longitude: '-121.2863998',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:46:30',
    SlNo: '761',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:46:30 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:45:28',
    Latitude: '37.935549',
    Longitude: '-121.2863996',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:45:28',
    SlNo: '762',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:45:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:44:30',
    Latitude: '37.9355496',
    Longitude: '-121.2863985',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:44:30',
    SlNo: '763',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:44:30 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:43:45',
    Latitude: '37.935549',
    Longitude: '-121.2863997',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:43:45',
    SlNo: '764',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:43:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:42:28',
    Latitude: '37.9355478',
    Longitude: '-121.2864007',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:42:28',
    SlNo: '765',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:42:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:42:10',
    Latitude: '37.935548',
    Longitude: '-121.2864006',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:42:10',
    SlNo: '766',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:42:10 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:40:28',
    Latitude: '37.9355481',
    Longitude: '-121.2864004',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:40:28',
    SlNo: '767',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:40:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:39:25',
    Latitude: '37.9355478',
    Longitude: '-121.2864006',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:39:25',
    SlNo: '768',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:39:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:38:28',
    Latitude: '37.9355492',
    Longitude: '-121.2863989',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:38:28',
    SlNo: '769',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:38:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:38:09',
    Latitude: '37.9355495',
    Longitude: '-121.2863978',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:38:09',
    SlNo: '770',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:38:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:36:28',
    Latitude: '37.9355493',
    Longitude: '-121.286401',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:36:28',
    SlNo: '771',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:36:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:35:28',
    Latitude: '37.9355501',
    Longitude: '-121.2863977',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:35:28',
    SlNo: '772',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:35:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:34:26',
    Latitude: '37.9355503',
    Longitude: '-121.2863973',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:34:26',
    SlNo: '773',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:34:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:33:45',
    Latitude: '37.93555',
    Longitude: '-121.2864122',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:33:45',
    SlNo: '774',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:33:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:32:26',
    Latitude: '37.9355501',
    Longitude: '-121.2864121',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:32:26',
    SlNo: '775',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:32:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:32:09',
    Latitude: '37.9355503',
    Longitude: '-121.2864132',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:32:09',
    SlNo: '776',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:32:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:30:26',
    Latitude: '37.935567',
    Longitude: '-121.2863891',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:30:26',
    SlNo: '777',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:30:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:29:25',
    Latitude: '37.9355648',
    Longitude: '-121.2863988',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:29:25',
    SlNo: '778',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:29:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:28:26',
    Latitude: '37.9355651',
    Longitude: '-121.2863524',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:28:26',
    SlNo: '779',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:28:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:28:09',
    Latitude: '37.935566',
    Longitude: '-121.2863528',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:28:09',
    SlNo: '780',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:28:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:26:26',
    Latitude: '37.9355371',
    Longitude: '-121.2863597',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:26:26',
    SlNo: '781',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:26:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:25:26',
    Latitude: '37.9355407',
    Longitude: '-121.2863608',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:25:26',
    SlNo: '782',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:25:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:24:26',
    Latitude: '37.935536',
    Longitude: '-121.2863624',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:24:26',
    SlNo: '783',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:24:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:23:45',
    Latitude: '37.9355368',
    Longitude: '-121.2863616',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:23:45',
    SlNo: '784',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:23:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:22:26',
    Latitude: '37.9355321',
    Longitude: '-121.2863732',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:22:26',
    SlNo: '785',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:22:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:22:09',
    Latitude: '37.9355071',
    Longitude: '-121.2863852',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:22:09',
    SlNo: '786',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:22:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:20:26',
    Latitude: '37.9355078',
    Longitude: '-121.286384',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:20:26',
    SlNo: '787',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:20:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:19:25',
    Latitude: '37.9355072',
    Longitude: '-121.2863838',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:19:25',
    SlNo: '788',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:19:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:18:26',
    Latitude: '37.9355037',
    Longitude: '-121.2863834',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:18:26',
    SlNo: '789',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:18:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:18:09',
    Latitude: '37.9355061',
    Longitude: '-121.2863831',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:18:09',
    SlNo: '790',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:18:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:16:26',
    Latitude: '37.9355045',
    Longitude: '-121.2863829',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:16:26',
    SlNo: '791',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:16:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:15:26',
    Latitude: '37.9355216',
    Longitude: '-121.2863685',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:15:26',
    SlNo: '792',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:15:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:14:26',
    Latitude: '37.9355276',
    Longitude: '-121.2863697',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:14:26',
    SlNo: '793',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:14:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:13:46',
    Latitude: '37.9355553',
    Longitude: '-121.2863713',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:13:46',
    SlNo: '794',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:13:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:12:25',
    Latitude: '37.9355538',
    Longitude: '-121.2863706',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:12:25',
    SlNo: '795',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:12:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:12:09',
    Latitude: '37.9355541',
    Longitude: '-121.2863703',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:12:09',
    SlNo: '796',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:12:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:10:26',
    Latitude: '37.9355531',
    Longitude: '-121.2863709',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:10:26',
    SlNo: '797',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:10:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:09:25',
    Latitude: '37.9355418',
    Longitude: '-121.2863973',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:09:25',
    SlNo: '798',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:09:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:08:26',
    Latitude: '37.9355371',
    Longitude: '-121.2865283',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:08:26',
    SlNo: '799',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:08:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:08:09',
    Latitude: '37.9355364',
    Longitude: '-121.2865291',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:08:09',
    SlNo: '800',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:08:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:06:25',
    Latitude: '37.9355368',
    Longitude: '-121.2865286',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:06:25',
    SlNo: '801',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:06:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:05:25',
    Latitude: '37.935537',
    Longitude: '-121.2865283',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:05:25',
    SlNo: '802',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:05:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:04:25',
    Latitude: '37.9355362',
    Longitude: '-121.2865286',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:04:25',
    SlNo: '803',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:04:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:03:45',
    Latitude: '37.9355353',
    Longitude: '-121.2865316',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:03:45',
    SlNo: '804',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:03:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:02:25',
    Latitude: '37.9355352',
    Longitude: '-121.2865312',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:02:25',
    SlNo: '805',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:02:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:02:09',
    Latitude: '37.9355353',
    Longitude: '-121.2865309',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:02:09',
    SlNo: '806',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:02:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 15:00:25',
    Latitude: '37.935531',
    Longitude: '-121.2865178',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '15:00:25',
    SlNo: '807',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 03:00:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:59:25',
    Latitude: '37.9355313',
    Longitude: '-121.2865176',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:59:25',
    SlNo: '808',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:59:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:58:25',
    Latitude: '37.9355331',
    Longitude: '-121.2865171',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:58:25',
    SlNo: '809',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:58:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:58:09',
    Latitude: '37.935532',
    Longitude: '-121.28652',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:58:09',
    SlNo: '810',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:58:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:56:25',
    Latitude: '37.9355308',
    Longitude: '-121.2865179',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:56:25',
    SlNo: '811',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:56:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:55:25',
    Latitude: '37.9355204',
    Longitude: '-121.2865338',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:55:25',
    SlNo: '812',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:55:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:54:25',
    Latitude: '37.9355201',
    Longitude: '-121.286534',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:54:25',
    SlNo: '813',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:54:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:53:45',
    Latitude: '37.9355205',
    Longitude: '-121.2865331',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:53:45',
    SlNo: '814',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:53:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:52:25',
    Latitude: '37.9355207',
    Longitude: '-121.2865321',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:52:25',
    SlNo: '815',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:52:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:52:09',
    Latitude: '37.9355207',
    Longitude: '-121.2865312',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:52:09',
    SlNo: '816',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:52:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:50:25',
    Latitude: '37.9355212',
    Longitude: '-121.2865332',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:50:25',
    SlNo: '817',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:50:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:49:25',
    Latitude: '37.9355205',
    Longitude: '-121.2865336',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:49:25',
    SlNo: '818',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:49:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:48:25',
    Latitude: '37.935519',
    Longitude: '-121.2865328',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:48:25',
    SlNo: '819',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:48:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:48:09',
    Latitude: '37.9355196',
    Longitude: '-121.2865332',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:48:09',
    SlNo: '820',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:48:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:46:25',
    Latitude: '37.9355203',
    Longitude: '-121.286534',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:46:25',
    SlNo: '821',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:46:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:45:25',
    Latitude: '37.9355196',
    Longitude: '-121.2865334',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:45:25',
    SlNo: '822',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:45:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:44:25',
    Latitude: '37.9355197',
    Longitude: '-121.2865335',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:44:25',
    SlNo: '823',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:44:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:43:47',
    Latitude: '37.9355456',
    Longitude: '-121.2865619',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:43:47',
    SlNo: '824',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:43:47 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:42:25',
    Latitude: '37.9355381',
    Longitude: '-121.2865183',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:42:25',
    SlNo: '825',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:42:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:42:09',
    Latitude: '37.9355352',
    Longitude: '-121.2865139',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:42:09',
    SlNo: '826',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:42:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:40:25',
    Latitude: '37.9355627',
    Longitude: '-121.286533',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:40:25',
    SlNo: '827',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:40:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:39:25',
    Latitude: '37.9355981',
    Longitude: '-121.2865631',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:39:25',
    SlNo: '828',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:39:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:38:24',
    Latitude: '37.9355469',
    Longitude: '-121.2865505',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:38:24',
    SlNo: '829',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:38:24 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:38:09',
    Latitude: '37.935557',
    Longitude: '-121.2865597',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:38:09',
    SlNo: '830',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:38:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:36:24',
    Latitude: '37.9355556',
    Longitude: '-121.2865618',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:36:24',
    SlNo: '831',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:36:24 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:35:09',
    Latitude: '37.935545',
    Longitude: '-121.2865324',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:35:09',
    SlNo: '832',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:35:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:34:12',
    Latitude: '37.9355256',
    Longitude: '-121.28654',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:34:12',
    SlNo: '833',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:34:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:33:44',
    Latitude: '37.9355233',
    Longitude: '-121.2865381',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:33:44',
    SlNo: '834',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:33:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:32:08',
    Latitude: '37.9355283',
    Longitude: '-121.2865332',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:32:08',
    SlNo: '835',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:32:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:31:02',
    Latitude: '37.9355277',
    Longitude: '-121.286534',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:31:02',
    SlNo: '836',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:31:02 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:30:08',
    Latitude: '37.935527',
    Longitude: '-121.2865354',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:30:08',
    SlNo: '837',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:30:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:29:24',
    Latitude: '37.935527',
    Longitude: '-121.2865347',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:29:24',
    SlNo: '838',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:29:24 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:28:08',
    Latitude: '37.9355213',
    Longitude: '-121.2865119',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:28:08',
    SlNo: '839',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:28:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:27:50',
    Latitude: '37.9355212',
    Longitude: '-121.286512',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:27:50',
    SlNo: '840',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:27:50 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:26:08',
    Latitude: '37.9354783',
    Longitude: '-121.2865401',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:26:08',
    SlNo: '841',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:26:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:25:08',
    Latitude: '37.9354171',
    Longitude: '-121.286531',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:25:08',
    SlNo: '842',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:25:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:24:16',
    Latitude: '37.9355166',
    Longitude: '-121.2865388',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:24:16',
    SlNo: '843',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:24:16 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:23:47',
    Latitude: '37.9355249',
    Longitude: '-121.2865745',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:23:47',
    SlNo: '844',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:23:47 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:22:08',
    Latitude: '37.9355224',
    Longitude: '-121.2865761',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:22:08',
    SlNo: '845',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:22:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:21:02',
    Latitude: '37.935524',
    Longitude: '-121.286574',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:21:02',
    SlNo: '846',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:21:02 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:20:08',
    Latitude: '37.935562',
    Longitude: '-121.2865407',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:20:08',
    SlNo: '847',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:20:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:19:24',
    Latitude: '37.9355634',
    Longitude: '-121.2865389',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:19:24',
    SlNo: '848',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:19:24 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:18:08',
    Latitude: '37.9355601',
    Longitude: '-121.2865417',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:18:08',
    SlNo: '849',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:18:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:17:48',
    Latitude: '37.9355439',
    Longitude: '-121.2865368',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:17:48',
    SlNo: '850',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:17:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:16:08',
    Latitude: '37.9355465',
    Longitude: '-121.2865436',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:16:08',
    SlNo: '851',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:16:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:15:08',
    Latitude: '37.9355776',
    Longitude: '-121.286509',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:15:08',
    SlNo: '852',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:15:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:14:02',
    Latitude: '37.9355505',
    Longitude: '-121.2865054',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:14:02',
    SlNo: '853',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:14:02 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:13:44',
    Latitude: '37.9355585',
    Longitude: '-121.2865227',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:13:44',
    SlNo: '854',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:13:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:12:02',
    Latitude: '37.9355353',
    Longitude: '-121.2865123',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:12:02',
    SlNo: '855',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:12:02 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:11:11',
    Latitude: '37.9355107',
    Longitude: '-121.2865063',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:11:11',
    SlNo: '856',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:11:11 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:09:49',
    Latitude: '37.9355121',
    Longitude: '-121.2865049',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:09:49',
    SlNo: '857',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:09:49 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:09:24',
    Latitude: '37.9354846',
    Longitude: '-121.286486',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:09:24',
    SlNo: '858',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:09:24 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:07:48',
    Latitude: '37.9355075',
    Longitude: '-121.2865629',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:07:48',
    SlNo: '859',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:07:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:06:44',
    Latitude: '37.935521',
    Longitude: '-121.2865646',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:06:44',
    SlNo: '860',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:06:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:05:44',
    Latitude: '37.9355184',
    Longitude: '-121.2865624',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:05:44',
    SlNo: '861',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:05:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:05:08',
    Latitude: '37.9355186',
    Longitude: '-121.2865638',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:05:08',
    SlNo: '862',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:05:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:03:44',
    Latitude: '37.9355127',
    Longitude: '-121.2865473',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:03:44',
    SlNo: '863',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:03:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:02:04',
    Latitude: '37.9354832',
    Longitude: '-121.2865355',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:02:04',
    SlNo: '864',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:02:04 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:01:44',
    Latitude: '37.9354765',
    Longitude: '-121.2865315',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:01:44',
    SlNo: '865',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:01:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 14:01:02',
    Latitude: '37.9354837',
    Longitude: '-121.2865294',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '14:01:02',
    SlNo: '866',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 02:01:02 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:59:44',
    Latitude: '37.935511',
    Longitude: '-121.2865244',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:59:44',
    SlNo: '867',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:59:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:59:24',
    Latitude: '37.9355119',
    Longitude: '-121.2865541',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:59:24',
    SlNo: '868',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:59:24 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:57:44',
    Latitude: '37.9354787',
    Longitude: '-121.2865756',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:57:44',
    SlNo: '869',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:57:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:56:44',
    Latitude: '37.9354751',
    Longitude: '-121.2865741',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:56:44',
    SlNo: '870',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:56:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:55:44',
    Latitude: '37.9355086',
    Longitude: '-121.2865559',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:55:44',
    SlNo: '871',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:55:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:55:08',
    Latitude: '37.9354893',
    Longitude: '-121.2865427',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:55:08',
    SlNo: '872',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:55:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:53:44',
    Latitude: '37.9354868',
    Longitude: '-121.2865423',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:53:44',
    SlNo: '873',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:53:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:52:02',
    Latitude: '37.9355236',
    Longitude: '-121.2865386',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:52:02',
    SlNo: '874',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:52:02 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:51:44',
    Latitude: '37.935537',
    Longitude: '-121.2865061',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:51:44',
    SlNo: '875',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:51:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:51:02',
    Latitude: '37.9354896',
    Longitude: '-121.2865278',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:51:02',
    SlNo: '876',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:51:02 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:49:44',
    Latitude: '37.9355095',
    Longitude: '-121.2864614',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:49:44',
    SlNo: '877',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:49:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:49:21',
    Latitude: '37.9355075',
    Longitude: '-121.2864622',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:49:21',
    SlNo: '878',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:49:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:47:44',
    Latitude: '37.9355096',
    Longitude: '-121.2864646',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:47:44',
    SlNo: '879',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:47:44 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:46:40',
    Latitude: '37.9355078',
    Longitude: '-121.2864658',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:46:40',
    SlNo: '880',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:46:40 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:45:40',
    Latitude: '37.9356',
    Longitude: '-121.2864483',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:45:40',
    SlNo: '881',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:45:40 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:45:04',
    Latitude: '37.9356529',
    Longitude: '-121.2864247',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:45:04',
    SlNo: '882',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:45:04 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:43:43',
    Latitude: '37.9358583',
    Longitude: '-121.2861699',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:43:43',
    SlNo: '883',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:43:43 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:42:03',
    Latitude: '37.9359024',
    Longitude: '-121.2859973',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:42:03',
    SlNo: '884',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:42:03 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:41:40',
    Latitude: '37.9359005',
    Longitude: '-121.2859973',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:41:40',
    SlNo: '885',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:41:40 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:41:02',
    Latitude: '37.9358615',
    Longitude: '-121.2860729',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:41:02',
    SlNo: '886',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:41:02 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:39:45',
    Latitude: '37.9358',
    Longitude: '-121.2862958',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:39:45',
    SlNo: '887',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:39:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:39:21',
    Latitude: '37.9357925',
    Longitude: '-121.2862804',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:39:21',
    SlNo: '888',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:39:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:37:40',
    Latitude: '37.9357919',
    Longitude: '-121.2862763',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:37:40',
    SlNo: '889',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:37:40 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:36:24',
    Latitude: '37.9357929',
    Longitude: '-121.2862784',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:36:24',
    SlNo: '890',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:36:24 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:35:27',
    Latitude: '37.9357929',
    Longitude: '-121.2862784',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:35:27',
    SlNo: '891',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:35:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:34:39',
    Latitude: '37.9358526',
    Longitude: '-121.2860868',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:34:39',
    SlNo: '892',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:34:39 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:33:21',
    Latitude: '37.9358955',
    Longitude: '-121.2856194',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1635 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:33:21',
    SlNo: '893',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:33:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:33:00',
    Latitude: '37.9358961',
    Longitude: '-121.2856196',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1635 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:33:00',
    SlNo: '894',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:33:00 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:31:21',
    Latitude: '37.9360633',
    Longitude: '-121.286061',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1605 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:31:21',
    SlNo: '895',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:31:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:30:20',
    Latitude: '37.9360655',
    Longitude: '-121.2860602',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1605 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:30:20',
    SlNo: '896',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:30:20 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:29:21',
    Latitude: '37.9360637',
    Longitude: '-121.2860635',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:29:21',
    SlNo: '897',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:29:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:27:21',
    Latitude: '37.9360751',
    Longitude: '-121.2860804',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:27:21',
    SlNo: '898',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:27:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:26:23',
    Latitude: '37.936075',
    Longitude: '-121.2860541',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1605 El Dorado St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:26:23',
    SlNo: '899',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:26:23 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:36:31',
    Latitude: '36.3867264',
    Longitude: '-119.444922',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '32403-32401 Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:36:31',
    SlNo: '195',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:36:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:35:43',
    Latitude: '36.3918612',
    Longitude: '-119.4482995',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:35:43',
    SlNo: '196',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:35:43 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:35:13',
    Latitude: '36.3932355',
    Longitude: '-119.4492027',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:35:13',
    SlNo: '197',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:35:13 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:35:12',
    Latitude: '36.3932355',
    Longitude: '-119.4492027',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:35:12',
    SlNo: '198',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:35:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:35:12',
    Latitude: '36.3932355',
    Longitude: '-119.4492027',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:35:12',
    SlNo: '199',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:35:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:34:31',
    Latitude: '36.3946297',
    Longitude: '-119.4501144',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:34:31',
    SlNo: '200',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:34:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:34:12',
    Latitude: '36.395122',
    Longitude: '-119.4504795',
    Speed: '13.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:34:12',
    SlNo: '201',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:34:12 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:32:31',
    Latitude: '36.4007462',
    Longitude: '-119.4542123',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Drv 60, Visalia, CA 93291, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:32:31',
    SlNo: '202',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:32:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:31:31',
    Latitude: '36.4138846',
    Longitude: '-119.4629158',
    Speed: '34.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Drv 60, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:31:31',
    SlNo: '203',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:31:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:30:31',
    Latitude: '36.4285923',
    Longitude: '-119.4726122',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4445 Ave 352, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:30:31',
    SlNo: '204',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:30:31 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:30:11',
    Latitude: '36.433614',
    Longitude: '-119.4759188',
    Speed: '59.03 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:30:11',
    SlNo: '205',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:30:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:28:30',
    Latitude: '36.4587381',
    Longitude: '-119.4930569',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '36748 Dr 39, Traver, CA 93673, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:28:30',
    SlNo: '206',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:28:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:27:30',
    Latitude: '36.4737666',
    Longitude: '-119.5024204',
    Speed: '33.55 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3125 Ave 376, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:27:30',
    SlNo: '207',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:27:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:26:30',
    Latitude: '36.4871965',
    Longitude: '-119.5139927',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '38406 Avenue 384, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:26:30',
    SlNo: '208',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:26:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:25:46',
    Latitude: '36.4942575',
    Longitude: '-119.5256965',
    Speed: '42.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '38743 CA-99, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:25:46',
    SlNo: '209',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:25:46 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:24:30',
    Latitude: '36.5058',
    Longitude: '-119.54682',
    Speed: '14.29 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '39262 Rd 12, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:24:30',
    SlNo: '210',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:24:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:24:06',
    Latitude: '36.510539',
    Longitude: '-119.5528524',
    Speed: '54.06 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1247 Earl St, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:24:06',
    SlNo: '211',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:24:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:22:33',
    Latitude: '36.5293888',
    Longitude: '-119.5754836',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'S Bethel Ave, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:22:33',
    SlNo: '212',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:22:33 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:21:26',
    Latitude: '36.5414987',
    Longitude: '-119.5899062',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '13443 S Amber Ave, Kingsburg, CA 93631, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:21:26',
    SlNo: '213',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:21:26 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:20:30',
    Latitude: '36.5526407',
    Longitude: '-119.6030857',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1507 Dockery Ave, Selma, CA 93662, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:20:30',
    SlNo: '214',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:20:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:20:11',
    Latitude: '36.5564835',
    Longitude: '-119.6076111',
    Speed: '53.44 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1853 Blaine Ave, Selma, CA 93662, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:20:11',
    SlNo: '215',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:20:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:18:30',
    Latitude: '36.5758535',
    Longitude: '-119.6290338',
    Speed: '32.93 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3142 S Highland Ave, Selma, CA 93662, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:18:30',
    SlNo: '216',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:18:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:17:30',
    Latitude: '36.5878773',
    Longitude: '-119.6422525',
    Speed: '33.55 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Selma, CA 93662, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:17:30',
    SlNo: '217',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:17:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:16:29',
    Latitude: '36.6000672',
    Longitude: '-119.6555796',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7494 E Springfield Ave, Fowler, CA 93625, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:16:29',
    SlNo: '218',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:16:29 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:15:47',
    Latitude: '36.6078891',
    Longitude: '-119.6641466',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8829 S Temperance Ave, Fowler, CA 93625, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:15:47',
    SlNo: '219',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:15:47 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:14:29',
    Latitude: '36.6222565',
    Longitude: '-119.6798727',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '300 W Peach St, Fowler, CA 93625, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:14:29',
    SlNo: '220',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:14:29 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:14:06',
    Latitude: '36.6265965',
    Longitude: '-119.6845688',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '104 S Fowler Ave, Fowler, CA 93625, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:14:06',
    SlNo: '221',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:14:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:12:41',
    Latitude: '36.6453756',
    Longitude: '-119.7051321',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Fresno, CA 93725, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:12:41',
    SlNo: '222',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:12:41 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:11:28',
    Latitude: '36.6584405',
    Longitude: '-119.7194014',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5353 S Peach Ave, Fresno, CA 93725, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:11:28',
    SlNo: '223',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:11:28 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:10:29',
    Latitude: '36.669681',
    Longitude: '-119.7317592',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3173 E Malaga Ave, Fresno, CA 93725, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:10:29',
    SlNo: '224',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:10:29 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:10:10',
    Latitude: '36.6733669',
    Longitude: '-119.7359561',
    Speed: '52.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4312 S Chestnut Ave, Fresno, CA 93725, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:10:10',
    SlNo: '225',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:10:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:10:10',
    Latitude: '36.6914272',
    Longitude: '-119.7577689',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3100 S Parkway Dr, Fresno, CA 93725, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:10:10',
    SlNo: '226',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:10:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:10:10',
    Latitude: '36.7026409',
    Longitude: '-119.7700361',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2712 S 4th St, Fresno, CA 93725, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:10:10',
    SlNo: '227',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:10:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:10:10',
    Latitude: '36.7139416',
    Longitude: '-119.7811055',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2593 E Church Ave, Fresno, CA 93706, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:10:10',
    SlNo: '228',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:10:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:10:10',
    Latitude: '36.7210696',
    Longitude: '-119.7883967',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2138 S Holly Ave, Fresno, CA 93706, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:10:10',
    SlNo: '229',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:10:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:10:10',
    Latitude: '36.7343161',
    Longitude: '-119.8029555',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1543 E St, Fresno, CA 93706, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:10:10',
    SlNo: '230',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:10:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:10:10',
    Latitude: '36.7374475',
    Longitude: '-119.8064118',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1833 E St, Fresno, CA 93706, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:10:10',
    SlNo: '231',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:10:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:01:26',
    Latitude: '36.7631956',
    Longitude: '-119.8333638',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2041 W McKinley Ave, Fresno, CA 93728, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:01:26',
    SlNo: '232',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:01:26 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 01:00:25',
    Latitude: '36.7763237',
    Longitude: '-119.8431218',
    Speed: '38.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2837 N Parkway Dr, Fresno, CA 93722, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '01:00:25',
    SlNo: '233',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 01:00:25 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:59:10',
    Latitude: '36.790122',
    Longitude: '-119.8588295',
    Speed: '23.61 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3811 N Golden State Blvd, Fresno, CA 93722, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:59:10',
    SlNo: '234',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:59:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:58:25',
    Latitude: '36.7968546',
    Longitude: '-119.8702454',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4120 N Blythe Ave, Fresno, CA 93722, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:58:25',
    SlNo: '235',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:58:25 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:57:36',
    Latitude: '36.8056045',
    Longitude: '-119.8833114',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4833 N Parkway Dr, Fresno, CA 93722, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:57:36',
    SlNo: '236',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:57:36 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:56:10',
    Latitude: '36.8196928',
    Longitude: '-119.9018521',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5965 N Golden State Blvd, Fresno, CA 93722, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:56:10',
    SlNo: '237',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:56:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:55:45',
    Latitude: '36.8240065',
    Longitude: '-119.9072712',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6585 W Stuart Ave, Fresno, CA 93723, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:55:45',
    SlNo: '238',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:55:45 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:54:10',
    Latitude: '36.8401725',
    Longitude: '-119.9276546',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Fresno, CA 93722, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:54:10',
    SlNo: '239',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:54:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:53:10',
    Latitude: '36.8488607',
    Longitude: '-119.9437722',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6382 Golden State Blvd, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:53:10',
    SlNo: '240',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:53:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:52:10',
    Latitude: '36.861039',
    Longitude: '-119.9559367',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7628 Golden State Blvd, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:52:10',
    SlNo: '241',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:52:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:49:10',
    Latitude: '36.8951597',
    Longitude: '-119.9913427',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Blvd, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:49:10',
    SlNo: '242',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:49:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:48:10',
    Latitude: '36.9068278',
    Longitude: '-120.0035818',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10696 Golden State Blvd, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:48:10',
    SlNo: '243',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:48:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:47:30',
    Latitude: '36.9147822',
    Longitude: '-120.0118367',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:47:30',
    SlNo: '244',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:47:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:46:10',
    Latitude: '36.9287473',
    Longitude: '-120.0261835',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '12390 Golden State Blvd, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:46:10',
    SlNo: '245',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:46:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:45:46',
    Latitude: '36.9330029',
    Longitude: '-120.0305696',
    Speed: '42.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:45:46',
    SlNo: '246',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:45:46 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:44:10',
    Latitude: '36.9484894',
    Longitude: '-120.0476168',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2074 Varbella Park, Madera, CA 93638, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:44:10',
    SlNo: '247',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:44:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:43:06',
    Latitude: '36.9570393',
    Longitude: '-120.0602997',
    Speed: '25.48 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '305 S I St, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:43:06',
    SlNo: '248',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:43:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:42:11',
    Latitude: '36.9666172',
    Longitude: '-120.0701321',
    Speed: '6.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '780 N I St, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:42:11',
    SlNo: '249',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:42:11 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:41:56',
    Latitude: '36.9696652',
    Longitude: '-120.071837',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1029 Riverview Dr, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:41:56',
    SlNo: '250',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:41:56 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:40:10',
    Latitude: '36.9874986',
    Longitude: '-120.0903894',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2832 Golden State Blvd, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:40:10',
    SlNo: '251',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:40:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:39:06',
    Latitude: '36.9973074',
    Longitude: '-120.1034795',
    Speed: '14.29 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:39:06',
    SlNo: '252',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:39:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:38:06',
    Latitude: '37.0027231',
    Longitude: '-120.1088486',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17415 Sharon Blvd, Madera, CA 93638, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:38:06',
    SlNo: '253',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:38:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:37:30',
    Latitude: '37.0047721',
    Longitude: '-120.1113124',
    Speed: '15.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17577 Rd 24, Madera, CA 93638, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:37:30',
    SlNo: '254',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:37:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:36:06',
    Latitude: '37.0101203',
    Longitude: '-120.1178319',
    Speed: '3.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17870 Golden State Blvd, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:36:06',
    SlNo: '255',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:36:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:35:06',
    Latitude: '37.011404',
    Longitude: '-120.1194288',
    Speed: '3.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17870 Golden State Blvd, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:35:06',
    SlNo: '256',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:35:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:34:06',
    Latitude: '37.0126433',
    Longitude: '-120.1210089',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '23431 Ave 18, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:34:06',
    SlNo: '257',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:34:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:33:06',
    Latitude: '37.014499',
    Longitude: '-120.1233274',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:33:06',
    SlNo: '258',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:33:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:31:54',
    Latitude: '37.0208238',
    Longitude: '-120.131591',
    Speed: '15.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '18555 Pistachio Dr, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:31:54',
    SlNo: '259',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:31:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:31:22',
    Latitude: '37.026515',
    Longitude: '-120.137876',
    Speed: '42.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '22480 Ave 19, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:31:22',
    SlNo: '260',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:31:22 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:29:54',
    Latitude: '37.0406161',
    Longitude: '-120.1566622',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '20100 Fairmead Blvd, Madera, CA 93638, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:29:54',
    SlNo: '261',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:29:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:28:43',
    Latitude: '37.0523445',
    Longitude: '-120.169442',
    Speed: '24.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:28:43',
    SlNo: '262',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:28:43 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:27:54',
    Latitude: '37.0606502',
    Longitude: '-120.180268',
    Speed: '18.02 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '21346 CA-99, Madera, CA 93637, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:27:54',
    SlNo: '263',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:27:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:27:18',
    Latitude: '37.0667763',
    Longitude: '-120.1882052',
    Speed: '43.50 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:27:18',
    SlNo: '264',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:27:18 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:25:54',
    Latitude: '37.0819204',
    Longitude: '-120.2062895',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:25:54',
    SlNo: '265',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:25:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:24:14',
    Latitude: '37.1025504',
    Longitude: '-120.2207102',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:24:14',
    SlNo: '266',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:24:14 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:23:54',
    Latitude: '37.1061491',
    Longitude: '-120.2254387',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:23:54',
    SlNo: '267',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:23:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:23:14',
    Latitude: '37.1134317',
    Longitude: '-120.2349495',
    Speed: '41.01 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8785 Laguna Dr, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:23:14',
    SlNo: '268',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:23:14 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:21:54',
    Latitude: '37.1271239',
    Longitude: '-120.2528159',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '16283 Avenue 26, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:21:54',
    SlNo: '269',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:21:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:20:39',
    Latitude: '37.1401893',
    Longitude: '-120.2717481',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '26777 Chowchilla Blvd, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:20:39',
    SlNo: '270',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:20:39 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:19:54',
    Latitude: '37.1450316',
    Longitude: '-120.2815884',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:19:54',
    SlNo: '271',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:19:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:19:14',
    Latitude: '37.1519239',
    Longitude: '-120.289986',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Chowchilla, CA 93610, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:19:14',
    SlNo: '272',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:19:14 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:17:54',
    Latitude: '37.1667369',
    Longitude: '-120.3069521',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8199 CA-99, Le Grand, CA 95333, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:17:54',
    SlNo: '273',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:17:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:16:34',
    Latitude: '37.1819333',
    Longitude: '-120.3232531',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Le Grand, CA 95333, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:16:34',
    SlNo: '274',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:16:34 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:15:55',
    Latitude: '37.1893919',
    Longitude: '-120.3321928',
    Speed: '22.99 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Le Grand, CA 95333, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:15:55',
    SlNo: '275',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:15:55 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:15:10',
    Latitude: '37.1966606',
    Longitude: '-120.3429359',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1170 CA-99, Le Grand, CA 95333, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:15:10',
    SlNo: '276',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:15:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:13:54',
    Latitude: '37.2101323',
    Longitude: '-120.3599367',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Kahlon Rd, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:13:54',
    SlNo: '277',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:13:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:12:54',
    Latitude: '37.221835',
    Longitude: '-120.3716473',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:12:54',
    SlNo: '278',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:12:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:11:54',
    Latitude: '37.2309683',
    Longitude: '-120.3856255',
    Speed: '53.44 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:11:54',
    SlNo: '279',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:11:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:10:14',
    Latitude: '37.2490566',
    Longitude: '-120.4089063',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3134 CA-99, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:10:14',
    SlNo: '280',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:10:14 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:09:50',
    Latitude: '37.253256',
    Longitude: '-120.4141148',
    Speed: '18.02 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:09:50',
    SlNo: '281',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:09:50 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:09:15',
    Latitude: '37.2594618',
    Longitude: '-120.4217635',
    Speed: '41.01 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:09:15',
    SlNo: '282',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:09:15 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:07:51',
    Latitude: '37.2747778',
    Longitude: '-120.437789',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'E Mission Ave, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:07:51',
    SlNo: '283',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:07:51 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:06:34',
    Latitude: '37.2861276',
    Longitude: '-120.4542299',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2000 E Childs Ave, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:06:34',
    SlNo: '284',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:06:34 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:05:50',
    Latitude: '37.2921058',
    Longitude: '-120.4636613',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1033 Motel Dr, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:05:50',
    SlNo: '285',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:05:50 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:05:10',
    Latitude: '37.2954632',
    Longitude: '-120.4733795',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '37 E 15th St, Merced, CA 95340, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:05:10',
    SlNo: '286',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:05:10 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:03:51',
    Latitude: '37.3004035',
    Longitude: '-120.4934188',
    Speed: '24.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1010 W 13th St, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:03:51',
    SlNo: '287',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:03:51 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:02:50',
    Latitude: '37.3054303',
    Longitude: '-120.5070745',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1735 CA-140, Merced, CA 95341, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:02:50',
    SlNo: '288',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:02:50 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:01:50',
    Latitude: '37.3131526',
    Longitude: '-120.5182369',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2060 W 16th St, Merced, CA 95348, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:01:50',
    SlNo: '289',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:01:50 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:01:06',
    Latitude: '37.3164684',
    Longitude: '-120.5291929',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1949 Beachwood Dr, Merced, CA 95348, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:01:06',
    SlNo: '290',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:01:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 00:00:05',
    Latitude: '37.3238831',
    Longitude: '-120.5493719',
    Speed: '29.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2550 Ashby Rd, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/10/2021 00:00:00',
    Time: '00:00:05',
    SlNo: '291',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/10/2021 12:00:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:58:50',
    Latitude: '37.3304576',
    Longitude: '-120.5649438',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:58:50',
    SlNo: '292',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:58:50 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:57:50',
    Latitude: '37.3350356',
    Longitude: '-120.5798096',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5140 E Broadway Ave, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:57:50',
    SlNo: '293',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:57:50 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:56:35',
    Latitude: '37.3398795',
    Longitude: '-120.597532',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '45 Commerce Ave, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:56:35',
    SlNo: '294',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:56:35 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:55:50',
    Latitude: '37.3427379',
    Longitude: '-120.6085377',
    Speed: '18.02 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '901 Commerce Ave, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:55:50',
    SlNo: '295',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:55:50 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:55:10',
    Latitude: '37.3464116',
    Longitude: '-120.618569',
    Speed: '32.93 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1899 Bell Ln, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:55:10',
    SlNo: '296',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:55:10 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:53:50',
    Latitude: '37.3553699',
    Longitude: '-120.6350918',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4517 Bert Crane Rd, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:53:50',
    SlNo: '297',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:53:50 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:52:30',
    Latitude: '37.3629969',
    Longitude: '-120.6554547',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9200 Westside Blvd, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:52:30',
    SlNo: '298',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:52:30 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:51:50',
    Latitude: '37.3666798',
    Longitude: '-120.6660563',
    Speed: '23.61 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Atwater, CA 95301, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:51:50',
    SlNo: '299',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:51:50 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:51:06',
    Latitude: '37.3721791',
    Longitude: '-120.6787666',
    Speed: '39.15 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Livingston, CA 95334, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:51:06',
    SlNo: '300',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:51:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:49:50',
    Latitude: '37.3792917',
    Longitude: '-120.7005886',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Livingston, CA 95334, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:49:50',
    SlNo: '301',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:49:50 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:48:27',
    Latitude: '37.387882',
    Longitude: '-120.7223244',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1334 Court St, Livingston, CA 95334, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:48:27',
    SlNo: '302',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:48:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:47:46',
    Latitude: '37.3890188',
    Longitude: '-120.7338437',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '483 Joseph Gallo Ct, Livingston, CA 95334, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:47:46',
    SlNo: '303',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:47:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:47:10',
    Latitude: '37.3953206',
    Longitude: '-120.7405706',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7329 N Pacific Ave, Livingston, CA 95334, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:47:10',
    SlNo: '304',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:47:10 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:45:46',
    Latitude: '37.4132229',
    Longitude: '-120.755877',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8538 Sycamore St, Delhi, CA 95315, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:45:46',
    SlNo: '305',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:45:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:44:47',
    Latitude: '37.4236205',
    Longitude: '-120.7686692',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9257 Stephens St, Delhi, CA 95315, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:44:47',
    SlNo: '306',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:44:47 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:43:46',
    Latitude: '37.4350887',
    Longitude: '-120.7811803',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '16255 Beatty St, Delhi, CA 95315, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:43:46',
    SlNo: '307',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:43:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:42:06',
    Latitude: '37.4528483',
    Longitude: '-120.8009308',
    Speed: '9.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11297 E Clausen Rd, Turlock, CA 95380, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:42:06',
    SlNo: '308',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:42:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:41:46',
    Latitude: '37.4561597',
    Longitude: '-120.8050002',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Turlock, CA 95380, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:41:46',
    SlNo: '309',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:41:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:41:06',
    Latitude: '37.4605047',
    Longitude: '-120.8154584',
    Speed: '38.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Turlock, CA 95380, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:41:06',
    SlNo: '310',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:41:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:39:46',
    Latitude: '37.4687752',
    Longitude: '-120.8364869',
    Speed: '39.15 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1101 E Greenway Ave, Turlock, CA 95380, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:39:46',
    SlNo: '311',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:39:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:38:26',
    Latitude: '37.4772276',
    Longitude: '-120.8578663',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '724 W Linwood Ave, Turlock, CA 95380, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:38:26',
    SlNo: '312',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:38:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:37:51',
    Latitude: '37.4841737',
    Longitude: '-120.8656951',
    Speed: '22.37 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1020 S Walnut Rd, Turlock, CA 95380, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:37:51',
    SlNo: '313',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:37:51 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:37:01',
    Latitude: '37.4941108',
    Longitude: '-120.8709561',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '201 N Tully Rd, Turlock, CA 95380, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:37:01',
    SlNo: '314',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:37:01 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:35:47',
    Latitude: '37.5117671',
    Longitude: '-120.8803531',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2000 Auto Mall Dr, Turlock, CA 95380, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:35:47',
    SlNo: '315',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:35:47 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:34:12',
    Latitude: '37.5339347',
    Longitude: '-120.8908463',
    Speed: '16.78 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4401 N Golden State Blvd, Turlock, CA 95382, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:34:12',
    SlNo: '316',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:34:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:33:41',
    Latitude: '37.5406415',
    Longitude: '-120.8963065',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4318 W Warner Rd, Turlock, CA 95382, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:33:41',
    SlNo: '317',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:33:41 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:33:06',
    Latitude: '37.5463617',
    Longitude: '-120.9038145',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6424 Taylor Ct, Turlock, CA 95382, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:33:06',
    SlNo: '318',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:33:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:31:40',
    Latitude: '37.5618204',
    Longitude: '-120.923144',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3924 Starlite Dr, Ceres, CA 95307, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:31:40',
    SlNo: '319',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:31:40 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:30:27',
    Latitude: '37.5752351',
    Longitude: '-120.9379781',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5000 Prairie Flower Rd, Ceres, CA 95307, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:30:27',
    SlNo: '320',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:30:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:29:39',
    Latitude: '37.5836012',
    Longitude: '-120.9480616',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2601 Don Pedro Rd, Ceres, CA 95307, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:29:39',
    SlNo: '321',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:29:39 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:29:19',
    Latitude: '37.5873509',
    Longitude: '-120.9523259',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3427 Railroad Ave, Ceres, CA 95307, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:29:19',
    SlNo: '322',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:29:19 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:27:39',
    Latitude: '37.6062057',
    Longitude: '-120.9737797',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1648 Herndon Ave, Ceres, CA 95307, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:27:39',
    SlNo: '323',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:27:39 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:26:39',
    Latitude: '37.6164936',
    Longitude: '-120.9847001',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '959 S 9th St, Modesto, CA 95351, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:26:39',
    SlNo: '324',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:26:39 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:25:26',
    Latitude: '37.6265312',
    Longitude: '-120.9968772',
    Speed: '39.15 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '402 Cliff Dr, Modesto, CA 95351, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:25:26',
    SlNo: '325',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:25:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:24:06',
    Latitude: '37.6419622',
    Longitude: '-121.0106195',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '305 N Madison St, Modesto, CA 95351, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:24:06',
    SlNo: '326',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:24:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:23:32',
    Latitude: '37.6480288',
    Longitude: '-121.0190883',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '903 Kansas Ave, Modesto, CA 95351, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:23:32',
    SlNo: '327',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:23:32 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:23:09',
    Latitude: '37.6517561',
    Longitude: '-121.0234317',
    Speed: '44.12 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1127 Lone Palm Ave, Modesto, CA 95351, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:23:09',
    SlNo: '328',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:23:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:21:26',
    Latitude: '37.6697768',
    Longitude: '-121.0377994',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Sisk Rd & Plaza Pkw, Modesto, CA 95350, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:21:26',
    SlNo: '329',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:21:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:20:41',
    Latitude: '37.6804507',
    Longitude: '-121.0503252',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2828 Sisk Rd, Modesto, CA 95350, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:20:41',
    SlNo: '330',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:20:41 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:19:26',
    Latitude: '37.6912352',
    Longitude: '-121.0633868',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Sisk Rd & Vintage Dr, Modesto, CA 95356, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:19:26',
    SlNo: '331',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:19:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:19:06',
    Latitude: '37.6947823',
    Longitude: '-121.0674167',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3835 N Dakota Ave, Modesto, CA 95358, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:19:06',
    SlNo: '332',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:19:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:17:26',
    Latitude: '37.7124372',
    Longitude: '-121.087395',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5030 Salida Blvd, Salida, CA 95368, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:17:26',
    SlNo: '333',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:17:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:16:26',
    Latitude: '37.7227717',
    Longitude: '-121.1008033',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5807 Garden View Way, Salida, CA 95368, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:16:26',
    SlNo: '334',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:16:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:15:26',
    Latitude: '37.7331868',
    Longitude: '-121.1135859',
    Speed: '19.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Army Corps Park, 710 Reynolds Ave, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:15:26',
    SlNo: '335',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:15:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:14:45',
    Latitude: '37.7399729',
    Longitude: '-121.1218067',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '166 Frontage Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:14:45',
    SlNo: '336',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:14:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:13:26',
    Latitude: '37.7504096',
    Longitude: '-121.1395015',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1010 Moffat Blvd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:13:26',
    SlNo: '337',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:13:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:13:05',
    Latitude: '37.7518963',
    Longitude: '-121.1423016',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1040 Moffat Blvd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:13:05',
    SlNo: '338',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:13:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:11:34',
    Latitude: '37.7568603',
    Longitude: '-121.1422308',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1595 Santos Ave, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:11:34',
    SlNo: '339',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:11:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:10:25',
    Latitude: '37.7565863',
    Longitude: '-121.1405479',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1493 Santos Ave, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:10:25',
    SlNo: '340',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:10:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:09:26',
    Latitude: '37.7558505',
    Longitude: '-121.1420939',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:09:26',
    SlNo: '341',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:09:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:09:05',
    Latitude: '37.7557834',
    Longitude: '-121.1419155',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:09:05',
    SlNo: '342',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:09:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:07:26',
    Latitude: '37.7557574',
    Longitude: '-121.1419215',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:07:26',
    SlNo: '343',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:07:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:06:26',
    Latitude: '37.7557682',
    Longitude: '-121.1419173',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:06:26',
    SlNo: '344',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:06:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:05:26',
    Latitude: '37.7557718',
    Longitude: '-121.1419021',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:05:26',
    SlNo: '345',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:05:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:04:45',
    Latitude: '37.7557718',
    Longitude: '-121.1419022',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:04:45',
    SlNo: '346',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:04:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:03:26',
    Latitude: '37.7557744',
    Longitude: '-121.1419027',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:03:26',
    SlNo: '347',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:03:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:03:05',
    Latitude: '37.7558002',
    Longitude: '-121.1417711',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:03:05',
    SlNo: '348',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:03:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:01:26',
    Latitude: '37.7558283',
    Longitude: '-121.1415827',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:01:26',
    SlNo: '349',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:01:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 23:00:25',
    Latitude: '37.7560887',
    Longitude: '-121.1409356',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1553 W Colony Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '23:00:25',
    SlNo: '350',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 11:00:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:59:26',
    Latitude: '37.7568191',
    Longitude: '-121.1419454',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1595 Santos Ave, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:59:26',
    SlNo: '351',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:59:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:57:25',
    Latitude: '37.7537777',
    Longitude: '-121.1450894',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '22580 S Moffat Blvd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:57:25',
    SlNo: '352',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:57:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:56:25',
    Latitude: '37.7616143',
    Longitude: '-121.1590735',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '21692 CA-99, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:56:25',
    SlNo: '353',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:56:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:55:25',
    Latitude: '37.7703494',
    Longitude: '-121.1738411',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '20782 99 Frontage Rd, Ripon, CA 95366, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:55:25',
    SlNo: '354',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:55:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:54:45',
    Latitude: '37.7770223',
    Longitude: '-121.1825689',
    Speed: '26.72 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2101 Moffat Blvd, Manteca, CA 95336, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:54:45',
    SlNo: '355',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:54:45 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:53:25',
    Latitude: '37.7832311',
    Longitude: '-121.1967496',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '217 Moffat Blvd, Manteca, CA 95336, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:53:25',
    SlNo: '356',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:53:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:53:05',
    Latitude: '37.7832368',
    Longitude: '-121.201829',
    Speed: '47.22 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1339 Van Ryn Ave, Manteca, CA 95337, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:53:05',
    SlNo: '357',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:53:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:51:25',
    Latitude: '37.783176',
    Longitude: '-121.2307691',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1398 Union Rd, Manteca, CA 95337, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:51:25',
    SlNo: '358',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:51:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:50:28',
    Latitude: '37.7830805',
    Longitude: '-121.2479583',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1401 Hazelnut Way, Manteca, CA 95337, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:50:28',
    SlNo: '359',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:50:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:49:25',
    Latitude: '37.7834114',
    Longitude: '-121.2652671',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '19240 McKinley Ave, Manteca, CA 95337, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:49:25',
    SlNo: '360',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:49:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:49:05',
    Latitude: '37.7848689',
    Longitude: '-121.2709954',
    Speed: '47.22 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '19365 McKinley Ave, Manteca, CA 95337, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:49:05',
    SlNo: '361',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:49:05 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:47:25',
    Latitude: '37.788907',
    Longitude: '-121.2991611',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'CA-120, Lathrop, CA 95330, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:47:25',
    SlNo: '362',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:47:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:46:26',
    Latitude: '37.7866395',
    Longitude: '-121.3049118',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Mossdale Crossing Regional Park, 19091 S Manthey Rd, Lathrop, CA 95330, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:46:26',
    SlNo: '363',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:46:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:45:31',
    Latitude: '37.7949654',
    Longitude: '-121.3009693',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '18250 Golden Valley Park, Lathrop, CA 95330, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:45:31',
    SlNo: '364',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:45:31 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:44:51',
    Latitude: '37.8030596',
    Longitude: '-121.2988925',
    Speed: '33.55 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5354 Manthey Rd, Lathrop, CA 95330, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:44:51',
    SlNo: '365',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:44:51 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:43:25',
    Latitude: '37.8186669',
    Longitude: '-121.2932434',
    Speed: '9.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '15919 S Manthey Rd, Lathrop, CA 95330, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:43:25',
    SlNo: '366',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:43:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:43:06',
    Latitude: '37.8229083',
    Longitude: '-121.2916141',
    Speed: '47.22 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '15318 S Harlan Rd, Lathrop, CA 95330, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:43:06',
    SlNo: '367',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:43:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:41:25',
    Latitude: '37.8450942',
    Longitude: '-121.284847',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '39 Klo Rd, Lathrop, CA 95330, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:41:25',
    SlNo: '368',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:41:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:40:26',
    Latitude: '37.8591195',
    Longitude: '-121.2813628',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10848 S Harlan Rd, French Camp, CA 95231, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:40:26',
    SlNo: '369',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:40:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:39:25',
    Latitude: '37.8723539',
    Longitude: '-121.279067',
    Speed: '6.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9241 S Harlan Rd, French Camp, CA 95231, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:39:25',
    SlNo: '370',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:39:25 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 22:39:06',
    Latitude: '37.87531',
    Longitude: '-121.277377',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9504 S Harlan Rd, French Camp, CA 95231, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '22:39:06',
    SlNo: '371',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:39:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:25:21',
    Latitude: '37.9360815',
    Longitude: '-121.2860663',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:25:21',
    SlNo: '900',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:25:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:24:39',
    Latitude: '37.9360793',
    Longitude: '-121.2860719',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:24:39',
    SlNo: '901',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:24:39 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:23:21',
    Latitude: '37.936082',
    Longitude: '-121.2860679',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:23:21',
    SlNo: '902',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:23:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:23:00',
    Latitude: '37.9360803',
    Longitude: '-121.286078',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:23:00',
    SlNo: '903',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:23:00 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:21:21',
    Latitude: '37.9361018',
    Longitude: '-121.2860896',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:21:21',
    SlNo: '904',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:21:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:20:21',
    Latitude: '37.936109',
    Longitude: '-121.2860957',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:20:21',
    SlNo: '905',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:20:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:19:30',
    Latitude: '37.936093',
    Longitude: '-121.2861179',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:19:30',
    SlNo: '906',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:19:30 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:19:01',
    Latitude: '37.9360852',
    Longitude: '-121.2861074',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627a S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:19:01',
    SlNo: '907',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:19:01 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:17:21',
    Latitude: '37.9362716',
    Longitude: '-121.28617',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1566 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:17:21',
    SlNo: '908',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:17:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:16:21',
    Latitude: '37.9389233',
    Longitude: '-121.2874303',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1334 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:16:21',
    SlNo: '909',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:16:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:15:21',
    Latitude: '37.938923',
    Longitude: '-121.2874303',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1334 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:15:21',
    SlNo: '910',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:15:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:14:40',
    Latitude: '37.9391661',
    Longitude: '-121.2875107',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1325 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:14:40',
    SlNo: '911',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:14:40 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:13:21',
    Latitude: '37.9443145',
    Longitude: '-121.288902',
    Speed: '4.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '823 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:13:21',
    SlNo: '912',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:13:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:13:01',
    Latitude: '37.946648',
    Longitude: '-121.2895334',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '540 S Center St, Stockton, CA 95203, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:13:01',
    SlNo: '913',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:13:01 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:11:21',
    Latitude: '37.950438',
    Longitude: '-121.2903803',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '183 El Dorado St, Stockton, CA 95202, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:11:21',
    SlNo: '914',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:11:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:10:21',
    Latitude: '37.9506552',
    Longitude: '-121.2890843',
    Speed: '6.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '222 El Dorado St, Stockton, CA 95202, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:10:21',
    SlNo: '915',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:10:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:09:21',
    Latitude: '37.9510696',
    Longitude: '-121.285185',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '212 S Sutter St, Stockton, CA 95203, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:09:21',
    SlNo: '916',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:09:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:09:01',
    Latitude: '37.9519212',
    Longitude: '-121.2802262',
    Speed: '38.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '160 S Grant St, Stockton, CA 95202, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:09:01',
    SlNo: '917',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:09:01 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:07:21',
    Latitude: '37.9585871',
    Longitude: '-121.2584144',
    Speed: '22.37 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2293 E Myrtle St, Stockton, CA 95205, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:07:21',
    SlNo: '918',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:07:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:06:21',
    Latitude: '37.9615094',
    Longitude: '-121.2452456',
    Speed: '18.02 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3232 E Lafayette St, Stockton, CA 95205, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:06:21',
    SlNo: '919',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:06:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:05:21',
    Latitude: '37.9701598',
    Longitude: '-121.2460222',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Golden State Hwy, Stockton, CA 95205, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:05:21',
    SlNo: '920',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:05:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:04:40',
    Latitude: '37.9780043',
    Longitude: '-121.2493025',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1500 N Shaw Rd, Stockton, CA 95215, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:04:40',
    SlNo: '921',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:04:40 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:03:21',
    Latitude: '37.9926059',
    Longitude: '-121.2530159',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3661 Cherokee Rd, Stockton, CA 95205, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:03:21',
    SlNo: '922',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:03:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:03:01',
    Latitude: '37.9965853',
    Longitude: '-121.2545945',
    Speed: '37.90 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4045 Wilcox Rd, Stockton, CA 95215, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:03:01',
    SlNo: '923',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:03:01 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:01:21',
    Latitude: '38.0147285',
    Longitude: '-121.2582278',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5480 CA-99, Stockton, CA 95212, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:01:21',
    SlNo: '924',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:01:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 13:00:21',
    Latitude: '38.0263004',
    Longitude: '-121.2584036',
    Speed: '24.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4415 Gnekow Dr, Stockton, CA 95212, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '13:00:21',
    SlNo: '925',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 01:00:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 12:59:21',
    Latitude: '38.0383823',
    Longitude: '-121.2585456',
    Speed: '40.39 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4339 99 Frontage Rd, Stockton, CA 95212, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '12:59:21',
    SlNo: '926',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 12:59:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 12:57:47',
    Latitude: '38.0578161',
    Longitude: '-121.2596732',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4520 E 8 Mile Rd, Morada, CA 95212, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '12:57:47',
    SlNo: '927',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 12:57:47 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 12:57:21',
    Latitude: '38.0630879',
    Longitude: '-121.2597602',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11200 CA-99, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '12:57:21',
    SlNo: '928',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 12:57:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 12:56:21',
    Latitude: '38.0755537',
    Longitude: '-121.259996',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11644 99 Frontage Rd, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '12:56:21',
    SlNo: '929',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 12:56:21 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 12:55:26',
    Latitude: '38.0882915',
    Longitude: '-121.2602116',
    Speed: '18.02 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '12200 CA-99, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '12:55:26',
    SlNo: '930',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 12:55:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 12:54:40',
    Latitude: '38.0969525',
    Longitude: '-121.2602964',
    Speed: '32.93 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2917 S Cherokee Ln, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '12:54:40',
    SlNo: '931',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 12:54:40 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 12:53:20',
    Latitude: '38.1127028',
    Longitude: '-121.2588061',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1700 S Cherokee Ln, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '12:53:20',
    SlNo: '932',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 12:53:20 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 12:53:00',
    Latitude: '38.1164635',
    Longitude: '-121.2580135',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '71 E Kettleman Ln, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '12:53:00',
    SlNo: '933',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 12:53:00 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:37:52',
    Latitude: '38.1280895',
    Longitude: '-121.2710395',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:37:52',
    SlNo: '934',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:37:52 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:36:34',
    Latitude: '38.1280889',
    Longitude: '-121.27104',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:36:34',
    SlNo: '935',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:36:34 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:35:54',
    Latitude: '38.1280892',
    Longitude: '-121.2710388',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:35:54',
    SlNo: '936',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:35:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:34:34',
    Latitude: '38.1280891',
    Longitude: '-121.2710366',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:34:34',
    SlNo: '937',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:34:34 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:34:14',
    Latitude: '38.1280891',
    Longitude: '-121.2710397',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:34:14',
    SlNo: '938',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:34:14 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:32:34',
    Latitude: '38.1280865',
    Longitude: '-121.27104',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:32:34',
    SlNo: '939',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:32:34 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:31:34',
    Latitude: '38.1280871',
    Longitude: '-121.2710411',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:31:34',
    SlNo: '940',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:31:34 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:30:25',
    Latitude: '38.1280877',
    Longitude: '-121.2710206',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:30:25',
    SlNo: '941',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:30:25 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:30:06',
    Latitude: '38.1280888',
    Longitude: '-121.2710203',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:30:06',
    SlNo: '942',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:30:06 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:28:25',
    Latitude: '38.1280764',
    Longitude: '-121.2710195',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:28:25',
    SlNo: '943',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:28:25 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:27:25',
    Latitude: '38.1280817',
    Longitude: '-121.2710069',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:27:25',
    SlNo: '944',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:27:25 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:26:14',
    Latitude: '38.1280883',
    Longitude: '-121.2710119',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:26:14',
    SlNo: '945',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:26:14 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:25:54',
    Latitude: '38.1280867',
    Longitude: '-121.2710116',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:25:54',
    SlNo: '946',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:25:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:24:14',
    Latitude: '38.128092',
    Longitude: '-121.2709963',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '104 Flora St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:24:14',
    SlNo: '947',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:24:14 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:23:20',
    Latitude: '38.1280913',
    Longitude: '-121.2709819',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:23:20',
    SlNo: '948',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:23:20 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:22:49',
    Latitude: '38.12809',
    Longitude: '-121.2709835',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:22:49',
    SlNo: '949',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:22:49 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:22:05',
    Latitude: '38.1280913',
    Longitude: '-121.2709799',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:22:05',
    SlNo: '950',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:22:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:21:34',
    Latitude: '38.1280914',
    Longitude: '-121.2709811',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:21:34',
    SlNo: '951',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:21:34 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:20:05',
    Latitude: '38.1280755',
    Longitude: '-121.2710355',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:20:05',
    SlNo: '952',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:20:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:19:05',
    Latitude: '38.1280773',
    Longitude: '-121.2710331',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:19:05',
    SlNo: '953',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:19:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:18:05',
    Latitude: '38.1280754',
    Longitude: '-121.2710338',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:18:05',
    SlNo: '954',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:18:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:17:05',
    Latitude: '38.1280765',
    Longitude: '-121.271035',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:17:05',
    SlNo: '955',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:17:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:15:54',
    Latitude: '38.1280755',
    Longitude: '-121.2710343',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:15:54',
    SlNo: '956',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:15:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:15:24',
    Latitude: '38.1281005',
    Longitude: '-121.271014',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:15:24',
    SlNo: '957',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:15:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:13:54',
    Latitude: '38.1280833',
    Longitude: '-121.2710186',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:13:54',
    SlNo: '958',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:13:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:12:33',
    Latitude: '38.1280913',
    Longitude: '-121.2710261',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:12:33',
    SlNo: '959',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:12:33 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:11:54',
    Latitude: '38.1280834',
    Longitude: '-121.2709901',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:11:54',
    SlNo: '960',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:11:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:11:33',
    Latitude: '38.1280861',
    Longitude: '-121.2709882',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:11:33',
    SlNo: '961',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:11:33 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:09:54',
    Latitude: '38.1281038',
    Longitude: '-121.2709885',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '104 Flora St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:09:54',
    SlNo: '962',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:09:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:08:54',
    Latitude: '38.1281036',
    Longitude: '-121.2709884',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '104 Flora St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:08:54',
    SlNo: '963',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:08:54 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:07:53',
    Latitude: '38.128077',
    Longitude: '-121.271032',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:07:53',
    SlNo: '964',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:07:53 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:06:53',
    Latitude: '38.1280762',
    Longitude: '-121.2710321',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:06:53',
    SlNo: '965',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:06:53 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:05:42',
    Latitude: '38.1280792',
    Longitude: '-121.2710313',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:05:42',
    SlNo: '966',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:05:42 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:05:24',
    Latitude: '38.1280775',
    Longitude: '-121.2710313',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:05:24',
    SlNo: '967',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:05:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:03:42',
    Latitude: '38.1280767',
    Longitude: '-121.2710312',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:03:42',
    SlNo: '968',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:03:42 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:02:30',
    Latitude: '38.1280776',
    Longitude: '-121.2710315',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:02:30',
    SlNo: '969',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:02:30 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:01:42',
    Latitude: '38.1280726',
    Longitude: '-121.2710032',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:01:42',
    SlNo: '970',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:01:42 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 10:01:24',
    Latitude: '38.1280718',
    Longitude: '-121.2710042',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '10:01:24',
    SlNo: '971',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 10:01:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:59:42',
    Latitude: '38.1280711',
    Longitude: '-121.2710032',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:59:42',
    SlNo: '972',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:59:42 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:58:42',
    Latitude: '38.1280705',
    Longitude: '-121.2710035',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:58:42',
    SlNo: '973',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:58:42 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:57:35',
    Latitude: '38.1280705',
    Longitude: '-121.2710044',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:57:35',
    SlNo: '974',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:57:35 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:56:35',
    Latitude: '38.1280726',
    Longitude: '-121.2710032',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:56:35',
    SlNo: '975',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:56:35 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:55:24',
    Latitude: '38.1280686',
    Longitude: '-121.2710042',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:55:24',
    SlNo: '976',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:55:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:55:05',
    Latitude: '38.1280736',
    Longitude: '-121.2709982',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:55:05',
    SlNo: '977',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:55:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:53:24',
    Latitude: '38.1280806',
    Longitude: '-121.2710155',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:53:24',
    SlNo: '978',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:53:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:52:24',
    Latitude: '38.1280801',
    Longitude: '-121.2710172',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:52:24',
    SlNo: '979',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:52:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:51:24',
    Latitude: '38.12808',
    Longitude: '-121.2710169',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:51:24',
    SlNo: '980',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:51:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:50:24',
    Latitude: '38.12808',
    Longitude: '-121.271017',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:50:24',
    SlNo: '981',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:50:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:49:24',
    Latitude: '38.1280805',
    Longitude: '-121.2710168',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:49:24',
    SlNo: '982',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:49:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:48:42',
    Latitude: '38.1280801',
    Longitude: '-121.2710174',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:48:42',
    SlNo: '983',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:48:42 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:47:26',
    Latitude: '38.1280796',
    Longitude: '-121.2710178',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:47:26',
    SlNo: '984',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:47:26 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:46:24',
    Latitude: '38.1280798',
    Longitude: '-121.2710171',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:46:24',
    SlNo: '985',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:46:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:45:24',
    Latitude: '38.1280718',
    Longitude: '-121.2710141',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:45:24',
    SlNo: '986',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:45:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:45:05',
    Latitude: '38.1280598',
    Longitude: '-121.2710086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:45:05',
    SlNo: '987',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:45:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:45:05',
    Latitude: '38.1280598',
    Longitude: '-121.2710086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:45:05',
    SlNo: '988',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:45:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:43:24',
    Latitude: '38.1280591',
    Longitude: '-121.2710087',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:43:24',
    SlNo: '989',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:43:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:42:24',
    Latitude: '38.1280592',
    Longitude: '-121.2710086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:42:24',
    SlNo: '990',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:42:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:41:24',
    Latitude: '38.1280588',
    Longitude: '-121.2710088',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:41:24',
    SlNo: '991',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:41:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:40:24',
    Latitude: '38.128059',
    Longitude: '-121.2710087',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:40:24',
    SlNo: '992',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:40:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:39:24',
    Latitude: '38.1280595',
    Longitude: '-121.271008',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:39:24',
    SlNo: '993',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:39:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:38:42',
    Latitude: '38.1280593',
    Longitude: '-121.2710086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:38:42',
    SlNo: '994',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:38:42 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:37:24',
    Latitude: '38.1280594',
    Longitude: '-121.2710086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:37:24',
    SlNo: '995',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:37:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:36:24',
    Latitude: '38.1280594',
    Longitude: '-121.2710086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:36:24',
    SlNo: '996',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:36:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:35:24',
    Latitude: '38.1280591',
    Longitude: '-121.271008',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:35:24',
    SlNo: '997',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:35:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:35:05',
    Latitude: '38.1280585',
    Longitude: '-121.2710072',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:35:05',
    SlNo: '998',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:35:05 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:33:24',
    Latitude: '38.1280595',
    Longitude: '-121.2710086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:33:24',
    SlNo: '999',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:33:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 09:32:24',
    Latitude: '38.1280597',
    Longitude: '-121.2710088',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '505 S Main St, Lodi, CA 95240, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '09:32:24',
    SlNo: '1000',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 09:32:24 AM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:38:28',
    Latitude: '37.9355581',
    Longitude: '-121.286371',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:38:28',
    SlNo: '548',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:38:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:37:14',
    Latitude: '37.9355565',
    Longitude: '-121.2863727',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:37:14',
    SlNo: '549',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:37:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:36:54',
    Latitude: '37.9355607',
    Longitude: '-121.286371',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:36:54',
    SlNo: '550',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:36:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:35:14',
    Latitude: '37.9355499',
    Longitude: '-121.2863833',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:35:14',
    SlNo: '551',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:35:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:34:14',
    Latitude: '37.9355499',
    Longitude: '-121.2863833',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:34:14',
    SlNo: '552',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:34:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:33:08',
    Latitude: '37.935549',
    Longitude: '-121.2863837',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:33:08',
    SlNo: '553',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:33:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:32:48',
    Latitude: '37.9355489',
    Longitude: '-121.2863849',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:32:48',
    SlNo: '554',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:32:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:31:08',
    Latitude: '37.9355505',
    Longitude: '-121.2863829',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:31:08',
    SlNo: '555',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:31:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:30:08',
    Latitude: '37.9355502',
    Longitude: '-121.2863831',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:30:08',
    SlNo: '556',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:30:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:28:54',
    Latitude: '37.935564',
    Longitude: '-121.2863747',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:28:54',
    SlNo: '557',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:28:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:28:28',
    Latitude: '37.9355695',
    Longitude: '-121.2863721',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:28:28',
    SlNo: '558',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:28:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:26:54',
    Latitude: '37.9355527',
    Longitude: '-121.2863755',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:26:54',
    SlNo: '559',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:26:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:25:54',
    Latitude: '37.9355539',
    Longitude: '-121.2863743',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:25:54',
    SlNo: '560',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:25:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:24:48',
    Latitude: '37.9355567',
    Longitude: '-121.2863967',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:24:48',
    SlNo: '561',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:24:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:24:14',
    Latitude: '37.9355839',
    Longitude: '-121.2863999',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:24:14',
    SlNo: '562',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:24:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:22:33',
    Latitude: '37.9355774',
    Longitude: '-121.2864138',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:22:33',
    SlNo: '563',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:22:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:21:34',
    Latitude: '37.9355687',
    Longitude: '-121.2863813',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:21:34',
    SlNo: '564',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:21:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:20:28',
    Latitude: '37.9356077',
    Longitude: '-121.2864278',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:20:28',
    SlNo: '565',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:20:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:20:08',
    Latitude: '37.9356136',
    Longitude: '-121.2864122',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:20:08',
    SlNo: '566',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:20:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:18:28',
    Latitude: '37.9356087',
    Longitude: '-121.2864115',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:18:28',
    SlNo: '567',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:18:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:17:28',
    Latitude: '37.9356145',
    Longitude: '-121.286403',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:17:28',
    SlNo: '568',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:17:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:16:14',
    Latitude: '37.935598',
    Longitude: '-121.2864025',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:16:14',
    SlNo: '569',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:16:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:15:54',
    Latitude: '37.935599',
    Longitude: '-121.2864025',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:15:54',
    SlNo: '570',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:15:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:14:14',
    Latitude: '37.9356103',
    Longitude: '-121.2863972',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:14:14',
    SlNo: '571',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:14:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:13:13',
    Latitude: '37.9355839',
    Longitude: '-121.2864046',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:13:13',
    SlNo: '572',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:13:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:12:10',
    Latitude: '37.935573',
    Longitude: '-121.2863825',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:12:10',
    SlNo: '573',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:12:10 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:11:33',
    Latitude: '37.9355731',
    Longitude: '-121.2863809',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:11:33',
    SlNo: '574',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:11:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:10:08',
    Latitude: '37.9355829',
    Longitude: '-121.2864098',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:10:08',
    SlNo: '575',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:10:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:08:53',
    Latitude: '37.9355499',
    Longitude: '-121.2863842',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:08:53',
    SlNo: '576',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:08:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:08:08',
    Latitude: '37.9355503',
    Longitude: '-121.286384',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:08:08',
    SlNo: '577',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:08:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:07:28',
    Latitude: '37.9355511',
    Longitude: '-121.2863839',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:07:28',
    SlNo: '578',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:07:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:05:53',
    Latitude: '37.9355484',
    Longitude: '-121.2863659',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:05:53',
    SlNo: '579',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:05:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:04:48',
    Latitude: '37.9355481',
    Longitude: '-121.2863647',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:04:48',
    SlNo: '580',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:04:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:03:54',
    Latitude: '37.9355493',
    Longitude: '-121.2863814',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:03:54',
    SlNo: '581',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:03:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:03:13',
    Latitude: '37.9355768',
    Longitude: '-121.286387',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:03:13',
    SlNo: '582',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:03:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:01:53',
    Latitude: '37.9355759',
    Longitude: '-121.2863866',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:01:53',
    SlNo: '583',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:01:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 19:01:33',
    Latitude: '37.935577',
    Longitude: '-121.286387',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '19:01:33',
    SlNo: '584',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 07:01:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:59:53',
    Latitude: '37.9355763',
    Longitude: '-121.2863868',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:59:53',
    SlNo: '585',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:59:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:58:53',
    Latitude: '37.9355785',
    Longitude: '-121.2863874',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:58:53',
    SlNo: '586',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:58:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:57:48',
    Latitude: '37.9355783',
    Longitude: '-121.2863874',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:57:48',
    SlNo: '587',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:57:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:57:28',
    Latitude: '37.9355776',
    Longitude: '-121.2863872',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:57:28',
    SlNo: '588',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:57:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:55:48',
    Latitude: '37.9355776',
    Longitude: '-121.2863871',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:55:48',
    SlNo: '589',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:55:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:54:48',
    Latitude: '37.9355774',
    Longitude: '-121.286387',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:54:48',
    SlNo: '590',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:54:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:53:33',
    Latitude: '37.9355783',
    Longitude: '-121.2863871',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:53:33',
    SlNo: '591',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:53:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:53:14',
    Latitude: '37.935578',
    Longitude: '-121.2863871',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:53:14',
    SlNo: '592',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:53:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:51:33',
    Latitude: '37.935577',
    Longitude: '-121.286387',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:51:33',
    SlNo: '593',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:51:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:50:33',
    Latitude: '37.9355762',
    Longitude: '-121.2863871',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:50:33',
    SlNo: '594',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:50:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:49:28',
    Latitude: '37.9355754',
    Longitude: '-121.2863864',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:49:28',
    SlNo: '595',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:49:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:48:54',
    Latitude: '37.9355761',
    Longitude: '-121.2863867',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:48:54',
    SlNo: '596',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:48:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:47:28',
    Latitude: '37.9355768',
    Longitude: '-121.2863869',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:47:28',
    SlNo: '597',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:47:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:46:13',
    Latitude: '37.9355763',
    Longitude: '-121.2863875',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:46:13',
    SlNo: '598',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:46:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:45:14',
    Latitude: '37.9355748',
    Longitude: '-121.286389',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:45:14',
    SlNo: '599',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:45:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:44:48',
    Latitude: '37.9355695',
    Longitude: '-121.2863988',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:44:48',
    SlNo: '600',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:44:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:43:14',
    Latitude: '37.9355689',
    Longitude: '-121.2863998',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:43:14',
    SlNo: '601',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:43:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:42:09',
    Latitude: '37.9355691',
    Longitude: '-121.2864',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:42:09',
    SlNo: '602',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:42:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:41:17',
    Latitude: '37.9355688',
    Longitude: '-121.2863997',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:41:17',
    SlNo: '603',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:41:17 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:40:34',
    Latitude: '37.9355685',
    Longitude: '-121.2864005',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:40:34',
    SlNo: '604',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:40:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:39:13',
    Latitude: '37.93557',
    Longitude: '-121.2864003',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:39:13',
    SlNo: '605',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:39:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:38:54',
    Latitude: '37.9355703',
    Longitude: '-121.2863985',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:38:54',
    SlNo: '606',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:38:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:37:13',
    Latitude: '37.9355694',
    Longitude: '-121.2863981',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:37:13',
    SlNo: '607',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:37:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:36:13',
    Latitude: '37.9355687',
    Longitude: '-121.2863992',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:36:13',
    SlNo: '608',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:36:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:35:08',
    Latitude: '37.9355673',
    Longitude: '-121.2864008',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:35:08',
    SlNo: '609',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:35:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:34:48',
    Latitude: '37.9355682',
    Longitude: '-121.2863969',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:34:48',
    SlNo: '610',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:34:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:33:08',
    Latitude: '37.9355683',
    Longitude: '-121.2863992',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:33:08',
    SlNo: '611',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:33:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:32:08',
    Latitude: '37.9355685',
    Longitude: '-121.2863993',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:32:08',
    SlNo: '612',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:32:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:30:54',
    Latitude: '37.9355677',
    Longitude: '-121.2863981',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:30:54',
    SlNo: '613',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:30:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:30:33',
    Latitude: '37.935567',
    Longitude: '-121.2863969',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:30:33',
    SlNo: '614',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:30:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:28:53',
    Latitude: '37.9355694',
    Longitude: '-121.2863989',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:28:53',
    SlNo: '615',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:28:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:27:54',
    Latitude: '37.9355681',
    Longitude: '-121.2863988',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:27:54',
    SlNo: '616',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:27:54 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:26:48',
    Latitude: '37.9355681',
    Longitude: '-121.2863994',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:26:48',
    SlNo: '617',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:26:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:26:14',
    Latitude: '37.9355676',
    Longitude: '-121.2863987',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:26:14',
    SlNo: '618',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:26:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:24:48',
    Latitude: '37.9355665',
    Longitude: '-121.2864006',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:24:48',
    SlNo: '619',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:24:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:23:33',
    Latitude: '37.9355684',
    Longitude: '-121.2863998',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:23:33',
    SlNo: '620',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:23:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:22:34',
    Latitude: '37.9355679',
    Longitude: '-121.2863978',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:22:34',
    SlNo: '621',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:22:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:22:08',
    Latitude: '37.9355662',
    Longitude: '-121.2863981',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:22:08',
    SlNo: '622',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:22:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:20:33',
    Latitude: '37.9355639',
    Longitude: '-121.2863993',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:20:33',
    SlNo: '623',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:20:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:18:59',
    Latitude: '37.9355367',
    Longitude: '-121.2864054',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:18:59',
    SlNo: '624',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:18:59 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:18:34',
    Latitude: '37.9355372',
    Longitude: '-121.2864024',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:18:34',
    SlNo: '625',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:18:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:17:53',
    Latitude: '37.9355352',
    Longitude: '-121.2864059',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:17:53',
    SlNo: '626',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:17:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:16:33',
    Latitude: '37.9355375',
    Longitude: '-121.2864031',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:16:33',
    SlNo: '627',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:16:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:16:13',
    Latitude: '37.9355373',
    Longitude: '-121.2864034',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:16:13',
    SlNo: '628',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:16:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:14:36',
    Latitude: '37.9355357',
    Longitude: '-121.2864059',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:14:36',
    SlNo: '629',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:14:36 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:13:33',
    Latitude: '37.9355365',
    Longitude: '-121.2864061',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:13:33',
    SlNo: '630',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:13:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:12:28',
    Latitude: '37.9355346',
    Longitude: '-121.2864062',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:12:28',
    SlNo: '631',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:12:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:12:09',
    Latitude: '37.9355351',
    Longitude: '-121.2864058',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:12:09',
    SlNo: '632',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:12:09 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:10:28',
    Latitude: '37.9355353',
    Longitude: '-121.2864029',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:10:28',
    SlNo: '633',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:10:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:08:53',
    Latitude: '37.9355352',
    Longitude: '-121.2864048',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:08:53',
    SlNo: '634',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:08:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:08:28',
    Latitude: '37.9355353',
    Longitude: '-121.286404',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:08:28',
    SlNo: '635',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:08:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:07:53',
    Latitude: '37.9355367',
    Longitude: '-121.2864026',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:07:53',
    SlNo: '636',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:07:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:06:13',
    Latitude: '37.9355369',
    Longitude: '-121.2864031',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:06:13',
    SlNo: '637',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:06:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:05:08',
    Latitude: '37.9355364',
    Longitude: '-121.2864045',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:05:08',
    SlNo: '638',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:05:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:04:12',
    Latitude: '37.935536',
    Longitude: '-121.2864024',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:04:12',
    SlNo: '639',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:04:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:03:33',
    Latitude: '37.9355359',
    Longitude: '-121.2864043',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:03:33',
    SlNo: '640',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:03:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:02:08',
    Latitude: '37.9355357',
    Longitude: '-121.2864024',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:02:08',
    SlNo: '641',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:02:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:01:08',
    Latitude: '37.9355362',
    Longitude: '-121.2864034',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:01:08',
    SlNo: '642',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:01:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 18:00:08',
    Latitude: '37.9355372',
    Longitude: '-121.2864051',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '18:00:08',
    SlNo: '643',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 06:00:08 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:58:28',
    Latitude: '37.9355388',
    Longitude: '-121.2863912',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:58:28',
    SlNo: '644',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:58:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:57:53',
    Latitude: '37.9355467',
    Longitude: '-121.286433',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:57:53',
    SlNo: '645',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:57:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:57:28',
    Latitude: '37.9355246',
    Longitude: '-121.2864408',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:57:28',
    SlNo: '646',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:57:28 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:55:53',
    Latitude: '37.9355963',
    Longitude: '-121.2863948',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:55:53',
    SlNo: '647',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:55:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:54:48',
    Latitude: '37.9355969',
    Longitude: '-121.2863941',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:54:48',
    SlNo: '648',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:54:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:53:53',
    Latitude: '37.9355956',
    Longitude: '-121.2863932',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:53:53',
    SlNo: '649',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:53:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:53:33',
    Latitude: '37.9355953',
    Longitude: '-121.2863948',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:53:33',
    SlNo: '650',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:53:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:51:53',
    Latitude: '37.9355955',
    Longitude: '-121.2863954',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:51:53',
    SlNo: '651',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:51:53 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:50:14',
    Latitude: '37.9355971',
    Longitude: '-121.2864337',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:50:14',
    SlNo: '652',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:50:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:49:48',
    Latitude: '37.9355954',
    Longitude: '-121.2864365',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:49:48',
    SlNo: '653',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:49:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:49:13',
    Latitude: '37.9355954',
    Longitude: '-121.2864367',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:49:13',
    SlNo: '654',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:49:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:48:01',
    Latitude: '37.9355953',
    Longitude: '-121.2864359',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:48:01',
    SlNo: '655',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:48:01 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:46:48',
    Latitude: '37.9355952',
    Longitude: '-121.2864333',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:46:48',
    SlNo: '656',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:46:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:45:48',
    Latitude: '37.9355954',
    Longitude: '-121.2864356',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:45:48',
    SlNo: '657',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:45:48 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:44:33',
    Latitude: '37.9355948',
    Longitude: '-121.2864331',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:44:33',
    SlNo: '658',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:44:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:43:33',
    Latitude: '37.9355946',
    Longitude: '-121.2864363',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:43:33',
    SlNo: '659',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:43:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:43:07',
    Latitude: '37.9355971',
    Longitude: '-121.286436',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:43:07',
    SlNo: '660',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:43:07 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:41:33',
    Latitude: '37.9355966',
    Longitude: '-121.2864358',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:41:33',
    SlNo: '661',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:41:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:40:13',
    Latitude: '37.9355925',
    Longitude: '-121.2864344',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:40:13',
    SlNo: '662',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:40:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:39:33',
    Latitude: '37.9355926',
    Longitude: '-121.2864357',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:39:33',
    SlNo: '663',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:39:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:39:13',
    Latitude: '37.9355676',
    Longitude: '-121.2864311',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:39:13',
    SlNo: '664',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:39:13 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:37:33',
    Latitude: '37.9355788',
    Longitude: '-121.2864106',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:37:33',
    SlNo: '665',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:37:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:36:34',
    Latitude: '37.935547',
    Longitude: '-121.2864383',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:36:34',
    SlNo: '666',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:36:34 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:35:33',
    Latitude: '37.9355486',
    Longitude: '-121.2864342',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:35:33',
    SlNo: '667',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:35:33 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:34:27',
    Latitude: '37.9355524',
    Longitude: '-121.2864323',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:34:27',
    SlNo: '668',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:34:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:33:27',
    Latitude: '37.9355523',
    Longitude: '-121.2864319',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:33:27',
    SlNo: '669',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:33:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:33:06',
    Latitude: '37.9355523',
    Longitude: '-121.2864319',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:33:06',
    SlNo: '670',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:33:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:31:27',
    Latitude: '37.9355527',
    Longitude: '-121.286433',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:31:27',
    SlNo: '671',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:31:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:30:07',
    Latitude: '37.9355577',
    Longitude: '-121.2864372',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:30:07',
    SlNo: '672',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:30:07 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:29:27',
    Latitude: '37.9355575',
    Longitude: '-121.2864368',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:29:27',
    SlNo: '673',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:29:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:29:07',
    Latitude: '37.9355578',
    Longitude: '-121.2864363',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:29:07',
    SlNo: '674',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:29:07 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:27:27',
    Latitude: '37.9355618',
    Longitude: '-121.2864028',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:27:27',
    SlNo: '675',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:27:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:26:27',
    Latitude: '37.9355498',
    Longitude: '-121.2863965',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:26:27',
    SlNo: '676',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:26:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:25:27',
    Latitude: '37.9355517',
    Longitude: '-121.2863931',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:25:27',
    SlNo: '677',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:25:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:24:27',
    Latitude: '37.935553',
    Longitude: '-121.2863919',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:24:27',
    SlNo: '678',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:24:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:23:27',
    Latitude: '37.935555',
    Longitude: '-121.2863829',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:23:27',
    SlNo: '679',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:23:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:23:07',
    Latitude: '37.9355538',
    Longitude: '-121.2863818',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:23:07',
    SlNo: '680',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:23:07 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:21:27',
    Latitude: '37.9356006',
    Longitude: '-121.2863722',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:21:27',
    SlNo: '681',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:21:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:20:07',
    Latitude: '37.9355965',
    Longitude: '-121.2863749',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:20:07',
    SlNo: '682',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:20:07 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:19:30',
    Latitude: '37.9355988',
    Longitude: '-121.2863735',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:19:30',
    SlNo: '683',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:19:30 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:19:07',
    Latitude: '37.9355908',
    Longitude: '-121.2863824',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:19:07',
    SlNo: '684',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:19:07 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:17:27',
    Latitude: '37.9355953',
    Longitude: '-121.2863775',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:17:27',
    SlNo: '685',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:17:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:16:27',
    Latitude: '37.9355732',
    Longitude: '-121.286378',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:16:27',
    SlNo: '686',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:16:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:15:27',
    Latitude: '37.9355519',
    Longitude: '-121.2863795',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:15:27',
    SlNo: '687',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:15:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:14:27',
    Latitude: '37.9355327',
    Longitude: '-121.2864549',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:14:27',
    SlNo: '688',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:14:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 17:13:27',
    Latitude: '37.9355326',
    Longitude: '-121.2864557',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '17:13:27',
    SlNo: '689',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 05:13:27 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:59:26',
    Latitude: '37.9355653',
    Longitude: '-121.2863915',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:59:26',
    SlNo: '690',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:59:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:59:06',
    Latitude: '37.9355647',
    Longitude: '-121.2863907',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:59:06',
    SlNo: '691',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:59:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:57:26',
    Latitude: '37.9355646',
    Longitude: '-121.2863911',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:57:26',
    SlNo: '692',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:57:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:56:26',
    Latitude: '37.9355648',
    Longitude: '-121.2863921',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:56:26',
    SlNo: '693',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:56:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:55:26',
    Latitude: '37.9355646',
    Longitude: '-121.2863925',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:55:26',
    SlNo: '694',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:55:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:54:26',
    Latitude: '37.9355635',
    Longitude: '-121.2863927',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:54:26',
    SlNo: '695',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:54:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:53:26',
    Latitude: '37.9355635',
    Longitude: '-121.2863927',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:53:26',
    SlNo: '696',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:53:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:53:06',
    Latitude: '37.9355638',
    Longitude: '-121.286393',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:53:06',
    SlNo: '697',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:53:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:51:26',
    Latitude: '37.9355657',
    Longitude: '-121.2863926',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:51:26',
    SlNo: '698',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:51:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:50:06',
    Latitude: '37.9355647',
    Longitude: '-121.2863907',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:50:06',
    SlNo: '699',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:50:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:49:26',
    Latitude: '37.9355642',
    Longitude: '-121.28639',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:49:26',
    SlNo: '700',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:49:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:49:14',
    Latitude: '37.9355673',
    Longitude: '-121.2863911',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:49:14',
    SlNo: '701',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:49:14 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:47:26',
    Latitude: '37.9355653',
    Longitude: '-121.2863915',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:47:26',
    SlNo: '702',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:47:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:46:26',
    Latitude: '37.9355645',
    Longitude: '-121.2863903',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:46:26',
    SlNo: '703',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:46:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:45:26',
    Latitude: '37.9355654',
    Longitude: '-121.2863908',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:45:26',
    SlNo: '704',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:45:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:44:12',
    Latitude: '37.9355661',
    Longitude: '-121.2863913',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:44:12',
    SlNo: '705',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:44:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:43:26',
    Latitude: '37.9355663',
    Longitude: '-121.2863907',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:43:26',
    SlNo: '706',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:43:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:43:06',
    Latitude: '37.9355662',
    Longitude: '-121.2863911',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:43:06',
    SlNo: '707',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:43:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:41:26',
    Latitude: '37.9355661',
    Longitude: '-121.2863913',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:41:26',
    SlNo: '708',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:41:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:40:06',
    Latitude: '37.935566',
    Longitude: '-121.2863916',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:40:06',
    SlNo: '709',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:40:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:39:26',
    Latitude: '37.9355664',
    Longitude: '-121.2863904',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:39:26',
    SlNo: '710',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:39:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:39:06',
    Latitude: '37.935566',
    Longitude: '-121.2863915',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:39:06',
    SlNo: '711',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:39:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:37:30',
    Latitude: '37.9355654',
    Longitude: '-121.2863915',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:37:30',
    SlNo: '712',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:37:30 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:36:26',
    Latitude: '37.9355651',
    Longitude: '-121.2863911',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:36:26',
    SlNo: '713',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:36:26 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:35:12',
    Latitude: '37.9355655',
    Longitude: '-121.2863917',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:35:12',
    SlNo: '714',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:35:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:34:12',
    Latitude: '37.9355658',
    Longitude: '-121.2863914',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:34:12',
    SlNo: '715',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:34:12 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:33:06',
    Latitude: '37.9355639',
    Longitude: '-121.286391',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:33:06',
    SlNo: '716',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:33:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:32:46',
    Latitude: '37.9355654',
    Longitude: '-121.2863898',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:32:46',
    SlNo: '717',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:32:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:31:06',
    Latitude: '37.9355473',
    Longitude: '-121.2863945',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:31:06',
    SlNo: '718',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:31:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:30:06',
    Latitude: '37.9355382',
    Longitude: '-121.2863932',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:30:06',
    SlNo: '719',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:30:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:29:06',
    Latitude: '37.9355545',
    Longitude: '-121.286371',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:29:06',
    SlNo: '720',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:29:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:27:46',
    Latitude: '37.9355535',
    Longitude: '-121.2863724',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:27:46',
    SlNo: '721',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:27:46 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:27:06',
    Latitude: '37.9355538',
    Longitude: '-121.2863722',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:27:06',
    SlNo: '722',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:27:06 PM',
  },
  {
    DeviceId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/09/2021 16:26:26',
    Latitude: '37.9355508',
    Longitude: '-121.2863633',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1627 S Center St, Stockton, CA 95206, USA',
    Icon_Status: 'A',
    Date: '06/09/2021 00:00:00',
    Time: '16:26:26',
    SlNo: '723',
    Asset_Name: 'BH006',
    ExcludeDate: null,
    DisplayDate: '06/09/2021 04:26:26 PM',
  },
];

export default data;
