/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './Navbar.css';
import avatar from '../../assets/avatar.svg';
import { setLogoutBox, clearContactData } from '../../features/login/loginSlice';
import SwitchCompanyPopUp from '../../features/switchCompany/SwitchCompanyPopUp';
import { setShowPopUp } from '../../features/switchCompany/switchCompSlice';
import authUtility from '../../utils/auth';

const mapStateToProps = (state) => {
  return {
    login: {
      displayLogout: state.login.logoutBox,
      displayContactData: state.login.contactData,
    },
  };
};

const mapDispatchToProps = {
  setLogoutBoxDispatch: setLogoutBox,
  clearContactDataDispatch: clearContactData,
};

const Navbar = ({ openSidebar, login, setLogoutBoxDispatch, clearContactDataDispatch }) => {
  const history = useHistory();
  const logout = () => {
    localStorage.clear();
    clearContactDataDispatch();
    history.push('/');
  };

  const dispatch = useDispatch();
  // const [showCompPopup, setShowCompPopup] = useState(false);
  const { isReseller, showPopUp } = useSelector((state) => state.switchCompany);
  const [isSubComp, setIsSubComp] = useState(localStorage.getItem('setIsSubCompany'));
  // const [showSwitchCompBtn, setShowCompBtn] = useState(true);

  // useEffect(() => {
  //   setShowCompBtn(isReseller);
  // }, isReseller);

  return (
    <nav className="navbar">
      <div className="navIcon" data-testid="navbar-icon" onClick={() => openSidebar()}>
        <i className="fa fa-bars" aria-hidden="true" />
      </div>

      <div />

      <div className="navbarRight">
        <a href="#">
          <i className="fa fa-bell-o" aria-hidden="true" />
        </a>

        {isReseller && (
          <div
            className=""
            onClick={() => {
              // setShowCompPopup(true);
              dispatch(setShowPopUp(true));
            }}
          >
            <div className="btn btn-danger btn-top">Switch Company</div>
          </div>
        )}
        {isSubComp && !isReseller && (
          <div
            className=""
            onClick={() => {
              const tok = localStorage.getItem('resellerToken');
              authUtility.storeLoginCredentials(tok, 'New User');
              window.location.reload(false);
              setIsSubComp(false);
              localStorage.setItem('setIsSubCompany', false);
            }}
          >
            <div className="btn btn-primary btn-top">Back to Reseller</div>
          </div>
        )}
        <a href="#">{login.displayContactData.contactName}</a>
        <a tabIndex="0" onBlur={() => setLogoutBoxDispatch(false)}>
          <img
            width="30"
            src={avatar}
            alt="avatar"
            data-testid="avatar"
            style={{
              cursor: 'pointer',
            }}
            onClick={() =>
              login.displayLogout ? setLogoutBoxDispatch(false) : setLogoutBoxDispatch(true)
            }
          />
          {login.displayLogout && (
            <>
              <div data-testid="navbarLogout" className="navbarLogoutBox" onClick={logout}>
                <div className="logoutText">Logout</div>
              </div>
            </>
          )}
          {showPopUp && <SwitchCompanyPopUp showPopUp={isReseller} />}
        </a>
      </div>
    </nav>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

Navbar.propTypes = {
  openSidebar: PropTypes.func.isRequired,
  login: PropTypes.shape({
    displayLogout: PropTypes.bool.isRequired,
    displayContactData: PropTypes.shape({
      contactName: PropTypes.string,
    }),
  }),
  setLogoutBoxDispatch: PropTypes.func.isRequired,
  clearContactDataDispatch: PropTypes.func.isRequired,
};
