const data = [
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:40:52',
    Latitude: '32.677345',
    Longitude: '-97.122784',
    Speed: '55.92 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1390 W Interstate 20, Arlington, TX 76017, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:40:52',
    SlNo: '4',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:40:52 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:38:53',
    Latitude: '32.6744674',
    Longitude: '-97.1544972',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2805 Fox Creek Trail, Arlington, TX 76017, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:38:53',
    SlNo: '5',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:38:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:36:51',
    Latitude: '32.6757448',
    Longitude: '-97.1896521',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5307 Ridge Springs Ct, Arlington, TX 76017, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:36:51',
    SlNo: '6',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:36:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:34:53',
    Latitude: '32.6679445',
    Longitude: '-97.2249019',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5820 I-20, Kennedale, TX 76060, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:34:53',
    SlNo: '7',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:34:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:32:50',
    Latitude: '32.6613149',
    Longitude: '-97.2594986',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6500 Hartman Rd, Fort Worth, TX 76140, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:32:50',
    SlNo: '8',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:32:50 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:30:49',
    Latitude: '32.6652499',
    Longitude: '-97.2926614',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2718 SE Loop 820, Fort Worth, TX 76140, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:30:49',
    SlNo: '9',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:30:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:28:49',
    Latitude: '32.6662141',
    Longitude: '-97.3222323',
    Speed: '6.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-20, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:28:49',
    SlNo: '10',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:28:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:26:49',
    Latitude: '32.6690673',
    Longitude: '-97.3232587',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:26:49',
    SlNo: '11',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:26:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:24:49',
    Latitude: '32.6690765',
    Longitude: '-97.3242857',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:24:49',
    SlNo: '12',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:24:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:22:49',
    Latitude: '32.6687643',
    Longitude: '-97.3244085',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:22:49',
    SlNo: '13',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:22:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:20:48',
    Latitude: '32.6687616',
    Longitude: '-97.3244747',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:20:48',
    SlNo: '14',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:20:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:18:48',
    Latitude: '32.668747',
    Longitude: '-97.32449',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:18:48',
    SlNo: '15',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:18:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:16:50',
    Latitude: '32.6687448',
    Longitude: '-97.3244737',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:16:50',
    SlNo: '16',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:16:50 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:14:47',
    Latitude: '32.6687278',
    Longitude: '-97.3244475',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:14:47',
    SlNo: '17',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:14:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:12:46',
    Latitude: '32.6687366',
    Longitude: '-97.3244518',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:12:46',
    SlNo: '18',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:12:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:10:45',
    Latitude: '32.6686992',
    Longitude: '-97.3244763',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:10:45',
    SlNo: '19',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:10:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:08:45',
    Latitude: '32.6687379',
    Longitude: '-97.3244805',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:08:45',
    SlNo: '20',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:08:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:06:44',
    Latitude: '32.6687232',
    Longitude: '-97.3244799',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:06:44',
    SlNo: '21',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:06:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:04:44',
    Latitude: '32.6687214',
    Longitude: '-97.3244813',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:04:44',
    SlNo: '22',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:04:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:02:42',
    Latitude: '32.6687305',
    Longitude: '-97.3244704',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:02:42',
    SlNo: '23',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:02:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 10:00:42',
    Latitude: '32.6687293',
    Longitude: '-97.3244721',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '10:00:42',
    SlNo: '24',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 10:00:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:58:41',
    Latitude: '32.6687075',
    Longitude: '-97.3244405',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:58:41',
    SlNo: '25',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:58:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:56:40',
    Latitude: '32.6687438',
    Longitude: '-97.3243976',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:56:40',
    SlNo: '26',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:56:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:54:40',
    Latitude: '32.6687456',
    Longitude: '-97.3242383',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:54:40',
    SlNo: '27',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:54:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:52:39',
    Latitude: '32.6687542',
    Longitude: '-97.3242236',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:52:39',
    SlNo: '28',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:52:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:50:38',
    Latitude: '32.6682812',
    Longitude: '-97.3244351',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:50:38',
    SlNo: '29',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:50:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:48:38',
    Latitude: '32.6682563',
    Longitude: '-97.3244365',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:48:38',
    SlNo: '30',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:48:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:46:37',
    Latitude: '32.6682737',
    Longitude: '-97.3244305',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:46:37',
    SlNo: '31',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:46:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:44:37',
    Latitude: '32.6683083',
    Longitude: '-97.3244765',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:44:37',
    SlNo: '32',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:44:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:42:36',
    Latitude: '32.6682755',
    Longitude: '-97.3245012',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:42:36',
    SlNo: '33',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:42:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:40:35',
    Latitude: '32.6682491',
    Longitude: '-97.3244873',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:40:35',
    SlNo: '34',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:40:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:38:34',
    Latitude: '32.6682822',
    Longitude: '-97.3244733',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:38:34',
    SlNo: '35',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:38:34 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:36:34',
    Latitude: '32.6682766',
    Longitude: '-97.3244523',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:36:34',
    SlNo: '36',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:36:34 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:34:33',
    Latitude: '32.6682746',
    Longitude: '-97.3244552',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:34:33',
    SlNo: '37',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:34:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:32:35',
    Latitude: '32.6682712',
    Longitude: '-97.324457',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:32:35',
    SlNo: '38',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:32:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:30:33',
    Latitude: '32.6682791',
    Longitude: '-97.3244489',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:30:33',
    SlNo: '39',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:30:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:28:32',
    Latitude: '32.6682625',
    Longitude: '-97.3244741',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:28:32',
    SlNo: '40',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:28:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:26:32',
    Latitude: '32.6683313',
    Longitude: '-97.3244437',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:26:32',
    SlNo: '41',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:26:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:24:31',
    Latitude: '32.6683306',
    Longitude: '-97.3244424',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:24:31',
    SlNo: '42',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:24:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:22:31',
    Latitude: '32.6682937',
    Longitude: '-97.3244969',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:22:31',
    SlNo: '43',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:22:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:20:30',
    Latitude: '32.6683076',
    Longitude: '-97.3244766',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:20:30',
    SlNo: '44',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:20:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:18:30',
    Latitude: '32.6683053',
    Longitude: '-97.3244772',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:18:30',
    SlNo: '45',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:18:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:16:30',
    Latitude: '32.6682695',
    Longitude: '-97.3244789',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:16:30',
    SlNo: '46',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:16:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:14:30',
    Latitude: '32.668267',
    Longitude: '-97.3244753',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:14:30',
    SlNo: '47',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:14:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:12:29',
    Latitude: '32.6682489',
    Longitude: '-97.3244815',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:12:29',
    SlNo: '48',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:12:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:10:28',
    Latitude: '32.6682795',
    Longitude: '-97.3244656',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:10:28',
    SlNo: '49',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:10:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:08:28',
    Latitude: '32.6682607',
    Longitude: '-97.3244732',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:08:28',
    SlNo: '50',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:08:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:06:28',
    Latitude: '32.6682503',
    Longitude: '-97.3244672',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:06:28',
    SlNo: '51',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:06:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:04:27',
    Latitude: '32.6682347',
    Longitude: '-97.3244563',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:04:27',
    SlNo: '52',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:04:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:02:27',
    Latitude: '32.668255',
    Longitude: '-97.3244843',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:02:27',
    SlNo: '53',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:02:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 09:00:27',
    Latitude: '32.6682759',
    Longitude: '-97.3245003',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '09:00:27',
    SlNo: '54',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 09:00:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:58:26',
    Latitude: '32.6682857',
    Longitude: '-97.3244704',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:58:26',
    SlNo: '55',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:58:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:56:26',
    Latitude: '32.6682853',
    Longitude: '-97.3244753',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:56:26',
    SlNo: '56',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:56:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:54:25',
    Latitude: '32.6682869',
    Longitude: '-97.3244874',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:54:25',
    SlNo: '57',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:54:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:52:25',
    Latitude: '32.6682724',
    Longitude: '-97.3244726',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:52:25',
    SlNo: '58',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:52:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:50:24',
    Latitude: '32.6682471',
    Longitude: '-97.3244565',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:50:24',
    SlNo: '59',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:50:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:48:24',
    Latitude: '32.6682654',
    Longitude: '-97.3244631',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:48:24',
    SlNo: '60',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:48:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:46:24',
    Latitude: '32.6682425',
    Longitude: '-97.3244798',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:46:24',
    SlNo: '61',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:46:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:44:25',
    Latitude: '32.6682432',
    Longitude: '-97.3244786',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:44:25',
    SlNo: '62',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:44:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:42:26',
    Latitude: '32.6682436',
    Longitude: '-97.3244793',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:42:26',
    SlNo: '63',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:42:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:40:22',
    Latitude: '32.6682489',
    Longitude: '-97.3245011',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:40:22',
    SlNo: '64',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:40:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:38:21',
    Latitude: '32.6682696',
    Longitude: '-97.3244669',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:38:21',
    SlNo: '65',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:38:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:36:21',
    Latitude: '32.668271',
    Longitude: '-97.324468',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:36:21',
    SlNo: '66',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:36:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:34:20',
    Latitude: '32.6682589',
    Longitude: '-97.3244483',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:34:20',
    SlNo: '67',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:34:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:32:20',
    Latitude: '32.6682413',
    Longitude: '-97.3244452',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:32:20',
    SlNo: '68',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:32:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:30:21',
    Latitude: '32.6682566',
    Longitude: '-97.3244429',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:30:21',
    SlNo: '69',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:30:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:28:19',
    Latitude: '32.6682575',
    Longitude: '-97.3244451',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:28:19',
    SlNo: '70',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:28:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:26:18',
    Latitude: '32.6682598',
    Longitude: '-97.3244454',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:26:18',
    SlNo: '71',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:26:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:24:18',
    Latitude: '32.6682439',
    Longitude: '-97.3244674',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:24:18',
    SlNo: '72',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:24:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:22:18',
    Latitude: '32.6682468',
    Longitude: '-97.3244694',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:22:18',
    SlNo: '73',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:22:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:20:17',
    Latitude: '32.6682812',
    Longitude: '-97.3245096',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:20:17',
    SlNo: '74',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:20:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:18:16',
    Latitude: '32.668282',
    Longitude: '-97.3245075',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:18:16',
    SlNo: '75',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:18:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:16:15',
    Latitude: '32.6682831',
    Longitude: '-97.3245081',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:16:15',
    SlNo: '76',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:16:15 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:14:14',
    Latitude: '32.6683077',
    Longitude: '-97.3245254',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:14:14',
    SlNo: '77',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:14:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:12:13',
    Latitude: '32.6683129',
    Longitude: '-97.3244971',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:12:13',
    SlNo: '78',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:12:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:10:12',
    Latitude: '32.668282',
    Longitude: '-97.3244865',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:10:12',
    SlNo: '79',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:10:12 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:08:11',
    Latitude: '32.6682864',
    Longitude: '-97.3244641',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:08:11',
    SlNo: '80',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:08:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:06:11',
    Latitude: '32.6683118',
    Longitude: '-97.3244686',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:06:11',
    SlNo: '81',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:06:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:04:10',
    Latitude: '32.6683047',
    Longitude: '-97.3244929',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:04:10',
    SlNo: '82',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:04:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:02:10',
    Latitude: '32.6682903',
    Longitude: '-97.3244943',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:02:10',
    SlNo: '83',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:02:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 08:00:09',
    Latitude: '32.6682875',
    Longitude: '-97.3244927',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '08:00:09',
    SlNo: '84',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 08:00:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:58:09',
    Latitude: '32.6682913',
    Longitude: '-97.324466',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:58:09',
    SlNo: '85',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:58:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:56:10',
    Latitude: '32.6682892',
    Longitude: '-97.3244492',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:56:10',
    SlNo: '86',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:56:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:54:17',
    Latitude: '32.6682986',
    Longitude: '-97.3244292',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:54:17',
    SlNo: '87',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:54:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:52:10',
    Latitude: '32.6682728',
    Longitude: '-97.3244385',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:52:10',
    SlNo: '88',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:52:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:50:07',
    Latitude: '32.668271',
    Longitude: '-97.3244394',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:50:07',
    SlNo: '89',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:50:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:48:06',
    Latitude: '32.6682515',
    Longitude: '-97.3244611',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:48:06',
    SlNo: '90',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:48:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:46:06',
    Latitude: '32.6682892',
    Longitude: '-97.3244582',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:46:06',
    SlNo: '91',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:46:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:44:05',
    Latitude: '32.6683124',
    Longitude: '-97.3245426',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:44:05',
    SlNo: '92',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:44:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:42:04',
    Latitude: '32.668315',
    Longitude: '-97.3245241',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:42:04',
    SlNo: '93',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:42:04 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:40:03',
    Latitude: '32.6682993',
    Longitude: '-97.3244905',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:40:03',
    SlNo: '94',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:40:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:38:02',
    Latitude: '32.6682996',
    Longitude: '-97.3244879',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:38:02',
    SlNo: '95',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:38:02 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:36:02',
    Latitude: '32.6682985',
    Longitude: '-97.3244888',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:36:02',
    SlNo: '96',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:36:02 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:34:02',
    Latitude: '32.6683072',
    Longitude: '-97.3244302',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:34:02',
    SlNo: '97',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:34:02 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:32:01',
    Latitude: '32.6682729',
    Longitude: '-97.324417',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:32:01',
    SlNo: '98',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:32:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:30:00',
    Latitude: '32.6683237',
    Longitude: '-97.3243538',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:30:00',
    SlNo: '99',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:30:00 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:28:00',
    Latitude: '32.6685292',
    Longitude: '-97.3242815',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:28:00',
    SlNo: '100',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:28:00 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:26:00',
    Latitude: '32.6684801',
    Longitude: '-97.3243036',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:26:00',
    SlNo: '101',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:26:00 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:23:59',
    Latitude: '32.6682989',
    Longitude: '-97.3243897',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:23:59',
    SlNo: '102',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:23:59 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:22:01',
    Latitude: '32.668388',
    Longitude: '-97.3243795',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:22:01',
    SlNo: '103',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:22:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:19:58',
    Latitude: '32.6690849',
    Longitude: '-97.3240341',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Unnamed Road, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:19:58',
    SlNo: '104',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:19:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:17:58',
    Latitude: '32.6695525',
    Longitude: '-97.3234572',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:17:58',
    SlNo: '105',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:17:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:15:58',
    Latitude: '32.669524',
    Longitude: '-97.323455',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:15:58',
    SlNo: '106',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:15:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:13:58',
    Latitude: '32.6695044',
    Longitude: '-97.3234137',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:13:58',
    SlNo: '107',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:13:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:11:57',
    Latitude: '32.6695055',
    Longitude: '-97.3234763',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:11:57',
    SlNo: '108',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:11:57 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:09:57',
    Latitude: '32.6695776',
    Longitude: '-97.3234474',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:09:57',
    SlNo: '109',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:09:57 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:07:57',
    Latitude: '32.6695117',
    Longitude: '-97.323562',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:07:57',
    SlNo: '110',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:07:57 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:05:56',
    Latitude: '32.6695212',
    Longitude: '-97.3235009',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:05:56',
    SlNo: '111',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:05:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:03:56',
    Latitude: '32.6695401',
    Longitude: '-97.3234552',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:03:56',
    SlNo: '112',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:03:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 07:01:56',
    Latitude: '32.6695518',
    Longitude: '-97.3233923',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '07:01:56',
    SlNo: '113',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 07:01:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:59:55',
    Latitude: '32.6696237',
    Longitude: '-97.3233824',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:59:55',
    SlNo: '114',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:59:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:57:55',
    Latitude: '32.6696247',
    Longitude: '-97.3234274',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:57:55',
    SlNo: '115',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:57:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:55:54',
    Latitude: '32.66958',
    Longitude: '-97.323381',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:55:54',
    SlNo: '116',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:55:54 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:53:53',
    Latitude: '32.6695679',
    Longitude: '-97.3233823',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:53:53',
    SlNo: '117',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:53:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:51:53',
    Latitude: '32.6695672',
    Longitude: '-97.3234021',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:51:53',
    SlNo: '118',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:51:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:49:53',
    Latitude: '32.669566',
    Longitude: '-97.3234175',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:49:53',
    SlNo: '119',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:49:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:47:52',
    Latitude: '32.6695927',
    Longitude: '-97.3233842',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:47:52',
    SlNo: '120',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:47:52 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:45:52',
    Latitude: '32.6695913',
    Longitude: '-97.3233814',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:45:52',
    SlNo: '121',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:45:52 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:43:51',
    Latitude: '32.66962',
    Longitude: '-97.3233901',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:43:51',
    SlNo: '122',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:43:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:41:50',
    Latitude: '32.6696211',
    Longitude: '-97.32339',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:41:50',
    SlNo: '123',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:41:50 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:39:49',
    Latitude: '32.6696208',
    Longitude: '-97.3233909',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:39:49',
    SlNo: '124',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:39:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:37:49',
    Latitude: '32.6696207',
    Longitude: '-97.3234078',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:37:49',
    SlNo: '125',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:37:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:35:48',
    Latitude: '32.6696198',
    Longitude: '-97.3234086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:35:48',
    SlNo: '126',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:35:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:33:53',
    Latitude: '32.6695971',
    Longitude: '-97.3234278',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:33:53',
    SlNo: '127',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:33:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:31:47',
    Latitude: '32.6696506',
    Longitude: '-97.3234246',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:31:47',
    SlNo: '128',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:31:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:29:46',
    Latitude: '32.6696503',
    Longitude: '-97.3234237',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:29:46',
    SlNo: '129',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:29:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:27:46',
    Latitude: '32.6696501',
    Longitude: '-97.3234222',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:27:46',
    SlNo: '130',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:27:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:25:46',
    Latitude: '32.6696941',
    Longitude: '-97.3234089',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:25:46',
    SlNo: '131',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:25:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:23:45',
    Latitude: '32.6695853',
    Longitude: '-97.3233725',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:23:45',
    SlNo: '132',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:23:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:21:45',
    Latitude: '32.669607',
    Longitude: '-97.3233497',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:21:45',
    SlNo: '133',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:21:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:19:44',
    Latitude: '32.669563',
    Longitude: '-97.3233998',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:19:44',
    SlNo: '134',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:19:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:17:44',
    Latitude: '32.6695633',
    Longitude: '-97.3233981',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:17:44',
    SlNo: '135',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:17:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:15:44',
    Latitude: '32.6695729',
    Longitude: '-97.3233859',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:15:44',
    SlNo: '136',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:15:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:13:44',
    Latitude: '32.6695702',
    Longitude: '-97.3233885',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:13:44',
    SlNo: '137',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:13:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:11:43',
    Latitude: '32.669571',
    Longitude: '-97.3233888',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:11:43',
    SlNo: '138',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:11:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:09:42',
    Latitude: '32.6695715',
    Longitude: '-97.3233886',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:09:42',
    SlNo: '139',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:09:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:07:42',
    Latitude: '32.6695754',
    Longitude: '-97.3233964',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:07:42',
    SlNo: '140',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:07:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:05:41',
    Latitude: '32.6695974',
    Longitude: '-97.3234255',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:05:41',
    SlNo: '141',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:05:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:03:41',
    Latitude: '32.6695879',
    Longitude: '-97.3234681',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:03:41',
    SlNo: '142',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:03:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 06:01:40',
    Latitude: '32.6695691',
    Longitude: '-97.3234316',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '06:01:40',
    SlNo: '143',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 06:01:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:59:39',
    Latitude: '32.6695604',
    Longitude: '-97.3234122',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:59:39',
    SlNo: '144',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:59:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:57:38',
    Latitude: '32.6695616',
    Longitude: '-97.3234121',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:57:38',
    SlNo: '145',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:57:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:55:38',
    Latitude: '32.6695311',
    Longitude: '-97.3233914',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:55:38',
    SlNo: '146',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:55:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:53:37',
    Latitude: '32.6695295',
    Longitude: '-97.3233881',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:53:37',
    SlNo: '147',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:53:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:51:36',
    Latitude: '32.6695288',
    Longitude: '-97.3233938',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:51:36',
    SlNo: '148',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:51:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:49:36',
    Latitude: '32.6695177',
    Longitude: '-97.3233027',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:49:36',
    SlNo: '149',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:49:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:47:35',
    Latitude: '32.6695146',
    Longitude: '-97.3234346',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:47:35',
    SlNo: '150',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:47:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:45:35',
    Latitude: '32.6695301',
    Longitude: '-97.3234529',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:45:35',
    SlNo: '151',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:45:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:43:34',
    Latitude: '32.6695403',
    Longitude: '-97.3234337',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:43:34',
    SlNo: '152',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:43:34 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:41:33',
    Latitude: '32.6695466',
    Longitude: '-97.3234359',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:41:33',
    SlNo: '153',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:41:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:39:32',
    Latitude: '32.6695552',
    Longitude: '-97.3234275',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:39:32',
    SlNo: '154',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:39:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:37:32',
    Latitude: '32.6695476',
    Longitude: '-97.3234063',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:37:32',
    SlNo: '155',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:37:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:35:32',
    Latitude: '32.6695614',
    Longitude: '-97.3233911',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:35:32',
    SlNo: '156',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:35:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:33:31',
    Latitude: '32.6695571',
    Longitude: '-97.3234268',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:33:31',
    SlNo: '157',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:33:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:31:30',
    Latitude: '32.6695583',
    Longitude: '-97.3233638',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:31:30',
    SlNo: '158',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:31:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:29:30',
    Latitude: '32.669561',
    Longitude: '-97.3233658',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:29:30',
    SlNo: '159',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:29:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:27:29',
    Latitude: '32.6695548',
    Longitude: '-97.3234382',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:27:29',
    SlNo: '160',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:27:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:25:29',
    Latitude: '32.669565',
    Longitude: '-97.3234196',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:25:29',
    SlNo: '161',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:25:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:23:35',
    Latitude: '32.6695455',
    Longitude: '-97.3234107',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:23:35',
    SlNo: '162',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:23:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:21:28',
    Latitude: '32.6695454',
    Longitude: '-97.3234109',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:21:28',
    SlNo: '163',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:21:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:19:28',
    Latitude: '32.6695561',
    Longitude: '-97.3234018',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:19:28',
    SlNo: '164',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:19:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:17:27',
    Latitude: '32.669553',
    Longitude: '-97.3234034',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:17:27',
    SlNo: '165',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:17:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:15:26',
    Latitude: '32.6695541',
    Longitude: '-97.3234011',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:15:26',
    SlNo: '166',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:15:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:13:26',
    Latitude: '32.669555',
    Longitude: '-97.3234027',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:13:26',
    SlNo: '167',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:13:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:11:25',
    Latitude: '32.6695537',
    Longitude: '-97.3233894',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:11:25',
    SlNo: '168',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:11:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:09:25',
    Latitude: '32.6695557',
    Longitude: '-97.3233896',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:09:25',
    SlNo: '169',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:09:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:07:24',
    Latitude: '32.669553',
    Longitude: '-97.3233892',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:07:24',
    SlNo: '170',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:07:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:59:53',
    Latitude: '35.0746434',
    Longitude: '-107.579706',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Casa Blanca, NM 87007, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:59:53',
    SlNo: '529',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:59:53 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:57:52',
    Latitude: '35.0757296',
    Longitude: '-107.6238504',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, San Fidel, NM 87049, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:57:52',
    SlNo: '530',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:57:52 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:55:52',
    Latitude: '35.0723053',
    Longitude: '-107.6669609',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, San Fidel, NM 87049, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:55:52',
    SlNo: '531',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:55:52 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:53:51',
    Latitude: '35.0633012',
    Longitude: '-107.7075011',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Grants, NM 87020, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:53:51',
    SlNo: '532',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:53:51 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:51:50',
    Latitude: '35.0722951',
    Longitude: '-107.7496763',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '102 Horace Rd, Grants, NM 87020, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:51:50',
    SlNo: '533',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:51:50 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:49:50',
    Latitude: '35.0938397',
    Longitude: '-107.7838966',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Grants, NM 87020, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:49:50',
    SlNo: '534',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:49:50 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:47:49',
    Latitude: '35.1160443',
    Longitude: '-107.8177795',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '96 Walter, Grants, NM 87020, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:47:49',
    SlNo: '535',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:47:49 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:45:48',
    Latitude: '35.1395061',
    Longitude: '-107.8497607',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Grants, NM 87020, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:45:48',
    SlNo: '536',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:45:48 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:43:47',
    Latitude: '35.1592327',
    Longitude: '-107.8850606',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Grants, NM 87020, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:43:47',
    SlNo: '537',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:43:47 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:41:47',
    Latitude: '35.187661',
    Longitude: '-107.9047625',
    Speed: '77.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '409 Motel, Milan, NM 87021, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:41:47',
    SlNo: '538',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:41:47 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:39:47',
    Latitude: '35.2169682',
    Longitude: '-107.9325918',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Grants, NM 87020, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:39:47',
    SlNo: '539',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:39:47 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:37:46',
    Latitude: '35.244998',
    Longitude: '-107.959246',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bluewater, NM 87005, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:37:46',
    SlNo: '540',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:37:46 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:35:45',
    Latitude: '35.2747745',
    Longitude: '-107.983822',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bluewater, NM 87005, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:35:45',
    SlNo: '541',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:35:45 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:33:44',
    Latitude: '35.3106201',
    Longitude: '-107.9915342',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bluewater, NM 87005, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:33:44',
    SlNo: '542',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:33:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:31:44',
    Latitude: '35.3436803',
    Longitude: '-108.0090405',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Prewitt, NM 87045, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:31:44',
    SlNo: '543',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:31:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:29:44',
    Latitude: '35.3611768',
    Longitude: '-108.0474167',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Prewitt, NM 87045, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:29:44',
    SlNo: '544',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:29:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:27:44',
    Latitude: '35.3730437',
    Longitude: '-108.0885183',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Prewitt, NM 87045, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:27:44',
    SlNo: '545',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:27:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:25:44',
    Latitude: '35.38069',
    Longitude: '-108.120973',
    Speed: '53.44 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Thoreau, NM 87323, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:25:44',
    SlNo: '546',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:25:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:23:43',
    Latitude: '35.3865255',
    Longitude: '-108.1518279',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Thoreau, NM 87323, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:23:43',
    SlNo: '547',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:23:43 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:21:42',
    Latitude: '35.3892715',
    Longitude: '-108.1893348',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Thoreau, NM 87323, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:21:42',
    SlNo: '548',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:21:42 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:19:41',
    Latitude: '35.3977428',
    Longitude: '-108.2311642',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '491 NM-122, Thoreau, NM 87323, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:19:41',
    SlNo: '549',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:19:41 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:17:40',
    Latitude: '35.4066056',
    Longitude: '-108.2729337',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Thoreau, NM 87323, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:17:40',
    SlNo: '550',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:17:40 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:15:40',
    Latitude: '35.4230527',
    Longitude: '-108.3111334',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '15 U.S. Rte 66, Continental Divide, NM 87312, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:15:40',
    SlNo: '551',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:15:40 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:13:39',
    Latitude: '35.4402803',
    Longitude: '-108.349829',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '84 Elim Haven Rd, Continental Divide, NM 87312, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:13:39',
    SlNo: '552',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:13:39 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:11:39',
    Latitude: '35.4564789',
    Longitude: '-108.3890942',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Jamestown, NM 87347, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:11:39',
    SlNo: '553',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:11:39 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:09:38',
    Latitude: '35.4733896',
    Longitude: '-108.4191481',
    Speed: '50.33 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Jamestown, NM 87347, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:09:38',
    SlNo: '554',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:09:38 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:07:38',
    Latitude: '35.4824765',
    Longitude: '-108.4468423',
    Speed: '55.30 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Jamestown, NM 87347, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:07:38',
    SlNo: '555',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:07:38 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:05:37',
    Latitude: '35.4896352',
    Longitude: '-108.4785079',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Fort Wingate, NM 87316, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:05:37',
    SlNo: '556',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:05:37 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:03:37',
    Latitude: '35.5028038',
    Longitude: '-108.5189729',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Fort Wingate, NM 87316, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:03:37',
    SlNo: '557',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:03:37 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 15:01:36',
    Latitude: '35.5170274',
    Longitude: '-108.5592277',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Fort Wingate, NM 87316, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '15:01:36',
    SlNo: '558',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:01:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:59:35',
    Latitude: '35.5293085',
    Longitude: '-108.6005709',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Rehoboth, NM 87322, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:59:35',
    SlNo: '559',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:59:35 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:57:34',
    Latitude: '35.5313102',
    Longitude: '-108.6316705',
    Speed: '16.78 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Casino, New Mexico 87322, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:57:34',
    SlNo: '560',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:57:34 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:55:34',
    Latitude: '35.5318857',
    Longitude: '-108.6414928',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Rehoboth, NM 87322, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:55:34',
    SlNo: '561',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:55:34 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:53:34',
    Latitude: '35.532213',
    Longitude: '-108.6471764',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4801 U.S. Rte 66, Rehoboth, NM 87322, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:53:34',
    SlNo: '562',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:53:34 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:51:33',
    Latitude: '35.5319774',
    Longitude: '-108.6537346',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Rehoboth Dr / Church Rock St, Rehoboth, NM 87322, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:51:33',
    SlNo: '563',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:51:33 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:49:32',
    Latitude: '35.5343832',
    Longitude: '-108.6807981',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:49:32',
    SlNo: '564',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:49:32 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:47:32',
    Latitude: '35.5332028',
    Longitude: '-108.7230758',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:47:32',
    SlNo: '565',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:47:32 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:45:31',
    Latitude: '35.5268049',
    Longitude: '-108.7655507',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1560 W Maloney Ave, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:45:31',
    SlNo: '566',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:45:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:43:31',
    Latitude: '35.5135294',
    Longitude: '-108.8061541',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:43:31',
    SlNo: '567',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:43:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:41:31',
    Latitude: '35.5040991',
    Longitude: '-108.8305142',
    Speed: '9.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '111 Twin Buttes Rd, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:41:31',
    SlNo: '568',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:41:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:39:31',
    Latitude: '35.5041623',
    Longitude: '-108.8361529',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:39:31',
    SlNo: '569',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:39:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:37:31',
    Latitude: '35.5039633',
    Longitude: '-108.8358681',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:37:31',
    SlNo: '570',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:37:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:35:31',
    Latitude: '35.5039648',
    Longitude: '-108.8358672',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:35:31',
    SlNo: '571',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:35:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:33:31',
    Latitude: '35.5039369',
    Longitude: '-108.8358809',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:33:31',
    SlNo: '572',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:33:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:31:33',
    Latitude: '35.5039469',
    Longitude: '-108.8358794',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:31:33',
    SlNo: '573',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:31:33 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:29:30',
    Latitude: '35.5039658',
    Longitude: '-108.8358622',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:29:30',
    SlNo: '574',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:29:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:27:30',
    Latitude: '35.5039332',
    Longitude: '-108.8358785',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:27:30',
    SlNo: '575',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:27:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:25:31',
    Latitude: '35.503928',
    Longitude: '-108.8358794',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:25:31',
    SlNo: '576',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:25:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:23:30',
    Latitude: '35.5039372',
    Longitude: '-108.8358728',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:23:30',
    SlNo: '577',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:23:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:21:30',
    Latitude: '35.5039047',
    Longitude: '-108.8358594',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:21:30',
    SlNo: '578',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:21:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:19:30',
    Latitude: '35.5038458',
    Longitude: '-108.8358302',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:19:30',
    SlNo: '579',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:19:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:17:30',
    Latitude: '35.5038371',
    Longitude: '-108.8358306',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:17:30',
    SlNo: '580',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:17:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:15:30',
    Latitude: '35.5038503',
    Longitude: '-108.8358715',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:15:30',
    SlNo: '581',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:15:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:13:30',
    Latitude: '35.5038492',
    Longitude: '-108.8359007',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:13:30',
    SlNo: '582',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:13:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:11:30',
    Latitude: '35.5039495',
    Longitude: '-108.8359002',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:11:30',
    SlNo: '583',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:11:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:09:30',
    Latitude: '35.5039484',
    Longitude: '-108.8358991',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:09:30',
    SlNo: '584',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:09:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:07:29',
    Latitude: '35.5039498',
    Longitude: '-108.8359012',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:07:29',
    SlNo: '585',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:07:29 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:05:28',
    Latitude: '35.503951',
    Longitude: '-108.8359015',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:05:28',
    SlNo: '586',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:05:28 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:03:28',
    Latitude: '35.5039506',
    Longitude: '-108.8359014',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:03:28',
    SlNo: '587',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:03:28 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 14:01:27',
    Latitude: '35.5039516',
    Longitude: '-108.8359015',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '14:01:27',
    SlNo: '588',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:01:27 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:59:27',
    Latitude: '35.5039498',
    Longitude: '-108.8358999',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:59:27',
    SlNo: '589',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:59:27 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:57:26',
    Latitude: '35.503951',
    Longitude: '-108.8359016',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:57:26',
    SlNo: '590',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:57:26 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:55:26',
    Latitude: '35.5057991',
    Longitude: '-108.8300366',
    Speed: '29.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '405 W Historic Hwy 66, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:55:26',
    SlNo: '591',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:55:26 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:53:26',
    Latitude: '35.4985515',
    Longitude: '-108.8446956',
    Speed: '50.33 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:53:26',
    SlNo: '592',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:53:26 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:51:24',
    Latitude: '35.4934197',
    Longitude: '-108.8736345',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '19 Williams Acres Rd, Mentmore, NM 87319, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:51:24',
    SlNo: '593',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:51:24 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:49:36',
    Latitude: '35.4858359',
    Longitude: '-108.9015812',
    Speed: '33.55 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Port of Entry, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:49:36',
    SlNo: '594',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:49:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:47:24',
    Latitude: '35.4772647',
    Longitude: '-108.9185538',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:47:24',
    SlNo: '595',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:47:24 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:45:23',
    Latitude: '35.4537493',
    Longitude: '-108.9496597',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Gallup, NM 87301, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:45:23',
    SlNo: '596',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:45:23 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:25:42',
    Latitude: '35.2620875',
    Longitude: '-109.2734572',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Houck, AZ 86506, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:25:42',
    SlNo: '597',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:25:42 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:23:41',
    Latitude: '35.2364864',
    Longitude: '-109.3046486',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Sanders, AZ 86512, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:23:41',
    SlNo: '598',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:23:41 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:21:40',
    Latitude: '35.2132244',
    Longitude: '-109.3381341',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-191, Sanders, AZ 86512, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:21:40',
    SlNo: '599',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:21:40 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:19:39',
    Latitude: '35.2020763',
    Longitude: '-109.3800265',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-191, Sanders, AZ 86512, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:19:39',
    SlNo: '600',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:19:39 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:17:38',
    Latitude: '35.1931837',
    Longitude: '-109.4223557',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-191, Chambers, AZ 86502, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:17:38',
    SlNo: '601',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:17:38 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:15:37',
    Latitude: '35.1794449',
    Longitude: '-109.462798',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Sanders, AZ 86512, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:15:37',
    SlNo: '602',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:15:37 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:13:37',
    Latitude: '35.15703',
    Longitude: '-109.4972125',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Chambers, AZ 86502, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:13:37',
    SlNo: '603',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:13:37 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:11:36',
    Latitude: '35.1345298',
    Longitude: '-109.5317922',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Chambers, AZ 86502, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:11:36',
    SlNo: '604',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:11:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:09:36',
    Latitude: '35.1181265',
    Longitude: '-109.5703445',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Chambers, AZ 86502, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:09:36',
    SlNo: '605',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:09:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:07:36',
    Latitude: '35.1042793',
    Longitude: '-109.6110105',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Chambers, AZ 86502, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:07:36',
    SlNo: '606',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:07:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:07:36',
    Latitude: '35.0975012',
    Longitude: '-109.6535518',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Chambers, AZ 86502, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:07:36',
    SlNo: '607',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:07:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 13:01:08',
    Latitude: '35.0737595',
    Longitude: '-109.7430734',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Petrified Forest National Park, AZ 86028, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '13:01:08',
    SlNo: '608',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:01:08 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:59:08',
    Latitude: '35.0594089',
    Longitude: '-109.7826472',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Petrified Forest National Park, AZ 86028, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:59:08',
    SlNo: '609',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:59:08 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:57:07',
    Latitude: '35.0387198',
    Longitude: '-109.818724',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Petrified Forest National Park, AZ 86028, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:57:07',
    SlNo: '610',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:57:07 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:55:07',
    Latitude: '35.0181042',
    Longitude: '-109.8543841',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:55:07',
    SlNo: '611',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:55:07 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:53:07',
    Latitude: '35.0024052',
    Longitude: '-109.8905474',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9472 Northern Star Rd, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:53:07',
    SlNo: '612',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:53:07 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:51:06',
    Latitude: '34.9945598',
    Longitude: '-109.9331637',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:51:06',
    SlNo: '613',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:51:06 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:49:06',
    Latitude: '34.9905498',
    Longitude: '-109.9764394',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:49:06',
    SlNo: '614',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:49:06 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:47:05',
    Latitude: '34.9866333',
    Longitude: '-110.0203423',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:47:05',
    SlNo: '615',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:47:05 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:45:04',
    Latitude: '34.9789941',
    Longitude: '-110.0632937',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:45:04',
    SlNo: '616',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:45:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:43:14',
    Latitude: '34.9642456',
    Longitude: '-110.1013041',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:43:14',
    SlNo: '617',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:43:14 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:41:03',
    Latitude: '34.9380405',
    Longitude: '-110.129405',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:41:03',
    SlNo: '618',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:41:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:39:03',
    Latitude: '34.9126966',
    Longitude: '-110.1557046',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:39:03',
    SlNo: '619',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:39:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:37:02',
    Latitude: '34.9053956',
    Longitude: '-110.1969892',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:37:02',
    SlNo: '620',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:37:02 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:35:02',
    Latitude: '34.9112111',
    Longitude: '-110.2399865',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:35:02',
    SlNo: '621',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:35:02 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:33:02',
    Latitude: '34.9327481',
    Longitude: '-110.2742416',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:33:02',
    SlNo: '622',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:33:02 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:31:01',
    Latitude: '34.9504762',
    Longitude: '-110.3062362',
    Speed: '14.29 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:31:01',
    SlNo: '623',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:31:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:29:01',
    Latitude: '34.9550173',
    Longitude: '-110.3127594',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4691 Main St, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:29:01',
    SlNo: '624',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:29:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:27:01',
    Latitude: '34.9543672',
    Longitude: '-110.3117913',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4691 Main St, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:27:01',
    SlNo: '625',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:27:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:25:00',
    Latitude: '34.9542567',
    Longitude: '-110.3113681',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:25:00',
    SlNo: '626',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:25:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:23:00',
    Latitude: '34.954257',
    Longitude: '-110.3113679',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:23:00',
    SlNo: '627',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:23:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:20:59',
    Latitude: '34.9542563',
    Longitude: '-110.3113686',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:20:59',
    SlNo: '628',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:20:59 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:18:59',
    Latitude: '34.9542581',
    Longitude: '-110.311367',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:18:59',
    SlNo: '629',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:18:59 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:16:59',
    Latitude: '34.9542569',
    Longitude: '-110.3113681',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:16:59',
    SlNo: '630',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:16:59 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:14:58',
    Latitude: '34.9542566',
    Longitude: '-110.3113681',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:14:58',
    SlNo: '631',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:14:58 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:12:58',
    Latitude: '34.9542567',
    Longitude: '-110.3113681',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:12:58',
    SlNo: '632',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:12:58 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:10:57',
    Latitude: '34.9542754',
    Longitude: '-110.3113717',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:10:57',
    SlNo: '633',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:10:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:08:56',
    Latitude: '34.9542614',
    Longitude: '-110.311384',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:08:56',
    SlNo: '634',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:08:56 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:06:55',
    Latitude: '34.9542532',
    Longitude: '-110.3113734',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:06:55',
    SlNo: '635',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:06:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:04:55',
    Latitude: '34.9542575',
    Longitude: '-110.3113978',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:04:55',
    SlNo: '636',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:04:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:02:55',
    Latitude: '34.954259',
    Longitude: '-110.3113956',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:02:55',
    SlNo: '637',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:02:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 12:00:55',
    Latitude: '34.9542581',
    Longitude: '-110.3113976',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '12:00:55',
    SlNo: '638',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:00:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:58:54',
    Latitude: '34.9542584',
    Longitude: '-110.3114157',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:58:54',
    SlNo: '639',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:58:54 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:56:53',
    Latitude: '34.9542582',
    Longitude: '-110.3114169',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:56:53',
    SlNo: '640',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:56:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:54:52',
    Latitude: '34.9542681',
    Longitude: '-110.3113994',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:54:52',
    SlNo: '641',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:54:52 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:52:51',
    Latitude: '34.9542668',
    Longitude: '-110.311403',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:52:51',
    SlNo: '642',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:52:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:50:51',
    Latitude: '34.9542564',
    Longitude: '-110.3114307',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:50:51',
    SlNo: '643',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:50:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:48:50',
    Latitude: '34.9542696',
    Longitude: '-110.311402',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4703 Main St, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:48:50',
    SlNo: '644',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:48:50 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:46:49',
    Latitude: '34.9540748',
    Longitude: '-110.3099661',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4716 Main St, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:46:49',
    SlNo: '645',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:46:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:44:49',
    Latitude: '34.9542451',
    Longitude: '-110.3290145',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4555 Frontage Rd, Holbrook, AZ 86025, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:44:49',
    SlNo: '646',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:44:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:42:48',
    Latitude: '34.9594478',
    Longitude: '-110.3725695',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:42:48',
    SlNo: '647',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:42:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:40:48',
    Latitude: '34.9662529',
    Longitude: '-110.4154277',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:40:48',
    SlNo: '648',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:40:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:38:48',
    Latitude: '34.9705241',
    Longitude: '-110.4585707',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:38:48',
    SlNo: '649',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:38:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:36:48',
    Latitude: '34.9724566',
    Longitude: '-110.5021358',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:36:48',
    SlNo: '650',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:36:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:34:47',
    Latitude: '34.9814761',
    Longitude: '-110.5444156',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:34:47',
    SlNo: '651',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:34:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:32:46',
    Latitude: '34.9953564',
    Longitude: '-110.5850226',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Joseph City, AZ 86032, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:32:46',
    SlNo: '652',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:32:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:30:45',
    Latitude: '35.0065612',
    Longitude: '-110.6264086',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:30:45',
    SlNo: '653',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:30:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:28:44',
    Latitude: '35.0160734',
    Longitude: '-110.6685635',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:28:44',
    SlNo: '654',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:28:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:26:44',
    Latitude: '35.039339',
    Longitude: '-110.6976023',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1700 N Park Dr, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:26:44',
    SlNo: '655',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:26:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:24:44',
    Latitude: '35.0400205',
    Longitude: '-110.7408045',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1901 Mountain Dr, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:24:44',
    SlNo: '656',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:24:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:22:43',
    Latitude: '35.0516049',
    Longitude: '-110.7819489',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:22:43',
    SlNo: '657',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:22:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:20:42',
    Latitude: '35.0637984',
    Longitude: '-110.8234259',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:20:42',
    SlNo: '658',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:20:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:18:42',
    Latitude: '35.0758129',
    Longitude: '-110.8646098',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:18:42',
    SlNo: '659',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:18:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:16:41',
    Latitude: '35.0887062',
    Longitude: '-110.9051409',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:16:41',
    SlNo: '660',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:16:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:14:40',
    Latitude: '35.0964746',
    Longitude: '-110.9470632',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:14:40',
    SlNo: '661',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:14:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:12:39',
    Latitude: '35.1018873',
    Longitude: '-110.9884165',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:12:39',
    SlNo: '662',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:12:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:10:39',
    Latitude: '35.1097283',
    Longitude: '-111.0275066',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:10:39',
    SlNo: '663',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:10:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:08:39',
    Latitude: '35.1136556',
    Longitude: '-111.0643802',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:08:39',
    SlNo: '664',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:08:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:06:38',
    Latitude: '35.1163031',
    Longitude: '-111.1086065',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:06:38',
    SlNo: '665',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:06:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:04:38',
    Latitude: '35.1201607',
    Longitude: '-111.1525972',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Winslow, AZ 86047, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:04:38',
    SlNo: '666',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:04:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:02:38',
    Latitude: '35.1327658',
    Longitude: '-111.1942164',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:02:38',
    SlNo: '667',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:02:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 11:00:37',
    Latitude: '35.1475437',
    Longitude: '-111.234483',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '11:00:37',
    SlNo: '668',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:00:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:58:36',
    Latitude: '35.161862',
    Longitude: '-111.2750316',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:58:36',
    SlNo: '669',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:58:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:56:35',
    Latitude: '35.1655053',
    Longitude: '-111.3166138',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Arizona, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:56:35',
    SlNo: '670',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:56:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:54:35',
    Latitude: '35.1811125',
    Longitude: '-111.3560613',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:54:35',
    SlNo: '671',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:54:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:52:35',
    Latitude: '35.1970465',
    Longitude: '-111.3956066',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:52:35',
    SlNo: '672',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:52:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:50:35',
    Latitude: '35.2015437',
    Longitude: '-111.4390627',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:50:35',
    SlNo: '673',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:50:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:48:34',
    Latitude: '35.2026184',
    Longitude: '-111.4834371',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:48:34',
    SlNo: '674',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:48:34 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:46:34',
    Latitude: '35.2104814',
    Longitude: '-111.5262708',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:46:34',
    SlNo: '675',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:46:34 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:44:33',
    Latitude: '35.2183347',
    Longitude: '-111.5683231',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-180, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:44:33',
    SlNo: '676',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:44:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:42:33',
    Latitude: '35.2036973',
    Longitude: '-111.6054581',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Soliere / Foxlair (West) WB, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:42:33',
    SlNo: '677',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:42:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:40:41',
    Latitude: '35.1802856',
    Longitude: '-111.6376845',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Flagstaff, AZ 86004, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:40:41',
    SlNo: '678',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:40:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:38:32',
    Latitude: '35.176612',
    Longitude: '-111.6755647',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1422 W University Heights Dr N, Flagstaff, AZ 86005, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:38:32',
    SlNo: '679',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:38:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:36:31',
    Latitude: '35.1863542',
    Longitude: '-111.7129249',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Flagstaff, AZ 86001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:36:31',
    SlNo: '680',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:36:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:34:30',
    Latitude: '35.1968245',
    Longitude: '-111.7412558',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Flagstaff, AZ 86001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:34:30',
    SlNo: '681',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:34:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:32:30',
    Latitude: '35.2128893',
    Longitude: '-111.7770939',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Flagstaff, AZ 86001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:32:30',
    SlNo: '682',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:32:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:30:30',
    Latitude: '35.2318291',
    Longitude: '-111.8137927',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11766 W Rte 66, Flagstaff, AZ 86015, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:30:30',
    SlNo: '683',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:30:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:28:29',
    Latitude: '35.2476507',
    Longitude: '-111.8532363',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bellemont, AZ 86015, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:28:29',
    SlNo: '684',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:28:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:26:29',
    Latitude: '35.249816',
    Longitude: '-111.8950898',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bellemont, AZ 86015, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:26:29',
    SlNo: '685',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:26:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:24:59',
    Latitude: '35.256914',
    Longitude: '-111.9301845',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Parks, AZ 86018, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:24:59',
    SlNo: '686',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:24:59 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:22:29',
    Latitude: '35.2476364',
    Longitude: '-111.9715247',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:22:29',
    SlNo: '687',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:22:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:20:28',
    Latitude: '35.2555519',
    Longitude: '-112.0131593',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:20:28',
    SlNo: '688',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:20:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:18:31',
    Latitude: '35.2647101',
    Longitude: '-112.0550662',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6906 Mountain Ranch Resort Dr, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:18:31',
    SlNo: '689',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:18:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:16:26',
    Latitude: '35.2582916',
    Longitude: '-112.0970773',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:16:26',
    SlNo: '690',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:16:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:14:25',
    Latitude: '35.2578743',
    Longitude: '-112.1403393',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:14:25',
    SlNo: '691',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:14:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:12:24',
    Latitude: '35.2638567',
    Longitude: '-112.1730647',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '910 Wells Fargo Dr, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:12:24',
    SlNo: '692',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:12:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:10:23',
    Latitude: '35.2605018',
    Longitude: '-112.1934333',
    Speed: '6.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1029 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:10:23',
    SlNo: '693',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:10:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:08:23',
    Latitude: '35.2635564',
    Longitude: '-112.1934058',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:08:23',
    SlNo: '694',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:08:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:06:22',
    Latitude: '35.2635334',
    Longitude: '-112.193412',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:06:22',
    SlNo: '695',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:06:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:04:22',
    Latitude: '35.2635253',
    Longitude: '-112.1934139',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:04:22',
    SlNo: '696',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:04:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:02:21',
    Latitude: '35.2635264',
    Longitude: '-112.1934321',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:02:21',
    SlNo: '697',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:02:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 10:00:21',
    Latitude: '35.2635376',
    Longitude: '-112.1934395',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '10:00:21',
    SlNo: '698',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:00:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:58:21',
    Latitude: '35.2635465',
    Longitude: '-112.1934393',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:58:21',
    SlNo: '699',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:58:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:56:21',
    Latitude: '35.2635266',
    Longitude: '-112.1934265',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:56:21',
    SlNo: '700',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:56:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:54:28',
    Latitude: '35.263529',
    Longitude: '-112.193427',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:54:28',
    SlNo: '701',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:54:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:52:20',
    Latitude: '35.2635554',
    Longitude: '-112.1934394',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:52:20',
    SlNo: '702',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:52:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:50:19',
    Latitude: '35.2635437',
    Longitude: '-112.1933917',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:50:19',
    SlNo: '703',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:50:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:48:19',
    Latitude: '35.2635448',
    Longitude: '-112.193392',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:48:19',
    SlNo: '704',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:48:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:46:18',
    Latitude: '35.2635447',
    Longitude: '-112.193392',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:46:18',
    SlNo: '705',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:46:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:44:17',
    Latitude: '35.2635442',
    Longitude: '-112.1933919',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:44:17',
    SlNo: '706',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:44:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:42:16',
    Latitude: '35.2635436',
    Longitude: '-112.1933916',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:42:16',
    SlNo: '707',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:42:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:05:23',
    Latitude: '32.6695549',
    Longitude: '-97.3233887',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:05:23',
    SlNo: '171',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:05:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:03:22',
    Latitude: '32.6695544',
    Longitude: '-97.3233903',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:03:22',
    SlNo: '172',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:03:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 05:01:21',
    Latitude: '32.6695552',
    Longitude: '-97.3233912',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '05:01:21',
    SlNo: '173',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 05:01:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:59:20',
    Latitude: '32.6695541',
    Longitude: '-97.323389',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:59:20',
    SlNo: '174',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:59:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:57:19',
    Latitude: '32.6695525',
    Longitude: '-97.3233884',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:57:19',
    SlNo: '175',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:57:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:55:19',
    Latitude: '32.6695522',
    Longitude: '-97.3233896',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:55:19',
    SlNo: '176',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:55:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:53:18',
    Latitude: '32.6695454',
    Longitude: '-97.3233934',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:53:18',
    SlNo: '177',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:53:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:51:18',
    Latitude: '32.6695473',
    Longitude: '-97.3233943',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:51:18',
    SlNo: '178',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:51:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:49:17',
    Latitude: '32.6695461',
    Longitude: '-97.3233938',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:49:17',
    SlNo: '179',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:49:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:47:16',
    Latitude: '32.6695463',
    Longitude: '-97.323394',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:47:16',
    SlNo: '180',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:47:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:45:15',
    Latitude: '32.6695696',
    Longitude: '-97.323403',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:45:15',
    SlNo: '181',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:45:15 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:43:17',
    Latitude: '32.6695708',
    Longitude: '-97.323401',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:43:17',
    SlNo: '182',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:43:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:41:14',
    Latitude: '32.6695949',
    Longitude: '-97.3233985',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:41:14',
    SlNo: '183',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:41:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:39:14',
    Latitude: '32.6696166',
    Longitude: '-97.3233761',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:39:14',
    SlNo: '184',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:39:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:37:14',
    Latitude: '32.6696004',
    Longitude: '-97.3233945',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:37:14',
    SlNo: '185',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:37:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:35:13',
    Latitude: '32.6696022',
    Longitude: '-97.3233958',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:35:13',
    SlNo: '186',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:35:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:33:12',
    Latitude: '32.6696223',
    Longitude: '-97.3233964',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:33:12',
    SlNo: '187',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:33:12 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:31:11',
    Latitude: '32.6695799',
    Longitude: '-97.323403',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:31:11',
    SlNo: '188',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:31:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:29:11',
    Latitude: '32.6695324',
    Longitude: '-97.3233646',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:29:11',
    SlNo: '189',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:29:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:27:10',
    Latitude: '32.6695149',
    Longitude: '-97.3233425',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:27:10',
    SlNo: '190',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:27:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:25:10',
    Latitude: '32.6694999',
    Longitude: '-97.3233133',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:25:10',
    SlNo: '191',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:25:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:23:09',
    Latitude: '32.6695286',
    Longitude: '-97.3233695',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:23:09',
    SlNo: '192',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:23:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:21:08',
    Latitude: '32.6695598',
    Longitude: '-97.3233935',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:21:08',
    SlNo: '193',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:21:08 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:19:07',
    Latitude: '32.6695464',
    Longitude: '-97.3233972',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:19:07',
    SlNo: '194',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:19:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:17:07',
    Latitude: '32.6695757',
    Longitude: '-97.3234297',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:17:07',
    SlNo: '195',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:17:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:15:06',
    Latitude: '32.6695978',
    Longitude: '-97.3234439',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:15:06',
    SlNo: '196',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:15:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:13:06',
    Latitude: '32.6695936',
    Longitude: '-97.3234003',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:13:06',
    SlNo: '197',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:13:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:11:05',
    Latitude: '32.6695928',
    Longitude: '-97.3233989',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:11:05',
    SlNo: '198',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:11:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:09:05',
    Latitude: '32.669608',
    Longitude: '-97.3234252',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:09:05',
    SlNo: '199',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:09:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:07:05',
    Latitude: '32.669602',
    Longitude: '-97.3234179',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:07:05',
    SlNo: '200',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:07:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:05:05',
    Latitude: '32.6696207',
    Longitude: '-97.3234471',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:05:05',
    SlNo: '201',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:05:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:03:04',
    Latitude: '32.6695873',
    Longitude: '-97.3234081',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:03:04',
    SlNo: '202',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:03:04 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 04:01:03',
    Latitude: '32.6696081',
    Longitude: '-97.3234204',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '04:01:03',
    SlNo: '203',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 04:01:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:59:03',
    Latitude: '32.6695824',
    Longitude: '-97.3234079',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:59:03',
    SlNo: '204',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:59:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:57:02',
    Latitude: '32.6695834',
    Longitude: '-97.3234041',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:57:02',
    SlNo: '205',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:57:02 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:55:01',
    Latitude: '32.6696307',
    Longitude: '-97.3234108',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:55:01',
    SlNo: '206',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:55:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:53:01',
    Latitude: '32.669591',
    Longitude: '-97.3233917',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:53:01',
    SlNo: '207',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:53:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:51:00',
    Latitude: '32.6695965',
    Longitude: '-97.3234314',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:51:00',
    SlNo: '208',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:51:00 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:49:02',
    Latitude: '32.6695607',
    Longitude: '-97.3233516',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:49:02',
    SlNo: '209',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:49:02 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:46:59',
    Latitude: '32.6691366',
    Longitude: '-97.3231537',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:46:59',
    SlNo: '210',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:46:59 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:44:58',
    Latitude: '32.669247',
    Longitude: '-97.3237851',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:44:58',
    SlNo: '211',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:44:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:42:57',
    Latitude: '32.6692151',
    Longitude: '-97.3236333',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dallas-Fort Worth Metropolitan Area, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:42:57',
    SlNo: '212',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:42:57 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:40:56',
    Latitude: '32.6692499',
    Longitude: '-97.3220943',
    Speed: '50.33 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5420 South Fwy, Fort Worth, TX 76115, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:40:56',
    SlNo: '213',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:40:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:38:56',
    Latitude: '32.6936889',
    Longitude: '-97.320768',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'South & Dickson, Fort Worth, TX 76110, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:38:56',
    SlNo: '214',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:38:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:36:55',
    Latitude: '32.7232294',
    Longitude: '-97.3206281',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '712 E Baltimore Ave, Fort Worth, TX 76104, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:36:55',
    SlNo: '215',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:36:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:34:55',
    Latitude: '32.7539489',
    Longitude: '-97.319906',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1429 N South Fwy, Fort Worth, TX 76102, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:34:55',
    SlNo: '216',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:34:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:32:55',
    Latitude: '32.7842656',
    Longitude: '-97.3209394',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2200 Mercado Dr, Fort Worth, TX 76106, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:32:55',
    SlNo: '217',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:32:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:30:54',
    Latitude: '32.8154279',
    Longitude: '-97.3140588',
    Speed: '59.03 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Fort Worth, TX 76106, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:30:54',
    SlNo: '218',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:30:54 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:28:54',
    Latitude: '32.8439354',
    Longitude: '-97.3131511',
    Speed: '55.30 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5501 Sandshell Dr, Fort Worth, TX 76137, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:28:54',
    SlNo: '219',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:28:54 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:26:53',
    Latitude: '32.8703371',
    Longitude: '-97.3178187',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6301 N Fwy, Fort Worth, TX 76131, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:26:53',
    SlNo: '220',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:26:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:24:52',
    Latitude: '32.8848073',
    Longitude: '-97.3176378',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Fort Worth, TX 76131, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:24:52',
    SlNo: '221',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:24:52 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:22:51',
    Latitude: '32.9092483',
    Longitude: '-97.3426078',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9325 Belle River Trail, Fort Worth, TX 76177, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:22:51',
    SlNo: '222',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:22:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:20:51',
    Latitude: '32.9342939',
    Longitude: '-97.3703629',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11240 US-287, Haslet, TX 76052, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:20:51',
    SlNo: '223',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:20:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:18:51',
    Latitude: '32.9571305',
    Longitude: '-97.4024142',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '12501 US-287 BUS, Fort Worth, TX 76179, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:18:51',
    SlNo: '224',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:18:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:16:50',
    Latitude: '32.9843369',
    Longitude: '-97.4274418',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '14025 US-287, Fort Worth, TX 76179, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:16:50',
    SlNo: '225',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:16:50 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:14:49',
    Latitude: '33.0187262',
    Longitude: '-97.4413231',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '582 US-287, Haslet, TX 76052, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:14:49',
    SlNo: '226',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:14:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:14:49',
    Latitude: '33.0454572',
    Longitude: '-97.467242',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '118 U.S. 81 Ser Rd, Rhome, TX 76078, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:14:49',
    SlNo: '227',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:14:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:10:48',
    Latitude: '33.0749654',
    Longitude: '-97.4732211',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-81, Rhome, TX 76078, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:10:48',
    SlNo: '228',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:10:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:08:47',
    Latitude: '33.109907',
    Longitude: '-97.4769408',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-81, Rhome, TX 76078, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:08:47',
    SlNo: '229',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:08:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:06:47',
    Latitude: '33.1401115',
    Longitude: '-97.497755',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-81, Rhome, TX 76078, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:06:47',
    SlNo: '230',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:06:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:04:46',
    Latitude: '33.1624173',
    Longitude: '-97.5273694',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5296 US-81, Decatur, TX 76234, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:04:46',
    SlNo: '231',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:04:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:02:45',
    Latitude: '33.1857762',
    Longitude: '-97.5527988',
    Speed: '34.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-81, Decatur, TX 76234, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:02:45',
    SlNo: '232',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:02:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 03:00:44',
    Latitude: '33.1995982',
    Longitude: '-97.5636664',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3496 US-287, Decatur, TX 76234, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '03:00:44',
    SlNo: '233',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 03:00:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:58:44',
    Latitude: '33.1996422',
    Longitude: '-97.5636815',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3496 US-287, Decatur, TX 76234, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:58:44',
    SlNo: '234',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:58:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:56:44',
    Latitude: '33.206469',
    Longitude: '-97.569776',
    Speed: '55.30 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3271 US-287, Decatur, TX 76234, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:56:44',
    SlNo: '235',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:56:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:54:43',
    Latitude: '33.2253947',
    Longitude: '-97.5920224',
    Speed: '52.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-81, Decatur, TX 76234, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:54:43',
    SlNo: '236',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:54:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:52:43',
    Latitude: '33.2478171',
    Longitude: '-97.606081',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1789 US-287, Decatur, TX 76234, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:52:43',
    SlNo: '237',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:52:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:50:43',
    Latitude: '33.2763853',
    Longitude: '-97.6250603',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2397 US-287, Decatur, TX 76234, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:50:43',
    SlNo: '238',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:50:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:48:42',
    Latitude: '33.3000843',
    Longitude: '-97.6460559',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3889-3849 US-81, Alvord, TX 76225, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:48:42',
    SlNo: '239',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:48:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:46:42',
    Latitude: '33.3243603',
    Longitude: '-97.6659979',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5611 US-287, Alvord, TX 76225, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:46:42',
    SlNo: '240',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:46:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:44:42',
    Latitude: '33.3493471',
    Longitude: '-97.6941662',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '681 W Bypass 287, Alvord, TX 76225, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:44:42',
    SlNo: '241',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:44:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:42:41',
    Latitude: '33.3791225',
    Longitude: '-97.7112847',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '179 Pr 1609, Alvord, TX 76225, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:42:41',
    SlNo: '242',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:42:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:40:41',
    Latitude: '33.4067497',
    Longitude: '-97.7378556',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8977 US-287, Alvord, TX 76225, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:40:41',
    SlNo: '243',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:40:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:38:40',
    Latitude: '33.4365548',
    Longitude: '-97.7620959',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '930 Proctor Ln, Sunset, TX 76270, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:38:40',
    SlNo: '244',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:38:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:36:41',
    Latitude: '33.4661681',
    Longitude: '-97.7855359',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-81, Sunset, TX 76270, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:36:41',
    SlNo: '245',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:36:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:34:39',
    Latitude: '33.4976184',
    Longitude: '-97.8061423',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5072 US-287, Bowie, TX 76230, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:34:39',
    SlNo: '246',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:34:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:32:39',
    Latitude: '33.5291149',
    Longitude: '-97.8267453',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Bowie, TX 76230, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:32:39',
    SlNo: '247',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:32:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:30:46',
    Latitude: '33.5512892',
    Longitude: '-97.8604428',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '700 US-287, Bowie, TX 76230, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:30:46',
    SlNo: '248',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:30:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:28:37',
    Latitude: '33.5737703',
    Longitude: '-97.8934604',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '821 Williamson Rd, Bowie, TX 76230, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:28:37',
    SlNo: '249',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:28:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:26:37',
    Latitude: '33.594414',
    Longitude: '-97.9279476',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '16165 US-287, Bowie, TX 76230, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:26:37',
    SlNo: '250',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:26:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:24:37',
    Latitude: '33.6121686',
    Longitude: '-97.9650063',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Bellevue, TX 76228, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:24:37',
    SlNo: '251',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:24:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:22:36',
    Latitude: '33.6304184',
    Longitude: '-98.0020131',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Bellevue, TX 76228, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:22:36',
    SlNo: '252',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:22:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:20:48',
    Latitude: '33.6480517',
    Longitude: '-98.0387701',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Bellevue, TX 76228, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:20:48',
    SlNo: '253',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:20:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:18:36',
    Latitude: '33.6735753',
    Longitude: '-98.0681192',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Henrietta, TX 76365, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:18:36',
    SlNo: '254',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:18:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:16:35',
    Latitude: '33.7033252',
    Longitude: '-98.0917974',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Bellevue, TX 76228, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:16:35',
    SlNo: '255',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:16:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:14:34',
    Latitude: '33.7356683',
    Longitude: '-98.1101259',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Henrietta, TX 76365, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:14:34',
    SlNo: '256',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:14:34 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:12:33',
    Latitude: '33.7667088',
    Longitude: '-98.1310936',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Henrietta, TX 76365, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:12:33',
    SlNo: '257',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:12:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:10:32',
    Latitude: '33.787403',
    Longitude: '-98.1662012',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Henrietta, TX 76365, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:10:32',
    SlNo: '258',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:10:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:08:31',
    Latitude: '33.8026643',
    Longitude: '-98.2051169',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1816 US Hwy 287 Access Rd, Henrietta, TX 76365, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:08:31',
    SlNo: '259',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:08:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:06:30',
    Latitude: '33.8241992',
    Longitude: '-98.2339999',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-82, Henrietta, TX 76365, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:06:30',
    SlNo: '260',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:06:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:04:30',
    Latitude: '33.8359554',
    Longitude: '-98.2740519',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-82, Henrietta, TX 76365, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:04:30',
    SlNo: '261',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:04:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:02:29',
    Latitude: '33.8534762',
    Longitude: '-98.312026',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-82, Henrietta, TX 76365, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:02:29',
    SlNo: '262',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:02:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 02:00:28',
    Latitude: '33.8693457',
    Longitude: '-98.3507805',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3460 US-287, Wichita Falls, TX 76305, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '02:00:28',
    SlNo: '263',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 02:00:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:58:28',
    Latitude: '33.8770226',
    Longitude: '-98.392729',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-82, Wichita Falls, TX 76305, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:58:28',
    SlNo: '264',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:58:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:56:27',
    Latitude: '33.885108',
    Longitude: '-98.4328508',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2914 Central Fwy E, Wichita Falls, TX 76301, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:56:27',
    SlNo: '265',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:56:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:54:26',
    Latitude: '33.8843726',
    Longitude: '-98.4755693',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2550 Windthorst Rd, Wichita Falls, TX 76310, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:54:26',
    SlNo: '266',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:54:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:52:26',
    Latitude: '33.9077607',
    Longitude: '-98.5009291',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1413 7th St, Wichita Falls, TX 76301, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:52:26',
    SlNo: '267',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:52:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:50:25',
    Latitude: '33.9377982',
    Longitude: '-98.5194186',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1300 Central E Fwy, Wichita Falls, TX 76306, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:50:25',
    SlNo: '268',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:50:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:48:25',
    Latitude: '33.9490663',
    Longitude: '-98.5571489',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4518 Northwest Fwy, Wichita Falls, TX 76306, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:48:25',
    SlNo: '269',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:48:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:46:25',
    Latitude: '33.9551269',
    Longitude: '-98.5982773',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '889 FM 369, Iowa Park, TX 76367, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:46:25',
    SlNo: '270',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:46:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:42:24',
    Latitude: '33.9656636',
    Longitude: '-98.6809054',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '805 SW Access Rd, Iowa Park, TX 76367, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:42:24',
    SlNo: '271',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:42:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:40:24',
    Latitude: '33.9660158',
    Longitude: '-98.7224236',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2865 SW Access Rd, Iowa Park, TX 76367, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:40:24',
    SlNo: '272',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:40:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:38:24',
    Latitude: '33.9709368',
    Longitude: '-98.7633809',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Iowa Park, TX 76367, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:38:24',
    SlNo: '273',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:38:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:36:23',
    Latitude: '33.9807152',
    Longitude: '-98.8035105',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Electra, TX 76360, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:36:23',
    SlNo: '274',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:36:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:34:22',
    Latitude: '34.0021865',
    Longitude: '-98.8377752',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Electra, TX 76360, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:34:22',
    SlNo: '275',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:34:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:24:20',
    Latitude: '34.075234',
    Longitude: '-99.0192203',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Harrold, TX 76364, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:24:20',
    SlNo: '276',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:24:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:22:20',
    Latitude: '34.0916348',
    Longitude: '-99.0566589',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7460 US-287, Electra, TX 76360, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:22:20',
    SlNo: '277',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:22:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:20:19',
    Latitude: '34.108989',
    Longitude: '-99.0948255',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Oklaunion, TX 76373, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:20:19',
    SlNo: '278',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:20:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:16:17',
    Latitude: '34.134143',
    Longitude: '-99.1689735',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-70, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:16:17',
    SlNo: '279',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:16:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:14:30',
    Latitude: '34.1441375',
    Longitude: '-99.2104099',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-70, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:14:30',
    SlNo: '280',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:14:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:12:16',
    Latitude: '34.1537358',
    Longitude: '-99.2513707',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-70, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:12:16',
    SlNo: '281',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:12:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:10:15',
    Latitude: '34.160672',
    Longitude: '-99.2936875',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2600 McKinney St, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:10:15',
    SlNo: '282',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:10:15 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:08:15',
    Latitude: '34.1794117',
    Longitude: '-99.3235291',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10365-10183 US-287, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:08:15',
    SlNo: '283',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:08:15 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:06:16',
    Latitude: '34.1987798',
    Longitude: '-99.3595076',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '12545-12047 US-287, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:06:16',
    SlNo: '284',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:06:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:04:15',
    Latitude: '34.2184933',
    Longitude: '-99.3946568',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:04:15',
    SlNo: '285',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:04:15 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:02:14',
    Latitude: '34.2308445',
    Longitude: '-99.4337786',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:02:14',
    SlNo: '286',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:02:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 01:00:13',
    Latitude: '34.2477865',
    Longitude: '-99.4684123',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '18495 US-287, Vernon, TX 76384, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '01:00:13',
    SlNo: '287',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 01:00:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:56:12',
    Latitude: '34.2537287',
    Longitude: '-99.5364289',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '101 Emerson Rd, Chillicothe, TX 79225, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:56:12',
    SlNo: '288',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:56:12 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:54:12',
    Latitude: '34.2577663',
    Longitude: '-99.5777732',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Texas, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:54:12',
    SlNo: '289',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:54:12 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:52:12',
    Latitude: '34.2670221',
    Longitude: '-99.6195842',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:52:12',
    SlNo: '290',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:52:12 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:50:11',
    Latitude: '34.2722171',
    Longitude: '-99.6624099',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:50:11',
    SlNo: '291',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:50:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:48:14',
    Latitude: '34.2838291',
    Longitude: '-99.6999167',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:48:14',
    SlNo: '292',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:48:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:46:10',
    Latitude: '34.2883955',
    Longitude: '-99.7350289',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '611 E 11th St, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:46:10',
    SlNo: '293',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:46:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:44:09',
    Latitude: '34.2926433',
    Longitude: '-99.7574597',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1400 W 11th St, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:44:09',
    SlNo: '294',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:44:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:42:09',
    Latitude: '34.3011939',
    Longitude: '-99.7967677',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:42:09',
    SlNo: '295',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:42:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:40:08',
    Latitude: '34.3200381',
    Longitude: '-99.8325444',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:40:08',
    SlNo: '296',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:40:08 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:38:07',
    Latitude: '34.3325427',
    Longitude: '-99.8733506',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:38:07',
    SlNo: '297',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:38:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:36:07',
    Latitude: '34.3413484',
    Longitude: '-99.9151842',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '178 McGuire Rd, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:36:07',
    SlNo: '298',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:36:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:32:07',
    Latitude: '34.3660934',
    Longitude: '-99.9961507',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Quanah, TX 79252, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:32:07',
    SlNo: '299',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:32:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:30:05',
    Latitude: '34.375952',
    Longitude: '-100.0380635',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:30:05',
    SlNo: '300',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:30:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:28:05',
    Latitude: '34.3851826',
    Longitude: '-100.0798837',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:28:05',
    SlNo: '301',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:28:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:26:05',
    Latitude: '34.3971945',
    Longitude: '-100.1204585',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:26:05',
    SlNo: '302',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:26:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:24:04',
    Latitude: '34.4112341',
    Longitude: '-100.1606464',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9102 US-287, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:24:04',
    SlNo: '303',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:24:04 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:22:04',
    Latitude: '34.4233194',
    Longitude: '-100.1962679',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '811 Ave F NE, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:22:04',
    SlNo: '304',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:22:04 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:20:03',
    Latitude: '34.4323897',
    Longitude: '-100.2205236',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1500 Ave F NW, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:20:03',
    SlNo: '305',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:20:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:18:03',
    Latitude: '34.4395939',
    Longitude: '-100.2422136',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7772 US-287, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:18:03',
    SlNo: '306',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:18:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:16:02',
    Latitude: '34.4547119',
    Longitude: '-100.2812244',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7599 US-287, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:16:02',
    SlNo: '307',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:16:02 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:14:01',
    Latitude: '34.473551',
    Longitude: '-100.3172973',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '15519 Co Rd T, Childress, TX 79201, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:14:01',
    SlNo: '308',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:14:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:05:59',
    Latitude: '34.5500554',
    Longitude: '-100.439188',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Estelline, TX 79233, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:05:59',
    SlNo: '309',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:05:59 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:03:59',
    Latitude: '34.5821642',
    Longitude: '-100.4386588',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Memphis, TX 79245, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:03:59',
    SlNo: '310',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:03:59 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/18/2021 00:02:05',
    Latitude: '34.60721',
    Longitude: '-100.4675126',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Memphis, TX 79245, USA',
    Icon_Status: 'A',
    Date: '06/18/2021 00:00:00',
    Time: '00:02:05',
    SlNo: '311',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/18/2021 12:02:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:59:57',
    Latitude: '34.6423216',
    Longitude: '-100.4776736',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Memphis, TX 79245, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:59:57',
    SlNo: '312',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:59:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:57:56',
    Latitude: '34.6776879',
    Longitude: '-100.486156',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5706 US-287, Memphis, TX 79245, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:57:56',
    SlNo: '313',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:57:56 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:55:56',
    Latitude: '34.7071447',
    Longitude: '-100.5107001',
    Speed: '55.30 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Memphis, TX 79245, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:55:56',
    SlNo: '314',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:55:56 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:53:55',
    Latitude: '34.725092',
    Longitude: '-100.5348377',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '501 Boykin Dr, Memphis, TX 79245, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:53:55',
    SlNo: '315',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:53:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:51:55',
    Latitude: '34.7469355',
    Longitude: '-100.5555122',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5000 US-287, Memphis, TX 79245, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:51:55',
    SlNo: '316',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:51:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:49:54',
    Latitude: '34.7773958',
    Longitude: '-100.5788469',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4899 US-287, Hedley, TX 79237, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:49:54',
    SlNo: '317',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:49:54 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:45:53',
    Latitude: '34.8346083',
    Longitude: '-100.6231316',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Hedley, TX 79237, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:45:53',
    SlNo: '318',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:45:53 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:43:55',
    Latitude: '34.8608112',
    Longitude: '-100.6513053',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Hedley, TX 79237, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:43:55',
    SlNo: '319',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:43:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:41:52',
    Latitude: '34.8696678',
    Longitude: '-100.6892657',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Hedley, TX 79237, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:41:52',
    SlNo: '320',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:41:52 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:39:52',
    Latitude: '34.8789922',
    Longitude: '-100.7295661',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:39:52',
    SlNo: '321',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:39:52 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:37:52',
    Latitude: '34.8975301',
    Longitude: '-100.7660201',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:37:52',
    SlNo: '322',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:37:52 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:36:14',
    Latitude: '34.9116897',
    Longitude: '-100.8054837',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3761 US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:36:14',
    SlNo: '323',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:36:14 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:33:51',
    Latitude: '34.9215294',
    Longitude: '-100.8470459',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:33:51',
    SlNo: '324',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:33:51 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:31:51',
    Latitude: '34.9349347',
    Longitude: '-100.8811742',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '713 E 2nd St, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:31:51',
    SlNo: '325',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:31:51 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:29:50',
    Latitude: '34.9490647',
    Longitude: '-100.9082015',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3279 US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:29:50',
    SlNo: '326',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:29:50 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:27:49',
    Latitude: '34.9657708',
    Longitude: '-100.946766',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3131 US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:27:49',
    SlNo: '327',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:27:49 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:25:49',
    Latitude: '34.9772373',
    Longitude: '-100.9876461',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:25:49',
    SlNo: '328',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:25:49 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:23:48',
    Latitude: '34.9966646',
    Longitude: '-101.0246063',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:23:48',
    SlNo: '329',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:23:48 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:22:03',
    Latitude: '35.0151352',
    Longitude: '-101.0624641',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:22:03',
    SlNo: '330',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:22:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:19:47',
    Latitude: '35.0308626',
    Longitude: '-101.1010559',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2375 US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:19:47',
    SlNo: '331',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:19:47 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:17:47',
    Latitude: '35.0325109',
    Longitude: '-101.1446934',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2221 US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:17:47',
    SlNo: '332',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:17:47 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:15:46',
    Latitude: '35.0341508',
    Longitude: '-101.1886394',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:15:46',
    SlNo: '333',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:15:46 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:13:45',
    Latitude: '35.0472522',
    Longitude: '-101.2283662',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10395 Juliet Rd, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:13:45',
    SlNo: '334',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:13:45 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:11:45',
    Latitude: '35.0722413',
    Longitude: '-101.25937',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:11:45',
    SlNo: '335',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:11:45 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:09:44',
    Latitude: '35.0871035',
    Longitude: '-101.2978267',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:09:44',
    SlNo: '336',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:09:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:07:44',
    Latitude: '35.1020032',
    Longitude: '-101.3363541',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '390-869 US-287, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:07:44',
    SlNo: '337',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:07:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:05:57',
    Latitude: '35.1114467',
    Longitude: '-101.363282',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '308 W 1st St, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:05:57',
    SlNo: '338',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:05:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:03:43',
    Latitude: '35.1261102',
    Longitude: '-101.3993553',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Co Rd 13, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:03:43',
    SlNo: '339',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:03:43 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 23:01:44',
    Latitude: '35.1381223',
    Longitude: '-101.4405673',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '23:01:44',
    SlNo: '340',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 11:01:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:59:42',
    Latitude: '35.1501875',
    Longitude: '-101.4821',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:59:42',
    SlNo: '341',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:59:42 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:57:42',
    Latitude: '35.1621496',
    Longitude: '-101.5232983',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:57:42',
    SlNo: '342',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:57:42 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:55:42',
    Latitude: '35.174074',
    Longitude: '-101.5645149',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:55:42',
    SlNo: '343',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:55:42 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:53:41',
    Latitude: '35.1820855',
    Longitude: '-101.6073362',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Claude, TX 79019, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:53:41',
    SlNo: '344',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:53:41 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:51:40',
    Latitude: '35.1879724',
    Longitude: '-101.6508242',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '16052 S U.s. 287, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:51:40',
    SlNo: '345',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:51:40 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:49:40',
    Latitude: '35.1926151',
    Longitude: '-101.694397',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '12124-12536 E Interstate 40, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:49:40',
    SlNo: '346',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:49:40 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:52:17',
    Latitude: '35.2635269',
    Longitude: '-112.1934606',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:52:17',
    SlNo: '876',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:52:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:50:17',
    Latitude: '35.2635805',
    Longitude: '-112.1934568',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:50:17',
    SlNo: '877',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:50:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:48:16',
    Latitude: '35.2635656',
    Longitude: '-112.1934399',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:48:16',
    SlNo: '878',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:48:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:46:15',
    Latitude: '35.2635826',
    Longitude: '-112.1934625',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:46:15',
    SlNo: '879',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:46:15 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:44:14',
    Latitude: '35.2635104',
    Longitude: '-112.1934165',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:44:14',
    SlNo: '880',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:44:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:42:13',
    Latitude: '35.2635103',
    Longitude: '-112.1934163',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:42:13',
    SlNo: '881',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:42:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:40:12',
    Latitude: '35.2635216',
    Longitude: '-112.1934199',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:40:12',
    SlNo: '882',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:40:12 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:38:11',
    Latitude: '35.2635149',
    Longitude: '-112.1934256',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:38:11',
    SlNo: '883',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:38:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:36:10',
    Latitude: '35.2635053',
    Longitude: '-112.1934575',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:36:10',
    SlNo: '884',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:36:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:34:09',
    Latitude: '35.2635047',
    Longitude: '-112.193464',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:34:09',
    SlNo: '885',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:34:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:32:08',
    Latitude: '35.2635319',
    Longitude: '-112.1933965',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:32:08',
    SlNo: '886',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:32:08 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:30:07',
    Latitude: '35.2635762',
    Longitude: '-112.1933029',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:30:07',
    SlNo: '887',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:30:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:28:06',
    Latitude: '35.2635784',
    Longitude: '-112.1933014',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:28:06',
    SlNo: '888',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:28:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:26:05',
    Latitude: '35.263515',
    Longitude: '-112.1933831',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:26:05',
    SlNo: '889',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:26:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:24:04',
    Latitude: '35.2635761',
    Longitude: '-112.1933971',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:24:04',
    SlNo: '890',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:24:04 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:22:04',
    Latitude: '35.2635795',
    Longitude: '-112.1934034',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:22:04',
    SlNo: '891',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:22:04 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:20:03',
    Latitude: '35.2635777',
    Longitude: '-112.1934053',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:20:03',
    SlNo: '892',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:20:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:18:03',
    Latitude: '35.2635802',
    Longitude: '-112.1934064',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:18:03',
    SlNo: '893',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:18:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:16:03',
    Latitude: '35.263577',
    Longitude: '-112.1934095',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:16:03',
    SlNo: '894',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:16:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:14:03',
    Latitude: '35.2635519',
    Longitude: '-112.1934242',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:14:03',
    SlNo: '895',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:14:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:12:01',
    Latitude: '35.263542',
    Longitude: '-112.193429',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:12:01',
    SlNo: '896',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:12:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:10:01',
    Latitude: '35.2635314',
    Longitude: '-112.1934441',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:10:01',
    SlNo: '897',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:10:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:08:00',
    Latitude: '35.2635266',
    Longitude: '-112.1934452',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:08:00',
    SlNo: '898',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:08:00 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:05:59',
    Latitude: '35.2635019',
    Longitude: '-112.1934246',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:05:59',
    SlNo: '899',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:05:59 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:03:58',
    Latitude: '35.2634994',
    Longitude: '-112.1934227',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:03:58',
    SlNo: '900',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:03:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:01:58',
    Latitude: '35.2634994',
    Longitude: '-112.1934214',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:01:58',
    SlNo: '901',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:01:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:59:57',
    Latitude: '35.2634982',
    Longitude: '-112.1934222',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:59:57',
    SlNo: '902',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:59:57 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:57:56',
    Latitude: '35.2635004',
    Longitude: '-112.1934219',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:57:56',
    SlNo: '903',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:57:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:55:56',
    Latitude: '35.2634936',
    Longitude: '-112.1934027',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:55:56',
    SlNo: '904',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:55:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:53:55',
    Latitude: '35.263492',
    Longitude: '-112.1934036',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:53:55',
    SlNo: '905',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:53:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:51:55',
    Latitude: '35.2635237',
    Longitude: '-112.1934336',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:51:55',
    SlNo: '906',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:51:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:49:55',
    Latitude: '35.2635388',
    Longitude: '-112.1934211',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:49:55',
    SlNo: '907',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:49:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:47:58',
    Latitude: '35.2635391',
    Longitude: '-112.1934194',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:47:58',
    SlNo: '908',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:47:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:45:53',
    Latitude: '35.2635403',
    Longitude: '-112.1934309',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:45:53',
    SlNo: '909',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:45:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:43:53',
    Latitude: '35.2635172',
    Longitude: '-112.1934558',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:43:53',
    SlNo: '910',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:43:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:41:53',
    Latitude: '35.2635264',
    Longitude: '-112.1934788',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:41:53',
    SlNo: '911',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:41:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:39:52',
    Latitude: '35.2635262',
    Longitude: '-112.1934794',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:39:52',
    SlNo: '912',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:39:52 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:37:51',
    Latitude: '35.2635265',
    Longitude: '-112.1934894',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:37:51',
    SlNo: '913',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:37:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:35:51',
    Latitude: '35.2635266',
    Longitude: '-112.1934897',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:35:51',
    SlNo: '914',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:35:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:33:50',
    Latitude: '35.2635283',
    Longitude: '-112.1934907',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:33:50',
    SlNo: '915',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:33:50 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:31:49',
    Latitude: '35.2635258',
    Longitude: '-112.1934904',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:31:49',
    SlNo: '916',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:31:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:29:48',
    Latitude: '35.263528',
    Longitude: '-112.193493',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:29:48',
    SlNo: '917',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:29:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:27:47',
    Latitude: '35.2635249',
    Longitude: '-112.1934897',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:27:47',
    SlNo: '918',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:27:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:25:47',
    Latitude: '35.2635252',
    Longitude: '-112.1934958',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:25:47',
    SlNo: '919',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:25:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:23:46',
    Latitude: '35.2634772',
    Longitude: '-112.1934679',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:23:46',
    SlNo: '920',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:23:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:21:46',
    Latitude: '35.2634774',
    Longitude: '-112.1934679',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:21:46',
    SlNo: '921',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:21:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:19:46',
    Latitude: '35.2634889',
    Longitude: '-112.1934301',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:19:46',
    SlNo: '922',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:19:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:17:46',
    Latitude: '35.2634903',
    Longitude: '-112.193431',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:17:46',
    SlNo: '923',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:17:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:15:45',
    Latitude: '35.2634884',
    Longitude: '-112.1934314',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:15:45',
    SlNo: '924',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:15:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:13:45',
    Latitude: '35.2634874',
    Longitude: '-112.1934321',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:13:45',
    SlNo: '925',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:13:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:11:44',
    Latitude: '35.2634865',
    Longitude: '-112.1934211',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:11:44',
    SlNo: '926',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:11:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:09:43',
    Latitude: '35.2635042',
    Longitude: '-112.1934318',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:09:43',
    SlNo: '927',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:09:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:07:42',
    Latitude: '35.2635016',
    Longitude: '-112.1934415',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:07:42',
    SlNo: '928',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:07:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:05:41',
    Latitude: '35.2635026',
    Longitude: '-112.1934426',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:05:41',
    SlNo: '929',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:05:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:03:41',
    Latitude: '35.2634919',
    Longitude: '-112.1934349',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:03:41',
    SlNo: '930',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:03:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 02:01:40',
    Latitude: '35.263538',
    Longitude: '-112.1934385',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '02:01:40',
    SlNo: '931',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 02:01:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:59:39',
    Latitude: '35.2635381',
    Longitude: '-112.1934386',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:59:39',
    SlNo: '932',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:59:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:57:39',
    Latitude: '35.263535',
    Longitude: '-112.1934385',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:57:39',
    SlNo: '933',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:57:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:55:39',
    Latitude: '35.2639765',
    Longitude: '-112.1948607',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:55:39',
    SlNo: '934',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:55:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:53:38',
    Latitude: '35.2639835',
    Longitude: '-112.1948757',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:53:38',
    SlNo: '935',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:53:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:51:37',
    Latitude: '35.2601646',
    Longitude: '-112.1932063',
    Speed: '44.12 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1029 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:51:37',
    SlNo: '936',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:51:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:49:36',
    Latitude: '35.2464324',
    Longitude: '-112.2131045',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:49:36',
    SlNo: '937',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:49:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:45:35',
    Latitude: '35.2212899',
    Longitude: '-112.2710939',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:45:35',
    SlNo: '938',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:45:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:43:36',
    Latitude: '35.2195301',
    Longitude: '-112.3062064',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:43:36',
    SlNo: '939',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:43:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:37:32',
    Latitude: '35.2157849',
    Longitude: '-112.3858701',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:37:32',
    SlNo: '940',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:37:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:35:31',
    Latitude: '35.2135487',
    Longitude: '-112.4161406',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:35:31',
    SlNo: '941',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:35:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:33:31',
    Latitude: '35.2199069',
    Longitude: '-112.4584526',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Ash Fork, AZ 86320, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:33:31',
    SlNo: '942',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:33:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:31:31',
    Latitude: '35.2204336',
    Longitude: '-112.5001332',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Ash Fork, AZ 86320, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:31:31',
    SlNo: '943',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:31:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:29:30',
    Latitude: '35.2210113',
    Longitude: '-112.5429256',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Ash Fork, AZ 86320, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:29:30',
    SlNo: '944',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:29:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:27:29',
    Latitude: '35.2286648',
    Longitude: '-112.5821758',
    Speed: '52.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Ash Fork, AZ 86320, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:27:29',
    SlNo: '945',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:27:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:25:29',
    Latitude: '35.2325429',
    Longitude: '-112.6126114',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Ash Fork, AZ 86320, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:25:29',
    SlNo: '946',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:25:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:23:29',
    Latitude: '35.2370553',
    Longitude: '-112.649841',
    Speed: '77.67 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Ash Fork, AZ 86320, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:23:29',
    SlNo: '947',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:23:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:21:28',
    Latitude: '35.2506067',
    Longitude: '-112.6923732',
    Speed: '77.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Ash Fork, AZ 86320, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:21:28',
    SlNo: '948',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:21:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:19:27',
    Latitude: '35.2701614',
    Longitude: '-112.7308955',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Ash Fork, AZ 86320, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:19:27',
    SlNo: '949',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:19:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:17:27',
    Latitude: '35.2763457',
    Longitude: '-112.771274',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:17:27',
    SlNo: '950',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:17:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:15:36',
    Latitude: '35.2863272',
    Longitude: '-112.8062226',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:15:36',
    SlNo: '951',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:15:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:13:25',
    Latitude: '35.3015982',
    Longitude: '-112.8406716',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:13:25',
    SlNo: '952',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:13:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:11:25',
    Latitude: '35.3174426',
    Longitude: '-112.8791913',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:11:25',
    SlNo: '953',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:11:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:09:25',
    Latitude: '35.3225112',
    Longitude: '-112.9220308',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:09:25',
    SlNo: '954',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:09:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:07:26',
    Latitude: '35.3149979',
    Longitude: '-112.964984',
    Speed: '77.67 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '27555 Fort Rock Rd, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:07:26',
    SlNo: '955',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:07:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:05:23',
    Latitude: '35.3023114',
    Longitude: '-113.0079234',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:05:23',
    SlNo: '956',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:05:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:05:23',
    Latitude: '35.2920284',
    Longitude: '-113.0498606',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:05:23',
    SlNo: '957',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:05:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 01:01:23',
    Latitude: '35.2874424',
    Longitude: '-113.0916149',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '01:01:23',
    SlNo: '958',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 01:01:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:59:21',
    Latitude: '35.2806943',
    Longitude: '-113.1270724',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:59:21',
    SlNo: '959',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:59:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:57:20',
    Latitude: '35.2603728',
    Longitude: '-113.1628155',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:57:20',
    SlNo: '960',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:57:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:55:54',
    Latitude: '35.2408079',
    Longitude: '-113.1991907',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:55:54',
    SlNo: '961',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:55:54 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:53:19',
    Latitude: '35.2248073',
    Longitude: '-113.2378083',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:53:19',
    SlNo: '962',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:53:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:51:18',
    Latitude: '35.2073269',
    Longitude: '-113.2759124',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Seligman, AZ 86337, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:51:18',
    SlNo: '963',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:51:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:49:18',
    Latitude: '35.19292',
    Longitude: '-113.3098788',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:49:18',
    SlNo: '964',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:49:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:47:17',
    Latitude: '35.1907826',
    Longitude: '-113.3501712',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:47:17',
    SlNo: '965',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:47:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:45:17',
    Latitude: '35.1833163',
    Longitude: '-113.3917251',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:45:17',
    SlNo: '966',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:45:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:43:16',
    Latitude: '35.1893164',
    Longitude: '-113.4262165',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:43:16',
    SlNo: '967',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:43:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:41:16',
    Latitude: '35.1810658',
    Longitude: '-113.463858',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '320 Knight Creek Rd, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:41:16',
    SlNo: '968',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:41:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:37:22',
    Latitude: '35.1644844',
    Longitude: '-113.5303913',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:37:22',
    SlNo: '969',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:37:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:35:13',
    Latitude: '35.1586709',
    Longitude: '-113.5578725',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:35:13',
    SlNo: '970',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:35:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:33:17',
    Latitude: '35.16113',
    Longitude: '-113.5973229',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:33:17',
    SlNo: '971',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:33:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:31:13',
    Latitude: '35.1600578',
    Longitude: '-113.6310784',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:31:13',
    SlNo: '972',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:31:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:29:13',
    Latitude: '35.1610567',
    Longitude: '-113.675584',
    Speed: '80.16 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:29:13',
    SlNo: '973',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:29:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:27:11',
    Latitude: '35.1629231',
    Longitude: '-113.722563',
    Speed: '76.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-93, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:27:11',
    SlNo: '974',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:27:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:25:10',
    Latitude: '35.1697986',
    Longitude: '-113.7667982',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-93, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:25:10',
    SlNo: '975',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:25:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:23:09',
    Latitude: '35.1841518',
    Longitude: '-113.807039',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-93, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:23:09',
    SlNo: '976',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:23:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:17:08',
    Latitude: '35.1998607',
    Longitude: '-113.9238544',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-93, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:17:08',
    SlNo: '977',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:17:08 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:15:07',
    Latitude: '35.2095313',
    Longitude: '-113.9635992',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-93, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:15:07',
    SlNo: '978',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:15:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:13:08',
    Latitude: '35.217183',
    Longitude: '-114.0050087',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3174 Beverly Ave, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:13:08',
    SlNo: '979',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:13:08 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:11:07',
    Latitude: '35.2144523',
    Longitude: '-114.0474347',
    Speed: '60.27 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '721 Sunrise Ave, Kingman, AZ 86409, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:11:07',
    SlNo: '980',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:11:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:09:06',
    Latitude: '35.1899798',
    Longitude: '-114.0669319',
    Speed: '50.33 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '770 W Beale St, Kingman, AZ 86401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:09:06',
    SlNo: '981',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:09:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:07:06',
    Latitude: '35.1664401',
    Longitude: '-114.0738687',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Golden Valley, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:07:06',
    SlNo: '982',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:07:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:05:05',
    Latitude: '35.1413199',
    Longitude: '-114.0985431',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2250 Oatman Hwy, Kingman, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:05:05',
    SlNo: '983',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:05:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:03:13',
    Latitude: '35.1093233',
    Longitude: '-114.1156387',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Golden Valley, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:03:13',
    SlNo: '984',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:03:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 00:01:04',
    Latitude: '35.0747194',
    Longitude: '-114.1223785',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Golden Valley, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '00:01:04',
    SlNo: '985',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 12:01:04 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:59:04',
    Latitude: '35.0394142',
    Longitude: '-114.1290005',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Golden Valley, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:59:04',
    SlNo: '986',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:59:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:57:04',
    Latitude: '35.0040858',
    Longitude: '-114.1356365',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Golden Valley, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:57:04',
    SlNo: '987',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:57:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:55:03',
    Latitude: '34.9683728',
    Longitude: '-114.1423056',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Golden Valley, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:55:03',
    SlNo: '988',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:55:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:53:03',
    Latitude: '34.9330341',
    Longitude: '-114.1488989',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Yucca, AZ 86438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:53:03',
    SlNo: '989',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:53:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:51:02',
    Latitude: '34.8985438',
    Longitude: '-114.1550226',
    Speed: '59.03 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Yucca, AZ 86438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:51:02',
    SlNo: '990',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:51:02 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:49:01',
    Latitude: '34.8707291',
    Longitude: '-114.1473568',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '12349 S Yucca Frontage Rd, Yucca, AZ 86438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:49:01',
    SlNo: '991',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:49:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:47:01',
    Latitude: '34.8411054',
    Longitude: '-114.1453361',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Golden Valley, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:47:01',
    SlNo: '992',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:47:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:45:01',
    Latitude: '34.8086259',
    Longitude: '-114.149337',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Golden Valley, AZ 86413, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:45:01',
    SlNo: '993',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:45:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:43:00',
    Latitude: '34.7827796',
    Longitude: '-114.1769499',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Frontage Rd, Yucca, AZ 86438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:43:00',
    SlNo: '994',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:43:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:41:01',
    Latitude: '34.7599224',
    Longitude: '-114.2088906',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Yucca, AZ 86438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:41:01',
    SlNo: '995',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:41:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:41:00',
    Latitude: '34.7398191',
    Longitude: '-114.2438808',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Yucca, AZ 86438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:41:00',
    SlNo: '996',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:41:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:36:58',
    Latitude: '34.7303546',
    Longitude: '-114.2844231',
    Speed: '54.06 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Lake Havasu City, AZ 86404, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:36:58',
    SlNo: '997',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:36:58 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:34:58',
    Latitude: '34.7290944',
    Longitude: '-114.3158907',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '14750 S AZ-95, Lake Havasu City, AZ 86404, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:34:58',
    SlNo: '998',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:34:58 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:32:57',
    Latitude: '34.7234052',
    Longitude: '-114.3153794',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '14875 AZ-95, Lake Havasu City, AZ 86404, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:32:57',
    SlNo: '999',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:32:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 23:30:57',
    Latitude: '34.7238342',
    Longitude: '-114.3149858',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '14875 AZ-95, Lake Havasu City, AZ 86404, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '23:30:57',
    SlNo: '1000',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:30:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:47:38',
    Latitude: '35.192692',
    Longitude: '-101.733772',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-287, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:47:38',
    SlNo: '347',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:47:38 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:45:38',
    Latitude: '35.1925471',
    Longitude: '-101.769499',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6055 E Interstate 40, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:45:38',
    SlNo: '348',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:45:38 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:42:00',
    Latitude: '35.1942746',
    Longitude: '-101.8473901',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1905 S Madison St, Amarillo, TX 79109, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:42:00',
    SlNo: '349',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:42:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:39:36',
    Latitude: '35.1913798',
    Longitude: '-101.886578',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2100 S La Salle St, Amarillo, TX 79106, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:39:36',
    SlNo: '350',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:39:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:37:36',
    Latitude: '35.1867449',
    Longitude: '-101.927702',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8130 W, I-40, Amarillo, TX 79106, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:37:36',
    SlNo: '351',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:37:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:35:36',
    Latitude: '35.1873496',
    Longitude: '-101.9672686',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11051 I-40, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:35:36',
    SlNo: '352',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:35:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:33:36',
    Latitude: '35.1897377',
    Longitude: '-102.0056055',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '14213 Indian Hill Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:33:36',
    SlNo: '353',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:33:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:31:36',
    Latitude: '35.187493',
    Longitude: '-102.0116662',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:31:36',
    SlNo: '354',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:31:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:29:35',
    Latitude: '35.1873943',
    Longitude: '-102.0116608',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:29:35',
    SlNo: '355',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:29:35 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:27:34',
    Latitude: '35.1873298',
    Longitude: '-102.0114931',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:27:34',
    SlNo: '356',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:27:34 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 22:01:28',
    Latitude: '35.1893867',
    Longitude: '-102.011328',
    Speed: '55.92 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '14701 I-40, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '22:01:28',
    SlNo: '357',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 10:01:28 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:59:27',
    Latitude: '35.1907382',
    Longitude: '-102.0442853',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '17700 Indian Hill Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:59:27',
    SlNo: '358',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:59:27 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:57:26',
    Latitude: '35.1917282',
    Longitude: '-102.0870605',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bushland, TX 79012, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:57:26',
    SlNo: '359',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:57:26 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:55:26',
    Latitude: '35.1991094',
    Longitude: '-102.1280888',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bushland, TX 79012, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:55:26',
    SlNo: '360',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:55:26 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:53:25',
    Latitude: '35.2086921',
    Longitude: '-102.1692433',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:53:25',
    SlNo: '361',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:53:25 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:51:25',
    Latitude: '35.2093724',
    Longitude: '-102.2117691',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '711 I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:51:25',
    SlNo: '362',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:51:25 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:49:24',
    Latitude: '35.2118955',
    Longitude: '-102.2556199',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4595 I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:49:24',
    SlNo: '363',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:49:24 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:47:24',
    Latitude: '35.2198642',
    Longitude: '-102.2982317',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:47:24',
    SlNo: '364',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:47:24 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:45:23',
    Latitude: '35.2279418',
    Longitude: '-102.3412826',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '101 I-40 Frontage Rd, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:45:23',
    SlNo: '365',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:45:23 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:43:23',
    Latitude: '35.2359916',
    Longitude: '-102.383989',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:43:23',
    SlNo: '366',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:43:23 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:41:22',
    Latitude: '35.2363166',
    Longitude: '-102.4208104',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:41:22',
    SlNo: '367',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:41:22 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:39:22',
    Latitude: '35.2493112',
    Longitude: '-102.4550868',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40 Service Rd, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:39:22',
    SlNo: '368',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:39:22 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:37:22',
    Latitude: '35.2572321',
    Longitude: '-102.4978216',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:37:22',
    SlNo: '369',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:37:22 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:35:21',
    Latitude: '35.2655552',
    Longitude: '-102.5407889',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40 Service Rd, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:35:21',
    SlNo: '370',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:35:21 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:33:29',
    Latitude: '35.2733455',
    Longitude: '-102.5837921',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40 Service Rd, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:33:29',
    SlNo: '371',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:33:29 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:31:20',
    Latitude: '35.2722802',
    Longitude: '-102.627582',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40 Service Rd, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:31:20',
    SlNo: '372',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:31:20 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:29:19',
    Latitude: '35.2694562',
    Longitude: '-102.6713236',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '211 W Historic Rte 66, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:29:19',
    SlNo: '373',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:29:19 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:27:18',
    Latitude: '35.2698443',
    Longitude: '-102.7141568',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:27:18',
    SlNo: '374',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:27:18 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:27:18',
    Latitude: '35.2596776',
    Longitude: '-102.7553945',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1740 I-40 Service Rd, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:27:18',
    SlNo: '375',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:27:18 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:27:18',
    Latitude: '35.2485949',
    Longitude: '-102.7907657',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:27:18',
    SlNo: '376',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:27:18 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:27:18',
    Latitude: '35.2394772',
    Longitude: '-102.8304097',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:27:18',
    SlNo: '377',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:27:18 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:15:16',
    Latitude: '35.1977228',
    Longitude: '-102.9502674',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:15:16',
    SlNo: '378',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:15:16 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:13:15',
    Latitude: '35.1847496',
    Longitude: '-102.9910635',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Texas, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:13:15',
    SlNo: '379',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:13:15 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:11:15',
    Latitude: '35.1817736',
    Longitude: '-103.0342126',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Hereford, TX 79045, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:11:15',
    SlNo: '380',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:11:15 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:09:14',
    Latitude: '35.1805861',
    Longitude: '-103.0772461',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:09:14',
    SlNo: '381',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:09:14 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:07:14',
    Latitude: '35.1683384',
    Longitude: '-103.1183067',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6299 Quay Rd J, Bard, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:07:14',
    SlNo: '382',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:07:14 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:05:14',
    Latitude: '35.1544419',
    Longitude: '-103.1594737',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bard, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:05:14',
    SlNo: '383',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:05:14 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:03:14',
    Latitude: '35.1406769',
    Longitude: '-103.1994277',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bard, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:03:14',
    SlNo: '384',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:03:14 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 21:01:29',
    Latitude: '35.1257444',
    Longitude: '-103.2393779',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Bard, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '21:01:29',
    SlNo: '385',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:01:29 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:59:12',
    Latitude: '35.1133851',
    Longitude: '-103.2807718',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Bard, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:59:12',
    SlNo: '386',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:59:12 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:57:11',
    Latitude: '35.1120555',
    Longitude: '-103.324737',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:57:11',
    SlNo: '387',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:57:11 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:55:10',
    Latitude: '35.1160818',
    Longitude: '-103.3676229',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:55:10',
    SlNo: '388',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:55:10 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:53:13',
    Latitude: '35.1320338',
    Longitude: '-103.4067696',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:53:13',
    SlNo: '389',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:53:13 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:51:09',
    Latitude: '35.1483992',
    Longitude: '-103.4456185',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:51:09',
    SlNo: '390',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:51:09 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:49:08',
    Latitude: '35.168256',
    Longitude: '-103.4768651',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:49:08',
    SlNo: '391',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:49:08 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:47:07',
    Latitude: '35.1731804',
    Longitude: '-103.5197035',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:47:07',
    SlNo: '392',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:47:07 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:45:06',
    Latitude: '35.1732889',
    Longitude: '-103.5637742',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:45:06',
    SlNo: '393',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:45:06 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:43:05',
    Latitude: '35.1724693',
    Longitude: '-103.6079243',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:43:05',
    SlNo: '394',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:43:05 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:41:05',
    Latitude: '35.1722375',
    Longitude: '-103.6515772',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3609 Quay Rd 63, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:41:05',
    SlNo: '395',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:41:05 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:39:04',
    Latitude: '35.1609817',
    Longitude: '-103.6916654',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:39:04',
    SlNo: '396',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:39:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:37:04',
    Latitude: '35.1514514',
    Longitude: '-103.7328136',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '706 W Mesquite Ave, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:37:04',
    SlNo: '397',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:37:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:35:04',
    Latitude: '35.1527262',
    Longitude: '-103.7732541',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:35:04',
    SlNo: '398',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:35:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:33:04',
    Latitude: '35.1430059',
    Longitude: '-103.8144167',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:33:04',
    SlNo: '399',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:33:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:29:03',
    Latitude: '35.1187648',
    Longitude: '-103.893445',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4992 U.S. Rte 66, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:29:03',
    SlNo: '400',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:29:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:27:03',
    Latitude: '35.1249218',
    Longitude: '-103.9360063',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5195-5259 I-40 Frontage Rd, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:27:03',
    SlNo: '401',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:27:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:25:02',
    Latitude: '35.1186874',
    Longitude: '-103.9782008',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:25:02',
    SlNo: '402',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:25:02 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:23:02',
    Latitude: '35.1076036',
    Longitude: '-104.01981',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:23:02',
    SlNo: '403',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:23:02 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:21:01',
    Latitude: '35.0944732',
    Longitude: '-104.0606751',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:21:01',
    SlNo: '404',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:21:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:19:01',
    Latitude: '35.0853163',
    Longitude: '-104.1026889',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:19:01',
    SlNo: '405',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:19:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:17:00',
    Latitude: '35.0748874',
    Longitude: '-104.1448663',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:17:00',
    SlNo: '406',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:17:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:12:59',
    Latitude: '35.0695329',
    Longitude: '-104.2294362',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:12:59',
    SlNo: '407',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:12:59 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:10:58',
    Latitude: '35.0649007',
    Longitude: '-104.2732222',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:10:58',
    SlNo: '408',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:10:58 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:08:58',
    Latitude: '35.0577421',
    Longitude: '-104.3158965',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:08:58',
    SlNo: '409',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:08:58 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:06:58',
    Latitude: '35.0495025',
    Longitude: '-104.3585283',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:06:58',
    SlNo: '410',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:06:58 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:04:57',
    Latitude: '35.03613',
    Longitude: '-104.3980326',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:04:57',
    SlNo: '411',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:04:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:02:57',
    Latitude: '35.0193261',
    Longitude: '-104.4361524',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:02:57',
    SlNo: '412',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:02:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 20:00:56',
    Latitude: '35.0113074',
    Longitude: '-104.4759911',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '20:00:56',
    SlNo: '413',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:00:56 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:58:56',
    Latitude: '35.0057823',
    Longitude: '-104.5182434',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:58:56',
    SlNo: '414',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:58:56 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:56:56',
    Latitude: '34.988077',
    Longitude: '-104.5552147',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:56:56',
    SlNo: '415',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:56:56 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:54:55',
    Latitude: '34.9719936',
    Longitude: '-104.5887604',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:54:55',
    SlNo: '416',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:54:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:52:55',
    Latitude: '34.9561655',
    Longitude: '-104.6217066',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:52:55',
    SlNo: '417',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:52:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:50:54',
    Latitude: '34.945057',
    Longitude: '-104.6559349',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2428 U.S. Rte 66, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:50:54',
    SlNo: '418',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:50:54 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:48:54',
    Latitude: '34.9439555',
    Longitude: '-104.6618001',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2428 Will Rogers Dr, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:48:54',
    SlNo: '419',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:48:54 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:46:53',
    Latitude: '34.9439576',
    Longitude: '-104.6618185',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2428 Will Rogers Dr, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:46:53',
    SlNo: '420',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:46:53 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:44:52',
    Latitude: '34.9485122',
    Longitude: '-104.6829319',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '168 S 11 St, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:44:52',
    SlNo: '421',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:44:52 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:42:51',
    Latitude: '34.95484',
    Longitude: '-104.7238372',
    Speed: '79.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lucero Rd, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:42:51',
    SlNo: '422',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:42:51 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:40:50',
    Latitude: '34.9665079',
    Longitude: '-104.7682892',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:40:50',
    SlNo: '423',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:40:50 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:38:50',
    Latitude: '34.9690062',
    Longitude: '-104.812298',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:38:50',
    SlNo: '424',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:38:50 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:36:49',
    Latitude: '34.9694734',
    Longitude: '-104.8563911',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:36:49',
    SlNo: '425',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:36:49 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:34:49',
    Latitude: '34.9715882',
    Longitude: '-104.8989055',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:34:49',
    SlNo: '426',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:34:49 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:32:49',
    Latitude: '34.9737809',
    Longitude: '-104.9395766',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:32:49',
    SlNo: '427',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:32:49 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:30:49',
    Latitude: '34.9755856',
    Longitude: '-104.9764869',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:30:49',
    SlNo: '428',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:30:49 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:28:48',
    Latitude: '34.9778459',
    Longitude: '-105.0203391',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:28:48',
    SlNo: '429',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:28:48 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:26:47',
    Latitude: '34.9800416',
    Longitude: '-105.0643993',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:26:47',
    SlNo: '430',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:26:47 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:24:46',
    Latitude: '34.9822021',
    Longitude: '-105.1074859',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:24:46',
    SlNo: '431',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:24:46 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:22:46',
    Latitude: '34.9842773',
    Longitude: '-105.1489728',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:22:46',
    SlNo: '432',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:22:46 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:20:46',
    Latitude: '34.9862801',
    Longitude: '-105.190188',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:20:46',
    SlNo: '433',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:20:46 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:18:45',
    Latitude: '34.9883196',
    Longitude: '-105.2318214',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:18:45',
    SlNo: '434',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:18:45 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:16:44',
    Latitude: '34.990281',
    Longitude: '-105.2738335',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:16:44',
    SlNo: '435',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:16:44 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:14:43',
    Latitude: '34.9925313',
    Longitude: '-105.3171305',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:14:43',
    SlNo: '436',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:14:43 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:12:43',
    Latitude: '34.9946318',
    Longitude: '-105.3609718',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:12:43',
    SlNo: '437',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:12:43 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:10:43',
    Latitude: '34.996738',
    Longitude: '-105.4044813',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:10:43',
    SlNo: '438',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:10:43 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:08:42',
    Latitude: '34.9988403',
    Longitude: '-105.4486883',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:08:42',
    SlNo: '439',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:08:42 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:06:41',
    Latitude: '35.003891',
    Longitude: '-105.4905292',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:06:41',
    SlNo: '440',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:06:41 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:04:41',
    Latitude: '35.0061988',
    Longitude: '-105.529901',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:04:41',
    SlNo: '441',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:04:41 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:02:41',
    Latitude: '35.0024075',
    Longitude: '-105.5645482',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:02:41',
    SlNo: '442',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:02:41 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 19:00:40',
    Latitude: '35.0041123',
    Longitude: '-105.593092',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '19:00:40',
    SlNo: '443',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:00:40 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:58:40',
    Latitude: '35.0044416',
    Longitude: '-105.5992922',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:58:40',
    SlNo: '444',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:58:40 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:56:40',
    Latitude: '35.0059065',
    Longitude: '-105.6237173',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:56:40',
    SlNo: '445',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:56:40 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:54:52',
    Latitude: '35.0079695',
    Longitude: '-105.6606642',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:54:52',
    SlNo: '446',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:54:52 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:52:39',
    Latitude: '35.0074845',
    Longitude: '-105.6654856',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:52:39',
    SlNo: '447',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:52:39 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:50:39',
    Latitude: '35.0084814',
    Longitude: '-105.6687231',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:50:39',
    SlNo: '448',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:50:39 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:48:39',
    Latitude: '35.0085684',
    Longitude: '-105.6702214',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1 Yacht Club Dr, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:48:39',
    SlNo: '449',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:48:39 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:46:39',
    Latitude: '35.0086332',
    Longitude: '-105.6714802',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:46:39',
    SlNo: '450',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:46:39 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:44:39',
    Latitude: '35.0088767',
    Longitude: '-105.6734587',
    Speed: '4.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:44:39',
    SlNo: '451',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:44:39 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:42:38',
    Latitude: '35.0089793',
    Longitude: '-105.6758414',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:42:38',
    SlNo: '452',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:42:38 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:40:37',
    Latitude: '35.0090352',
    Longitude: '-105.6776327',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:40:37',
    SlNo: '453',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:40:37 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:38:36',
    Latitude: '35.0090837',
    Longitude: '-105.6789744',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:38:36',
    SlNo: '454',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:38:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:36:36',
    Latitude: '35.0091746',
    Longitude: '-105.6801544',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:36:36',
    SlNo: '455',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:36:36 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:34:35',
    Latitude: '35.0092112',
    Longitude: '-105.6817449',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:34:35',
    SlNo: '456',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:34:35 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:32:34',
    Latitude: '35.009353',
    Longitude: '-105.683244',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:32:34',
    SlNo: '457',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:32:34 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:30:34',
    Latitude: '35.0094612',
    Longitude: '-105.6850093',
    Speed: '3.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:30:34',
    SlNo: '458',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:30:34 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:28:33',
    Latitude: '35.0095673',
    Longitude: '-105.6873213',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:28:33',
    SlNo: '459',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:28:33 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:26:33',
    Latitude: '35.0096904',
    Longitude: '-105.6885391',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:26:33',
    SlNo: '460',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:26:33 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:24:32',
    Latitude: '35.0097349',
    Longitude: '-105.6899031',
    Speed: '4.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:24:32',
    SlNo: '461',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:24:32 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:22:31',
    Latitude: '35.0099033',
    Longitude: '-105.6926515',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:22:31',
    SlNo: '462',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:22:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:20:30',
    Latitude: '35.0099785',
    Longitude: '-105.6940048',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:20:30',
    SlNo: '463',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:20:30 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:18:29',
    Latitude: '35.0100824',
    Longitude: '-105.6955341',
    Speed: '4.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:18:29',
    SlNo: '464',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:18:29 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:16:29',
    Latitude: '35.0102131',
    Longitude: '-105.6981768',
    Speed: '8.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:16:29',
    SlNo: '465',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:16:29 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:14:28',
    Latitude: '35.0104934',
    Longitude: '-105.7027991',
    Speed: '6.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:14:28',
    SlNo: '466',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:14:28 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:12:27',
    Latitude: '35.0105252',
    Longitude: '-105.7064022',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:12:27',
    SlNo: '467',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:12:27 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:10:26',
    Latitude: '35.0103786',
    Longitude: '-105.7077849',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:10:26',
    SlNo: '468',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:10:26 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:08:26',
    Latitude: '35.0101168',
    Longitude: '-105.7089789',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:08:26',
    SlNo: '469',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:08:26 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:06:32',
    Latitude: '35.0096212',
    Longitude: '-105.7105514',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:06:32',
    SlNo: '470',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:06:32 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:04:24',
    Latitude: '35.0091802',
    Longitude: '-105.7115509',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:04:24',
    SlNo: '471',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:04:24 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:02:24',
    Latitude: '35.0086736',
    Longitude: '-105.7125625',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:02:24',
    SlNo: '472',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:02:24 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 18:00:31',
    Latitude: '35.0081058',
    Longitude: '-105.713703',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '18:00:31',
    SlNo: '473',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:00:31 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:58:23',
    Latitude: '35.0081018',
    Longitude: '-105.7136967',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:58:23',
    SlNo: '474',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:58:23 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:56:25',
    Latitude: '35.0079271',
    Longitude: '-105.714011',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:56:25',
    SlNo: '475',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:56:25 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:56:25',
    Latitude: '35.0076779',
    Longitude: '-105.7144535',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:56:25',
    SlNo: '476',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:56:25 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:56:25',
    Latitude: '35.0067936',
    Longitude: '-105.716107',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:56:25',
    SlNo: '477',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:56:25 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:56:25',
    Latitude: '35.0064116',
    Longitude: '-105.7168029',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:56:25',
    SlNo: '478',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:56:25 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:56:25',
    Latitude: '35.0060962',
    Longitude: '-105.7173451',
    Speed: '16.16 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:56:25',
    SlNo: '479',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:56:25 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:46:20',
    Latitude: '35.0041295',
    Longitude: '-105.726645',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:46:20',
    SlNo: '480',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:46:20 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:44:19',
    Latitude: '35.0045429',
    Longitude: '-105.7686629',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:44:19',
    SlNo: '481',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:44:19 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:42:18',
    Latitude: '35.0048701',
    Longitude: '-105.8111234',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:42:18',
    SlNo: '482',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:42:18 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:40:20',
    Latitude: '35.0051667',
    Longitude: '-105.8526674',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:40:20',
    SlNo: '483',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:40:20 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:38:16',
    Latitude: '35.0058211',
    Longitude: '-105.8917054',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:38:16',
    SlNo: '484',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:38:16 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:32:15',
    Latitude: '35.0046531',
    Longitude: '-106.0148141',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1 Pinto Rd, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:32:15',
    SlNo: '485',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:32:15 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:30:14',
    Latitude: '35.010202',
    Longitude: '-106.0580882',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1209 Poplar St, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:30:14',
    SlNo: '486',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:30:14 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:28:22',
    Latitude: '35.0251282',
    Longitude: '-106.0978086',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3007 U.S. Rte 66, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:28:22',
    SlNo: '487',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:28:22 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:26:13',
    Latitude: '35.0423388',
    Longitude: '-106.13698',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:26:13',
    SlNo: '488',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:26:13 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:24:13',
    Latitude: '35.058231',
    Longitude: '-106.1767188',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '30 McCall Rd, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:24:13',
    SlNo: '489',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:24:13 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:22:12',
    Latitude: '35.0729132',
    Longitude: '-106.2166503',
    Speed: '78.29 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '149 W Frontage Rd, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:22:12',
    SlNo: '490',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:22:12 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:20:12',
    Latitude: '35.0895708',
    Longitude: '-106.2578178',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2070 Dinkle Rd, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:20:12',
    SlNo: '491',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:20:12 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:18:20',
    Latitude: '35.1037074',
    Longitude: '-106.2972662',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1130 NM-333, Tijeras, NM 87059, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:18:20',
    SlNo: '492',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:18:20 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:16:11',
    Latitude: '35.1152798',
    Longitude: '-106.326351',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2 Sedillo Rd, Tijeras, NM 87059, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:16:11',
    SlNo: '493',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:16:11 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:14:10',
    Latitude: '35.100481',
    Longitude: '-106.3584566',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '111 N Zamora Rd, Tijeras, NM 87059, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:14:10',
    SlNo: '494',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:14:10 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:12:10',
    Latitude: '35.0826497',
    Longitude: '-106.384621',
    Speed: '14.91 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8 Pine View Rd, Tijeras, NM 87059, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:12:10',
    SlNo: '495',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:12:10 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:10:09',
    Latitude: '35.0804745',
    Longitude: '-106.3929447',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '467 NM-333, Tijeras, NM 87059, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:10:09',
    SlNo: '496',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:10:09 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:08:09',
    Latitude: '35.080469',
    Longitude: '-106.3929415',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '467 NM-333, Tijeras, NM 87059, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:08:09',
    SlNo: '497',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:08:09 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:06:09',
    Latitude: '35.0804744',
    Longitude: '-106.3929724',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '467 NM-333, Tijeras, NM 87059, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:06:09',
    SlNo: '498',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:06:09 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:04:08',
    Latitude: '35.0804601',
    Longitude: '-106.393127',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '467 NM-333, Tijeras, NM 87059, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:04:08',
    SlNo: '499',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:04:08 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 17:02:09',
    Latitude: '35.0671791',
    Longitude: '-106.4224092',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '273 U.S. Rte 66, Albuquerque, NM 87123, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '17:02:09',
    SlNo: '500',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:02:09 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:58:07',
    Latitude: '35.0704469',
    Longitude: '-106.4994748',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '13308 Panorama Loop NE, Albuquerque, NM 87123, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:58:07',
    SlNo: '501',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:58:07 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:56:06',
    Latitude: '35.0850001',
    Longitude: '-106.5376382',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9717 Copper Ave NE, Albuquerque, NM 87123, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:56:06',
    SlNo: '502',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:56:06 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:54:05',
    Latitude: '35.1001024',
    Longitude: '-106.5757491',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6105 Zimmerman Ave NE, Albuquerque, NM 87110, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:54:05',
    SlNo: '503',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:54:05 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:52:04',
    Latitude: '35.1072388',
    Longitude: '-106.6141461',
    Speed: '54.06 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2600 Prospect Ave NE, Albuquerque, NM 87107, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:52:04',
    SlNo: '504',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:52:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:50:04',
    Latitude: '35.1057875',
    Longitude: '-106.6458973',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Albuquerque, NM 87102, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:50:04',
    SlNo: '505',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:50:04 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:48:09',
    Latitude: '35.1058291',
    Longitude: '-106.6861399',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3322 Gabaldon Pl NW, Albuquerque, NM 87104, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:48:09',
    SlNo: '506',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:48:09 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:46:03',
    Latitude: '35.0959787',
    Longitude: '-106.7259156',
    Speed: '77.67 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7711 Fortuna Rd NW, Albuquerque, NM 87121, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:46:03',
    SlNo: '507',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:46:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:44:03',
    Latitude: '35.0764058',
    Longitude: '-106.764842',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Albuquerque, NM 87121, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:44:03',
    SlNo: '508',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:44:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:42:01',
    Latitude: '35.0601084',
    Longitude: '-106.8022939',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '13805 I-40W Frontage, Albuquerque, NM 87121, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:42:01',
    SlNo: '509',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:42:01 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:40:03',
    Latitude: '35.0536774',
    Longitude: '-106.8439758',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '16701 Central Ave NW, Albuquerque, NM 87121, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:40:03',
    SlNo: '510',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:40:03 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:38:00',
    Latitude: '35.0476519',
    Longitude: '-106.8764849',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Albuquerque, NM 87151, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:38:00',
    SlNo: '511',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:38:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:36:02',
    Latitude: '35.0401617',
    Longitude: '-106.909735',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Albuquerque, NM 87121, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:36:02',
    SlNo: '512',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:36:02 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:36:02',
    Latitude: '35.0312517',
    Longitude: '-106.9491192',
    Speed: '76.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '14314 Central Ave NW, Albuquerque, NM 87121, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:36:02',
    SlNo: '513',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:36:02 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:36:00',
    Latitude: '35.0213624',
    Longitude: '-106.9922849',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Albuquerque, NM 87121, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:36:00',
    SlNo: '514',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:36:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:36:00',
    Latitude: '35.0086891',
    Longitude: '-107.0339408',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6278-6284 I-40, Albuquerque, NM 87121, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:36:00',
    SlNo: '515',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:36:00 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:26:07',
    Latitude: '34.9826722',
    Longitude: '-107.1155264',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6018-6132 I-40, Laguna, NM 87026, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:26:07',
    SlNo: '516',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:26:07 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:23:57',
    Latitude: '34.9809719',
    Longitude: '-107.1568843',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5840-6016 I-40, Laguna, NM 87026, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:23:57',
    SlNo: '517',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:23:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:21:57',
    Latitude: '34.9874971',
    Longitude: '-107.1994858',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Laguna, NM 87026, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:21:57',
    SlNo: '518',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:21:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:19:57',
    Latitude: '34.9929059',
    Longitude: '-107.2425128',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5270-5400 I-40, Laguna, NM 87026, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:19:57',
    SlNo: '519',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:19:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:17:57',
    Latitude: '34.9934697',
    Longitude: '-107.2859095',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Laguna, NM 87026, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:17:57',
    SlNo: '520',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:17:57 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:15:56',
    Latitude: '35.010403',
    Longitude: '-107.3204512',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '32340 State Road 522, Mesita, NM 87026, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:15:56',
    SlNo: '521',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:15:56 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:13:55',
    Latitude: '35.0336171',
    Longitude: '-107.3401951',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1612 I-40, New Laguna, NM 87038, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:13:55',
    SlNo: '522',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:13:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:11:55',
    Latitude: '35.0376207',
    Longitude: '-107.3691078',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2589 Old Rte 66 Rd, Laguna, NM 87026, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:11:55',
    SlNo: '523',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:11:55 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:09:54',
    Latitude: '35.0304166',
    Longitude: '-107.3834466',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4425 I-40, New Laguna, NM 87038, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:09:54',
    SlNo: '524',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:09:54 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:07:54',
    Latitude: '35.0267568',
    Longitude: '-107.4244135',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4001-4299 I-40, New Laguna, NM 87038, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:07:54',
    SlNo: '525',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:07:54 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:05:54',
    Latitude: '35.0318285',
    Longitude: '-107.4669138',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3907 I-40, Paraje, NM 87007, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:05:54',
    SlNo: '526',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:05:54 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:03:54',
    Latitude: '35.0468544',
    Longitude: '-107.506482',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3701-3719 I-40, Paraje, NM 87007, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:03:54',
    SlNo: '527',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:03:54 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 16:01:54',
    Latitude: '35.073076',
    Longitude: '-107.5367089',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-40, Casa Blanca, NM 87007, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '16:01:54',
    SlNo: '528',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:01:54 PM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:40:16',
    Latitude: '35.263544',
    Longitude: '-112.1933917',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:40:16',
    SlNo: '708',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:40:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:38:17',
    Latitude: '35.2635438',
    Longitude: '-112.1933916',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:38:17',
    SlNo: '709',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:38:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:36:16',
    Latitude: '35.2635447',
    Longitude: '-112.1933919',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:36:16',
    SlNo: '710',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:36:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:34:15',
    Latitude: '35.2635448',
    Longitude: '-112.1933919',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:34:15',
    SlNo: '711',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:34:15 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:32:14',
    Latitude: '35.2635302',
    Longitude: '-112.1934135',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:32:14',
    SlNo: '712',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:32:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:30:13',
    Latitude: '35.2634938',
    Longitude: '-112.1933693',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:30:13',
    SlNo: '713',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:30:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:28:13',
    Latitude: '35.2635035',
    Longitude: '-112.1933878',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:28:13',
    SlNo: '714',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:28:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:26:12',
    Latitude: '35.2635461',
    Longitude: '-112.1934167',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:26:12',
    SlNo: '715',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:26:12 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:24:11',
    Latitude: '35.2635592',
    Longitude: '-112.1934116',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:24:11',
    SlNo: '716',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:24:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:22:11',
    Latitude: '35.2635473',
    Longitude: '-112.1934369',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:22:11',
    SlNo: '717',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:22:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:20:11',
    Latitude: '35.2634941',
    Longitude: '-112.1934311',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:20:11',
    SlNo: '718',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:20:11 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:18:10',
    Latitude: '35.2635265',
    Longitude: '-112.1934395',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:18:10',
    SlNo: '719',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:18:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:16:09',
    Latitude: '35.2635231',
    Longitude: '-112.1934845',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:16:09',
    SlNo: '720',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:16:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:14:08',
    Latitude: '35.2635235',
    Longitude: '-112.1934849',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:14:08',
    SlNo: '721',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:14:08 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:12:07',
    Latitude: '35.2635254',
    Longitude: '-112.193487',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:12:07',
    SlNo: '722',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:12:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:10:06',
    Latitude: '35.2635521',
    Longitude: '-112.1934349',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:10:06',
    SlNo: '723',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:10:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:08:05',
    Latitude: '35.2635542',
    Longitude: '-112.1934393',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:08:05',
    SlNo: '724',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:08:05 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:06:03',
    Latitude: '35.2635495',
    Longitude: '-112.1934363',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:06:03',
    SlNo: '725',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:06:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:04:03',
    Latitude: '35.2635518',
    Longitude: '-112.1934358',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:04:03',
    SlNo: '726',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:04:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:02:02',
    Latitude: '35.2635525',
    Longitude: '-112.1934371',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:02:02',
    SlNo: '727',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:02:02 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 09:00:01',
    Latitude: '35.263551',
    Longitude: '-112.1934248',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '09:00:01',
    SlNo: '728',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 09:00:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:58:00',
    Latitude: '35.2635528',
    Longitude: '-112.1934249',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:58:00',
    SlNo: '729',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:58:00 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:55:59',
    Latitude: '35.2635206',
    Longitude: '-112.1934284',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:55:59',
    SlNo: '730',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:55:59 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:53:58',
    Latitude: '35.2635385',
    Longitude: '-112.1934397',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:53:58',
    SlNo: '731',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:53:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:51:57',
    Latitude: '35.2635437',
    Longitude: '-112.1934459',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:51:57',
    SlNo: '732',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:51:57 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:49:56',
    Latitude: '35.2635455',
    Longitude: '-112.1934469',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:49:56',
    SlNo: '733',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:49:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:47:56',
    Latitude: '35.2635348',
    Longitude: '-112.1934116',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:47:56',
    SlNo: '734',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:47:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:45:55',
    Latitude: '35.263559',
    Longitude: '-112.1934088',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:45:55',
    SlNo: '735',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:45:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:43:54',
    Latitude: '35.263559',
    Longitude: '-112.1934095',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:43:54',
    SlNo: '736',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:43:54 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:41:54',
    Latitude: '35.2635068',
    Longitude: '-112.1933918',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:41:54',
    SlNo: '737',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:41:54 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:40:00',
    Latitude: '35.2635064',
    Longitude: '-112.1934279',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:40:00',
    SlNo: '738',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:40:00 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:37:52',
    Latitude: '35.2635053',
    Longitude: '-112.1934256',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:37:52',
    SlNo: '739',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:37:52 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:35:51',
    Latitude: '35.2634938',
    Longitude: '-112.1934505',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:35:51',
    SlNo: '740',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:35:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:33:50',
    Latitude: '35.2634904',
    Longitude: '-112.1934466',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:33:50',
    SlNo: '741',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:33:50 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:31:49',
    Latitude: '35.263499',
    Longitude: '-112.1934273',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:31:49',
    SlNo: '742',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:31:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:29:48',
    Latitude: '35.2634989',
    Longitude: '-112.1934141',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:29:48',
    SlNo: '743',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:29:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:27:47',
    Latitude: '35.2634866',
    Longitude: '-112.1934227',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:27:47',
    SlNo: '744',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:27:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:25:46',
    Latitude: '35.2635233',
    Longitude: '-112.1934259',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:25:46',
    SlNo: '745',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:25:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:23:49',
    Latitude: '35.2635211',
    Longitude: '-112.1934247',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:23:49',
    SlNo: '746',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:23:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:21:44',
    Latitude: '35.2635241',
    Longitude: '-112.1934505',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:21:44',
    SlNo: '747',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:21:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:19:43',
    Latitude: '35.2635386',
    Longitude: '-112.1934097',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:19:43',
    SlNo: '748',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:19:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:17:43',
    Latitude: '35.2635278',
    Longitude: '-112.193426',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:17:43',
    SlNo: '749',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:17:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:15:42',
    Latitude: '35.2635354',
    Longitude: '-112.1934199',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:15:42',
    SlNo: '750',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:15:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:11:41',
    Latitude: '35.2635197',
    Longitude: '-112.1934331',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:11:41',
    SlNo: '751',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:11:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:09:40',
    Latitude: '35.26352',
    Longitude: '-112.1934325',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:09:40',
    SlNo: '752',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:09:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:07:39',
    Latitude: '35.2635225',
    Longitude: '-112.1934301',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:07:39',
    SlNo: '753',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:07:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:05:38',
    Latitude: '35.2635202',
    Longitude: '-112.1934333',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:05:38',
    SlNo: '754',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:05:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:03:37',
    Latitude: '35.2635169',
    Longitude: '-112.1934348',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:03:37',
    SlNo: '755',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:03:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 08:01:36',
    Latitude: '35.2635154',
    Longitude: '-112.1934343',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '08:01:36',
    SlNo: '756',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 08:01:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:59:35',
    Latitude: '35.2635153',
    Longitude: '-112.1934348',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:59:35',
    SlNo: '757',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:59:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:57:34',
    Latitude: '35.2635207',
    Longitude: '-112.1934378',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:57:34',
    SlNo: '758',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:57:34 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:55:37',
    Latitude: '35.2635087',
    Longitude: '-112.1934522',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:55:37',
    SlNo: '759',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:55:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:53:33',
    Latitude: '35.263507',
    Longitude: '-112.1934501',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:53:33',
    SlNo: '760',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:53:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:51:31',
    Latitude: '35.263518',
    Longitude: '-112.1934093',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:51:31',
    SlNo: '761',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:51:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:49:31',
    Latitude: '35.2635316',
    Longitude: '-112.1934535',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:49:31',
    SlNo: '762',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:49:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:47:31',
    Latitude: '35.263511',
    Longitude: '-112.1934658',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:47:31',
    SlNo: '763',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:47:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:45:30',
    Latitude: '35.2634682',
    Longitude: '-112.1934175',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:45:30',
    SlNo: '764',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:45:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:43:30',
    Latitude: '35.2635041',
    Longitude: '-112.1934168',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:43:30',
    SlNo: '765',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:43:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:41:29',
    Latitude: '35.2635038',
    Longitude: '-112.1934156',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:41:29',
    SlNo: '766',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:41:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:39:29',
    Latitude: '35.2635275',
    Longitude: '-112.1934585',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:39:29',
    SlNo: '767',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:39:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:37:29',
    Latitude: '35.263527',
    Longitude: '-112.1934564',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:37:29',
    SlNo: '768',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:37:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:35:28',
    Latitude: '35.2635338',
    Longitude: '-112.1934357',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:35:28',
    SlNo: '769',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:35:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:33:27',
    Latitude: '35.2635316',
    Longitude: '-112.1934656',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:33:27',
    SlNo: '770',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:33:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:31:27',
    Latitude: '35.2635292',
    Longitude: '-112.1934657',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:31:27',
    SlNo: '771',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:31:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:29:31',
    Latitude: '35.2635309',
    Longitude: '-112.1934695',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:29:31',
    SlNo: '772',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:29:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:27:26',
    Latitude: '35.2635314',
    Longitude: '-112.1934656',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:27:26',
    SlNo: '773',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:27:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:25:26',
    Latitude: '35.2635391',
    Longitude: '-112.1934827',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:25:26',
    SlNo: '774',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:25:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:23:25',
    Latitude: '35.263535',
    Longitude: '-112.1934476',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:23:25',
    SlNo: '775',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:23:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:21:24',
    Latitude: '35.2635532',
    Longitude: '-112.1934226',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:21:24',
    SlNo: '776',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:21:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:19:23',
    Latitude: '35.2635645',
    Longitude: '-112.1934127',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:19:23',
    SlNo: '777',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:19:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:17:22',
    Latitude: '35.2635488',
    Longitude: '-112.1934246',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:17:22',
    SlNo: '778',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:17:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:15:21',
    Latitude: '35.263529',
    Longitude: '-112.1933508',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:15:21',
    SlNo: '779',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:15:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:13:20',
    Latitude: '35.2634985',
    Longitude: '-112.1934001',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:13:20',
    SlNo: '780',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:13:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:11:19',
    Latitude: '35.2635144',
    Longitude: '-112.1934193',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:11:19',
    SlNo: '781',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:11:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:09:18',
    Latitude: '35.26347',
    Longitude: '-112.1934293',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:09:18',
    SlNo: '782',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:09:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:07:17',
    Latitude: '35.2634753',
    Longitude: '-112.193433',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:07:17',
    SlNo: '783',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:07:17 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:05:16',
    Latitude: '35.2634761',
    Longitude: '-112.1934335',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:05:16',
    SlNo: '784',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:05:16 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:03:15',
    Latitude: '35.2634744',
    Longitude: '-112.1934319',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:03:15',
    SlNo: '785',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:03:15 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 07:01:14',
    Latitude: '35.2634737',
    Longitude: '-112.1934323',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '07:01:14',
    SlNo: '786',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 07:01:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:59:14',
    Latitude: '35.2634745',
    Longitude: '-112.1934319',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:59:14',
    SlNo: '787',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:59:14 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:57:12',
    Latitude: '35.2634734',
    Longitude: '-112.1934468',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:57:12',
    SlNo: '788',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:57:12 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:55:13',
    Latitude: '35.2635223',
    Longitude: '-112.1934633',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:55:13',
    SlNo: '789',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:55:13 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:53:10',
    Latitude: '35.2635446',
    Longitude: '-112.1934535',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:53:10',
    SlNo: '790',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:53:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:51:10',
    Latitude: '35.2635311',
    Longitude: '-112.1934481',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:51:10',
    SlNo: '791',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:51:10 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:49:09',
    Latitude: '35.2635183',
    Longitude: '-112.1934425',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:49:09',
    SlNo: '792',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:49:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:47:08',
    Latitude: '35.2635174',
    Longitude: '-112.1934443',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:47:08',
    SlNo: '793',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:47:08 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:45:07',
    Latitude: '35.2635208',
    Longitude: '-112.193444',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:45:07',
    SlNo: '794',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:45:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:43:07',
    Latitude: '35.2635188',
    Longitude: '-112.1934442',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:43:07',
    SlNo: '795',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:43:07 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:41:06',
    Latitude: '35.2635176',
    Longitude: '-112.1934459',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:41:06',
    SlNo: '796',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:41:06 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:37:04',
    Latitude: '35.2635046',
    Longitude: '-112.1934787',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:37:04',
    SlNo: '797',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:37:04 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:35:03',
    Latitude: '35.2635138',
    Longitude: '-112.1934758',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:35:03',
    SlNo: '798',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:35:03 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:33:02',
    Latitude: '35.263488',
    Longitude: '-112.1934773',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:33:02',
    SlNo: '799',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:33:02 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:31:01',
    Latitude: '35.2635025',
    Longitude: '-112.1934429',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:31:01',
    SlNo: '800',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:31:01 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:29:09',
    Latitude: '35.2634879',
    Longitude: '-112.1934232',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:29:09',
    SlNo: '801',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:29:09 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:27:00',
    Latitude: '35.2634915',
    Longitude: '-112.1934289',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:27:00',
    SlNo: '802',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:27:00 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:24:59',
    Latitude: '35.2634868',
    Longitude: '-112.1934346',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:24:59',
    SlNo: '803',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:24:59 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:22:58',
    Latitude: '35.26349',
    Longitude: '-112.1934249',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:22:58',
    SlNo: '804',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:22:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:20:58',
    Latitude: '35.263521',
    Longitude: '-112.1934217',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:20:58',
    SlNo: '805',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:20:58 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:18:57',
    Latitude: '35.2635387',
    Longitude: '-112.1934334',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:18:57',
    SlNo: '806',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:18:57 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:16:56',
    Latitude: '35.2635528',
    Longitude: '-112.1934692',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:16:56',
    SlNo: '807',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:16:56 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:14:55',
    Latitude: '35.2635507',
    Longitude: '-112.1934783',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:14:55',
    SlNo: '808',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:14:55 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:12:54',
    Latitude: '35.2635312',
    Longitude: '-112.1934045',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:12:54',
    SlNo: '809',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:12:54 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:10:53',
    Latitude: '35.2635296',
    Longitude: '-112.1934018',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:10:53',
    SlNo: '810',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:10:53 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:06:51',
    Latitude: '35.2634939',
    Longitude: '-112.1933758',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:06:51',
    SlNo: '811',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:06:51 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:04:50',
    Latitude: '35.2634637',
    Longitude: '-112.1933937',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:04:50',
    SlNo: '812',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:04:50 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:02:49',
    Latitude: '35.2634539',
    Longitude: '-112.1934218',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:02:49',
    SlNo: '813',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:02:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 06:00:49',
    Latitude: '35.2634925',
    Longitude: '-112.1934563',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '06:00:49',
    SlNo: '814',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 06:00:49 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:58:48',
    Latitude: '35.2635076',
    Longitude: '-112.1934342',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:58:48',
    SlNo: '815',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:58:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:56:48',
    Latitude: '35.263517',
    Longitude: '-112.1934412',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:56:48',
    SlNo: '816',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:56:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:54:48',
    Latitude: '35.2635302',
    Longitude: '-112.1934466',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:54:48',
    SlNo: '817',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:54:48 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:52:47',
    Latitude: '35.2635263',
    Longitude: '-112.1934178',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:52:47',
    SlNo: '818',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:52:47 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:50:46',
    Latitude: '35.263528',
    Longitude: '-112.193423',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:50:46',
    SlNo: '819',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:50:46 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:48:45',
    Latitude: '35.2635281',
    Longitude: '-112.193448',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:48:45',
    SlNo: '820',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:48:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:46:45',
    Latitude: '35.2635317',
    Longitude: '-112.1934519',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:46:45',
    SlNo: '821',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:46:45 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:44:44',
    Latitude: '35.2635126',
    Longitude: '-112.1934667',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:44:44',
    SlNo: '822',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:44:44 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:42:43',
    Latitude: '35.2634832',
    Longitude: '-112.193454',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:42:43',
    SlNo: '823',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:42:43 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:40:42',
    Latitude: '35.2635138',
    Longitude: '-112.1934428',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:40:42',
    SlNo: '824',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:40:42 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:38:41',
    Latitude: '35.263511',
    Longitude: '-112.1934564',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:38:41',
    SlNo: '825',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:38:41 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:36:40',
    Latitude: '35.2634944',
    Longitude: '-112.1934852',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:36:40',
    SlNo: '826',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:36:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:34:40',
    Latitude: '35.2635058',
    Longitude: '-112.1934735',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:34:40',
    SlNo: '827',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:34:40 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:32:39',
    Latitude: '35.2635324',
    Longitude: '-112.1934486',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:32:39',
    SlNo: '828',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:32:39 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:30:38',
    Latitude: '35.2635049',
    Longitude: '-112.1934176',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:30:38',
    SlNo: '829',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:30:38 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:28:37',
    Latitude: '35.2635066',
    Longitude: '-112.1934169',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:28:37',
    SlNo: '830',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:28:37 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:26:36',
    Latitude: '35.2635065',
    Longitude: '-112.1934156',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:26:36',
    SlNo: '831',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:26:36 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:24:35',
    Latitude: '35.2635035',
    Longitude: '-112.1934144',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:24:35',
    SlNo: '832',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:24:35 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:22:34',
    Latitude: '35.263518',
    Longitude: '-112.1934322',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:22:34',
    SlNo: '833',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:22:34 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:20:33',
    Latitude: '35.2635158',
    Longitude: '-112.1934365',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:20:33',
    SlNo: '834',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:20:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:18:33',
    Latitude: '35.2635308',
    Longitude: '-112.193471',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:18:33',
    SlNo: '835',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:18:33 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:16:32',
    Latitude: '35.2635301',
    Longitude: '-112.1934541',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:16:32',
    SlNo: '836',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:16:32 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:14:31',
    Latitude: '35.2635431',
    Longitude: '-112.1934343',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:14:31',
    SlNo: '837',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:14:31 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:12:30',
    Latitude: '35.2635321',
    Longitude: '-112.1934367',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:12:30',
    SlNo: '838',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:12:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:10:30',
    Latitude: '35.2635421',
    Longitude: '-112.1934544',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:10:30',
    SlNo: '839',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:10:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:08:29',
    Latitude: '35.263539',
    Longitude: '-112.1934566',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:08:29',
    SlNo: '840',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:08:29 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:06:28',
    Latitude: '35.2635374',
    Longitude: '-112.1934614',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:06:28',
    SlNo: '841',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:06:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:04:28',
    Latitude: '35.2635323',
    Longitude: '-112.1934176',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:04:28',
    SlNo: '842',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:04:28 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:02:26',
    Latitude: '35.2635502',
    Longitude: '-112.1934711',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:02:26',
    SlNo: '843',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:02:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 05:00:26',
    Latitude: '35.2635498',
    Longitude: '-112.1934728',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '05:00:26',
    SlNo: '844',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 05:00:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:58:26',
    Latitude: '35.2635498',
    Longitude: '-112.1934721',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:58:26',
    SlNo: '845',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:58:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:56:26',
    Latitude: '35.2635343',
    Longitude: '-112.1934755',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:56:26',
    SlNo: '846',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:56:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:54:26',
    Latitude: '35.2635108',
    Longitude: '-112.1934681',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:54:26',
    SlNo: '847',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:54:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:52:27',
    Latitude: '35.2635339',
    Longitude: '-112.193442',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:52:27',
    SlNo: '848',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:52:27 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:50:26',
    Latitude: '35.2635092',
    Longitude: '-112.1934259',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:50:26',
    SlNo: '849',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:50:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:48:26',
    Latitude: '35.2635099',
    Longitude: '-112.1934253',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:48:26',
    SlNo: '850',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:48:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:46:26',
    Latitude: '35.2634897',
    Longitude: '-112.1934201',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:46:26',
    SlNo: '851',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:46:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:44:26',
    Latitude: '35.2635331',
    Longitude: '-112.1933973',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:44:26',
    SlNo: '852',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:44:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:42:26',
    Latitude: '35.2635513',
    Longitude: '-112.1934192',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:42:26',
    SlNo: '853',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:42:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:40:26',
    Latitude: '35.2635651',
    Longitude: '-112.1934829',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:40:26',
    SlNo: '854',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:40:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:38:26',
    Latitude: '35.2635516',
    Longitude: '-112.193467',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:38:26',
    SlNo: '855',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:38:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:36:26',
    Latitude: '35.2635373',
    Longitude: '-112.1934358',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:36:26',
    SlNo: '856',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:36:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:34:26',
    Latitude: '35.2635107',
    Longitude: '-112.1934316',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:34:26',
    SlNo: '857',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:34:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:32:26',
    Latitude: '35.2635002',
    Longitude: '-112.1934074',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:32:26',
    SlNo: '858',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:32:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:30:30',
    Latitude: '35.2635213',
    Longitude: '-112.1933992',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:30:30',
    SlNo: '859',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:30:30 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:28:26',
    Latitude: '35.2635131',
    Longitude: '-112.1934142',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:28:26',
    SlNo: '860',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:28:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:26:26',
    Latitude: '35.2635495',
    Longitude: '-112.1934526',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:26:26',
    SlNo: '861',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:26:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:22:26',
    Latitude: '35.2635235',
    Longitude: '-112.1934547',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:22:26',
    SlNo: '862',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:22:26 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:18:25',
    Latitude: '35.2634979',
    Longitude: '-112.1934406',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:18:25',
    SlNo: '863',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:18:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:16:25',
    Latitude: '35.2635063',
    Longitude: '-112.1934548',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:16:25',
    SlNo: '864',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:16:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:14:25',
    Latitude: '35.263524',
    Longitude: '-112.1935052',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:14:25',
    SlNo: '865',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:14:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:12:25',
    Latitude: '35.2635384',
    Longitude: '-112.1935121',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:12:25',
    SlNo: '866',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:12:25 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:10:24',
    Latitude: '35.2635169',
    Longitude: '-112.1934362',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1055 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:10:24',
    SlNo: '867',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:10:24 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:08:23',
    Latitude: '35.2634997',
    Longitude: '-112.1934132',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:08:23',
    SlNo: '868',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:08:23 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:06:22',
    Latitude: '35.2635438',
    Longitude: '-112.1934028',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:06:22',
    SlNo: '869',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:06:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:04:22',
    Latitude: '35.2635454',
    Longitude: '-112.1933988',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:04:22',
    SlNo: '870',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:04:22 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:02:21',
    Latitude: '35.2635487',
    Longitude: '-112.1933972',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:02:21',
    SlNo: '871',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:02:21 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 04:00:20',
    Latitude: '35.2635487',
    Longitude: '-112.1933969',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '04:00:20',
    SlNo: '872',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 04:00:20 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:58:19',
    Latitude: '35.2635485',
    Longitude: '-112.1933945',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:58:19',
    SlNo: '873',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:58:19 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:56:18',
    Latitude: '35.2635454',
    Longitude: '-112.1933951',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:56:18',
    SlNo: '874',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:56:18 AM',
  },
  {
    DeviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/17/2021 03:54:17',
    Latitude: '35.2635566',
    Longitude: '-112.1933996',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1060 N Grand Canyon Blvd, Williams, AZ 86046, USA',
    Icon_Status: 'A',
    Date: '06/17/2021 00:00:00',
    Time: '03:54:17',
    SlNo: '875',
    Asset_Name: 'BH005',
    ExcludeDate: null,
    DisplayDate: '06/17/2021 03:54:17 AM',
  },
];

export default data;
