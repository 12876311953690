import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import './scss/ErrorPopup.scss';

const ErrorPopup = (props) => {
  const { header = 'Error', messageBody = 'Please try again.', handleClose } = props;
  return (
    <Modal show onHide={handleClose} animation={false} className="error-modal">
      {/* <Modal.Header closeButton>
        <Modal.Title
          style={{
            color: 'red',
            fontWeight: 'strong',
          }}
        >
          {header}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{messageBody}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer> */}
      <div className="error-popup">
        <div className="close-btn">
          {/* <btn type="botton" onClick={handleClose}>
            close
          </btn> */}
          <i className="fas fa-times" onClick={handleClose} />
        </div>
        <div className="header"> {header} </div>
        <div className="message">{messageBody}</div>
      </div>
    </Modal>
  );
};

export default ErrorPopup;
ErrorPopup.propTypes = {
  header: PropTypes.string,
  messageBody: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};
