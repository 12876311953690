import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BrowserRouter as Router } from 'react-router-dom';
import Loader from './core/loader/Loader';
import { addUsername, setContactData } from './features/login/loginSlice';
import ErrorPopup from './core/popups/ErrorPopup';
import { setErrorMessage } from './core/popups/Error500Slice';
import authUtility from './utils/auth';
import basicFetch from './api/basicFetch';
import * as API from './api/API';
import './App.css';
import history from './app/history';
import Routes from './app/routes';

const mapStateToProps = (state) => {
  return {
    loader: {
      loading: state.loader.loading,
    },
    errorMessage: {
      showing: state.errorMessage.showing,
      header: state.errorMessage.header,
      message: state.errorMessage.message,
    },
  };
};

window.addEventListener('error', (e) => {
  if (/Loading chunk [\d]+ failed/.test(e.message)) {
    window.location.reload();
  }
});

const storeMeResult = async () => {
  const resJson = await basicFetch(`${API.DASHCAM_MASTER_ME}`);
  if (resJson.data !== undefined) {
    return authUtility.storeCompanyInfo(resJson.data);
  }
  return {};
};

const mapDispatchToProps = { addUsername, setContactData, setErrorMessage };

function App(props) {
  const { loader, errorMessage } = props;
  const [isBusy, setBusy] = useState(true);

  authUtility.getAndStoreTokenFromUrlQuery();
  useEffect(async () => {
    const authItem = authUtility.getLoginCredentials();
    if (authItem && authItem.token) {
      setBusy(true);
      loader.loading = true;
      try {
        const meResult = await storeMeResult();
        props.setContactData(meResult);
        setBusy(false);
        loader.loading = false;
      } catch {
        setBusy(false);
        loader.loading = false;
        localStorage.clear();
        window.location.assign(`/Login`);
      }
    } else {
      setBusy(false);
      if (window.location.pathname !== '/Login') {
        localStorage.setItem('splashURL', window.location.pathname);
      }
    }
    if (authItem) {
      props.addUsername(authItem.userName);
    }
  }, []);
  return (
    <div>
      {loader.loading && (
        <div>
          <Loader />
        </div>
      )}
      <div
        style={{
          display: 'block',
          opacity: loader.loading ? '0.2' : '1',
        }}
      >
        {!isBusy && (
          <Router history={history}>
            <Suspense fallback={<Loader />}>
              <Routes />
            </Suspense>
          </Router>
        )}
        {errorMessage.showing && (
          <div>
            <ErrorPopup
              header={errorMessage.header}
              messageBody={errorMessage.message}
              handleClose={() => {
                errorMessage.showing = false;
                props.setErrorMessage({
                  error: false,
                  errorHeader: undefined,
                  errorBody: undefined,
                });
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);

App.propTypes = {
  loader: PropTypes.shape({ loading: PropTypes.bool.isRequired }).isRequired,
  errorMessage: PropTypes.shape({
    showing: PropTypes.bool.isRequired,
    header: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
  }).isRequired,
  addUsername: PropTypes.func.isRequired,
  setContactData: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};
