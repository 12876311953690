import { createSlice } from '@reduxjs/toolkit';

export const incidentDetailSlice = createSlice({
  name: 'incidentDetail',
  initialState: {
    isDetailSelected: false,
    incidentStatus: '',
    incidentStatusIcon: '',
    assetName: '',
    incidentCreatedDate: '',
    speed: '',
    deviceTemperature: '',
    backupBatteryVoltege: '',
    batteryPercentage: '',
    lat: '',
    long: '',
  },
  reducers: {
    setIsdetailSelected: (state, action) => {
      state.isDetailSelected = action.payload;
    },
    setIncidentStatus: (state, action) => {
      state.incidentStatus = action.payload;
    },
    setIncidentStatusIcon: (state, action) => {
      state.incidentStatusIcon = action.payload;
    },
    setAssetName: (state, action) => {
      state.assetName = action.payload;
    },
    setIncidentCreatedDate: (state, action) => {
      state.incidentCreatedDate = action.payload;
    },
    setSpeed: (state, action) => {
      state.speed = action.payload;
    },
    setDeviceTemperature: (state, action) => {
      state.deviceTemperature = action.payload;
    },
    setBackupBatteryVoltage: (state, action) => {
      state.backupBatteryVoltege = action.payload;
    },
    setBatteryPercentage: (state, action) => {
      state.batteryPercentage = action.payload;
    },
    setLatitude: (state, action) => {
      state.lat = action.payload;
    },
    setLongitude: (state, action) => {
      state.long = action.payload;
    },
  },
});

export const {
  setIsdetailSelected,
  setAssetName,
  setBackupBatteryVoltage,
  setBatteryPercentage,
  setDeviceTemperature,
  setIncidentCreatedDate,
  setIncidentStatus,
  setIncidentStatusIcon,
  setSpeed,
  setLatitude,
  setLongitude,
} = incidentDetailSlice.actions;
export default incidentDetailSlice.reducer;
