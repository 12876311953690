import { createSlice } from '@reduxjs/toolkit';

const initialContactDataState = {
  contactId: 0,
  branchId: 0,
  companyId: 0,
  contactLogoPath: '',
  branchLogoPath: '',
  companyLogoPath: '',
  contactName: '',
  branchName: '',
  companyName: '',
  roleId: 0,
  roleName: '',
  contactType: '',
  isTMSLogin: false,
  isHOSLogin: false,
  isTelematicsLogin: false,
  wialonToken: '',
  isLocation: false,
  accountId: 0,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    username: '',
    logoutBox: false,
    contactData: { ...initialContactDataState },
  },
  reducers: {
    addUsername: (state, action) => {
      state.username = action.payload;
    },
    clearLogin: (state) => {
      state.username = '';
    },
    setContactData: (state, action) => {
      state.contactData = action.payload;
    },
    setLogoutBox: (state, action) => {
      state.logoutBox = action.payload;
    },
    clearContactData: (state) => {
      state.contactData = {
        ...initialContactDataState,
      };
    },
  },
});

export const { addUsername, setLogoutBox, setContactData, clearContactData } = loginSlice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount) => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectUsername = (state) => state.login.username;
export const selectContactData = (state) => state.login.contactData;

export default loginSlice.reducer;
