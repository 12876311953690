const data = [
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:43:46',
    Latitude: '35.0697969',
    Longitude: '-106.2404062',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '31 Church St E, Edgewood, NM 87015',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:43:46',
    SlNo: '1',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:43:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:42:45',
    Latitude: '35.0718066',
    Longitude: '-106.1901265',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:42:45',
    SlNo: '2',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:42:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:41:46',
    Latitude: '35.0718097',
    Longitude: '-106.1901175',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:41:46',
    SlNo: '3',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:41:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:41:25',
    Latitude: '35.0718099',
    Longitude: '-106.1901154',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:41:25',
    SlNo: '4',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:41:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:39:46',
    Latitude: '35.0718105',
    Longitude: '-106.1901799',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:39:46',
    SlNo: '5',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:39:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:38:45',
    Latitude: '35.0718111',
    Longitude: '-106.1901797',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:38:45',
    SlNo: '6',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:38:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:37:45',
    Latitude: '35.0718188',
    Longitude: '-106.1900519',
    Speed: '4.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:37:45',
    SlNo: '7',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:37:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:36:22',
    Latitude: '35.0719142',
    Longitude: '-106.1875354',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '66 NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:36:22',
    SlNo: '8',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:36:22 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:35:45',
    Latitude: '35.0716268',
    Longitude: '-106.187393',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '66 NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:35:45',
    SlNo: '9',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:35:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:35:22',
    Latitude: '35.0711989',
    Longitude: '-106.187384',
    Speed: '3.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '66 NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:35:22',
    SlNo: '10',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:35:22 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:33:45',
    Latitude: '35.0719257',
    Longitude: '-106.1893002',
    Speed: '7.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '66 NM-344, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:33:45',
    SlNo: '11',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:33:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:32:45',
    Latitude: '35.06875',
    Longitude: '-106.1914272',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '8 Ridgewood Rd, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:32:45',
    SlNo: '12',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:32:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:31:42',
    Latitude: '35.0634035',
    Longitude: '-106.1897459',
    Speed: '6.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '11 E Frontage Rd, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:31:42',
    SlNo: '13',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:31:42 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:31:22',
    Latitude: '35.0618896',
    Longitude: '-106.1861806',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2 Cactus Rd, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:31:22',
    SlNo: '14',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:31:22 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:29:42',
    Latitude: '35.0500829',
    Longitude: '-106.1554205',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2131 Old US 66, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:29:42',
    SlNo: '15',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:29:42 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:28:49',
    Latitude: '35.042729',
    Longitude: '-106.1371114',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Edgewood, NM 87015, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:28:49',
    SlNo: '16',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:28:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:27:42',
    Latitude: '35.0346366',
    Longitude: '-106.118252',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2242 U.S. Rte 66, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:27:42',
    SlNo: '17',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:27:42 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:26:22',
    Latitude: '35.0236425',
    Longitude: '-106.0936883',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '323 Abrahames Rd, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:26:22',
    SlNo: '18',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:26:22 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:25:42',
    Latitude: '35.0183801',
    Longitude: '-106.0807722',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2407 U.S. Rte 66, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:25:42',
    SlNo: '19',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:25:42 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:25:22',
    Latitude: '35.0161188',
    Longitude: '-106.0740526',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2201 US Rt 66 Loop, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:25:22',
    SlNo: '20',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:25:22 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:23:42',
    Latitude: '35.0080142',
    Longitude: '-106.0395845',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '315 Ida St, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:23:42',
    SlNo: '21',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:23:42 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:22:42',
    Latitude: '35.0056516',
    Longitude: '-106.0182133',
    Speed: '43.50 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:22:42',
    SlNo: '22',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:22:42 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:21:30',
    Latitude: '35.0048517',
    Longitude: '-105.9926665',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:21:30',
    SlNo: '23',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:21:30 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:14:00',
    Latitude: '35.1866123',
    Longitude: '-102.0110253',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:14:00',
    SlNo: '562',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:14:00 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:12:59',
    Latitude: '35.1866031',
    Longitude: '-102.0109868',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:12:59',
    SlNo: '563',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:12:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:11:59',
    Latitude: '35.186603',
    Longitude: '-102.0109866',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:11:59',
    SlNo: '564',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:11:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:11:17',
    Latitude: '35.1865928',
    Longitude: '-102.0110055',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:11:17',
    SlNo: '565',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:11:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:09:59',
    Latitude: '35.1865923',
    Longitude: '-102.0110053',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:09:59',
    SlNo: '566',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:09:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:09:39',
    Latitude: '35.1865911',
    Longitude: '-102.0110205',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:09:39',
    SlNo: '567',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:09:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:07:59',
    Latitude: '35.1865861',
    Longitude: '-102.0110029',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:07:59',
    SlNo: '568',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:07:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:07:02',
    Latitude: '35.1865815',
    Longitude: '-102.0109917',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:07:02',
    SlNo: '569',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:07:02 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:05:59',
    Latitude: '35.1865945',
    Longitude: '-102.0109961',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:05:59',
    SlNo: '570',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:05:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:05:39',
    Latitude: '35.1865953',
    Longitude: '-102.0110196',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:05:39',
    SlNo: '571',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:05:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:03:59',
    Latitude: '35.1865981',
    Longitude: '-102.0110214',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:03:59',
    SlNo: '572',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:03:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:01:59',
    Latitude: '35.1865923',
    Longitude: '-102.0110013',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:01:59',
    SlNo: '573',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:01:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:01:17',
    Latitude: '35.1865887',
    Longitude: '-102.0109991',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:01:17',
    SlNo: '574',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:01:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:59:59',
    Latitude: '35.1865863',
    Longitude: '-102.0109928',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:59:59',
    SlNo: '575',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:59:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:59:39',
    Latitude: '35.1865866',
    Longitude: '-102.0109931',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:59:39',
    SlNo: '576',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:59:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:57:59',
    Latitude: '35.186574',
    Longitude: '-102.01103',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:57:59',
    SlNo: '577',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:57:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:56:59',
    Latitude: '35.1865971',
    Longitude: '-102.0109574',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:56:59',
    SlNo: '578',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:56:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:55:59',
    Latitude: '35.1866091',
    Longitude: '-102.01099',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:55:59',
    SlNo: '579',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:55:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:55:39',
    Latitude: '35.1865843',
    Longitude: '-102.0110041',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:55:39',
    SlNo: '580',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:55:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:53:59',
    Latitude: '35.1865402',
    Longitude: '-102.0110393',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:53:59',
    SlNo: '581',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:53:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:52:59',
    Latitude: '35.1865322',
    Longitude: '-102.0110573',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:52:59',
    SlNo: '582',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:52:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:51:59',
    Latitude: '35.1865499',
    Longitude: '-102.0110744',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:51:59',
    SlNo: '583',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:51:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:51:23',
    Latitude: '35.1865727',
    Longitude: '-102.0110506',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:51:23',
    SlNo: '584',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:51:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:49:59',
    Latitude: '35.1866011',
    Longitude: '-102.0110162',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:49:59',
    SlNo: '585',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:49:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:49:39',
    Latitude: '35.1865972',
    Longitude: '-102.0110087',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:49:39',
    SlNo: '586',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:49:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:47:59',
    Latitude: '35.1866091',
    Longitude: '-102.0109886',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:47:59',
    SlNo: '587',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:47:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:46:59',
    Latitude: '35.1866225',
    Longitude: '-102.0109907',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:46:59',
    SlNo: '588',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:46:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:45:59',
    Latitude: '35.1866245',
    Longitude: '-102.0109895',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:45:59',
    SlNo: '589',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:45:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:45:39',
    Latitude: '35.186625',
    Longitude: '-102.0110079',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:45:39',
    SlNo: '590',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:45:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:44:00',
    Latitude: '35.1866174',
    Longitude: '-102.0110162',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:44:00',
    SlNo: '591',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:44:00 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:43:02',
    Latitude: '35.1866436',
    Longitude: '-102.0109909',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:43:02',
    SlNo: '592',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:43:02 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:41:59',
    Latitude: '35.1866416',
    Longitude: '-102.01099',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:41:59',
    SlNo: '593',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:41:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:41:31',
    Latitude: '35.1866637',
    Longitude: '-102.0110041',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:41:31',
    SlNo: '594',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:41:31 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:39:59',
    Latitude: '35.1866735',
    Longitude: '-102.0109993',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:39:59',
    SlNo: '595',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:39:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:39:39',
    Latitude: '35.1866675',
    Longitude: '-102.0110088',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:39:39',
    SlNo: '596',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:39:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:37:59',
    Latitude: '35.1866508',
    Longitude: '-102.0110074',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:37:59',
    SlNo: '597',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:37:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:36:58',
    Latitude: '35.1866621',
    Longitude: '-102.011044',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:36:58',
    SlNo: '598',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:36:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:35:58',
    Latitude: '35.1866545',
    Longitude: '-102.0110468',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:35:58',
    SlNo: '599',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:35:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:35:38',
    Latitude: '35.1866572',
    Longitude: '-102.0110427',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:35:38',
    SlNo: '600',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:35:38 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:33:58',
    Latitude: '35.1866516',
    Longitude: '-102.0110472',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:33:58',
    SlNo: '601',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:33:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:32:58',
    Latitude: '35.1866433',
    Longitude: '-102.0110415',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:32:58',
    SlNo: '602',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:32:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:31:58',
    Latitude: '35.1866358',
    Longitude: '-102.011044',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:31:58',
    SlNo: '603',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:31:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:31:16',
    Latitude: '35.1866025',
    Longitude: '-102.0110226',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:31:16',
    SlNo: '604',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:31:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:29:58',
    Latitude: '35.1866024',
    Longitude: '-102.0110238',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:29:58',
    SlNo: '605',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:29:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:29:38',
    Latitude: '35.1866024',
    Longitude: '-102.0110235',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:29:38',
    SlNo: '606',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:29:38 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:28:00',
    Latitude: '35.1865673',
    Longitude: '-102.0110292',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:28:00',
    SlNo: '607',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:28:00 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:26:58',
    Latitude: '35.1865652',
    Longitude: '-102.0110281',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:26:58',
    SlNo: '608',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:26:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:25:56',
    Latitude: '35.1865714',
    Longitude: '-102.0110571',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:25:56',
    SlNo: '609',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:25:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:25:36',
    Latitude: '35.1865872',
    Longitude: '-102.0110109',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:25:36',
    SlNo: '610',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:25:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:23:56',
    Latitude: '35.186624',
    Longitude: '-102.0109867',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:23:56',
    SlNo: '611',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:23:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:22:36',
    Latitude: '35.1866179',
    Longitude: '-102.0110121',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:22:36',
    SlNo: '612',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:22:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:21:47',
    Latitude: '35.1866452',
    Longitude: '-102.0109993',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:21:47',
    SlNo: '613',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:21:47 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:21:16',
    Latitude: '35.1866279',
    Longitude: '-102.0110081',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:21:16',
    SlNo: '614',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:21:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:19:38',
    Latitude: '35.1866488',
    Longitude: '-102.0110479',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:19:38',
    SlNo: '615',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:19:38 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:18:22',
    Latitude: '35.1866483',
    Longitude: '-102.011049',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:18:22',
    SlNo: '616',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:18:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:17:37',
    Latitude: '35.1866504',
    Longitude: '-102.0110474',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:17:37',
    SlNo: '617',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:17:37 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:16:48',
    Latitude: '35.1866391',
    Longitude: '-102.0110191',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:16:48',
    SlNo: '618',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:16:48 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:15:40',
    Latitude: '35.1866081',
    Longitude: '-102.0109736',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:15:40',
    SlNo: '619',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:15:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:15:17',
    Latitude: '35.1865964',
    Longitude: '-102.0109606',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:15:17',
    SlNo: '620',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:15:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:13:36',
    Latitude: '35.1866185',
    Longitude: '-102.0109523',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:13:36',
    SlNo: '621',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:13:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:12:36',
    Latitude: '35.1866053',
    Longitude: '-102.0109275',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:12:36',
    SlNo: '622',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:12:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:11:32',
    Latitude: '35.1865474',
    Longitude: '-102.0109489',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:11:32',
    SlNo: '623',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:11:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:11:16',
    Latitude: '35.1865644',
    Longitude: '-102.0109868',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:11:16',
    SlNo: '624',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:11:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:09:32',
    Latitude: '35.186639',
    Longitude: '-102.0110157',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:09:32',
    SlNo: '625',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:09:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:08:21',
    Latitude: '35.1866555',
    Longitude: '-102.0109678',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:08:21',
    SlNo: '626',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:08:21 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:07:57',
    Latitude: '35.186605',
    Longitude: '-102.0109581',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:07:57',
    SlNo: '627',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:07:57 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:07:25',
    Latitude: '35.186596',
    Longitude: '-102.0109779',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:07:25',
    SlNo: '628',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:07:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:07:17',
    Latitude: '35.1865956',
    Longitude: '-102.0109762',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:07:17',
    SlNo: '629',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:07:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:06:38',
    Latitude: '35.1866027',
    Longitude: '-102.0109755',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:06:38',
    SlNo: '630',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:06:38 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:05:17',
    Latitude: '35.1865868',
    Longitude: '-102.010943',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:05:17',
    SlNo: '631',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:05:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:04:56',
    Latitude: '35.1865889',
    Longitude: '-102.01096',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:04:56',
    SlNo: '632',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:04:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:03:17',
    Latitude: '35.1866358',
    Longitude: '-102.0109398',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:03:17',
    SlNo: '633',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:03:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:02:17',
    Latitude: '35.1866433',
    Longitude: '-102.0109283',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:02:17',
    SlNo: '634',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:02:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:01:16',
    Latitude: '35.1866461',
    Longitude: '-102.0109297',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:01:16',
    SlNo: '635',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:01:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 07:00:56',
    Latitude: '35.1866568',
    Longitude: '-102.0109414',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:00:56',
    SlNo: '636',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:00:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:59:16',
    Latitude: '35.1866501',
    Longitude: '-102.0109606',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:59:16',
    SlNo: '637',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:59:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:58:16',
    Latitude: '35.1866527',
    Longitude: '-102.0109504',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:58:16',
    SlNo: '638',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:58:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:57:18',
    Latitude: '35.1866346',
    Longitude: '-102.0109807',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:57:18',
    SlNo: '639',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:57:18 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:56:37',
    Latitude: '35.1866235',
    Longitude: '-102.0109969',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:56:37',
    SlNo: '640',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:56:37 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:55:16',
    Latitude: '35.1866226',
    Longitude: '-102.0110407',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:55:16',
    SlNo: '641',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:55:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:54:56',
    Latitude: '35.1866607',
    Longitude: '-102.0110393',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:54:56',
    SlNo: '642',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:54:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:53:16',
    Latitude: '35.1866335',
    Longitude: '-102.0109793',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:53:16',
    SlNo: '643',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:53:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:52:16',
    Latitude: '35.1866564',
    Longitude: '-102.010983',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:52:16',
    SlNo: '644',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:52:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:51:16',
    Latitude: '35.1866726',
    Longitude: '-102.0109704',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:51:16',
    SlNo: '645',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:51:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:50:56',
    Latitude: '35.1866412',
    Longitude: '-102.0110157',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:50:56',
    SlNo: '646',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:50:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:49:16',
    Latitude: '35.186651',
    Longitude: '-102.0109761',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:49:16',
    SlNo: '647',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:49:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:48:16',
    Latitude: '35.1866174',
    Longitude: '-102.0109995',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:48:16',
    SlNo: '648',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:48:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:47:16',
    Latitude: '35.1866293',
    Longitude: '-102.0109767',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:47:16',
    SlNo: '649',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:47:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:46:37',
    Latitude: '35.1866427',
    Longitude: '-102.0109339',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:46:37',
    SlNo: '650',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:46:37 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:45:16',
    Latitude: '35.1866444',
    Longitude: '-102.0109398',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:45:16',
    SlNo: '651',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:45:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:44:56',
    Latitude: '35.1866402',
    Longitude: '-102.0109387',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:44:56',
    SlNo: '652',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:44:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:43:16',
    Latitude: '35.186613',
    Longitude: '-102.010959',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:43:16',
    SlNo: '653',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:43:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:42:17',
    Latitude: '35.1866248',
    Longitude: '-102.0109406',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:42:17',
    SlNo: '654',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:42:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:41:16',
    Latitude: '35.1866283',
    Longitude: '-102.0109395',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:41:16',
    SlNo: '655',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:41:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:39:16',
    Latitude: '35.1866373',
    Longitude: '-102.0109972',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:39:16',
    SlNo: '656',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:38:16',
    Latitude: '35.1866374',
    Longitude: '-102.0110044',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:38:16',
    SlNo: '657',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:38:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:37:16',
    Latitude: '35.1866347',
    Longitude: '-102.0109983',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:37:16',
    SlNo: '658',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:37:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:36:40',
    Latitude: '35.1866573',
    Longitude: '-102.0109984',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:36:40',
    SlNo: '659',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:36:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:35:16',
    Latitude: '35.1866887',
    Longitude: '-102.0109765',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:35:16',
    SlNo: '660',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:35:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:34:56',
    Latitude: '35.1866777',
    Longitude: '-102.0109277',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:34:56',
    SlNo: '661',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:34:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:33:16',
    Latitude: '35.1866487',
    Longitude: '-102.0109799',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:33:16',
    SlNo: '662',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:33:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:32:15',
    Latitude: '35.1866317',
    Longitude: '-102.0109623',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:32:15',
    SlNo: '663',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:32:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:31:09',
    Latitude: '35.1866302',
    Longitude: '-102.0109794',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:31:09',
    SlNo: '664',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:31:09 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:30:54',
    Latitude: '35.1866285',
    Longitude: '-102.0110282',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:30:54',
    SlNo: '665',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:30:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:29:09',
    Latitude: '35.1866294',
    Longitude: '-102.011028',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:29:09',
    SlNo: '666',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:29:09 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:28:09',
    Latitude: '35.1866487',
    Longitude: '-102.0110201',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:28:09',
    SlNo: '667',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:28:09 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:26:56',
    Latitude: '35.1866548',
    Longitude: '-102.0109904',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:26:56',
    SlNo: '668',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:26:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:26:38',
    Latitude: '35.1866585',
    Longitude: '-102.0109928',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:26:38',
    SlNo: '669',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:26:38 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:24:56',
    Latitude: '35.1867023',
    Longitude: '-102.0109774',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:24:56',
    SlNo: '670',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:24:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:23:54',
    Latitude: '35.1867019',
    Longitude: '-102.0109774',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:23:54',
    SlNo: '671',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:23:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:22:54',
    Latitude: '35.1866879',
    Longitude: '-102.0109928',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:22:54',
    SlNo: '672',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:22:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:22:15',
    Latitude: '35.186688',
    Longitude: '-102.0109899',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:22:15',
    SlNo: '673',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:22:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:20:54',
    Latitude: '35.186666',
    Longitude: '-102.0109162',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:20:54',
    SlNo: '674',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:20:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:19:16',
    Latitude: '35.1866661',
    Longitude: '-102.0109129',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:19:16',
    SlNo: '675',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:19:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:18:54',
    Latitude: '35.1866664',
    Longitude: '-102.0109169',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:18:54',
    SlNo: '676',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:18:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:18:09',
    Latitude: '35.1866669',
    Longitude: '-102.0109168',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:18:09',
    SlNo: '677',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:18:09 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:16:54',
    Latitude: '35.1866516',
    Longitude: '-102.0109229',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:16:54',
    SlNo: '678',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:16:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:16:36',
    Latitude: '35.1866505',
    Longitude: '-102.0109228',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:16:36',
    SlNo: '679',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:16:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:14:54',
    Latitude: '35.1866129',
    Longitude: '-102.0109482',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:14:54',
    SlNo: '680',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:14:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:13:54',
    Latitude: '35.1866067',
    Longitude: '-102.0109625',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:13:54',
    SlNo: '681',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:13:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:12:50',
    Latitude: '35.186609',
    Longitude: '-102.0109488',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:12:50',
    SlNo: '682',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:12:50 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:12:15',
    Latitude: '35.1866168',
    Longitude: '-102.0109523',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:12:15',
    SlNo: '683',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:12:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:10:50',
    Latitude: '35.1865923',
    Longitude: '-102.0109899',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:10:50',
    SlNo: '684',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:10:50 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:10:40',
    Latitude: '35.1865924',
    Longitude: '-102.0109929',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:10:40',
    SlNo: '685',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:10:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:08:40',
    Latitude: '35.1865702',
    Longitude: '-102.0110457',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:08:40',
    SlNo: '686',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:08:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:08:10',
    Latitude: '35.1865733',
    Longitude: '-102.0110336',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:08:10',
    SlNo: '687',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:08:10 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:07:39',
    Latitude: '35.1865836',
    Longitude: '-102.0110256',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:07:39',
    SlNo: '688',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:07:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:06:37',
    Latitude: '35.1865987',
    Longitude: '-102.0110189',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:06:37',
    SlNo: '689',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:06:37 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:05:16',
    Latitude: '35.1866073',
    Longitude: '-102.011021',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:05:16',
    SlNo: '690',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:05:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:04:37',
    Latitude: '35.186609',
    Longitude: '-102.0110262',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:04:37',
    SlNo: '691',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:04:37 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:03:57',
    Latitude: '35.1867',
    Longitude: '-102.0109292',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:03:57',
    SlNo: '692',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:03:57 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:02:36',
    Latitude: '35.1867288',
    Longitude: '-102.0109129',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:02:36',
    SlNo: '693',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:02:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:02:16',
    Latitude: '35.1867195',
    Longitude: '-102.0109181',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:02:16',
    SlNo: '694',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:02:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 06:00:36',
    Latitude: '35.1866039',
    Longitude: '-102.0109751',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:00:36',
    SlNo: '695',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:00:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:59:36',
    Latitude: '35.1866413',
    Longitude: '-102.0109465',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:59:36',
    SlNo: '696',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:59:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:58:36',
    Latitude: '35.1866373',
    Longitude: '-102.0109767',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:58:36',
    SlNo: '697',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:58:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:57:56',
    Latitude: '35.1865983',
    Longitude: '-102.0109967',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:57:56',
    SlNo: '698',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:57:56 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:56:36',
    Latitude: '35.1865669',
    Longitude: '-102.0110267',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:56:36',
    SlNo: '699',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:56:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:55:16',
    Latitude: '35.1865744',
    Longitude: '-102.0109962',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:55:16',
    SlNo: '700',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:55:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:54:36',
    Latitude: '35.1865608',
    Longitude: '-102.0110078',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:54:36',
    SlNo: '701',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:54:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:53:55',
    Latitude: '35.1865397',
    Longitude: '-102.0109872',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:53:55',
    SlNo: '702',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:53:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:52:36',
    Latitude: '35.1865641',
    Longitude: '-102.0110312',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:52:36',
    SlNo: '703',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:52:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:52:16',
    Latitude: '35.1865878',
    Longitude: '-102.0109838',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:52:16',
    SlNo: '704',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:52:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:50:36',
    Latitude: '35.1866192',
    Longitude: '-102.0109351',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:50:36',
    SlNo: '705',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:50:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:49:36',
    Latitude: '35.1866046',
    Longitude: '-102.010948',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:49:36',
    SlNo: '706',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:49:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:48:36',
    Latitude: '35.1866231',
    Longitude: '-102.0110002',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:48:36',
    SlNo: '707',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:48:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:47:55',
    Latitude: '35.1866202',
    Longitude: '-102.0110137',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:47:55',
    SlNo: '708',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:47:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:46:36',
    Latitude: '35.1866236',
    Longitude: '-102.0110723',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:46:36',
    SlNo: '709',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:46:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:45:19',
    Latitude: '35.1866569',
    Longitude: '-102.0110044',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:45:19',
    SlNo: '710',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:45:19 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:44:36',
    Latitude: '35.1866448',
    Longitude: '-102.0110293',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:44:36',
    SlNo: '711',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:44:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:43:55',
    Latitude: '35.1866111',
    Longitude: '-102.0110011',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:43:55',
    SlNo: '712',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:43:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:42:36',
    Latitude: '35.1866409',
    Longitude: '-102.0109849',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:42:36',
    SlNo: '713',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:42:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:42:16',
    Latitude: '35.1866509',
    Longitude: '-102.0109882',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:42:16',
    SlNo: '714',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:42:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:40:36',
    Latitude: '35.1866144',
    Longitude: '-102.0110322',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:40:36',
    SlNo: '715',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:40:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:39:36',
    Latitude: '35.1866146',
    Longitude: '-102.0110334',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:39:36',
    SlNo: '716',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:39:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:38:36',
    Latitude: '35.1866168',
    Longitude: '-102.0110138',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:38:36',
    SlNo: '717',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:38:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:38:00',
    Latitude: '35.1866116',
    Longitude: '-102.0110111',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:38:00',
    SlNo: '718',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:38:00 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:36:36',
    Latitude: '35.1866103',
    Longitude: '-102.0109841',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:36:36',
    SlNo: '719',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:36:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:35:15',
    Latitude: '35.1866103',
    Longitude: '-102.0109841',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:35:15',
    SlNo: '720',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:35:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:34:36',
    Latitude: '35.1866074',
    Longitude: '-102.0109839',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:34:36',
    SlNo: '721',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:34:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:33:54',
    Latitude: '35.1866599',
    Longitude: '-102.0109863',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:33:54',
    SlNo: '722',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:33:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:32:36',
    Latitude: '35.1866756',
    Longitude: '-102.0109914',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:32:36',
    SlNo: '723',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:32:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:32:16',
    Latitude: '35.186673',
    Longitude: '-102.0109908',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:32:16',
    SlNo: '724',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:32:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:30:36',
    Latitude: '35.1868172',
    Longitude: '-102.0110222',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:30:36',
    SlNo: '725',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:30:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:29:37',
    Latitude: '35.1877759',
    Longitude: '-102.0102538',
    Speed: '6.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:29:37',
    SlNo: '726',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:29:37 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:28:36',
    Latitude: '35.1903819',
    Longitude: '-102.008738',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '14475 Indian Hill Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:28:36',
    SlNo: '727',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:28:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:27:55',
    Latitude: '35.1898419',
    Longitude: '-101.9985972',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '13777 I-40, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:27:55',
    SlNo: '728',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:27:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:26:36',
    Latitude: '35.1873206',
    Longitude: '-101.9737379',
    Speed: '34.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '11650 I-40 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:26:36',
    SlNo: '729',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:26:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:25:15',
    Latitude: '35.1873786',
    Longitude: '-101.9536181',
    Speed: '16.78 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Amarillo, TX 79121, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:25:15',
    SlNo: '730',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:25:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:24:36',
    Latitude: '35.1872697',
    Longitude: '-101.9435647',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '9000 I-40 W, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:24:36',
    SlNo: '731',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:24:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:23:54',
    Latitude: '35.1869367',
    Longitude: '-101.9317203',
    Speed: '41.01 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '7701 I-40 W #236, Amarillo, TX 79121, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:23:54',
    SlNo: '732',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:23:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:22:35',
    Latitude: '35.1894998',
    Longitude: '-101.9079374',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '6100 I-40, Amarillo, TX 79106, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:22:35',
    SlNo: '733',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:22:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:22:16',
    Latitude: '35.1905675',
    Longitude: '-101.9021275',
    Speed: '47.22 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '5815 W Interstate 40, Amarillo, TX 79106, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:22:16',
    SlNo: '734',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:22:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:20:36',
    Latitude: '35.1923345',
    Longitude: '-101.8745654',
    Speed: '25.48 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '3400 I-40 Frontage Rd, Amarillo, TX 79102, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:20:36',
    SlNo: '735',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:20:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:19:35',
    Latitude: '35.1953844',
    Longitude: '-101.8598777',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1619 S Fannin St, Amarillo, TX 79102, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:19:35',
    SlNo: '736',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:19:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:18:35',
    Latitude: '35.193625',
    Longitude: '-101.8412312',
    Speed: '20.51 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1806 S Tyler St, Amarillo, TX 79102, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:18:35',
    SlNo: '737',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:18:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:21:14',
    Latitude: '35.0048823',
    Longitude: '-105.9865212',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:21:14',
    SlNo: '24',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:21:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:19:31',
    Latitude: '35.0049368',
    Longitude: '-105.9492278',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '197 Abrahames Rd, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:19:31',
    SlNo: '25',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:19:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:19:24',
    Latitude: '35.0049106',
    Longitude: '-105.9459627',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '197 Abrahames Rd, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:19:24',
    SlNo: '26',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:19:24 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:16:22',
    Latitude: '35.0058782',
    Longitude: '-105.8810809',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:16:22',
    SlNo: '27',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:16:22 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:15:22',
    Latitude: '35.0053359',
    Longitude: '-105.8590054',
    Speed: '37.90 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:15:22',
    SlNo: '28',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:15:22 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:14:24',
    Latitude: '35.0052657',
    Longitude: '-105.8366251',
    Speed: '41.01 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Moriarty, NM 87035, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:14:24',
    SlNo: '29',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:14:24 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:13:14',
    Latitude: '35.0051747',
    Longitude: '-105.8125079',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:13:14',
    SlNo: '30',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:13:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:12:50',
    Latitude: '35.0050621',
    Longitude: '-105.8009623',
    Speed: '55.30 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:12:50',
    SlNo: '31',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:12:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:11:13',
    Latitude: '35.0047971',
    Longitude: '-105.7683435',
    Speed: '46.60 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:11:13',
    SlNo: '32',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:11:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:10:00',
    Latitude: '35.0045452',
    Longitude: '-105.7410866',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:10:00',
    SlNo: '33',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:10:00 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:08:52',
    Latitude: '35.006915',
    Longitude: '-105.7175426',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Stanley, NM 87056, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:08:52',
    SlNo: '34',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:08:52 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:05:52',
    Latitude: '35.0079741',
    Longitude: '-105.6555304',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:05:52',
    SlNo: '35',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:05:52 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:05:13',
    Latitude: '35.007328',
    Longitude: '-105.6439372',
    Speed: '29.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:05:13',
    SlNo: '36',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:05:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:04:20',
    Latitude: '35.006273',
    Longitude: '-105.6268834',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:04:20',
    SlNo: '37',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:04:20 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:03:01',
    Latitude: '35.004669',
    Longitude: '-105.5981181',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:03:01',
    SlNo: '38',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:03:01 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:02:40',
    Latitude: '35.0042263',
    Longitude: '-105.5915157',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clines Corners, NM 87070, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:02:40',
    SlNo: '39',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:02:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 17:01:01',
    Latitude: '35.0022871',
    Longitude: '-105.5575163',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '17:01:01',
    SlNo: '40',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:01:01 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:59:56',
    Latitude: '35.0061195',
    Longitude: '-105.539094',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:59:56',
    SlNo: '41',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:59:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:58:51',
    Latitude: '35.0057979',
    Longitude: '-105.5156672',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:58:51',
    SlNo: '42',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:58:51 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:58:20',
    Latitude: '35.0052602',
    Longitude: '-105.5048201',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:58:20',
    SlNo: '43',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:58:20 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:56:50',
    Latitude: '35.0012473',
    Longitude: '-105.47212',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:56:50',
    SlNo: '44',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:56:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:55:50',
    Latitude: '34.9991259',
    Longitude: '-105.4505095',
    Speed: '40.39 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:55:50',
    SlNo: '45',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:55:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:54:41',
    Latitude: '34.9980472',
    Longitude: '-105.4266701',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:54:41',
    SlNo: '46',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:54:41 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:54:20',
    Latitude: '34.9976815',
    Longitude: '-105.4193814',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:54:20',
    SlNo: '47',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:54:20 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:52:40',
    Latitude: '34.9959098',
    Longitude: '-105.383274',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:52:40',
    SlNo: '48',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:52:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:51:40',
    Latitude: '34.994845',
    Longitude: '-105.3616794',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:51:40',
    SlNo: '49',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:51:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:50:40',
    Latitude: '34.9937907',
    Longitude: '-105.3400115',
    Speed: '25.48 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:50:40',
    SlNo: '50',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:50:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:49:56',
    Latitude: '34.9931311',
    Longitude: '-105.3251537',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:49:56',
    SlNo: '51',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:49:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:48:40',
    Latitude: '34.9918431',
    Longitude: '-105.2990683',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Encino, NM 88321, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:48:40',
    SlNo: '52',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:48:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:48:20',
    Latitude: '34.9915079',
    Longitude: '-105.2921958',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:48:20',
    SlNo: '53',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:48:20 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:46:40',
    Latitude: '34.9898056',
    Longitude: '-105.2559447',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:46:40',
    SlNo: '54',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:46:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:45:50',
    Latitude: '34.9889408',
    Longitude: '-105.2380766',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:45:50',
    SlNo: '55',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:45:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:45:20',
    Latitude: '34.9883856',
    Longitude: '-105.2272185',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:45:20',
    SlNo: '56',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:45:20 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:45:19',
    Latitude: '34.9881085',
    Longitude: '-105.2214234',
    Speed: '14.29 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:45:19',
    SlNo: '57',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:45:19 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:44:40',
    Latitude: '34.9877086',
    Longitude: '-105.2131487',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:44:40',
    SlNo: '58',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:44:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:44:20',
    Latitude: '34.9873748',
    Longitude: '-105.205925',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:44:20',
    SlNo: '59',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:44:20 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:42:40',
    Latitude: '34.9855897',
    Longitude: '-105.1703272',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:42:40',
    SlNo: '60',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:42:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:41:40',
    Latitude: '34.9845349',
    Longitude: '-105.1489183',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:41:40',
    SlNo: '61',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:41:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:40:40',
    Latitude: '34.9834379',
    Longitude: '-105.1272772',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:40:40',
    SlNo: '62',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:40:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:39:56',
    Latitude: '34.9826406',
    Longitude: '-105.1118034',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:39:56',
    SlNo: '63',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:39:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:38:40',
    Latitude: '34.9813105',
    Longitude: '-105.0849294',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:38:40',
    SlNo: '64',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:38:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:38:20',
    Latitude: '34.9809827',
    Longitude: '-105.078142',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:38:20',
    SlNo: '65',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:38:20 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:36:39',
    Latitude: '34.9791757',
    Longitude: '-105.0421146',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:36:39',
    SlNo: '66',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:36:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:35:19',
    Latitude: '34.9777165',
    Longitude: '-105.0133066',
    Speed: '24.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:35:19',
    SlNo: '67',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:35:19 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:34:39',
    Latitude: '34.9769962',
    Longitude: '-104.9988902',
    Speed: '24.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:34:39',
    SlNo: '68',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:34:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:33:59',
    Latitude: '34.9762445',
    Longitude: '-104.9844585',
    Speed: '50.95 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:33:59',
    SlNo: '69',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:33:59 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:32:40',
    Latitude: '34.9746111',
    Longitude: '-104.9546',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:32:40',
    SlNo: '70',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:32:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:31:39',
    Latitude: '34.9735655',
    Longitude: '-104.932859',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:31:39',
    SlNo: '71',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:31:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:30:39',
    Latitude: '34.9724685',
    Longitude: '-104.9113688',
    Speed: '25.48 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:30:39',
    SlNo: '72',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:30:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:29:56',
    Latitude: '34.9716607',
    Longitude: '-104.8964213',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:29:56',
    SlNo: '73',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:29:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:28:40',
    Latitude: '34.9702858',
    Longitude: '-104.8699597',
    Speed: '15.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:28:40',
    SlNo: '74',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:28:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:28:15',
    Latitude: '34.9698576',
    Longitude: '-104.860973',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:28:15',
    SlNo: '75',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:28:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:26:40',
    Latitude: '34.9693817',
    Longitude: '-104.8267952',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:26:40',
    SlNo: '76',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:26:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:24:39',
    Latitude: '34.9683755',
    Longitude: '-104.7840952',
    Speed: '22.37 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:24:39',
    SlNo: '77',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:24:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:24:00',
    Latitude: '34.96681',
    Longitude: '-104.7712601',
    Speed: '44.12 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:24:00',
    SlNo: '78',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:24:00 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:22:39',
    Latitude: '34.9645107',
    Longitude: '-104.7456585',
    Speed: '42.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-84, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:22:39',
    SlNo: '79',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:22:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:21:19',
    Latitude: '34.9547953',
    Longitude: '-104.7235098',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '4 A A, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:21:19',
    SlNo: '80',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:21:19 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:20:39',
    Latitude: '34.9481101',
    Longitude: '-104.7118342',
    Speed: '24.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:20:39',
    SlNo: '81',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:20:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:19:57',
    Latitude: '34.9456449',
    Longitude: '-104.697597',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:19:57',
    SlNo: '82',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:19:57 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:19:56',
    Latitude: '34.9457817',
    Longitude: '-104.6969341',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:19:56',
    SlNo: '83',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:19:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:18:39',
    Latitude: '34.9443615',
    Longitude: '-104.6720867',
    Speed: '13.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2245 U.S. Rte 66, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:18:39',
    SlNo: '84',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:18:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:18:16',
    Latitude: '34.9436558',
    Longitude: '-104.6643634',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2306 U.S. Rte 66, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:18:16',
    SlNo: '85',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:18:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:16:39',
    Latitude: '34.9515531',
    Longitude: '-104.6322723',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:16:39',
    SlNo: '86',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:16:39 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:15:31',
    Latitude: '34.9619307',
    Longitude: '-104.6107005',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:15:31',
    SlNo: '87',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:15:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:14:31',
    Latitude: '34.9707098',
    Longitude: '-104.5922351',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:14:31',
    SlNo: '88',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:14:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:13:59',
    Latitude: '34.9755196',
    Longitude: '-104.5822497',
    Speed: '54.06 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:13:59',
    SlNo: '89',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:13:59 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:12:31',
    Latitude: '34.9886427',
    Longitude: '-104.5546775',
    Speed: '44.12 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Santa Rosa, NM 88435, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:12:31',
    SlNo: '90',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:12:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:11:19',
    Latitude: '34.9994121',
    Longitude: '-104.5321093',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:11:19',
    SlNo: '91',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:11:19 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:10:31',
    Latitude: '35.0066643',
    Longitude: '-104.5168825',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:10:31',
    SlNo: '92',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:10:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:09:56',
    Latitude: '35.0098744',
    Longitude: '-104.5047845',
    Speed: '50.95 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:09:56',
    SlNo: '93',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:09:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:08:31',
    Latitude: '35.0115775',
    Longitude: '-104.4747816',
    Speed: '9.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:08:31',
    SlNo: '94',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:08:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:08:16',
    Latitude: '35.0115047',
    Longitude: '-104.4693849',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:08:16',
    SlNo: '95',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:08:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:06:31',
    Latitude: '35.0206296',
    Longitude: '-104.4339517',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:06:31',
    SlNo: '96',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:06:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:05:31',
    Latitude: '35.0290626',
    Longitude: '-104.4149903',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:05:31',
    SlNo: '97',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:05:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:04:21',
    Latitude: '35.0387521',
    Longitude: '-104.3936087',
    Speed: '13.67 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:04:21',
    SlNo: '98',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:04:21 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:03:58',
    Latitude: '35.0424024',
    Longitude: '-104.3867481',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:03:58',
    SlNo: '99',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:03:58 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:02:19',
    Latitude: '35.0502547',
    Longitude: '-104.3542781',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:02:19',
    SlNo: '100',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:02:19 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:01:19',
    Latitude: '35.0534019',
    Longitude: '-104.3336739',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Frontage Rd, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:01:19',
    SlNo: '101',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:01:19 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 16:00:16',
    Latitude: '35.0593783',
    Longitude: '-104.3113689',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Frontage Rd, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:00:16',
    SlNo: '102',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:00:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:59:56',
    Latitude: '35.0612064',
    Longitude: '-104.3045142',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:59:56',
    SlNo: '103',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:59:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:58:16',
    Latitude: '35.0656735',
    Longitude: '-104.2689007',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:58:16',
    SlNo: '104',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:58:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:57:16',
    Latitude: '35.0679358',
    Longitude: '-104.2473992',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:57:16',
    SlNo: '105',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:57:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:56:16',
    Latitude: '35.0701873',
    Longitude: '-104.2259762',
    Speed: '26.72 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:56:16',
    SlNo: '106',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:56:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:55:32',
    Latitude: '35.0692638',
    Longitude: '-104.2101683',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:55:32',
    SlNo: '107',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:55:32 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:54:16',
    Latitude: '35.0665393',
    Longitude: '-104.1835798',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:54:16',
    SlNo: '108',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:54:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:53:57',
    Latitude: '35.0673022',
    Longitude: '-104.1766207',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Frontage Rd, Newkirk, NM 88431, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:53:57',
    SlNo: '109',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:57 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:52:16',
    Latitude: '35.0744196',
    Longitude: '-104.1476597',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:52:16',
    SlNo: '110',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:52:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:51:15',
    Latitude: '35.0795766',
    Longitude: '-104.126935',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Cuervo, NM 88417, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:51:15',
    SlNo: '111',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:51:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:50:16',
    Latitude: '35.0846539',
    Longitude: '-104.1063383',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:50:16',
    SlNo: '112',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:50:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:49:56',
    Latitude: '35.0863283',
    Longitude: '-104.099425',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:49:56',
    SlNo: '113',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:49:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:48:24',
    Latitude: '35.0938327',
    Longitude: '-104.0645523',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:48:24',
    SlNo: '114',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:48:24 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:47:15',
    Latitude: '35.1012117',
    Longitude: '-104.0449988',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:47:15',
    SlNo: '115',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:47:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:46:15',
    Latitude: '35.1067199',
    Longitude: '-104.0243609',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:46:15',
    SlNo: '116',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:46:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:45:31',
    Latitude: '35.110849',
    Longitude: '-104.008888',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:45:31',
    SlNo: '117',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:45:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:44:16',
    Latitude: '35.1177436',
    Longitude: '-103.9830775',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:44:16',
    SlNo: '118',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:44:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:43:57',
    Latitude: '35.1195023',
    Longitude: '-103.9765051',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:43:57',
    SlNo: '119',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:43:57 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:42:15',
    Latitude: '35.1257836',
    Longitude: '-103.9412637',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:42:15',
    SlNo: '120',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:42:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:41:15',
    Latitude: '35.1227276',
    Longitude: '-103.9197533',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:41:15',
    SlNo: '121',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:41:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:40:15',
    Latitude: '35.119688',
    Longitude: '-103.8985394',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:40:15',
    SlNo: '122',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:40:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:39:57',
    Latitude: '35.1186402',
    Longitude: '-103.8913981',
    Speed: '55.30 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:39:57',
    SlNo: '123',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:39:57 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:38:16',
    Latitude: '35.1219527',
    Longitude: '-103.8592362',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:38:16',
    SlNo: '124',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:38:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:37:15',
    Latitude: '35.1316555',
    Longitude: '-103.8414168',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:37:15',
    SlNo: '125',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:37:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:36:15',
    Latitude: '35.1409222',
    Longitude: '-103.8234002',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:36:15',
    SlNo: '126',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:36:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:35:31',
    Latitude: '35.1450356',
    Longitude: '-103.8078735',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:35:31',
    SlNo: '127',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:35:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:34:15',
    Latitude: '35.1494128',
    Longitude: '-103.7821322',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'US-54, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:34:15',
    SlNo: '128',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:34:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:33:57',
    Latitude: '35.1517369',
    Longitude: '-103.7759045',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:33:57',
    SlNo: '129',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:33:57 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:32:15',
    Latitude: '35.1516863',
    Longitude: '-103.7422383',
    Speed: '34.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2717 Tiffany Rd, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:32:15',
    SlNo: '130',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:32:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:31:15',
    Latitude: '35.1516192',
    Longitude: '-103.7221232',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '200 E Estrella Ave, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:31:15',
    SlNo: '131',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:31:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:30:15',
    Latitude: '35.156288',
    Longitude: '-103.7024233',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1700 S Mountain Rd, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:30:15',
    SlNo: '132',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:30:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:29:55',
    Latitude: '35.1591446',
    Longitude: '-103.6963051',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:29:55',
    SlNo: '133',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:29:55 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:29:15',
    Latitude: '35.1651443',
    Longitude: '-103.6841092',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:29:15',
    SlNo: '134',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:29:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:28:33',
    Latitude: '35.1719627',
    Longitude: '-103.6712442',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:28:33',
    SlNo: '135',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:28:33 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:28:15',
    Latitude: '35.1723953',
    Longitude: '-103.665275',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '6306 Quay Rd A L, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:28:15',
    SlNo: '136',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:28:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:27:15',
    Latitude: '35.1725252',
    Longitude: '-103.6433115',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:27:15',
    SlNo: '137',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:27:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:26:15',
    Latitude: '35.172636',
    Longitude: '-103.6215778',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:26:15',
    SlNo: '138',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:26:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:25:30',
    Latitude: '35.1727196',
    Longitude: '-103.6052755',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:25:30',
    SlNo: '139',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:25:30 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:24:14',
    Latitude: '35.172876',
    Longitude: '-103.5781738',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '6305 Q R Ag, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:24:14',
    SlNo: '140',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:24:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:23:56',
    Latitude: '35.1731575',
    Longitude: '-103.5712756',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:23:56',
    SlNo: '141',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:23:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:22:14',
    Latitude: '35.1733657',
    Longitude: '-103.5347194',
    Speed: '37.90 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:22:14',
    SlNo: '142',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:22:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:21:14',
    Latitude: '35.1734761',
    Longitude: '-103.5125352',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:21:14',
    SlNo: '143',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:21:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:20:14',
    Latitude: '35.1736191',
    Longitude: '-103.4917142',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2685 Quay Rd 63, Tucumcari, NM 88401, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:20:14',
    SlNo: '144',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:20:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:18:14',
    Latitude: '35.1571469',
    Longitude: '-103.4540122',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:18:14',
    SlNo: '145',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:18:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:17:14',
    Latitude: '35.1452537',
    Longitude: '-103.4381',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:17:14',
    SlNo: '146',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:17:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:16:14',
    Latitude: '35.1373606',
    Longitude: '-103.4186531',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:16:14',
    SlNo: '147',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:16:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:15:30',
    Latitude: '35.1313656',
    Longitude: '-103.4040976',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:15:30',
    SlNo: '148',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:15:30 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:14:14',
    Latitude: '35.1213804',
    Longitude: '-103.3797344',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:14:14',
    SlNo: '149',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:14:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:13:56',
    Latitude: '35.1189099',
    Longitude: '-103.3735852',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:13:56',
    SlNo: '150',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:13:56 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:12:14',
    Latitude: '35.1121067',
    Longitude: '-103.3384913',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:12:14',
    SlNo: '151',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:12:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:11:14',
    Latitude: '35.1124943',
    Longitude: '-103.3174711',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:11:14',
    SlNo: '152',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:11:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:10:14',
    Latitude: '35.113272',
    Longitude: '-103.303088',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '37351 I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:10:14',
    SlNo: '153',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:10:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:09:54',
    Latitude: '35.1133315',
    Longitude: '-103.3014545',
    Speed: '2.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '37351 I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:09:54',
    SlNo: '154',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:09:54 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:08:14',
    Latitude: '35.113282',
    Longitude: '-103.3000022',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '37351 I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:08:14',
    SlNo: '155',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:08:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:07:14',
    Latitude: '35.11291',
    Longitude: '-103.2925532',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:07:14',
    SlNo: '156',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:07:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:06:14',
    Latitude: '35.1154151',
    Longitude: '-103.2739921',
    Speed: '25.48 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:06:14',
    SlNo: '157',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:06:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:05:30',
    Latitude: '35.1195407',
    Longitude: '-103.2596954',
    Speed: '44.12 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:05:30',
    SlNo: '158',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:05:30 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:04:14',
    Latitude: '35.1278385',
    Longitude: '-103.2356215',
    Speed: '10.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Bard, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:04:14',
    SlNo: '159',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:04:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:03:55',
    Latitude: '35.1303916',
    Longitude: '-103.2300288',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:03:55',
    SlNo: '160',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:03:55 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:02:14',
    Latitude: '35.1420892',
    Longitude: '-103.1964264',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:02:14',
    SlNo: '161',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:02:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:01:18',
    Latitude: '35.1487848',
    Longitude: '-103.1770653',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:01:18',
    SlNo: '162',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:01:18 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 15:00:14',
    Latitude: '35.1556769',
    Longitude: '-103.1569485',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:00:14',
    SlNo: '163',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:00:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:59:33',
    Latitude: '35.1595619',
    Longitude: '-103.1455142',
    Speed: '50.95 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:59:33',
    SlNo: '164',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:33 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:58:09',
    Latitude: '35.1687912',
    Longitude: '-103.1177943',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '6299 Quay Rd J, Bard, NM 88411, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:58:09',
    SlNo: '165',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:58:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:56:55',
    Latitude: '35.1760471',
    Longitude: '-103.0925763',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:56:55',
    SlNo: '166',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:56:55 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:55:55',
    Latitude: '35.1824424',
    Longitude: '-103.0723566',
    Speed: '16.16 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:55:55',
    SlNo: '167',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:55:55 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:55:29',
    Latitude: '35.1835125',
    Longitude: '-103.0630434',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, San Jon, NM 88434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:55:29',
    SlNo: '168',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:55:29 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:53:55',
    Latitude: '35.1813264',
    Longitude: '-103.0291451',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Hereford, TX 79045, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:53:55',
    SlNo: '169',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:53:55 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:52:46',
    Latitude: '35.1833855',
    Longitude: '-103.0042658',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Texas, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:52:46',
    SlNo: '170',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:52:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:51:55',
    Latitude: '35.1856345',
    Longitude: '-102.9860827',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Texas, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:51:55',
    SlNo: '171',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:51:55 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:51:14',
    Latitude: '35.1903885',
    Longitude: '-102.9719671',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Texas, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:51:14',
    SlNo: '172',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:51:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:49:55',
    Latitude: '35.1995484',
    Longitude: '-102.9460195',
    Speed: '13.67 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:49:55',
    SlNo: '173',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:49:55 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:49:33',
    Latitude: '35.2023488',
    Longitude: '-102.9388466',
    Speed: '60.27 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:49:33',
    SlNo: '174',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:49:33 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:46:53',
    Latitude: '35.221718',
    Longitude: '-102.886189',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:46:53',
    SlNo: '175',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:46:53 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:45:55',
    Latitude: '35.2268226',
    Longitude: '-102.8661608',
    Speed: '16.16 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:45:55',
    SlNo: '176',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:45:55 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:45:29',
    Latitude: '35.2298898',
    Longitude: '-102.8575643',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:45:29',
    SlNo: '177',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:45:29 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:44:29',
    Latitude: '35.2410871',
    Longitude: '-102.8263346',
    Speed: '42.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:44:29',
    SlNo: '178',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:44:29 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:42:46',
    Latitude: '35.2459588',
    Longitude: '-102.8016092',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:42:46',
    SlNo: '179',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:42:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:41:46',
    Latitude: '35.2508615',
    Longitude: '-102.7806372',
    Speed: '3.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:41:46',
    SlNo: '180',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:41:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:41:40',
    Latitude: '35.2511594',
    Longitude: '-102.7784343',
    Speed: '15.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1601 I-40 Service Rd, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:41:40',
    SlNo: '181',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:41:40 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:41:15',
    Latitude: '35.2537929',
    Longitude: '-102.7700058',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1671 I-40 Service Rd, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:41:15',
    SlNo: '182',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:41:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:39:46',
    Latitude: '35.2646293',
    Longitude: '-102.7419736',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:39:46',
    SlNo: '183',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:39:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:38:31',
    Latitude: '35.2699673',
    Longitude: '-102.7165263',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:38:31',
    SlNo: '184',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:38:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:37:46',
    Latitude: '35.2704428',
    Longitude: '-102.6999102',
    Speed: '33.55 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:37:46',
    SlNo: '185',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:37:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:36:51',
    Latitude: '35.2702852',
    Longitude: '-102.6800932',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:36:51',
    SlNo: '186',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:36:51 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:35:31',
    Latitude: '35.270615',
    Longitude: '-102.651576',
    Speed: '16.16 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:35:31',
    SlNo: '187',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:35:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:35:06',
    Latitude: '35.2720768',
    Longitude: '-102.6423802',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:35:06',
    SlNo: '188',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:35:06 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:33:31',
    Latitude: '35.2730729',
    Longitude: '-102.608321',
    Speed: '40.39 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Service Rd, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:33:31',
    SlNo: '189',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:33:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:32:26',
    Latitude: '35.2736097',
    Longitude: '-102.5844077',
    Speed: '32.93 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Service Rd, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:32:26',
    SlNo: '190',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:32:26 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:31:31',
    Latitude: '35.2704883',
    Longitude: '-102.5652858',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:31:31',
    SlNo: '191',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:31:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:31:15',
    Latitude: '35.2689415',
    Longitude: '-102.5597516',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Adrian, TX 79001, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:31:15',
    SlNo: '192',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:31:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:29:31',
    Latitude: '35.2623697',
    Longitude: '-102.5227034',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'Co Rd 31, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:29:31',
    SlNo: '193',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:29:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:28:32',
    Latitude: '35.2582533',
    Longitude: '-102.5019399',
    Speed: '40.39 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:28:32',
    SlNo: '194',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:28:32 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:27:26',
    Latitude: '35.2540562',
    Longitude: '-102.4786195',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:27:26',
    SlNo: '195',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:27:26 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:26:50',
    Latitude: '35.251718',
    Longitude: '-102.4662937',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:26:50',
    SlNo: '196',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:26:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:25:26',
    Latitude: '35.2417091',
    Longitude: '-102.4384754',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:25:26',
    SlNo: '197',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:25:26 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:25:06',
    Latitude: '35.2385328',
    Longitude: '-102.432527',
    Speed: '50.95 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1205 Culberson St, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:25:06',
    SlNo: '198',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:25:06 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:23:26',
    Latitude: '35.2391043',
    Longitude: '-102.4025814',
    Speed: '30.45 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:23:26',
    SlNo: '199',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:26 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:22:26',
    Latitude: '35.2363743',
    Longitude: '-102.3849342',
    Speed: '42.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Frontage Rd, Vega, TX 79092, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:22:26',
    SlNo: '200',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:22:26 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:21:15',
    Latitude: '35.2317802',
    Longitude: '-102.3602891',
    Speed: '55.30 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:21:15',
    SlNo: '201',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:21:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:19:46',
    Latitude: '35.2257321',
    Longitude: '-102.3285099',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:19:46',
    SlNo: '202',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:19:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:19:15',
    Latitude: '35.2237857',
    Longitude: '-102.3179303',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:19:15',
    SlNo: '203',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:19:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:18:31',
    Latitude: '35.2207918',
    Longitude: '-102.3020534',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:18:31',
    SlNo: '204',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:18:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:17:15',
    Latitude: '35.2158124',
    Longitude: '-102.2757006',
    Speed: '14.91 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'Binford Rd, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:17:15',
    SlNo: '205',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:17:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:16:50',
    Latitude: '35.2141563',
    Longitude: '-102.2670263',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:16:50',
    SlNo: '206',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:16:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:15:15',
    Latitude: '35.2101749',
    Longitude: '-102.2340044',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:15:15',
    SlNo: '207',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:15:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:14:15',
    Latitude: '35.2095334',
    Longitude: '-102.2129923',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '711 I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:14:15',
    SlNo: '208',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:14:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:17:57',
    Latitude: '35.1925429',
    Longitude: '-101.8293566',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1901 Olive St, Amarillo, TX 79103, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:17:57',
    SlNo: '738',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:17:57 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:16:36',
    Latitude: '35.1934081',
    Longitude: '-101.8050275',
    Speed: '39.77 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2801 E I-40, Amarillo, TX 79104, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:16:36',
    SlNo: '739',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:16:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:15:15',
    Latitude: '35.192703',
    Longitude: '-101.781535',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '4601 Interstate 40 Access Rd, Amarillo, TX 79104, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:15:15',
    SlNo: '740',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:15:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:14:35',
    Latitude: '35.1928203',
    Longitude: '-101.7692276',
    Speed: '19.88 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '6055 E Interstate 40, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:14:35',
    SlNo: '741',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:14:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:13:54',
    Latitude: '35.1928449',
    Longitude: '-101.7576455',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '7210 E Interstate 40, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:13:54',
    SlNo: '742',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:13:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:12:36',
    Latitude: '35.1928951',
    Longitude: '-101.7361102',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '9151 Interstate 40 Access Rd, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:12:36',
    SlNo: '743',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:12:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:12:12',
    Latitude: '35.1928762',
    Longitude: '-101.7294499',
    Speed: '53.44 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '9400 I-40, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:12:12',
    SlNo: '744',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:12:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:10:36',
    Latitude: '35.1928181',
    Longitude: '-101.6978488',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '12100 E Interstate 40, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:10:36',
    SlNo: '745',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:10:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:09:34',
    Latitude: '35.2031589',
    Longitude: '-101.6811316',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'E Interstate 40 Hwy, Amarillo, TX 79111, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:09:34',
    SlNo: '746',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:09:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:08:35',
    Latitude: '35.206654',
    Longitude: '-101.6605705',
    Speed: '22.99 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:08:35',
    SlNo: '747',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:08:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:07:55',
    Latitude: '35.207014',
    Longitude: '-101.6469293',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Amarillo, TX 79118, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:07:55',
    SlNo: '748',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:07:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:06:34',
    Latitude: '35.2080781',
    Longitude: '-101.6186684',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Amarillo, TX 79108, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:06:34',
    SlNo: '749',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:06:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:05:15',
    Latitude: '35.2209408',
    Longitude: '-101.5964973',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '301 FM2575, Amarillo, TX 79108, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:05:15',
    SlNo: '750',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:05:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:04:34',
    Latitude: '35.2208336',
    Longitude: '-101.5820497',
    Speed: '23.61 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Texas, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:04:34',
    SlNo: '751',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:04:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:03:54',
    Latitude: '35.2209208',
    Longitude: '-101.5680265',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '8663 Interstate 40 Service Rd, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:03:54',
    SlNo: '752',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:03:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:02:35',
    Latitude: '35.2210001',
    Longitude: '-101.5397366',
    Speed: '13.67 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:02:35',
    SlNo: '753',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:02:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:02:12',
    Latitude: '35.2210995',
    Longitude: '-101.5316415',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:02:12',
    SlNo: '754',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:02:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 05:00:36',
    Latitude: '35.2215369',
    Longitude: '-101.4975166',
    Speed: '37.90 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:00:36',
    SlNo: '755',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:00:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:59:32',
    Latitude: '35.2215946',
    Longitude: '-101.4753544',
    Speed: '34.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:59:32',
    SlNo: '756',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:59:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:58:34',
    Latitude: '35.2217054',
    Longitude: '-101.4553274',
    Speed: '26.72 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Frontage Rd, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:58:34',
    SlNo: '757',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:58:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:57:54',
    Latitude: '35.2217484',
    Longitude: '-101.4394641',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '847 I-40 Frontage Rd, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:57:54',
    SlNo: '758',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:57:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:56:34',
    Latitude: '35.221817',
    Longitude: '-101.4130602',
    Speed: '46.60 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '9536 I-40, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:56:34',
    SlNo: '759',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:56:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:55:15',
    Latitude: '35.218283',
    Longitude: '-101.3860633',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '9670 I-40 Frontage Rd, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:55:15',
    SlNo: '760',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:55:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:54:32',
    Latitude: '35.212748',
    Longitude: '-101.372098',
    Speed: '22.37 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Panhandle, TX 79068, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:54:32',
    SlNo: '761',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:54:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:53:54',
    Latitude: '35.2083337',
    Longitude: '-101.3602467',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:53:54',
    SlNo: '762',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:53:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:52:32',
    Latitude: '35.2078314',
    Longitude: '-101.3310649',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:52:32',
    SlNo: '763',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:52:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:52:12',
    Latitude: '35.2077821',
    Longitude: '-101.3240174',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:52:12',
    SlNo: '764',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:52:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:50:32',
    Latitude: '35.2078032',
    Longitude: '-101.2887745',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Frontage Rd, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:50:32',
    SlNo: '765',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:50:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:49:32',
    Latitude: '35.2077816',
    Longitude: '-101.2676894',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '242 Co Rd T, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:49:32',
    SlNo: '766',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:49:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:48:20',
    Latitude: '35.2077698',
    Longitude: '-101.2426791',
    Speed: '18.64 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:48:20',
    SlNo: '767',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:48:20 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:47:50',
    Latitude: '35.2077497',
    Longitude: '-101.2317814',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:47:50',
    SlNo: '768',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:47:50 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:46:12',
    Latitude: '35.207707',
    Longitude: '-101.1973341',
    Speed: '16.16 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:46:12',
    SlNo: '769',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:46:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:45:45',
    Latitude: '35.2076971',
    Longitude: '-101.1878416',
    Speed: '27.96 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '12000 U.S. Rte 66, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:45:45',
    SlNo: '770',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:45:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:44:57',
    Latitude: '35.2076616',
    Longitude: '-101.171357',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:44:57',
    SlNo: '771',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:44:57 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:44:12',
    Latitude: '35.2076283',
    Longitude: '-101.1550776',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '10975 I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:44:12',
    SlNo: '772',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:44:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:43:54',
    Latitude: '35.2076322',
    Longitude: '-101.1490986',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '11395 I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:43:54',
    SlNo: '773',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:43:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:42:12',
    Latitude: '35.2135759',
    Longitude: '-101.1137155',
    Speed: '44.12 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:42:12',
    SlNo: '774',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:42:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:40:55',
    Latitude: '35.2035672',
    Longitude: '-101.0907354',
    Speed: '29.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:40:55',
    SlNo: '775',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:40:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:40:05',
    Latitude: '35.1952141',
    Longitude: '-101.0766955',
    Speed: '20.51 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:40:05',
    SlNo: '776',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:40:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:39:32',
    Latitude: '35.1930866',
    Longitude: '-101.0650526',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Frontage Rd, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:39:32',
    SlNo: '777',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:39:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:38:05',
    Latitude: '35.1876684',
    Longitude: '-101.0354902',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:38:05',
    SlNo: '778',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:38:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:36:45',
    Latitude: '35.1831666',
    Longitude: '-101.0080354',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40 Frontage Rd, Groom, TX 79039, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:36:45',
    SlNo: '779',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:36:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:36:05',
    Latitude: '35.1818067',
    Longitude: '-100.9940232',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Texas, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:36:05',
    SlNo: '780',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:36:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:35:46',
    Latitude: '35.1813703',
    Longitude: '-100.9866726',
    Speed: '59.03 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:35:46',
    SlNo: '781',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:35:46 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:34:05',
    Latitude: '35.1831434',
    Longitude: '-100.9520437',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:34:05',
    SlNo: '782',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:34:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:32:22',
    Latitude: '35.1831484',
    Longitude: '-100.9154331',
    Speed: '9.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:32:22',
    SlNo: '783',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:32:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:32:05',
    Latitude: '35.1831303',
    Longitude: '-100.909833',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:32:05',
    SlNo: '784',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:32:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:31:21',
    Latitude: '35.1793942',
    Longitude: '-100.8949907',
    Speed: '44.12 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:31:21',
    SlNo: '785',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:31:21 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:30:05',
    Latitude: '35.1835087',
    Longitude: '-100.8693409',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:30:05',
    SlNo: '786',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:30:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:28:48',
    Latitude: '35.1807734',
    Longitude: '-100.8427266',
    Speed: '25.48 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clarendon, TX 79226, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:28:48',
    SlNo: '787',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:28:48 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:26:06',
    Latitude: '35.2003991',
    Longitude: '-100.795643',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Texas, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:26:06',
    SlNo: '788',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:26:06 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:25:45',
    Latitude: '35.2034341',
    Longitude: '-100.7897619',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:25:45',
    SlNo: '789',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:25:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:24:05',
    Latitude: '35.214203',
    Longitude: '-100.7636419',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:24:05',
    SlNo: '790',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:24:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:24:04',
    Latitude: '35.214203',
    Longitude: '-100.7636419',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:24:04',
    SlNo: '791',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:24:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:21:22',
    Latitude: '35.2148009',
    Longitude: '-100.7048148',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:21:22',
    SlNo: '792',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:21:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:20:05',
    Latitude: '35.2167472',
    Longitude: '-100.6832237',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:20:05',
    SlNo: '793',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:20:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:19:44',
    Latitude: '35.2173998',
    Longitude: '-100.6761042',
    Speed: '60.27 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:19:44',
    SlNo: '794',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:19:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:18:05',
    Latitude: '35.2267375',
    Longitude: '-100.6423699',
    Speed: '33.55 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:18:05',
    SlNo: '795',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:18:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:17:05',
    Latitude: '35.2280896',
    Longitude: '-100.6228025',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:17:05',
    SlNo: '796',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:17:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:16:05',
    Latitude: '35.2269805',
    Longitude: '-100.6025125',
    Speed: '11.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '517 Main St, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:16:05',
    SlNo: '797',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:16:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:15:45',
    Latitude: '35.2268805',
    Longitude: '-100.5960834',
    Speed: '60.27 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '617 Cooke St, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:15:45',
    SlNo: '798',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:15:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:14:05',
    Latitude: '35.226757',
    Longitude: '-100.560667',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:14:05',
    SlNo: '799',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:14:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:13:06',
    Latitude: '35.2267629',
    Longitude: '-100.5390195',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:13:06',
    SlNo: '800',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:13:06 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:12:07',
    Latitude: '35.2271518',
    Longitude: '-100.5173613',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '14410 I-40, McLean, TX 79057, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:12:07',
    SlNo: '801',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:12:07 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:11:04',
    Latitude: '35.227082',
    Longitude: '-100.4956622',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:11:04',
    SlNo: '802',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:11:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:10:04',
    Latitude: '35.2271759',
    Longitude: '-100.4739262',
    Speed: '12.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:10:04',
    SlNo: '803',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:10:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:09:44',
    Latitude: '35.2272054',
    Longitude: '-100.4666841',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I- 40 Srv Rd, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:09:44',
    SlNo: '804',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:09:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:08:04',
    Latitude: '35.2270647',
    Longitude: '-100.4304882',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '14905 I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:08:04',
    SlNo: '805',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:08:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:07:04',
    Latitude: '35.2272443',
    Longitude: '-100.4088884',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I- 40 Srv Rd, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:07:04',
    SlNo: '806',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:07:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:06:05',
    Latitude: '35.2271105',
    Longitude: '-100.3877728',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15145 I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:06:05',
    SlNo: '807',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:06:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:06:04',
    Latitude: '35.2271105',
    Longitude: '-100.3877728',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15145 I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:06:04',
    SlNo: '808',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:06:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:02:13',
    Latitude: '35.2270553',
    Longitude: '-100.3058942',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I- 40 Srv Rd, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:02:13',
    SlNo: '809',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:02:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 04:02:13',
    Latitude: '35.227322',
    Longitude: '-100.2777916',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:02:13',
    SlNo: '810',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:02:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:58:04',
    Latitude: '35.2290458',
    Longitude: '-100.2296398',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:58:04',
    SlNo: '811',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:58:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:57:04',
    Latitude: '35.2270431',
    Longitude: '-100.2091314',
    Speed: '42.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:57:04',
    SlNo: '812',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:57:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:55:58',
    Latitude: '35.2270158',
    Longitude: '-100.183736',
    Speed: '55.92 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '16296 Interstate 40 Service Rd, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:55:58',
    SlNo: '813',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:55:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:54:23',
    Latitude: '35.2269849',
    Longitude: '-100.1508172',
    Speed: '18.02 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:54:23',
    SlNo: '814',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:54:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:53:54',
    Latitude: '35.2269518',
    Longitude: '-100.1402874',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'Interstate 40 Service Rd, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:53:54',
    SlNo: '815',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:53:54',
    Latitude: '35.2269518',
    Longitude: '-100.1402874',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'Interstate 40 Service Rd, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:53:54',
    SlNo: '816',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:53:54',
    Latitude: '35.2269014',
    Longitude: '-100.0968408',
    Speed: '13.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:53:54',
    SlNo: '817',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:53:54',
    Latitude: '35.2269097',
    Longitude: '-100.0892266',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:53:54',
    SlNo: '818',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:53:54',
    Latitude: '35.2269392',
    Longitude: '-100.0539471',
    Speed: '27.34 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:53:54',
    SlNo: '819',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:53:54',
    Latitude: '35.2269978',
    Longitude: '-100.037911',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '17150 Interstate 40 Service Rd, Shamrock, TX 79079, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:53:54',
    SlNo: '820',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:46:04',
    Latitude: '35.2314951',
    Longitude: '-99.9645395',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:46:04',
    SlNo: '821',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:46:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:45:26',
    Latitude: '35.2320782',
    Longitude: '-99.957691',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:45:26',
    SlNo: '822',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:45:26 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:43:44',
    Latitude: '35.2271481',
    Longitude: '-99.9217934',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:43:44',
    SlNo: '823',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:43:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:42:26',
    Latitude: '35.2265108',
    Longitude: '-99.8932701',
    Speed: '32.31 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:42:26',
    SlNo: '824',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:42:26 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:41:32',
    Latitude: '35.2265036',
    Longitude: '-99.8741025',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1001 N Sheb Wooley Ave, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:41:32',
    SlNo: '825',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:41:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:41:04',
    Latitude: '35.2264793',
    Longitude: '-99.8639645',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:41:04',
    SlNo: '826',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:41:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:39:32',
    Latitude: '35.2284043',
    Longitude: '-99.8307871',
    Speed: '38.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '17672 I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:39:32',
    SlNo: '827',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:39:32 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:38:30',
    Latitude: '35.2301639',
    Longitude: '-99.8083526',
    Speed: '37.90 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:38:30',
    SlNo: '828',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:38:30 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:37:24',
    Latitude: '35.2307517',
    Longitude: '-99.7859191',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:37:24',
    SlNo: '829',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:37:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:37:04',
    Latitude: '35.2321676',
    Longitude: '-99.7790597',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:37:04',
    SlNo: '830',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:37:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:35:25',
    Latitude: '35.2470459',
    Longitude: '-99.749108',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:35:25',
    SlNo: '831',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:35:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:34:24',
    Latitude: '35.25628',
    Longitude: '-99.7311236',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Erick, OK 73645, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:34:24',
    SlNo: '832',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:34:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:33:24',
    Latitude: '35.2614553',
    Longitude: '-99.7111305',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:33:24',
    SlNo: '833',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:33:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:32:22',
    Latitude: '35.2621613',
    Longitude: '-99.6902707',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:32:22',
    SlNo: '834',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:32:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:31:24',
    Latitude: '35.2620572',
    Longitude: '-99.6694376',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:31:24',
    SlNo: '835',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:31:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:31:04',
    Latitude: '35.2620519',
    Longitude: '-99.6623747',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:31:04',
    SlNo: '836',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:31:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:29:24',
    Latitude: '35.2701825',
    Longitude: '-99.6301252',
    Speed: '31.07 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:29:24',
    SlNo: '837',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:29:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:28:24',
    Latitude: '35.2840503',
    Longitude: '-99.6226559',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:28:24',
    SlNo: '838',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:28:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:27:18',
    Latitude: '35.3005292',
    Longitude: '-99.6177272',
    Speed: '55.92 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:27:18',
    SlNo: '839',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:27:18 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:25:48',
    Latitude: '35.3255469',
    Longitude: '-99.6054977',
    Speed: '15.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'Rte 66 N, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:25:48',
    SlNo: '840',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:25:48 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:25:21',
    Latitude: '35.3317765',
    Longitude: '-99.6001778',
    Speed: '31.69 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:25:21',
    SlNo: '841',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:25:21 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:24:23',
    Latitude: '35.3422309',
    Longitude: '-99.586403',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:24:23',
    SlNo: '842',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:24:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:23:04',
    Latitude: '35.3550886',
    Longitude: '-99.5628978',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:23:04',
    SlNo: '843',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:23:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:21:43',
    Latitude: '35.3699535',
    Longitude: '-99.5411068',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '19289 U.S. Rte 66, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:21:43',
    SlNo: '844',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:21:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:21:04',
    Latitude: '35.3763455',
    Longitude: '-99.5293252',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15465 Bkj, Sayre, OK 73662, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:21:04',
    SlNo: '845',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:21:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:20:19',
    Latitude: '35.3834155',
    Longitude: '-99.5163511',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:20:19',
    SlNo: '846',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:20:19 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:19:03',
    Latitude: '35.3858183',
    Longitude: '-99.4902828',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'OK-34, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:19:03',
    SlNo: '847',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:19:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:18:04',
    Latitude: '35.3860241',
    Longitude: '-99.4692757',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'OK-34, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:18:04',
    SlNo: '848',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:18:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:17:03',
    Latitude: '35.3862619',
    Longitude: '-99.4485505',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'OK-34, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:17:03',
    SlNo: '849',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:17:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:15:27',
    Latitude: '35.3858005',
    Longitude: '-99.4134937',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'OK-34, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:15:27',
    SlNo: '850',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:15:27 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:15:03',
    Latitude: '35.3872414',
    Longitude: '-99.4066718',
    Speed: '23.61 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '114 Meadow Ridge Dr, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:15:03',
    SlNo: '851',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:15:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:14:25',
    Latitude: '35.3948684',
    Longitude: '-99.3964372',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '119 Coral Ave, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:14:25',
    SlNo: '852',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:14:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:13:03',
    Latitude: '35.4127933',
    Longitude: '-99.3780884',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2300 E 3rd St, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:13:03',
    SlNo: '853',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:13:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:11:43',
    Latitude: '35.4215211',
    Longitude: '-99.3537406',
    Speed: '24.23 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:11:43',
    SlNo: '854',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:11:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:11:03',
    Latitude: '35.4216021',
    Longitude: '-99.339552',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:11:03',
    SlNo: '855',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:11:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:10:19',
    Latitude: '35.421663',
    Longitude: '-99.3242012',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '20548 S Frontage Rd, Elk City, OK 73644, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:10:19',
    SlNo: '856',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:10:19 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:09:03',
    Latitude: '35.4237746',
    Longitude: '-99.2978045',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Canute, OK 73626, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:09:03',
    SlNo: '857',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:09:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:07:59',
    Latitude: '35.4256593',
    Longitude: '-99.2760007',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'N2080 Rd, Canute, OK 73626, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:07:59',
    SlNo: '858',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:07:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:07:00',
    Latitude: '35.4217255',
    Longitude: '-99.2559705',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Canute, OK 73626, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:07:00',
    SlNo: '859',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:07:00 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:06:23',
    Latitude: '35.4217317',
    Longitude: '-99.243311',
    Speed: '50.33 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Canute, OK 73626, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:06:23',
    SlNo: '860',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:06:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:05:01',
    Latitude: '35.426145',
    Longitude: '-99.2141603',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '21167 U.S. Rte 66, Foss, OK 73647, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:05:01',
    SlNo: '861',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:05:01 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:03:59',
    Latitude: '35.4360325',
    Longitude: '-99.1976237',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Foss, OK 73647, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:03:59',
    SlNo: '862',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:03:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:02:59',
    Latitude: '35.4417734',
    Longitude: '-99.1778586',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Foss, OK 73647, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:02:59',
    SlNo: '863',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:02:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:01:20',
    Latitude: '35.4587575',
    Longitude: '-99.1505867',
    Speed: '14.29 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Foss, OK 73647, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:01:20',
    SlNo: '864',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:01:20 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:00:55',
    Latitude: '35.4638312',
    Longitude: '-99.1448407',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Foss, OK 73647, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:00:55',
    SlNo: '865',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:00:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 03:00:20',
    Latitude: '35.4708896',
    Longitude: '-99.135549',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:00:20',
    SlNo: '866',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:00:20 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:59:09',
    Latitude: '35.4801643',
    Longitude: '-99.1085944',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:09',
    SlNo: '867',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:09 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:57:40',
    Latitude: '35.4801906',
    Longitude: '-99.0821482',
    Speed: '26.10 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'Rte 66 N, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:57:40',
    SlNo: '868',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:57:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:56:55',
    Latitude: '35.4801626',
    Longitude: '-99.0666129',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:56:55',
    SlNo: '869',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:56:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:56:21',
    Latitude: '35.4801288',
    Longitude: '-99.0538859',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:56:21',
    SlNo: '870',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:56:21 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:54:55',
    Latitude: '35.4807918',
    Longitude: '-99.0247835',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:54:55',
    SlNo: '871',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:54:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:52:41',
    Latitude: '35.4978931',
    Longitude: '-98.9829732',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2000 Lexington Ave, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:52:41',
    SlNo: '872',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:52:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:50:55',
    Latitude: '35.5004308',
    Longitude: '-98.9497236',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:50:55',
    SlNo: '873',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:50:55 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:49:33',
    Latitude: '35.5113388',
    Longitude: '-98.9245976',
    Speed: '29.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:49:33',
    SlNo: '874',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:49:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:48:47',
    Latitude: '35.5162661',
    Longitude: '-98.9084962',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Custer City, OK 73639, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:48:47',
    SlNo: '875',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:48:47 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:48:15',
    Latitude: '35.5162873',
    Longitude: '-98.8982729',
    Speed: '54.06 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:48:15',
    SlNo: '876',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:48:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:46:43',
    Latitude: '35.5162792',
    Longitude: '-98.8662067',
    Speed: '34.80 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Clinton, OK 73601, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:46:43',
    SlNo: '877',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:46:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:45:43',
    Latitude: '35.5175319',
    Longitude: '-98.8457431',
    Speed: '38.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:45:43',
    SlNo: '878',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:45:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:44:39',
    Latitude: '35.5225051',
    Longitude: '-98.8237935',
    Speed: '11.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:44:39',
    SlNo: '879',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:44:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:44:19',
    Latitude: '35.522529',
    Longitude: '-98.8167309',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:44:19',
    SlNo: '880',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:44:19 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:42:39',
    Latitude: '35.5223178',
    Longitude: '-98.7813815',
    Speed: '35.42 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:42:39',
    SlNo: '881',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:42:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:41:39',
    Latitude: '35.5222604',
    Longitude: '-98.7603006',
    Speed: '34.18 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'U.S. Rte 66, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:41:39',
    SlNo: '882',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:41:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:40:39',
    Latitude: '35.5221784',
    Longitude: '-98.7402353',
    Speed: '36.04 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:40:39',
    SlNo: '883',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:40:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:39:34',
    Latitude: '35.5211193',
    Longitude: '-98.7189238',
    Speed: '28.58 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '900 SW N 4th St, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:39:34',
    SlNo: '884',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:39:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:38:39',
    Latitude: '35.5217655',
    Longitude: '-98.7021194',
    Speed: '9.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '500 E Eads Ave, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:38:39',
    SlNo: '885',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:38:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:38:15',
    Latitude: '35.5223047',
    Longitude: '-98.6964825',
    Speed: '6.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1026 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:38:15',
    SlNo: '886',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:38:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:36:39',
    Latitude: '35.5225447',
    Longitude: '-98.6924568',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1200 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:36:39',
    SlNo: '887',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:36:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:35:39',
    Latitude: '35.5225137',
    Longitude: '-98.6892735',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1440 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:35:39',
    SlNo: '888',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:35:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:34:36',
    Latitude: '35.5230138',
    Longitude: '-98.6893876',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:34:36',
    SlNo: '889',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:34:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:34:18',
    Latitude: '35.5232633',
    Longitude: '-98.6891455',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:34:18',
    SlNo: '890',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:34:18 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:32:36',
    Latitude: '35.5237752',
    Longitude: '-98.688813',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:32:36',
    SlNo: '891',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:32:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:31:36',
    Latitude: '35.5237766',
    Longitude: '-98.6888118',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:31:36',
    SlNo: '892',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:31:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:30:36',
    Latitude: '35.5237754',
    Longitude: '-98.6888138',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:30:36',
    SlNo: '893',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:30:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:29:33',
    Latitude: '35.5237742',
    Longitude: '-98.688814',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:29:33',
    SlNo: '894',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:29:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:28:36',
    Latitude: '35.5237681',
    Longitude: '-98.6887983',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:28:36',
    SlNo: '895',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:28:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:28:15',
    Latitude: '35.5237627',
    Longitude: '-98.6888049',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:28:15',
    SlNo: '896',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:28:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:26:45',
    Latitude: '35.5237733',
    Longitude: '-98.6887858',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:26:45',
    SlNo: '897',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:26:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:25:16',
    Latitude: '35.5237535',
    Longitude: '-98.6887604',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:25:16',
    SlNo: '898',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:25:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:24:36',
    Latitude: '35.5237516',
    Longitude: '-98.6887557',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:24:36',
    SlNo: '899',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:24:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:24:16',
    Latitude: '35.5237606',
    Longitude: '-98.6887905',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:24:16',
    SlNo: '900',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:24:16 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:22:36',
    Latitude: '35.5237674',
    Longitude: '-98.6887707',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:22:36',
    SlNo: '901',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:22:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:21:36',
    Latitude: '35.5237629',
    Longitude: '-98.6887601',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:21:36',
    SlNo: '902',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:21:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:20:36',
    Latitude: '35.5238088',
    Longitude: '-98.6888196',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:20:36',
    SlNo: '903',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:20:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:19:33',
    Latitude: '35.5238097',
    Longitude: '-98.6888289',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:19:33',
    SlNo: '904',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:19:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:18:35',
    Latitude: '35.5237961',
    Longitude: '-98.6888283',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:18:35',
    SlNo: '905',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:18:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:18:17',
    Latitude: '35.5237969',
    Longitude: '-98.688827',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:18:17',
    SlNo: '906',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:18:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:16:35',
    Latitude: '35.5238236',
    Longitude: '-98.6887988',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:16:35',
    SlNo: '907',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:16:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:15:15',
    Latitude: '35.5238195',
    Longitude: '-98.6888037',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:15:15',
    SlNo: '908',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:15:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:14:37',
    Latitude: '35.5238223',
    Longitude: '-98.6888356',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:14:37',
    SlNo: '909',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:14:37 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:14:15',
    Latitude: '35.5237926',
    Longitude: '-98.688817',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:14:15',
    SlNo: '910',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:14:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:12:35',
    Latitude: '35.5237951',
    Longitude: '-98.6888386',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:12:35',
    SlNo: '911',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:12:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:11:35',
    Latitude: '35.5238053',
    Longitude: '-98.6888289',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:11:35',
    SlNo: '912',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:11:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:10:35',
    Latitude: '35.5238056',
    Longitude: '-98.68883',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:10:35',
    SlNo: '913',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:10:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:09:33',
    Latitude: '35.5238059',
    Longitude: '-98.6888181',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:09:33',
    SlNo: '914',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:09:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:08:35',
    Latitude: '35.5238087',
    Longitude: '-98.6888085',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:08:35',
    SlNo: '915',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:08:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:08:14',
    Latitude: '35.5238071',
    Longitude: '-98.6888108',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:08:14',
    SlNo: '916',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:08:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:06:35',
    Latitude: '35.5237774',
    Longitude: '-98.6887962',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:06:35',
    SlNo: '917',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:06:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:05:14',
    Latitude: '35.5237541',
    Longitude: '-98.6888175',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:05:14',
    SlNo: '918',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:05:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:04:35',
    Latitude: '35.5237521',
    Longitude: '-98.6888271',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:04:35',
    SlNo: '919',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:04:35 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:13:15',
    Latitude: '35.2093764',
    Longitude: '-102.1915957',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Wildorado, TX 79098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:13:15',
    SlNo: '209',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:13:15 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:09:14',
    Latitude: '35.1946122',
    Longitude: '-102.1087872',
    Speed: '26.72 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Bushland, TX 79012, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:09:14',
    SlNo: '210',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:09:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:08:31',
    Latitude: '35.1920653',
    Longitude: '-102.0933262',
    Speed: '46.60 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Bushland, TX 79012, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:08:31',
    SlNo: '211',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:08:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:07:14',
    Latitude: '35.1913741',
    Longitude: '-102.0659356',
    Speed: '14.29 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '20101 I-40, Bushland, TX 79012, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:07:14',
    SlNo: '212',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:07:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:06:50',
    Latitude: '35.1911914',
    Longitude: '-102.0573496',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '18600 I-40, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:06:50',
    SlNo: '213',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:06:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:05:14',
    Latitude: '35.1904138',
    Longitude: '-102.023687',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: 'I-40, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:05:14',
    SlNo: '214',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:05:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:04:14',
    Latitude: '35.1904285',
    Longitude: '-102.0108107',
    Speed: '4.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '14731 Indian Hill Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:04:14',
    SlNo: '215',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:04:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:03:14',
    Latitude: '35.1881435',
    Longitude: '-102.0102392',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:03:14',
    SlNo: '216',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:03:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:02:54',
    Latitude: '35.1873651',
    Longitude: '-102.0102417',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:02:54',
    SlNo: '217',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:02:54 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:01:14',
    Latitude: '35.1866123',
    Longitude: '-102.0110057',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:01:14',
    SlNo: '218',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:01:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 14:00:14',
    Latitude: '35.1866237',
    Longitude: '-102.0110035',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:00:14',
    SlNo: '219',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:00:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:59:14',
    Latitude: '35.1866361',
    Longitude: '-102.0109994',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:59:14',
    SlNo: '220',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:59:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:58:30',
    Latitude: '35.1866331',
    Longitude: '-102.010994',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:58:30',
    SlNo: '221',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:58:30 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:57:13',
    Latitude: '35.1866438',
    Longitude: '-102.0109814',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:57:13',
    SlNo: '222',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:57:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:56:50',
    Latitude: '35.1866495',
    Longitude: '-102.0109678',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:56:50',
    SlNo: '223',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:56:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:55:13',
    Latitude: '35.1866405',
    Longitude: '-102.0109886',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:55:13',
    SlNo: '224',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:55:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:54:13',
    Latitude: '35.1866242',
    Longitude: '-102.0109861',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:54:13',
    SlNo: '225',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:54:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:53:14',
    Latitude: '35.1865714',
    Longitude: '-102.0109926',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:53:14',
    SlNo: '226',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:53:14 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:52:53',
    Latitude: '35.1865721',
    Longitude: '-102.0109898',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:52:53',
    SlNo: '227',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:52:53 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:51:19',
    Latitude: '35.1866022',
    Longitude: '-102.0109907',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:51:19',
    SlNo: '228',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:51:19 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:50:17',
    Latitude: '35.1866106',
    Longitude: '-102.0110034',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:50:17',
    SlNo: '229',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:50:17 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:49:16',
    Latitude: '35.1870534',
    Longitude: '-102.0109078',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:49:16',
    SlNo: '230',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:49:16 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:48:30',
    Latitude: '35.1866902',
    Longitude: '-102.0111231',
    Speed: '1.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:48:30',
    SlNo: '231',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:48:30 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:47:13',
    Latitude: '35.1869996',
    Longitude: '-102.0118319',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:47:13',
    SlNo: '232',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:47:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:46:49',
    Latitude: '35.1872542',
    Longitude: '-102.0120642',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:46:49',
    SlNo: '233',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:46:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:45:13',
    Latitude: '35.1874997',
    Longitude: '-102.0117964',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:45:13',
    SlNo: '234',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:45:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:44:13',
    Latitude: '35.187468',
    Longitude: '-102.011545',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:44:13',
    SlNo: '235',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:44:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:43:13',
    Latitude: '35.1874944',
    Longitude: '-102.0115304',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:43:13',
    SlNo: '236',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:43:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:43:04',
    Latitude: '35.1874767',
    Longitude: '-102.0114945',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:43:04',
    SlNo: '237',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:43:04 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:41:13',
    Latitude: '35.1875191',
    Longitude: '-102.0113358',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:41:13',
    SlNo: '238',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:41:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:40:13',
    Latitude: '35.1875226',
    Longitude: '-102.0113388',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:40:13',
    SlNo: '239',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:40:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:39:13',
    Latitude: '35.1875243',
    Longitude: '-102.011345',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:39:13',
    SlNo: '240',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:39:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:38:41',
    Latitude: '35.1875162',
    Longitude: '-102.0113221',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:38:41',
    SlNo: '241',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:38:41 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:37:13',
    Latitude: '35.1875383',
    Longitude: '-102.0113068',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:37:13',
    SlNo: '242',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:37:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:36:49',
    Latitude: '35.1875353',
    Longitude: '-102.0113295',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:36:49',
    SlNo: '243',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:36:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:35:13',
    Latitude: '35.1874811',
    Longitude: '-102.0112851',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:35:13',
    SlNo: '244',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:35:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:34:13',
    Latitude: '35.1874777',
    Longitude: '-102.0112707',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:34:13',
    SlNo: '245',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:34:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:33:09',
    Latitude: '35.1874271',
    Longitude: '-102.011331',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:33:09',
    SlNo: '246',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:33:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:32:49',
    Latitude: '35.1874277',
    Longitude: '-102.0113284',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:32:49',
    SlNo: '247',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:32:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:31:09',
    Latitude: '35.1873889',
    Longitude: '-102.0113817',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:31:09',
    SlNo: '248',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:31:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:30:09',
    Latitude: '35.187388',
    Longitude: '-102.011382',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:30:09',
    SlNo: '249',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:30:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:29:09',
    Latitude: '35.1873899',
    Longitude: '-102.011381',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '15001 Frontage Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:29:09',
    SlNo: '250',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:29:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:28:29',
    Latitude: '35.1874451',
    Longitude: '-102.0113136',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:28:29',
    SlNo: '251',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:28:29 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:27:09',
    Latitude: '35.1874458',
    Longitude: '-102.0113117',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:27:09',
    SlNo: '252',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:27:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:26:49',
    Latitude: '35.1874439',
    Longitude: '-102.0113288',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:26:49',
    SlNo: '253',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:26:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:25:09',
    Latitude: '35.1874643',
    Longitude: '-102.0113148',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:25:09',
    SlNo: '254',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:25:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:23:59',
    Latitude: '35.1874317',
    Longitude: '-102.0113159',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:23:59',
    SlNo: '255',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:23:59 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:23:09',
    Latitude: '35.1874673',
    Longitude: '-102.0111353',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:23:09',
    SlNo: '256',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:23:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:22:49',
    Latitude: '35.1874662',
    Longitude: '-102.0109625',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:22:49',
    SlNo: '257',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:22:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:21:09',
    Latitude: '35.1866087',
    Longitude: '-102.0110093',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:21:09',
    SlNo: '258',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:20:09',
    Latitude: '35.1866019',
    Longitude: '-102.0110024',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:20:09',
    SlNo: '259',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:20:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:19:09',
    Latitude: '35.1865561',
    Longitude: '-102.0109507',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:19:09',
    SlNo: '260',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:19:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:18:31',
    Latitude: '35.1865185',
    Longitude: '-102.0109395',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:18:31',
    SlNo: '261',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:18:31 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:17:09',
    Latitude: '35.1865284',
    Longitude: '-102.0109526',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:17:09',
    SlNo: '262',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:17:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:16:51',
    Latitude: '35.1865273',
    Longitude: '-102.010969',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:16:51',
    SlNo: '263',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:16:51 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:15:09',
    Latitude: '35.1866044',
    Longitude: '-102.0109915',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:15:09',
    SlNo: '264',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:15:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:13:49',
    Latitude: '35.1865851',
    Longitude: '-102.0110028',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:13:49',
    SlNo: '265',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:13:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:13:09',
    Latitude: '35.1866069',
    Longitude: '-102.0109958',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:13:09',
    SlNo: '266',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:13:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:12:26',
    Latitude: '35.186599',
    Longitude: '-102.0109928',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:12:26',
    SlNo: '267',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:12:26 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:11:09',
    Latitude: '35.1866109',
    Longitude: '-102.0109852',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:11:09',
    SlNo: '268',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:11:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:10:06',
    Latitude: '35.1866131',
    Longitude: '-102.0109854',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:10:06',
    SlNo: '269',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:10:06 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:09:06',
    Latitude: '35.1866211',
    Longitude: '-102.0109921',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:09:06',
    SlNo: '270',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:09:06 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:08:29',
    Latitude: '35.1866208',
    Longitude: '-102.0109781',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:08:29',
    SlNo: '271',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:08:29 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:07:06',
    Latitude: '35.1866911',
    Longitude: '-102.0109473',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:07:06',
    SlNo: '272',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:07:06 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:06:49',
    Latitude: '35.1866635',
    Longitude: '-102.0109672',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:06:49',
    SlNo: '273',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:06:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:05:09',
    Latitude: '35.1866782',
    Longitude: '-102.0109825',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:05:09',
    SlNo: '274',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:05:09 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:03:49',
    Latitude: '35.1866617',
    Longitude: '-102.0110396',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:03:49',
    SlNo: '275',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:03:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:03:01',
    Latitude: '35.1866477',
    Longitude: '-102.011029',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:03:01',
    SlNo: '276',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:03:01 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:02:26',
    Latitude: '35.1866393',
    Longitude: '-102.0110429',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:02:26',
    SlNo: '277',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:02:26 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:01:01',
    Latitude: '35.186648',
    Longitude: '-102.0110359',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:01:01',
    SlNo: '278',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:01:01 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 13:00:00',
    Latitude: '35.1866717',
    Longitude: '-102.0110035',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:00:00',
    SlNo: '279',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:00:00 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:58:49',
    Latitude: '35.1867011',
    Longitude: '-102.0109876',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:58:49',
    SlNo: '280',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:58:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:58:29',
    Latitude: '35.1866945',
    Longitude: '-102.0109964',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:58:29',
    SlNo: '281',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:58:29 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:56:49',
    Latitude: '35.1866532',
    Longitude: '-102.0109868',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:56:49',
    SlNo: '282',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:56:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:55:49',
    Latitude: '35.1866439',
    Longitude: '-102.0109857',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:55:49',
    SlNo: '283',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:55:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:54:49',
    Latitude: '35.186651',
    Longitude: '-102.0109862',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:54:49',
    SlNo: '284',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:54:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:53:48',
    Latitude: '35.1866404',
    Longitude: '-102.0109693',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:53:48',
    SlNo: '285',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:53:48 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:52:49',
    Latitude: '35.1866201',
    Longitude: '-102.0109592',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:52:49',
    SlNo: '286',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:52:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:52:26',
    Latitude: '35.1866357',
    Longitude: '-102.0109778',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:52:26',
    SlNo: '287',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:52:26 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:50:50',
    Latitude: '35.1866262',
    Longitude: '-102.0109597',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:50:50',
    SlNo: '288',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:50:50 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:49:48',
    Latitude: '35.1866283',
    Longitude: '-102.0109687',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:49:48',
    SlNo: '289',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:49:48 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:48:49',
    Latitude: '35.1866362',
    Longitude: '-102.0109862',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:48:49',
    SlNo: '290',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:48:49 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:48:28',
    Latitude: '35.186635',
    Longitude: '-102.0110062',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:48:28',
    SlNo: '291',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:48:28 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:46:48',
    Latitude: '35.1866057',
    Longitude: '-102.0110214',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:46:48',
    SlNo: '292',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:46:48 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:45:48',
    Latitude: '35.1866506',
    Longitude: '-102.0110592',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:45:48',
    SlNo: '293',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:45:48 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:44:48',
    Latitude: '35.1866625',
    Longitude: '-102.0110879',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:44:48',
    SlNo: '294',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:44:48 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:44:13',
    Latitude: '35.1866986',
    Longitude: '-102.011068',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:44:13',
    SlNo: '295',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:44:13 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:43:48',
    Latitude: '35.1867009',
    Longitude: '-102.0110657',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:43:48',
    SlNo: '296',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:43:48 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:42:46',
    Latitude: '35.1866631',
    Longitude: '-102.0110105',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:42:46',
    SlNo: '297',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:42:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:42:25',
    Latitude: '35.1866562',
    Longitude: '-102.0110133',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:42:25',
    SlNo: '298',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:42:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:40:46',
    Latitude: '35.1866323',
    Longitude: '-102.0110203',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:40:46',
    SlNo: '299',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:40:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:39:46',
    Latitude: '35.1866298',
    Longitude: '-102.0110288',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:39:46',
    SlNo: '300',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:39:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:38:52',
    Latitude: '35.1866259',
    Longitude: '-102.0110011',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:38:52',
    SlNo: '301',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:38:52 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:38:27',
    Latitude: '35.1866183',
    Longitude: '-102.0110191',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:38:27',
    SlNo: '302',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:38:27 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:36:46',
    Latitude: '35.1866441',
    Longitude: '-102.0110671',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:36:46',
    SlNo: '303',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:36:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:35:46',
    Latitude: '35.1866388',
    Longitude: '-102.0110448',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:35:46',
    SlNo: '304',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:35:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:34:46',
    Latitude: '35.1866431',
    Longitude: '-102.0109731',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:34:46',
    SlNo: '305',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:34:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:33:25',
    Latitude: '35.186623',
    Longitude: '-102.0109937',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:33:25',
    SlNo: '306',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:33:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:32:46',
    Latitude: '35.1866138',
    Longitude: '-102.0109961',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:32:46',
    SlNo: '307',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:32:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:32:25',
    Latitude: '35.1866214',
    Longitude: '-102.0110353',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:32:25',
    SlNo: '308',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:32:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:30:46',
    Latitude: '35.1866335',
    Longitude: '-102.0110187',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:30:46',
    SlNo: '309',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:30:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:29:46',
    Latitude: '35.1866594',
    Longitude: '-102.0110206',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:29:46',
    SlNo: '310',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:29:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:28:48',
    Latitude: '35.186641',
    Longitude: '-102.0110136',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:28:48',
    SlNo: '311',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:28:48 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:28:27',
    Latitude: '35.1866439',
    Longitude: '-102.011012',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:28:27',
    SlNo: '312',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:28:27 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:26:46',
    Latitude: '35.1866656',
    Longitude: '-102.0110155',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:26:46',
    SlNo: '313',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:26:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:25:51',
    Latitude: '35.1866733',
    Longitude: '-102.0109795',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:25:51',
    SlNo: '314',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:25:51 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:24:46',
    Latitude: '35.1866658',
    Longitude: '-102.011048',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:24:46',
    SlNo: '315',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:24:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:23:25',
    Latitude: '35.1866812',
    Longitude: '-102.0110632',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:23:25',
    SlNo: '316',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:23:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:22:46',
    Latitude: '35.1866871',
    Longitude: '-102.0110419',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:22:46',
    SlNo: '317',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:22:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:22:25',
    Latitude: '35.1866836',
    Longitude: '-102.0110256',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:22:25',
    SlNo: '318',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:22:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:20:48',
    Latitude: '35.1866825',
    Longitude: '-102.0109701',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:20:48',
    SlNo: '319',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:20:48 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:19:46',
    Latitude: '35.1866816',
    Longitude: '-102.0109692',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:19:46',
    SlNo: '320',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:19:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:18:46',
    Latitude: '35.1866784',
    Longitude: '-102.0110101',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:18:46',
    SlNo: '321',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:18:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:18:35',
    Latitude: '35.1866611',
    Longitude: '-102.0110125',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:18:35',
    SlNo: '322',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:18:35 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:16:46',
    Latitude: '35.1866561',
    Longitude: '-102.0110352',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:16:46',
    SlNo: '323',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:16:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:15:46',
    Latitude: '35.1866632',
    Longitude: '-102.0110386',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:15:46',
    SlNo: '324',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:15:46 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:14:45',
    Latitude: '35.186676',
    Longitude: '-102.0110551',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:14:45',
    SlNo: '325',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:14:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:13:25',
    Latitude: '35.1866735',
    Longitude: '-102.0110568',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:13:25',
    SlNo: '326',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:13:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:12:45',
    Latitude: '35.1866507',
    Longitude: '-102.0110583',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:12:45',
    SlNo: '327',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:12:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:12:25',
    Latitude: '35.1866474',
    Longitude: '-102.0110552',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:12:25',
    SlNo: '328',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:12:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:10:45',
    Latitude: '35.1866283',
    Longitude: '-102.0110356',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:10:45',
    SlNo: '329',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:10:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:09:45',
    Latitude: '35.1866411',
    Longitude: '-102.0110129',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:09:45',
    SlNo: '330',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:09:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:08:45',
    Latitude: '35.1866272',
    Longitude: '-102.0110225',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:08:45',
    SlNo: '331',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:08:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:08:25',
    Latitude: '35.1866272',
    Longitude: '-102.0110174',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:08:25',
    SlNo: '332',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:08:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:06:45',
    Latitude: '35.1866222',
    Longitude: '-102.0110182',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:06:45',
    SlNo: '333',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:06:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:05:47',
    Latitude: '35.1866063',
    Longitude: '-102.0110263',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:05:47',
    SlNo: '334',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:05:47 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:04:45',
    Latitude: '35.1866153',
    Longitude: '-102.010981',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:04:45',
    SlNo: '335',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:04:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:03:25',
    Latitude: '35.1866361',
    Longitude: '-102.0110032',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:03:25',
    SlNo: '336',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:03:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:02:45',
    Latitude: '35.1866327',
    Longitude: '-102.0109755',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:02:45',
    SlNo: '337',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:02:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:02:25',
    Latitude: '35.1866334',
    Longitude: '-102.0109734',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:02:25',
    SlNo: '338',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:02:25 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 12:00:45',
    Latitude: '35.1866459',
    Longitude: '-102.0109268',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:00:45',
    SlNo: '339',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:00:45 PM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:59:45',
    Latitude: '35.186651',
    Longitude: '-102.010918',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:59:45',
    SlNo: '340',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:59:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:58:45',
    Latitude: '35.1866463',
    Longitude: '-102.0109409',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:58:45',
    SlNo: '341',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:58:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:58:25',
    Latitude: '35.1866546',
    Longitude: '-102.0109539',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:58:25',
    SlNo: '342',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:58:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:56:45',
    Latitude: '35.1866592',
    Longitude: '-102.0109952',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:56:45',
    SlNo: '343',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:56:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:55:45',
    Latitude: '35.1866475',
    Longitude: '-102.0109808',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:55:45',
    SlNo: '344',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:55:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:54:45',
    Latitude: '35.1866217',
    Longitude: '-102.0110107',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:54:45',
    SlNo: '345',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:54:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:53:25',
    Latitude: '35.1866203',
    Longitude: '-102.0110121',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:53:25',
    SlNo: '346',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:53:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:52:45',
    Latitude: '35.1866205',
    Longitude: '-102.011015',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:52:45',
    SlNo: '347',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:52:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:52:25',
    Latitude: '35.1866203',
    Longitude: '-102.0110134',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:52:25',
    SlNo: '348',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:52:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:50:45',
    Latitude: '35.1866526',
    Longitude: '-102.0110252',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:50:45',
    SlNo: '349',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:50:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:49:45',
    Latitude: '35.1866545',
    Longitude: '-102.0110262',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:49:45',
    SlNo: '350',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:49:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:48:45',
    Latitude: '35.1866531',
    Longitude: '-102.0110296',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:48:45',
    SlNo: '351',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:48:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:48:26',
    Latitude: '35.1866544',
    Longitude: '-102.0110275',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:48:26',
    SlNo: '352',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:48:26 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:46:45',
    Latitude: '35.1866394',
    Longitude: '-102.0109994',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:46:45',
    SlNo: '353',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:46:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:45:45',
    Latitude: '35.1866185',
    Longitude: '-102.0110284',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:45:45',
    SlNo: '354',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:45:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:44:45',
    Latitude: '35.186618',
    Longitude: '-102.0109927',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:44:45',
    SlNo: '355',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:44:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:43:25',
    Latitude: '35.186633',
    Longitude: '-102.0110026',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:43:25',
    SlNo: '356',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:43:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:42:44',
    Latitude: '35.1866355',
    Longitude: '-102.0110039',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:42:44',
    SlNo: '357',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:42:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:42:25',
    Latitude: '35.1866359',
    Longitude: '-102.0110103',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:42:25',
    SlNo: '358',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:42:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:40:45',
    Latitude: '35.1866251',
    Longitude: '-102.0110581',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:40:45',
    SlNo: '359',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:40:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:39:45',
    Latitude: '35.1866269',
    Longitude: '-102.0110554',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:39:45',
    SlNo: '360',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:39:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:38:44',
    Latitude: '35.186632',
    Longitude: '-102.0110537',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:38:44',
    SlNo: '361',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:38:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:38:25',
    Latitude: '35.1866345',
    Longitude: '-102.0110498',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:38:25',
    SlNo: '362',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:38:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:36:44',
    Latitude: '35.1866182',
    Longitude: '-102.0110717',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:36:44',
    SlNo: '363',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:36:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:35:44',
    Latitude: '35.1866198',
    Longitude: '-102.0110707',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:35:44',
    SlNo: '364',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:35:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:34:44',
    Latitude: '35.1866188',
    Longitude: '-102.0110705',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:34:44',
    SlNo: '365',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:34:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:33:25',
    Latitude: '35.1866179',
    Longitude: '-102.0110739',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:33:25',
    SlNo: '366',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:33:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:32:44',
    Latitude: '35.186636',
    Longitude: '-102.0110396',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:32:44',
    SlNo: '367',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:32:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:32:25',
    Latitude: '35.1866334',
    Longitude: '-102.0110413',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:32:25',
    SlNo: '368',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:32:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:30:44',
    Latitude: '35.1866328',
    Longitude: '-102.0110532',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:30:44',
    SlNo: '369',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:30:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:29:44',
    Latitude: '35.1866321',
    Longitude: '-102.0110528',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:29:44',
    SlNo: '370',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:29:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:28:44',
    Latitude: '35.1866138',
    Longitude: '-102.0110696',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:28:44',
    SlNo: '371',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:28:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:28:25',
    Latitude: '35.1866114',
    Longitude: '-102.0110733',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:28:25',
    SlNo: '372',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:28:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:26:45',
    Latitude: '35.1866265',
    Longitude: '-102.0109932',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:26:45',
    SlNo: '373',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:26:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:25:44',
    Latitude: '35.1866259',
    Longitude: '-102.0109937',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:25:44',
    SlNo: '374',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:25:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:24:44',
    Latitude: '35.1866254',
    Longitude: '-102.0109972',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:24:44',
    SlNo: '375',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:24:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:23:25',
    Latitude: '35.186632',
    Longitude: '-102.0110072',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:23:25',
    SlNo: '376',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:23:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:22:44',
    Latitude: '35.1866409',
    Longitude: '-102.0109947',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:22:44',
    SlNo: '377',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:22:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:22:27',
    Latitude: '35.1866386',
    Longitude: '-102.0109997',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:22:27',
    SlNo: '378',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:22:27 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:20:44',
    Latitude: '35.1866393',
    Longitude: '-102.0109218',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:20:44',
    SlNo: '379',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:20:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:19:44',
    Latitude: '35.1866235',
    Longitude: '-102.0109305',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:19:44',
    SlNo: '380',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:19:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:18:44',
    Latitude: '35.1866207',
    Longitude: '-102.0109271',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:18:44',
    SlNo: '381',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:18:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:18:25',
    Latitude: '35.1866082',
    Longitude: '-102.0109217',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:18:25',
    SlNo: '382',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:18:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:16:44',
    Latitude: '35.186601',
    Longitude: '-102.0109711',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:16:44',
    SlNo: '383',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:16:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:15:44',
    Latitude: '35.1865793',
    Longitude: '-102.0109857',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:15:44',
    SlNo: '384',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:15:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:14:44',
    Latitude: '35.1866275',
    Longitude: '-102.0109862',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:14:44',
    SlNo: '385',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:14:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:04:14',
    Latitude: '35.5237712',
    Longitude: '-98.6888402',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:04:14',
    SlNo: '920',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:04:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:02:34',
    Latitude: '35.5237836',
    Longitude: '-98.6888486',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:02:34',
    SlNo: '921',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:02:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:01:34',
    Latitude: '35.5237689',
    Longitude: '-98.6888477',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:01:34',
    SlNo: '922',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:01:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 02:00:34',
    Latitude: '35.5237797',
    Longitude: '-98.6888513',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:00:34',
    SlNo: '923',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:00:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:59:33',
    Latitude: '35.5237851',
    Longitude: '-98.6888356',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:59:33',
    SlNo: '924',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:59:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:58:34',
    Latitude: '35.523779',
    Longitude: '-98.6888312',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:58:34',
    SlNo: '925',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:58:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:58:14',
    Latitude: '35.5237843',
    Longitude: '-98.6888408',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:58:14',
    SlNo: '926',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:58:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:56:34',
    Latitude: '35.5237847',
    Longitude: '-98.688839',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:56:34',
    SlNo: '927',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:56:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:55:14',
    Latitude: '35.5237862',
    Longitude: '-98.6888477',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:55:14',
    SlNo: '928',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:55:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:54:34',
    Latitude: '35.523788',
    Longitude: '-98.6888348',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:54:34',
    SlNo: '929',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:54:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:54:14',
    Latitude: '35.5237643',
    Longitude: '-98.6888451',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:54:14',
    SlNo: '930',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:54:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:52:34',
    Latitude: '35.523778',
    Longitude: '-98.6888096',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:52:34',
    SlNo: '931',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:52:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:51:34',
    Latitude: '35.523779',
    Longitude: '-98.6888094',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:51:34',
    SlNo: '932',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:51:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:50:34',
    Latitude: '35.5237685',
    Longitude: '-98.6888194',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:50:34',
    SlNo: '933',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:50:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:49:33',
    Latitude: '35.5237685',
    Longitude: '-98.6888317',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:49:33',
    SlNo: '934',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:49:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:48:34',
    Latitude: '35.5237703',
    Longitude: '-98.6888352',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:48:34',
    SlNo: '935',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:48:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:48:15',
    Latitude: '35.5237749',
    Longitude: '-98.6888333',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:48:15',
    SlNo: '936',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:48:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:46:34',
    Latitude: '35.5237959',
    Longitude: '-98.6888453',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:46:34',
    SlNo: '937',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:46:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:45:14',
    Latitude: '35.5237946',
    Longitude: '-98.6888486',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:45:14',
    SlNo: '938',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:45:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:44:34',
    Latitude: '35.5238147',
    Longitude: '-98.6888483',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:44:34',
    SlNo: '939',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:44:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:44:14',
    Latitude: '35.5238539',
    Longitude: '-98.688878',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:44:14',
    SlNo: '940',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:44:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:42:34',
    Latitude: '35.5238566',
    Longitude: '-98.6888786',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:42:34',
    SlNo: '941',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:42:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:41:34',
    Latitude: '35.5238618',
    Longitude: '-98.6888291',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:41:34',
    SlNo: '942',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:41:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:40:34',
    Latitude: '35.5238174',
    Longitude: '-98.6888015',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:40:34',
    SlNo: '943',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:40:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:39:33',
    Latitude: '35.5238301',
    Longitude: '-98.6888064',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:39:33',
    SlNo: '944',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:39:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:38:34',
    Latitude: '35.5238302',
    Longitude: '-98.6888255',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:38:34',
    SlNo: '945',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:38:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:38:14',
    Latitude: '35.5238379',
    Longitude: '-98.688836',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:38:14',
    SlNo: '946',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:38:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:36:34',
    Latitude: '35.5238639',
    Longitude: '-98.688852',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:36:34',
    SlNo: '947',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:36:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:35:14',
    Latitude: '35.5238479',
    Longitude: '-98.6888561',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:35:14',
    SlNo: '948',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:35:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:34:34',
    Latitude: '35.5238503',
    Longitude: '-98.6888516',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:34:34',
    SlNo: '949',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:34:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:34:14',
    Latitude: '35.5238408',
    Longitude: '-98.6888464',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:34:14',
    SlNo: '950',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:34:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:32:33',
    Latitude: '35.5238504',
    Longitude: '-98.6888297',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:32:33',
    SlNo: '951',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:32:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:31:33',
    Latitude: '35.5238335',
    Longitude: '-98.688821',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:31:33',
    SlNo: '952',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:31:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:30:34',
    Latitude: '35.5238335',
    Longitude: '-98.6888221',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:30:34',
    SlNo: '953',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:30:34 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:29:33',
    Latitude: '35.5238521',
    Longitude: '-98.6888156',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:29:33',
    SlNo: '954',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:29:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:28:33',
    Latitude: '35.5238451',
    Longitude: '-98.6888329',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:28:33',
    SlNo: '955',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:28:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:28:14',
    Latitude: '35.523847',
    Longitude: '-98.6888402',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:28:14',
    SlNo: '956',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:28:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:26:33',
    Latitude: '35.5238454',
    Longitude: '-98.6888337',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:26:33',
    SlNo: '957',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:26:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:25:14',
    Latitude: '35.5237921',
    Longitude: '-98.688842',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:25:14',
    SlNo: '958',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:25:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:24:33',
    Latitude: '35.5238362',
    Longitude: '-98.6888214',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:24:33',
    SlNo: '959',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:24:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:24:14',
    Latitude: '35.5238437',
    Longitude: '-98.6888101',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:24:14',
    SlNo: '960',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:24:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:22:33',
    Latitude: '35.5238324',
    Longitude: '-98.6888253',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:22:33',
    SlNo: '961',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:22:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:21:33',
    Latitude: '35.5238247',
    Longitude: '-98.6888301',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:33',
    SlNo: '962',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:20:33',
    Latitude: '35.5238522',
    Longitude: '-98.6888254',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:20:33',
    SlNo: '963',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:20:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:19:33',
    Latitude: '35.5238126',
    Longitude: '-98.6888737',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:19:33',
    SlNo: '964',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:19:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:18:29',
    Latitude: '35.5238428',
    Longitude: '-98.6888502',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:18:29',
    SlNo: '965',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:18:29 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:18:14',
    Latitude: '35.5238292',
    Longitude: '-98.6888593',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:18:14',
    SlNo: '966',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:18:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:16:29',
    Latitude: '35.523839',
    Longitude: '-98.6888488',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:16:29',
    SlNo: '967',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:16:29 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:15:14',
    Latitude: '35.5238329',
    Longitude: '-98.6888357',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:15:14',
    SlNo: '968',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:15:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:14:29',
    Latitude: '35.5238383',
    Longitude: '-98.6887967',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:14:29',
    SlNo: '969',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:14:29 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:14:14',
    Latitude: '35.52383',
    Longitude: '-98.6888163',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:14:14',
    SlNo: '970',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:14:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:12:29',
    Latitude: '35.5238345',
    Longitude: '-98.6888169',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:12:29',
    SlNo: '971',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:12:29 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:11:14',
    Latitude: '35.5238471',
    Longitude: '-98.688839',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:11:14',
    SlNo: '972',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:11:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:10:29',
    Latitude: '35.5238291',
    Longitude: '-98.6888492',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:10:29',
    SlNo: '973',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:10:29 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:09:36',
    Latitude: '35.5238064',
    Longitude: '-98.6888468',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:09:36',
    SlNo: '974',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:09:36 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:08:14',
    Latitude: '35.523831',
    Longitude: '-98.6888167',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:08:14',
    SlNo: '975',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:08:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:07:49',
    Latitude: '35.5238197',
    Longitude: '-98.6888431',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:07:49',
    SlNo: '976',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:07:49 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:06:14',
    Latitude: '35.5238059',
    Longitude: '-98.6888394',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:06:14',
    SlNo: '977',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:06:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:05:10',
    Latitude: '35.5238096',
    Longitude: '-98.6888228',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:05:10',
    SlNo: '978',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:05:10 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:04:14',
    Latitude: '35.5238236',
    Longitude: '-98.6888558',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:04:14',
    SlNo: '979',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:04:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:03:54',
    Latitude: '35.5238296',
    Longitude: '-98.6888465',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:03:54',
    SlNo: '980',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:03:54 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:02:14',
    Latitude: '35.5238179',
    Longitude: '-98.6888402',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:02:14',
    SlNo: '981',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:02:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:01:14',
    Latitude: '35.5238429',
    Longitude: '-98.6887973',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:01:14',
    SlNo: '982',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:01:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 01:00:14',
    Latitude: '35.5238528',
    Longitude: '-98.6887793',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:00:14',
    SlNo: '983',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:00:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:59:33',
    Latitude: '35.5238466',
    Longitude: '-98.6887512',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:59:33',
    SlNo: '984',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:59:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:58:14',
    Latitude: '35.5238308',
    Longitude: '-98.6887827',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:58:14',
    SlNo: '985',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:58:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:57:49',
    Latitude: '35.5238366',
    Longitude: '-98.6888',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:57:49',
    SlNo: '986',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:57:49 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:56:14',
    Latitude: '35.5238468',
    Longitude: '-98.6887692',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:56:14',
    SlNo: '987',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:56:14 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:55:10',
    Latitude: '35.523833',
    Longitude: '-98.6887805',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:55:10',
    SlNo: '988',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:55:10 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:52:13',
    Latitude: '35.5238082',
    Longitude: '-98.6888114',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:52:13',
    SlNo: '989',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:52:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:51:13',
    Latitude: '35.5238141',
    Longitude: '-98.6887894',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:51:13',
    SlNo: '990',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:51:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:50:13',
    Latitude: '35.5237949',
    Longitude: '-98.6888403',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:50:13',
    SlNo: '991',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:50:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:49:33',
    Latitude: '35.523826',
    Longitude: '-98.6888238',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:49:33',
    SlNo: '992',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:49:33 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:48:13',
    Latitude: '35.5238187',
    Longitude: '-98.6888325',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:48:13',
    SlNo: '993',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:48:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:47:49',
    Latitude: '35.5238167',
    Longitude: '-98.6888232',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:47:49',
    SlNo: '994',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:47:49 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:46:13',
    Latitude: '35.5238177',
    Longitude: '-98.6888287',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:46:13',
    SlNo: '995',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:46:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:45:10',
    Latitude: '35.5238194',
    Longitude: '-98.6888551',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:45:10',
    SlNo: '996',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:45:10 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:44:13',
    Latitude: '35.5238349',
    Longitude: '-98.6888379',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:44:13',
    SlNo: '997',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:44:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:43:53',
    Latitude: '35.523849',
    Longitude: '-98.6888263',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:43:53',
    SlNo: '998',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:43:53 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:42:15',
    Latitude: '35.5238583',
    Longitude: '-98.6888206',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:42:15',
    SlNo: '999',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:42:15 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 00:41:13',
    Latitude: '35.5238446',
    Longitude: '-98.6888153',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '1349 Eagle Rd, Weatherford, OK 73096, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '00:41:13',
    SlNo: '1000',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:41:13 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:13:25',
    Latitude: '35.1866086',
    Longitude: '-102.0109889',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:13:25',
    SlNo: '386',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:13:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:12:43',
    Latitude: '35.186606',
    Longitude: '-102.0109903',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:12:43',
    SlNo: '387',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:12:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:12:28',
    Latitude: '35.1866109',
    Longitude: '-102.0109898',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:12:28',
    SlNo: '388',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:12:28 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:10:44',
    Latitude: '35.1866069',
    Longitude: '-102.0110006',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:10:44',
    SlNo: '389',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:10:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:09:49',
    Latitude: '35.186593',
    Longitude: '-102.0109922',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:09:49',
    SlNo: '390',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:09:49 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:08:44',
    Latitude: '35.1866078',
    Longitude: '-102.0109767',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:08:44',
    SlNo: '391',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:08:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:08:26',
    Latitude: '35.1866105',
    Longitude: '-102.0109738',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:08:26',
    SlNo: '392',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:08:26 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:06:43',
    Latitude: '35.1866181',
    Longitude: '-102.0109552',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:06:43',
    SlNo: '393',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:06:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:05:43',
    Latitude: '35.1866196',
    Longitude: '-102.0109391',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:05:43',
    SlNo: '394',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:05:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:04:44',
    Latitude: '35.1866266',
    Longitude: '-102.0109746',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:04:44',
    SlNo: '395',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:04:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:02:43',
    Latitude: '35.1865979',
    Longitude: '-102.0109834',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:02:43',
    SlNo: '396',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:02:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:02:25',
    Latitude: '35.186597',
    Longitude: '-102.0109823',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:02:25',
    SlNo: '397',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:02:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 11:00:43',
    Latitude: '35.1865739',
    Longitude: '-102.011018',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:00:43',
    SlNo: '398',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:00:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:59:44',
    Latitude: '35.1865723',
    Longitude: '-102.0109921',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:59:44',
    SlNo: '399',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:59:44 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:58:43',
    Latitude: '35.1865657',
    Longitude: '-102.0109983',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:58:43',
    SlNo: '400',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:58:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:58:25',
    Latitude: '35.1865722',
    Longitude: '-102.0109992',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:58:25',
    SlNo: '401',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:58:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:56:43',
    Latitude: '35.1866078',
    Longitude: '-102.0110129',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:56:43',
    SlNo: '402',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:56:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:55:40',
    Latitude: '35.1866318',
    Longitude: '-102.0110197',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:55:40',
    SlNo: '403',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:55:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:54:43',
    Latitude: '35.1866307',
    Longitude: '-102.0110099',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:54:43',
    SlNo: '404',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:54:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:54:12',
    Latitude: '35.1866299',
    Longitude: '-102.0110085',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:54:12',
    SlNo: '405',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:54:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:52:43',
    Latitude: '35.1866184',
    Longitude: '-102.0109945',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:52:43',
    SlNo: '406',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:52:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:52:25',
    Latitude: '35.1866196',
    Longitude: '-102.0109894',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:52:25',
    SlNo: '407',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:52:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:50:43',
    Latitude: '35.1865967',
    Longitude: '-102.0110117',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:50:43',
    SlNo: '408',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:50:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:49:43',
    Latitude: '35.1865954',
    Longitude: '-102.0110114',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:49:43',
    SlNo: '409',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:49:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:48:43',
    Latitude: '35.1865986',
    Longitude: '-102.0109993',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:48:43',
    SlNo: '410',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:48:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:48:25',
    Latitude: '35.1865963',
    Longitude: '-102.0109914',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:48:25',
    SlNo: '411',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:48:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:46:43',
    Latitude: '35.1866075',
    Longitude: '-102.0109645',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:46:43',
    SlNo: '412',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:46:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:45:40',
    Latitude: '35.1866078',
    Longitude: '-102.0109762',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:45:40',
    SlNo: '413',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:45:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:44:43',
    Latitude: '35.1866087',
    Longitude: '-102.0109655',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:44:43',
    SlNo: '414',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:44:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:44:05',
    Latitude: '35.1866223',
    Longitude: '-102.0110146',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:44:05',
    SlNo: '415',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:44:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:42:43',
    Latitude: '35.186633',
    Longitude: '-102.0110137',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:42:43',
    SlNo: '416',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:42:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:42:25',
    Latitude: '35.1866353',
    Longitude: '-102.0110088',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:42:25',
    SlNo: '417',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:42:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:40:42',
    Latitude: '35.1866476',
    Longitude: '-102.0110092',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:40:42',
    SlNo: '418',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:40:42 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:39:42',
    Latitude: '35.18665',
    Longitude: '-102.0109963',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:39:42',
    SlNo: '419',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:39:42 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:38:42',
    Latitude: '35.1866426',
    Longitude: '-102.0109858',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:38:42',
    SlNo: '420',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:38:42 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:38:25',
    Latitude: '35.1866423',
    Longitude: '-102.0109878',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:38:25',
    SlNo: '421',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:38:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:36:42',
    Latitude: '35.1865824',
    Longitude: '-102.0110063',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:36:42',
    SlNo: '422',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:36:42 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:35:40',
    Latitude: '35.1865832',
    Longitude: '-102.0110019',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:35:40',
    SlNo: '423',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:35:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:34:43',
    Latitude: '35.1866021',
    Longitude: '-102.0110074',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:34:43',
    SlNo: '424',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:34:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:34:05',
    Latitude: '35.1866016',
    Longitude: '-102.011007',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:34:05',
    SlNo: '425',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:34:05 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:32:42',
    Latitude: '35.1865869',
    Longitude: '-102.0109864',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:32:42',
    SlNo: '426',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:32:42 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:32:25',
    Latitude: '35.1866073',
    Longitude: '-102.010978',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:32:25',
    SlNo: '427',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:32:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:30:42',
    Latitude: '35.1865979',
    Longitude: '-102.0109743',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:30:42',
    SlNo: '428',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:30:42 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:29:45',
    Latitude: '35.1866185',
    Longitude: '-102.0109724',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:29:45',
    SlNo: '429',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:29:45 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:28:42',
    Latitude: '35.1866089',
    Longitude: '-102.0109941',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:28:42',
    SlNo: '430',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:28:42 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:28:25',
    Latitude: '35.1866028',
    Longitude: '-102.0109918',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:28:25',
    SlNo: '431',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:28:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:25:39',
    Latitude: '35.1865966',
    Longitude: '-102.0109909',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:25:39',
    SlNo: '432',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:25:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:24:42',
    Latitude: '35.1866034',
    Longitude: '-102.0109804',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:24:42',
    SlNo: '433',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:24:42 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:24:04',
    Latitude: '35.1865901',
    Longitude: '-102.0109874',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:24:04',
    SlNo: '434',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:24:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:22:41',
    Latitude: '35.1865961',
    Longitude: '-102.0110004',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:22:41',
    SlNo: '435',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:22:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:22:24',
    Latitude: '35.186601',
    Longitude: '-102.0110007',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:22:24',
    SlNo: '436',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:22:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:20:41',
    Latitude: '35.1866077',
    Longitude: '-102.0110559',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:20:41',
    SlNo: '437',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:20:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:19:41',
    Latitude: '35.1866064',
    Longitude: '-102.0110543',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:19:41',
    SlNo: '438',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:19:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:18:41',
    Latitude: '35.1866017',
    Longitude: '-102.0110565',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:18:41',
    SlNo: '439',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:18:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:16:41',
    Latitude: '35.1866035',
    Longitude: '-102.011035',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:16:41',
    SlNo: '440',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:16:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:15:39',
    Latitude: '35.1866198',
    Longitude: '-102.0110128',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:15:39',
    SlNo: '441',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:15:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:14:41',
    Latitude: '35.1866229',
    Longitude: '-102.0109933',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:14:41',
    SlNo: '442',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:14:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:14:04',
    Latitude: '35.1866256',
    Longitude: '-102.0109942',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:14:04',
    SlNo: '443',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:14:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:12:41',
    Latitude: '35.1866256',
    Longitude: '-102.0109826',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:12:41',
    SlNo: '444',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:12:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:12:24',
    Latitude: '35.1866243',
    Longitude: '-102.0109828',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:12:24',
    SlNo: '445',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:12:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:10:41',
    Latitude: '35.1866254',
    Longitude: '-102.0109789',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:10:41',
    SlNo: '446',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:10:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:09:41',
    Latitude: '35.1865901',
    Longitude: '-102.01098',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:09:41',
    SlNo: '447',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:09:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:08:39',
    Latitude: '35.1865917',
    Longitude: '-102.0109786',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:08:39',
    SlNo: '448',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:08:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:08:24',
    Latitude: '35.1865885',
    Longitude: '-102.0109829',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:08:24',
    SlNo: '449',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:08:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:06:39',
    Latitude: '35.1865908',
    Longitude: '-102.0109806',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:06:39',
    SlNo: '450',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:06:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:05:39',
    Latitude: '35.1865894',
    Longitude: '-102.0109805',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:05:39',
    SlNo: '451',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:05:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:04:24',
    Latitude: '35.186598',
    Longitude: '-102.010976',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:04:24',
    SlNo: '452',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:04:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:04:04',
    Latitude: '35.1865654',
    Longitude: '-102.0109748',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:04:04',
    SlNo: '453',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:04:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:02:26',
    Latitude: '35.1865653',
    Longitude: '-102.0109739',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:02:26',
    SlNo: '454',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:02:26 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:01:24',
    Latitude: '35.186565',
    Longitude: '-102.0109758',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:01:24',
    SlNo: '455',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:01:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 10:00:24',
    Latitude: '35.1866033',
    Longitude: '-102.0109645',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:00:24',
    SlNo: '456',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:00:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:59:41',
    Latitude: '35.1866545',
    Longitude: '-102.0109448',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:59:41',
    SlNo: '457',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:59:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:58:24',
    Latitude: '35.1866545',
    Longitude: '-102.0109453',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:58:24',
    SlNo: '458',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:58:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:57:01',
    Latitude: '35.1866379',
    Longitude: '-102.0109352',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:57:01',
    SlNo: '459',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:57:01 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:56:24',
    Latitude: '35.1866369',
    Longitude: '-102.0109362',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:56:24',
    SlNo: '460',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:56:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:55:39',
    Latitude: '35.1866438',
    Longitude: '-102.0109625',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:55:39',
    SlNo: '461',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:55:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:54:24',
    Latitude: '35.1866404',
    Longitude: '-102.0109893',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:54:24',
    SlNo: '462',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:54:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:54:04',
    Latitude: '35.1866373',
    Longitude: '-102.0109879',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:54:04',
    SlNo: '463',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:54:04 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:52:24',
    Latitude: '35.1866371',
    Longitude: '-102.0109893',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:52:24',
    SlNo: '464',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:52:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:51:24',
    Latitude: '35.1866232',
    Longitude: '-102.0109796',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:51:24',
    SlNo: '465',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:51:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:50:24',
    Latitude: '35.1866186',
    Longitude: '-102.0109549',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:50:24',
    SlNo: '466',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:50:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:48:24',
    Latitude: '35.1866142',
    Longitude: '-102.0109407',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:48:24',
    SlNo: '467',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:48:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:47:01',
    Latitude: '35.186622',
    Longitude: '-102.0109523',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:47:01',
    SlNo: '468',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:47:01 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:46:24',
    Latitude: '35.1866237',
    Longitude: '-102.010951',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:46:24',
    SlNo: '469',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:46:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:45:39',
    Latitude: '35.1866354',
    Longitude: '-102.010949',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:45:39',
    SlNo: '470',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:45:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:44:24',
    Latitude: '35.1866327',
    Longitude: '-102.010959',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:44:24',
    SlNo: '471',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:44:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:44:03',
    Latitude: '35.1866368',
    Longitude: '-102.0109594',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:44:03',
    SlNo: '472',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:44:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:42:24',
    Latitude: '35.1866579',
    Longitude: '-102.0109766',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:42:24',
    SlNo: '473',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:42:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:41:23',
    Latitude: '35.1866675',
    Longitude: '-102.01096',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:41:23',
    SlNo: '474',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:41:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:40:23',
    Latitude: '35.1866756',
    Longitude: '-102.0109592',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:40:23',
    SlNo: '475',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:40:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:39:41',
    Latitude: '35.1866738',
    Longitude: '-102.0109756',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:39:41',
    SlNo: '476',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:39:41 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:38:23',
    Latitude: '35.1866968',
    Longitude: '-102.0109702',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:38:23',
    SlNo: '477',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:38:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:37:01',
    Latitude: '35.1866951',
    Longitude: '-102.0109692',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:37:01',
    SlNo: '478',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:37:01 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:36:23',
    Latitude: '35.1866774',
    Longitude: '-102.0109736',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:36:23',
    SlNo: '479',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:36:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:35:39',
    Latitude: '35.1866343',
    Longitude: '-102.0109768',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:35:39',
    SlNo: '480',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:35:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:34:28',
    Latitude: '35.1866208',
    Longitude: '-102.0109813',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:34:28',
    SlNo: '481',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:34:28 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:34:03',
    Latitude: '35.1866101',
    Longitude: '-102.0109902',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:34:03',
    SlNo: '482',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:34:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:32:25',
    Latitude: '35.1866129',
    Longitude: '-102.0109625',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:32:25',
    SlNo: '483',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:32:25 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:31:23',
    Latitude: '35.1865961',
    Longitude: '-102.0109652',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:31:23',
    SlNo: '484',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:31:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:30:23',
    Latitude: '35.1866325',
    Longitude: '-102.0109495',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:30:23',
    SlNo: '485',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:30:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:29:43',
    Latitude: '35.1866469',
    Longitude: '-102.0109876',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:29:43',
    SlNo: '486',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:29:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:28:23',
    Latitude: '35.186655',
    Longitude: '-102.0110051',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:28:23',
    SlNo: '487',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:28:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:27:01',
    Latitude: '35.186653',
    Longitude: '-102.0110086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:27:01',
    SlNo: '488',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:27:01 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:26:23',
    Latitude: '35.1866527',
    Longitude: '-102.011012',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:26:23',
    SlNo: '489',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:26:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:25:39',
    Latitude: '35.1866584',
    Longitude: '-102.0110032',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:25:39',
    SlNo: '490',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:25:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:24:23',
    Latitude: '35.1866738',
    Longitude: '-102.0110364',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:24:23',
    SlNo: '491',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:24:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:24:03',
    Latitude: '35.1866308',
    Longitude: '-102.0110135',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:24:03',
    SlNo: '492',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:24:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:22:23',
    Latitude: '35.1866431',
    Longitude: '-102.011002',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:22:23',
    SlNo: '493',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:22:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:21:23',
    Latitude: '35.1866304',
    Longitude: '-102.0109952',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:21:23',
    SlNo: '494',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:21:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:20:23',
    Latitude: '35.1866392',
    Longitude: '-102.0110135',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:20:23',
    SlNo: '495',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:20:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:19:39',
    Latitude: '35.1866336',
    Longitude: '-102.0110055',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:19:39',
    SlNo: '496',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:19:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:18:23',
    Latitude: '35.1866297',
    Longitude: '-102.0110223',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:18:23',
    SlNo: '497',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:18:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:16:59',
    Latitude: '35.1866517',
    Longitude: '-102.0110321',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:16:59',
    SlNo: '498',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:16:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:16:23',
    Latitude: '35.1866553',
    Longitude: '-102.0110291',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:16:23',
    SlNo: '499',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:16:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:15:46',
    Latitude: '35.1866796',
    Longitude: '-102.0110376',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:15:46',
    SlNo: '500',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:15:46 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:14:23',
    Latitude: '35.1866413',
    Longitude: '-102.0110225',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:14:23',
    SlNo: '501',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:14:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:14:03',
    Latitude: '35.1866431',
    Longitude: '-102.0110235',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:14:03',
    SlNo: '502',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:14:03 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:12:23',
    Latitude: '35.1866547',
    Longitude: '-102.0109846',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:12:23',
    SlNo: '503',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:12:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:11:23',
    Latitude: '35.1866547',
    Longitude: '-102.0109802',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:11:23',
    SlNo: '504',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:11:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:10:23',
    Latitude: '35.1866738',
    Longitude: '-102.0109935',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:10:23',
    SlNo: '505',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:10:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:09:40',
    Latitude: '35.1866666',
    Longitude: '-102.0109911',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:09:40',
    SlNo: '506',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:09:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:08:23',
    Latitude: '35.1866667',
    Longitude: '-102.0109891',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:08:23',
    SlNo: '507',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:08:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:06:58',
    Latitude: '35.1866699',
    Longitude: '-102.010974',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:06:58',
    SlNo: '508',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:06:58 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:06:23',
    Latitude: '35.1866829',
    Longitude: '-102.0109817',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:06:23',
    SlNo: '509',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:06:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:05:39',
    Latitude: '35.1866774',
    Longitude: '-102.0109814',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:05:39',
    SlNo: '510',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:05:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:04:23',
    Latitude: '35.1866723',
    Longitude: '-102.0110004',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:04:23',
    SlNo: '511',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:04:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:04:02',
    Latitude: '35.1866705',
    Longitude: '-102.0109882',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:04:02',
    SlNo: '512',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:04:02 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:02:23',
    Latitude: '35.1866543',
    Longitude: '-102.0109675',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:02:23',
    SlNo: '513',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:02:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:01:30',
    Latitude: '35.1866745',
    Longitude: '-102.0109852',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:01:30',
    SlNo: '514',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:01:30 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 09:00:29',
    Latitude: '35.1866769',
    Longitude: '-102.0109872',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:00:29',
    SlNo: '515',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:00:29 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:59:39',
    Latitude: '35.1866319',
    Longitude: '-102.010986',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:59:39',
    SlNo: '516',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:59:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:58:23',
    Latitude: '35.1866419',
    Longitude: '-102.0109844',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:58:23',
    SlNo: '517',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:58:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:56:59',
    Latitude: '35.1866481',
    Longitude: '-102.0109924',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:56:59',
    SlNo: '518',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:56:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:56:23',
    Latitude: '35.1866502',
    Longitude: '-102.010994',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:56:23',
    SlNo: '519',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:56:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:55:40',
    Latitude: '35.1866104',
    Longitude: '-102.0109748',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:55:40',
    SlNo: '520',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:55:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:54:22',
    Latitude: '35.1866052',
    Longitude: '-102.0109782',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:54:22',
    SlNo: '521',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:54:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:54:00',
    Latitude: '35.186613',
    Longitude: '-102.0110071',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:54:00',
    SlNo: '522',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:54:00 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:52:22',
    Latitude: '35.1866522',
    Longitude: '-102.0110588',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:52:22',
    SlNo: '523',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:52:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:51:22',
    Latitude: '35.1866223',
    Longitude: '-102.0110298',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:51:22',
    SlNo: '524',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:51:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:50:23',
    Latitude: '35.1865785',
    Longitude: '-102.0110412',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:50:23',
    SlNo: '525',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:50:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:49:39',
    Latitude: '35.1865839',
    Longitude: '-102.0110437',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:49:39',
    SlNo: '526',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:49:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:48:22',
    Latitude: '35.1865411',
    Longitude: '-102.0110074',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:48:22',
    SlNo: '527',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:48:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:46:59',
    Latitude: '35.1865521',
    Longitude: '-102.0110083',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:46:59',
    SlNo: '528',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:46:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:46:22',
    Latitude: '35.186561',
    Longitude: '-102.0110147',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:46:22',
    SlNo: '529',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:46:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:45:40',
    Latitude: '35.1865791',
    Longitude: '-102.0110044',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:45:40',
    SlNo: '530',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:45:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:44:23',
    Latitude: '35.1866008',
    Longitude: '-102.0110234',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:44:23',
    SlNo: '531',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:44:23 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:44:01',
    Latitude: '35.1865852',
    Longitude: '-102.0109642',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:44:01',
    SlNo: '532',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:44:01 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:42:22',
    Latitude: '35.1866026',
    Longitude: '-102.0109582',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:42:22',
    SlNo: '533',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:42:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:41:17',
    Latitude: '35.1865944',
    Longitude: '-102.0109596',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:41:17',
    SlNo: '534',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:41:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:40:22',
    Latitude: '35.1866216',
    Longitude: '-102.0110359',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:40:22',
    SlNo: '535',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:40:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:39:39',
    Latitude: '35.186624',
    Longitude: '-102.0110372',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:39:39',
    SlNo: '536',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:39:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:38:22',
    Latitude: '35.186608',
    Longitude: '-102.0110251',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:38:22',
    SlNo: '537',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:38:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:36:59',
    Latitude: '35.1866039',
    Longitude: '-102.0110237',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:36:59',
    SlNo: '538',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:36:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:36:22',
    Latitude: '35.1866158',
    Longitude: '-102.0110425',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:36:22',
    SlNo: '539',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:36:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:35:40',
    Latitude: '35.1866304',
    Longitude: '-102.0110547',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:35:40',
    SlNo: '540',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:35:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:34:22',
    Latitude: '35.1866379',
    Longitude: '-102.0110472',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:34:22',
    SlNo: '541',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:34:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:33:59',
    Latitude: '35.1866354',
    Longitude: '-102.0110325',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2420 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:33:59',
    SlNo: '542',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:33:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:32:22',
    Latitude: '35.1866216',
    Longitude: '-102.0110034',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:32:22',
    SlNo: '543',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:32:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:31:17',
    Latitude: '35.1866223',
    Longitude: '-102.0110009',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:31:17',
    SlNo: '544',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:31:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:30:22',
    Latitude: '35.1866071',
    Longitude: '-102.0110026',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:30:22',
    SlNo: '545',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:30:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:29:39',
    Latitude: '35.186584',
    Longitude: '-102.0109912',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:29:39',
    SlNo: '546',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:29:39 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:28:22',
    Latitude: '35.186584',
    Longitude: '-102.0109918',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:28:22',
    SlNo: '547',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:28:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:26:59',
    Latitude: '35.1865844',
    Longitude: '-102.0109936',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:26:59',
    SlNo: '548',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:26:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:26:24',
    Latitude: '35.1865849',
    Longitude: '-102.0109906',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:26:24',
    SlNo: '549',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:26:24 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:25:40',
    Latitude: '35.1865716',
    Longitude: '-102.0111022',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:25:40',
    SlNo: '550',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:25:40 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:24:17',
    Latitude: '35.1865877',
    Longitude: '-102.0110714',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:24:17',
    SlNo: '551',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:24:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:24:00',
    Latitude: '35.1865879',
    Longitude: '-102.0110763',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:24:00',
    SlNo: '552',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:24:00 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:22:17',
    Latitude: '35.1866089',
    Longitude: '-102.0110488',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:22:17',
    SlNo: '553',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:22:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:21:17',
    Latitude: '35.1866102',
    Longitude: '-102.0110647',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:21:17',
    SlNo: '554',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:21:17 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:20:12',
    Latitude: '35.1866109',
    Longitude: '-102.0110857',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:20:12',
    SlNo: '555',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:20:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:19:43',
    Latitude: '35.1866033',
    Longitude: '-102.011086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:19:43',
    SlNo: '556',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:19:43 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:18:22',
    Latitude: '35.1866101',
    Longitude: '-102.0110261',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:18:22',
    SlNo: '557',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:18:22 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:18:12',
    Latitude: '35.1866109',
    Longitude: '-102.0110129',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:18:12',
    SlNo: '558',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:18:12 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:16:59',
    Latitude: '35.186604',
    Longitude: '-102.0110208',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:16:59',
    SlNo: '559',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:16:59 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:16:00',
    Latitude: '35.1866116',
    Longitude: '-102.0110281',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:16:00',
    SlNo: '560',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:16:00 AM',
  },
  {
    DeviceId: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/16/2021 08:15:40',
    Latitude: '35.186611',
    Longitude: '-102.0110261',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0,
    Status: 'All Good',
    Location_Address: '2715 Arnot Rd, Amarillo, TX 79124, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:15:40',
    SlNo: '561',
    Asset_Name: 'BH004',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:15:40 AM',
  },
];

export default data;
