import { createSlice } from '@reduxjs/toolkit';
// import { Action } from 'history';

export const switchCompSlice = createSlice({
  name: 'switchCompany',
  initialState: {
    isReseller: false,
    selectedCompanyToken: '',
    showPopUp: false,
    resellerToken: '',
    isSubCompany: false,
    dashcamCount: 0,
    initialRouteGlobal: '/Home',
  },
  reducers: {
    setIsReseller: (state, action) => {
      state.isReseller = action.payload;
    },
    setCompanyToken: (state, action) => {
      state.selectedCompanyToken = action.payload;
    },
    setShowPopUp: (state, action) => {
      state.showPopUp = action.payload;
    },
    setResellerToken: (state, action) => {
      state.resellerToken = action.payload;
    },
    setIsSubCompany: (state, action) => {
      state.isSubCompany = action.payload;
    },
    setDashcamCountRedux: (state, action) => {
      state.dashcamCount = action.payload;
    },
    setInitRouteGlobal: (state, action) => {
      state.initialRouteGlobal = action.payload;
    },
  },
});

export const {
  setIsReseller,
  setCompanyToken,
  setShowPopUp,
  setResellerToken,
  setIsSubCompany,
  setDashcamCountRedux,
  setInitRouteGlobal,
} = switchCompSlice.actions;

export default switchCompSlice.reducer;
