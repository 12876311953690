import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Navbar from '../navbar/Navbar';
import Sidebar from '../sidebar/Sidebar';

const Layout = ({ children }) => {
  const [sidebarOpen, setsidebarOpen] = useState(false);
  const openSidebar = () => {
    setsidebarOpen(true);
  };
  const closeSidebar = () => {
    setsidebarOpen(false);
  };
  return (
    <div className="container-fluid" style={{ padding: '0' }}>
      <div className="container">
        <Navbar sidebarOpen={sidebarOpen} openSidebar={openSidebar} />
        <Sidebar sidebarOpen={sidebarOpen} closeSidebar={closeSidebar} />
        {children}
      </div>
    </div>
  );
};

export default Layout;
Layout.propTypes = {
  children: PropTypes.objectOf(PropTypes.any),
};
