import { createSlice } from '@reduxjs/toolkit';

export const assetDetailSlice = createSlice({
  name: 'assetDetail',
  initialState: {
    showAssetDetail: false,
    selectedSlNo: '',
    selectedIconStatus: '',
    selectedLat: '',
    selectedLng: '',
  },
  reducers: {
    setShowAssetDetail: (state, action) => {
      state.showAssetDetail = action.payload;
    },
    setslNo: (state, action) => {
      state.selectedSlNo = action.payload;
    },
    setIconStatus: (state, action) => {
      state.selectedIconStatus = action.payload;
    },
    setLatitude: (state, action) => {
      state.selectedLat = action.payload;
    },
    setLongitude: (state, action) => {
      state.selectedLng = action.payload;
    },
  },
});

export const {
  setShowAssetDetail,
  setslNo,
  setIconStatus,
  setLatitude,
  setLongitude,
} = assetDetailSlice.actions;

export default assetDetailSlice.reducer;
