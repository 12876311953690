const getAllDashCam = [
  {
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    dashcamDeviceId: '4gcc1-362523432434002',
    dashCamTypeId: 1,
    dashCamTypeName: null,
    companyId: 37084,
    branchId: 47096,
    firmwareVersion: null,
    appVersion: null,
    createdBy: 1,
    createdByName: null,
    createdDate: '2021-06-18T19:25:38.4732808Z',
    createdDateDisplay: null,
    updatedBy: null,
    updatedByName: null,
    updatedDate: '2021-06-18T19:25:38.4732808Z',
    updatedDateDisplay: null,
    idAssetDevice: 1050,
    simCardNo: '89011704278605834318',
    simCardPhoneNumber: '89011704278605834318',
    assetName: 'BH005',
    isActive: true,
    assetDescription: 'BH005',
    speedUnit: 'M',
    polylineType: 0,
    wayPointsCount: 0,
    assetType: 1,
    realTimeTrackingIntervalId: null,
    idAtAssetCheckIns: 1056,
    movingCheckinId: 5,
    stationaryCheckinId: 0,
  },
  {
    dashCamMasterId: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    dashcamDeviceId: '4gcc1-362523432431461',
    dashCamTypeId: 1,
    dashCamTypeName: null,
    companyId: 37084,
    branchId: 47096,
    firmwareVersion: null,
    appVersion: null,
    createdBy: 1,
    createdByName: null,
    createdDate: '2021-06-18T19:25:38.4734358Z',
    createdDateDisplay: null,
    updatedBy: null,
    updatedByName: null,
    updatedDate: '2021-06-18T19:25:38.4734358Z',
    updatedDateDisplay: null,
    idAssetDevice: 1048,
    simCardNo: '89011704278605834300',
    simCardPhoneNumber: '89011704278605834300',
    assetName: 'BH006',
    isActive: true,
    assetDescription: 'BH006',
    speedUnit: 'M',
    polylineType: 0,
    wayPointsCount: 0,
    assetType: 1,
    realTimeTrackingIntervalId: null,
    idAtAssetCheckIns: 1054,
    movingCheckinId: 5,
    stationaryCheckinId: 13,
  },
  {
    dashCamMasterId: '07b93190-ec83-405d-8184-7c48dc3fe590',
    dashcamDeviceId: '4gcc1-362523432435678',
    dashCamTypeId: 1,
    dashCamTypeName: null,
    companyId: 37084,
    branchId: 47096,
    firmwareVersion: null,
    appVersion: null,
    createdBy: 1,
    createdByName: null,
    createdDate: '2021-06-18T19:25:38.4736181Z',
    createdDateDisplay: null,
    updatedBy: null,
    updatedByName: null,
    updatedDate: '2021-06-18T19:25:38.4736182Z',
    updatedDateDisplay: null,
    idAssetDevice: 1046,
    simCardNo: '89011704278605834268',
    simCardPhoneNumber: '89011704278605834268',
    assetName: 'BH007',
    isActive: true,
    assetDescription: 'BH007',
    speedUnit: 'M',
    polylineType: null,
    wayPointsCount: null,
    assetType: 1,
    realTimeTrackingIntervalId: null,
    idAtAssetCheckIns: 1052,
    movingCheckinId: 5,
    stationaryCheckinId: 13,
  },
  {
    dashCamMasterId: '139408b8-a63f-488f-9354-971e45ac8661',
    dashcamDeviceId: '4gcc1-362523432428749',
    dashCamTypeId: 1,
    dashCamTypeName: null,
    companyId: 37084,
    branchId: 47096,
    firmwareVersion: null,
    appVersion: null,
    createdBy: 1,
    createdByName: null,
    createdDate: '2021-06-18T19:25:38.473711Z',
    createdDateDisplay: null,
    updatedBy: null,
    updatedByName: null,
    updatedDate: '2021-06-18T19:25:38.4737111Z',
    updatedDateDisplay: null,
    idAssetDevice: 1049,
    simCardNo: '89011704278605834250',
    simCardPhoneNumber: '89011704278605834250',
    assetName: 'BH004',
    isActive: true,
    assetDescription: 'BH004',
    speedUnit: 'M',
    polylineType: 0,
    wayPointsCount: 0,
    assetType: 1,
    realTimeTrackingIntervalId: null,
    idAtAssetCheckIns: 1055,
    movingCheckinId: 5,
    stationaryCheckinId: 13,
  },
  {
    dashCamMasterId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    dashcamDeviceId: '4gcc1-362523432431990',
    dashCamTypeId: 1,
    dashCamTypeName: null,
    companyId: 37084,
    branchId: 47096,
    firmwareVersion: null,
    appVersion: null,
    createdBy: 1,
    createdByName: null,
    createdDate: '2021-06-18T19:25:38.4738983Z',
    createdDateDisplay: null,
    updatedBy: 1,
    updatedByName: null,
    updatedDate: '2021-06-18T19:25:38.4738983Z',
    updatedDateDisplay: null,
    idAssetDevice: 1047,
    simCardNo: '89011704278605834243',
    simCardPhoneNumber: '89011704278605834243',
    assetName: 'BH008',
    isActive: true,
    assetDescription: 'BH008',
    speedUnit: 'M',
    polylineType: 0,
    wayPointsCount: 0,
    assetType: 1,
    realTimeTrackingIntervalId: null,
    idAtAssetCheckIns: 1053,
    movingCheckinId: 5,
    stationaryCheckinId: 13,
  },
];

const getAllDashCamVideoByCompanyBranch = [
  {
    iDDashCamVideo: '00000000-0000-0000-0000-000000000000',
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    videoURL: 'https://s3.us-east-2.amazonaws.com/app.eldmandate.biz/lock_20210621085311_back.mp4',
    dashCamName: 'BH005',
    utcUploadedDateTime: '2021-06-21T13:53:11',
    linkExpireUTCDateTime: '2021-06-21T19:36:06.0574933Z',
    companyId: 37084,
    branchId: 47096,
  },
  {
    iDDashCamVideo: '00000000-0000-0000-0000-000000000000',
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    videoURL: 'https://s3.us-east-2.amazonaws.com/app.eldmandate.biz/lock_20210621073807_front.mp4',
    dashCamName: 'BH005',
    utcUploadedDateTime: '2021-06-21T12:38:07',
    linkExpireUTCDateTime: '2021-06-21T19:36:06.1395959Z',
    companyId: 37084,
    branchId: 47096,
  },
  {
    iDDashCamVideo: '00000000-0000-0000-0000-000000000000',
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    videoURL: 'https://s3.us-east-2.amazonaws.com/app.eldmandate.biz/lock_20210621073807_back.mp4',
    dashCamName: 'BH005',
    utcUploadedDateTime: '2021-06-21T12:38:07',
    linkExpireUTCDateTime: '2021-06-21T19:36:06.1399192Z',
    companyId: 37084,
    branchId: 47096,
  },
  {
    iDDashCamVideo: '00000000-0000-0000-0000-000000000000',
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    videoURL: 'https://s3.us-east-2.amazonaws.com/app.eldmandate.biz/lock_20210620122802_front.mp4',
    dashCamName: 'BH005',
    utcUploadedDateTime: '2021-06-20T18:28:02',
    linkExpireUTCDateTime: '2021-06-21T19:36:06.140059Z',
    companyId: 37084,
    branchId: 47096,
  },
  {
    iDDashCamVideo: '00000000-0000-0000-0000-000000000000',
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    videoURL: 'https://s3.us-east-2.amazonaws.com/app.eldmandate.biz/lock_20210620122802_back.mp4',
    dashCamName: 'BH005',
    utcUploadedDateTime: '2021-06-20T18:28:02',
    linkExpireUTCDateTime: '2021-06-21T19:36:06.1402219Z',
    companyId: 37084,
    branchId: 47096,
  },
  {
    iDDashCamVideo: '00000000-0000-0000-0000-000000000000',
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    videoURL: 'https://s3.us-east-2.amazonaws.com/app.eldmandate.biz/lock_20210620085549_front.mp4',
    dashCamName: 'BH005',
    utcUploadedDateTime: '2021-06-20T14:55:49',
    linkExpireUTCDateTime: '2021-06-21T19:36:06.1403481Z',
    companyId: 37084,
    branchId: 47096,
  },
  {
    iDDashCamVideo: '00000000-0000-0000-0000-000000000000',
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    videoURL: 'https://s3.us-east-2.amazonaws.com/app.eldmandate.biz/lock_20210620085549_back.mp4',
    dashCamName: 'BH005',
    utcUploadedDateTime: '2021-06-20T14:55:49',
    linkExpireUTCDateTime: '2021-06-21T19:36:06.1404645Z',
    companyId: 37084,
    branchId: 47096,
  },
  {
    iDDashCamVideo: '00000000-0000-0000-0000-000000000000',
    dashCamMasterId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    videoURL: 'https://s3.us-east-2.amazonaws.com/app.eldmandate.biz/lock_20210619184752_front.mp4',
    dashCamName: 'BH005',
    utcUploadedDateTime: '2021-06-20T01:47:52',
    linkExpireUTCDateTime: '2021-06-21T19:36:06.1405808Z',
    companyId: 37084,
    branchId: 47096,
  },
];

export default {
  getAllDashCam,
  getAllDashCamVideoByCompanyBranch,
};
