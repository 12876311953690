import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from '../core/routes/PrivateRoute';
// import CrossStateLineAlert from '../features/alert/CrossStateLineAlert';
// import DownloadApp from '../features/notifyMobileUser/notifyMobileUser';

const Main = React.lazy(() => import('../features/main/Main'));
const Assets = React.lazy(() => import('../features/assets/AssetHome'));
const Login = React.lazy(() => import('../features/login/Login'));
const ChangePassword = React.lazy(() => import('../features/login/ChangePassword'));
const ViewAlert = React.lazy(() => import('../features/alert/ViewAlert'));
const CreateAlert = React.lazy(() => import('../features/alert/CreateAlert'));
const SpeedAlert = React.lazy(() => import('../features/alert/SpeedAlert'));
const BatteryAlert = React.lazy(() => import('../features/alert/BatteryAlert'));
const GeofenceAlert = React.lazy(() => import('../features/alert/GeofenceAlert'));
const CrossStateLinesAlert = React.lazy(() => import('../features/alert/CrossStateLinesAlert'));
const FirstTimeMovingAlert = React.lazy(() => import('../features/alert/FirstTimeMovingAlert'));
const TemperatureAlert = React.lazy(() => import('../features/alert/TemperatureAlert'));
const HumidityAlert = React.lazy(() => import('../features/alert/HumidityAlert'));
const TrailerIdleReport = React.lazy(() =>
  import('../features/trailerIdleReport/trailerIdleReport'),
);
const IncidentHome = React.lazy(() => import('../features/incident/incidentHome'));
const TripHome = React.lazy(() => import('../features/trips/tripHome'));
const CreateNewTrip = React.lazy(() => import('../features/trips/createNewTrip'));

const DashcamDetails = React.lazy(() => import('../features/dashcamDetails/DashcamDetails'));
const DashcamDetailsMobile = React.lazy(() =>
  import('../features/dashcamDetails/DashcamDetailsMobile'),
);
const AssetDetails = React.lazy(() => import('../features/assets/AssetsDetails'));
const LockVideos = React.lazy(() => import('../features/lockVideos/LockVideos'));
const EditAsset = React.lazy(() => import('../features/dashcamDetails/EditAsset'));
const Error404 = React.lazy(() => import('../features/errors/Error404'));
const Error500 = React.lazy(() => import('../features/errors/Error500'));
const SettingsRoleMain = React.lazy(() => import('../features/settingRole/settingsRoleMain'));
const ContactView = React.lazy(() => import('../features/settingRole/contactView'));
const UpdatePassword = React.lazy(() => import('../features/updatePassword/updatePassword'));
const InsertBranch = React.lazy(() => import('../features/settingRole/insertBranch'));
const EditBranch = React.lazy(() => import('../features/settingRole/editBranch'));
const InsertContact = React.lazy(() => import('../features/settingRole/insertContact'));
const EditContact = React.lazy(() => import('../features/settingRole/editContact'));

const DormancyReport = React.lazy(() => import('../features/assetReport/assetDormancy'));
const FrequentCheckInReport = React.lazy(() => import('../features/assetReport/frequentCheckIn'));
const SpeedReport = React.lazy(() => import('../features/assetReport/assetSpeed'));
const UtilizationReport = React.lazy(() => import('../features/assetReport/assetUtilization'));

const RequestVideo = React.lazy(() => import('../features/requestVideo/requestVideo'));
const FeatureFlag = React.lazy(() => import('../features/featureFlag/featureFlag'));

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute exact path="/assets" component={Assets} />
        <PrivateRoute exact path="/Home" component={Main} />
        <PrivateRoute exact path="/viewalert" component={ViewAlert} />
        <PrivateRoute exact path="/createAlert" component={CreateAlert} />
        <PrivateRoute exact path="/speedAlert/:alertId?" component={SpeedAlert} />
        <PrivateRoute exact path="/batteryAlert/:alertId?" component={BatteryAlert} />
        <PrivateRoute exact path="/geofenceAlert/:alertId?" component={GeofenceAlert} />
        <PrivateRoute
          exact
          path="/crossstatelinesAlert/:alertId?"
          component={CrossStateLinesAlert}
        />
        <PrivateRoute
          exact
          path="/firsttimemovingAlert/:alertId?"
          component={FirstTimeMovingAlert}
        />
        <PrivateRoute exact path="/temperatureAlert/:alertId?" component={TemperatureAlert} />
        <PrivateRoute exact path="/humidityAlert/:alertId?" component={HumidityAlert} />
        <PrivateRoute exact path="/trailerIdleReport" component={TrailerIdleReport} />
        <PrivateRoute exact path="/incidents" component={IncidentHome} />
        <PrivateRoute exact path="/trips" component={TripHome} />
        <PrivateRoute exact path="/createTrip" component={CreateNewTrip} />
        <PrivateRoute exact path="/viewTrips" component={TripHome} />
        <PrivateRoute exact path="/finishedTrips" component={TripHome} />
        <PrivateRoute exact path="/dormancyReport" component={DormancyReport} />
        <PrivateRoute exact path="/frequentCheckInReport" component={FrequentCheckInReport} />
        <PrivateRoute exact path="/speedReport" component={SpeedReport} />
        <PrivateRoute exact path="/utilizationReport" component={UtilizationReport} />

        <Route exact path="/changePassword/:userId?">
          <ChangePassword />
        </Route>
        <Route exact path="/Login">
          <Login />
        </Route>
        <PrivateRoute exact path="/AssetDetails/:assetId/:deviceId?" component={AssetDetails} />
        <PrivateRoute exact path="/DashcamDetails/:assetId/:deviceId?" component={DashcamDetails} />
        <PrivateRoute
          exact
          path="/DashcamDetailsMobile/:assetId/:deviceId?"
          component={DashcamDetailsMobile}
          showNavbar={false}
        />
        <PrivateRoute exact path="/lockVideos/:deviceId?" component={LockVideos} />
        <PrivateRoute exact path="/editAsset/:assetId/:deviceId?" component={EditAsset} />
        <PrivateRoute exact path="/updatePassword" component={UpdatePassword} />
        <PrivateRoute exact path="/settingRole" component={SettingsRoleMain} />
        <PrivateRoute exact path="/contactView" component={ContactView} />
        <PrivateRoute exact path="/insertBranch" component={InsertBranch} />
        <PrivateRoute exact path="/editBranch" component={EditBranch} />
        <PrivateRoute exact path="/insertContact" component={InsertContact} />
        <PrivateRoute exact path="/editContact" component={EditContact} />
        <PrivateRoute exact path="/requestVideo" component={RequestVideo} />
        <PrivateRoute exact path="/featureFlag" component={FeatureFlag} />
        <Route exact path="/">
          <Redirect from="/" to="/assets" />
        </Route>
        <Route path="/error500" component={Error500} />
        <Route path="*" component={Error404} />
        {/* <Route exaxt path="/" component={initialRouteGlobal === '/Home' ? Main : Assets} /> */}
      </Switch>
      {/* <DownloadApp /> */}
    </BrowserRouter>
  );
}
