const data = [
  {
    DeviceId: '07b93190-ec83-405d-8184-7c48dc3fe590',
    CreatedDate: '05/06/2021 13:16:20',
    Latitude: '33.6793074',
    Longitude: '-117.8740218',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3158 Red Hill Ave #140, Costa Mesa, CA 92626, USA',
    Icon_Status: 'A',
    Date: '05/06/2021 00:00:00',
    Time: '13:16:20',
    SlNo: '1',
    Asset_Name: 'BH007',
    ExcludeDate: null,
    DisplayDate: '05/06/2021 01:16:20 PM',
  },
];

export default data;
