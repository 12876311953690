import { createSlice } from '@reduxjs/toolkit';

export const SettingRoleSlice = createSlice({
  name: 'settingRole',
  initialState: {
    isBrachAdmin: false,
    isCompayAdmin: false,
    contactId: '',
    branchId: '',
    selectedBranchId: '',
    gCompanyName: '',
    gAddress: '',
    gBranchName: '',
    gCity: '',
    gCompId: '',
    gCompName: '',
    gContactId: '',
    gCountryId: '',
    gCountryName: '',
    gStateName: '',
    gEmail: '',
    gHodName: '',
    gMobile: '',
    gPhoneNo: '',
    gStateId: '',
    gUserName: '',
    gZipCode: '',
    gRoleName: '',
    gRoleId: '',
    gIsEdit: false,
  },
  reducers: {
    setIsBranchAdmin: (state, action) => {
      state.isBrachAdmin = action.payload;
    },
    setIsCompanyAdmin: (state, action) => {
      state.isCompayAdmin = action.payload;
    },
    setContactId: (state, action) => {
      state.contactId = action.payload;
    },
    setBranchId: (state, action) => {
      state.branchId = action.payload;
    },
    setSelectedBranchId: (state, action) => {
      state.selectedBranchId = action.payload;
    },
    setReduxCompanyName: (state, action) => {
      state.companyName = action.payload;
    },
    setGAddress: (state, action) => {
      state.gAddress = action.payload;
    },
    setGbranchName: (state, action) => {
      state.gBranchName = action.payload;
    },
    setGcity: (state, action) => {
      state.gCity = action.payload;
    },
    setGCompId: (state, action) => {
      state.gCompId = action.payload;
    },
    setGCompName: (state, action) => {
      state.gCompName = action.payload;
    },
    setGContactId: (state, action) => {
      state.gContactId = action.payload;
    },
    setGCountryId: (state, action) => {
      state.gCountryId = action.payload;
    },
    setGCountryName: (state, action) => {
      state.gCountryName = action.payload;
    },
    setGStateName: (state, action) => {
      state.gStateName = action.payload;
    },
    setGEmail: (state, action) => {
      state.gEmail = action.payload;
    },
    setGMobile: (state, action) => {
      state.gMobile = action.payload;
    },
    setGPhoneNo: (state, action) => {
      state.gPhoneNo = action.payload;
    },
    setGStateId: (state, action) => {
      state.gStateId = action.payload;
    },
    setGUserName: (state, action) => {
      state.gUserName = action.payload;
    },
    setGZipCode: (state, action) => {
      state.gZipCode = action.payload;
    },
    setGIsEdit: (state, action) => {
      state.gIsEdit = action.payload;
    },
    setGRoleName: (state, action) => {
      state.gRoleName = action.payload;
    },
    setGRoleId: (state, action) => {
      state.gRoleId = action.payload;
    },
  },
});

export const {
  setBranchId,
  setSelectedBranchId,
  setContactId,
  setIsBranchAdmin,
  setIsCompanyAdmin,
  setReduxCompanyName,
  setGAddress,
  setGbranchName,
  setGcity,
  setGCompId,
  setGCompName,
  setGContactId,
  setGEmail,
  setGMobile,
  setGPhoneNo,
  setGStateId,
  setGUserName,
  setGZipCode,
  setGIsEdit,
  setGCountryId,
  setGRoleId,
  setGRoleName,
  setGCountryName,
  setGStateName,
} = SettingRoleSlice.actions;

export default SettingRoleSlice.reducer;
