import { createSlice } from '@reduxjs/toolkit';

export const tripLocationSlice = createSlice({
  name: 'tripLocation',
  initialState: {
    startLat: '',
    startLng: '',
    endLat: '',
    endLng: '',
    startLabel: '',
    endLabel: '',
    showTripDetail: false,
    hideTripDetail: '',
  },
  reducers: {
    setStartLat: (state, action) => {
      state.startLat = action.payload;
    },
    setStartLng: (state, action) => {
      state.startLng = action.payload;
    },
    setEndLat: (state, action) => {
      state.endLat = action.payload;
    },
    setEndLng: (state, action) => {
      state.endLng = action.payload;
    },
    setStartLabel: (state, action) => {
      state.startLabel = action.payload;
    },
    setEndLabel: (state, action) => {
      state.endLabel = action.payload;
    },
    setShowTripDetailGlobal: (state, action) => {
      state.showTripDetail = action.payload;
    },
    setHideTripDetailGlobal: (state, action) => {
      state.hideTripDetail = action.payload;
    },
  },
});

export const {
  setStartLabel,
  setStartLat,
  setStartLng,
  setEndLabel,
  setEndLat,
  setEndLng,
  setShowTripDetailGlobal,
  setHideTripDetailGlobal,
} = tripLocationSlice.actions;

export default tripLocationSlice.reducer;
