import authUtility from '../utils/auth';
import loginMock from './mocks/login';
import demoConstants from './constants';
import getAtLastLocationAssetData from './mocks/getAtLastLocationAssetData';
import assetDetailsPageMock from './mocks/assetDetailsPageMock';
import lockedVideosPageMock from './mocks/lockedVideosMock';
import alertIncidentsMock from './mocks/alertIncidentsList';
import * as API from '../api/API';
import alertMocks from './mocks/alertMocks';

const isDemoAfterLoggedIn = () => {
  const auth = authUtility.getLoginCredentials();
  if (!auth) return false;
  const { token } = auth;
  if (token !== loginMock.token) {
    return false;
  }
  return true;
};

const isDemoBeforeLoggedIn = (username) => {
  let demoLogin = false;
  for (let i = 0; i < demoConstants.DEMO_LOGINS.length; i++) {
    if (demoConstants.DEMO_LOGINS[i] === username) {
      demoLogin = true;
      break;
    }
  }
  if (!demoLogin) {
    return false;
  }
  return true;
};

/**
 *
 * @param {string} endpoint
 * @param {?object} body
 * @returns {{isDemoAccount: boolean, response: object}}
 */
const handleDemoApiResponse = (endpoint, body = null) => {
  endpoint = `${API.ROOT}${endpoint}`;
  const returnValue = {
    isDemoAccount: true,
    response: {},
  };
  const url = new URL(endpoint);
  if (endpoint.includes(API.DASHCAM_MASTER_ME)) {
    returnValue.response = loginMock;
  } else if (endpoint.includes(API.ASSET_LAST_LOCATION_DATA)) {
    returnValue.response = getAtLastLocationAssetData.getResponseWithOffsettedDate();
  } else if (endpoint.includes(API.ASSET_GET_LAST_LOCATION_BY_DEVICE_ID)) {
    const urlParams = new URLSearchParams(url.search);
    returnValue.response = assetDetailsPageMock.getAssetLocationDeviceDataDeviceWise(
      urlParams.get('Device_ID'),
    );
  } else if (endpoint.includes(API.ASSET_LAST_FIVE_DAY_DATA)) {
    const { deviceId } = body;
    returnValue.response = assetDetailsPageMock.getFiveDaysDeviceData(deviceId);
  } else if (endpoint.includes(API.ASSET_DEVICE_CONNECTION_STATUS)) {
    const urlParams = new URLSearchParams(url.search);
    returnValue.response = assetDetailsPageMock.getAssetDeviceConnectionStatus(
      urlParams.get('deviceId'),
    );
  } else if (endpoint.includes(API.SIM_CARD_BALANCES)) {
    returnValue.response = assetDetailsPageMock.simCardBalance;
  } else if (endpoint.includes(API.DASHCAM_MASTER_BY_ID)) {
    const urlParams = new URLSearchParams(url.search);
    returnValue.response = assetDetailsPageMock.getDashCamMasterByDeviceId(urlParams.get('id'));
  } else if (endpoint.includes(API.DASHCAM_ALL_VIDEOS) && endpoint.includes(`&Page=1`)) {
    returnValue.response = lockedVideosPageMock.getAllDashCamVideoByCompanyBranch;
  } else if (endpoint.includes(API.DASHCAM_ALL_VIDEOS)) {
    returnValue.response = [];
  } else if (endpoint.includes(API.ALL_DASHCAM)) {
    returnValue.response = lockedVideosPageMock.getAllDashCam;
  } else if (endpoint.includes(API.AT_ALERT_TAGS_GET)) {
    returnValue.response = alertMocks.atAlertTagsGet;
  } else if (endpoint.includes(API.AT_ALERT_ASSETS_LIST_GET)) {
    returnValue.response = alertMocks.atAlertAssetsListGet;
  } else if (endpoint.includes(API.AT_ALERT_CO_ORDINATES_GET)) {
    returnValue.response = alertMocks.atAlertCoordinates;
  } else if (endpoint.includes(API.GET_NOTIFICATION_CONTACT_LIST)) {
    returnValue.response = alertMocks.getNotificationsContactList;
  } else if (endpoint.includes(API.AT_ALERT_NOTIFICATIONS_GET)) {
    returnValue.response = alertMocks.getAtAlertNotifications;
  } else if (endpoint.includes(API.AT_ALERT_MASTER_GET)) {
    returnValue.response = alertMocks.getAtAlertMaster;
  } else if (endpoint.includes(API.ASSET_TAGS_BY_DEVICE_ID)) {
    returnValue.response = assetDetailsPageMock.getAssetTagsByDeviceId;
  } else if (endpoint.includes(API.GET_INCIDENT_LIST)) {
    returnValue.response = alertIncidentsMock.getAlertIncidentsList;
  }

  return returnValue;
};

export default {
  isDemoAfterLoggedIn,
  isDemoBeforeLoggedIn,
  handleDemoApiResponse,
};
