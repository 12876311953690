const getAlertIncidentsList = [
  {
    Row_Count: '8',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/15/2021 05:12:17',
    GPSStatusMark: '3D',
    latitude: '20.013329554349184',
    longitude: '73.7842795997858',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: '34fbbbb2-f493-44fe-a896-93e48c1939b6',
    Asset_Id: '10394',
    Speed: '42.00 MPH',
    Speed_Value: '42.00',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/15/2021 05:12:17',
    Alert_Master_Id: '1057',
    Alert_Name: 'Speed',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '2.00 MPH',
    Speed_Range_Diff_Value: '2.00',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/15/2021',
    Incident_Id: '1627470',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '8',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/15/2021 05:12:17',
    GPSStatusMark: '3D',
    latitude: '20.013329554349184',
    longitude: '73.7842795997858',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: '34fbbbb2-f493-44fe-a896-93e48c1939b6',
    Asset_Id: '10394',
    Speed: '42.00 MPH',
    Speed_Value: '42.00',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/15/2021 05:12:17',
    Alert_Master_Id: '1085',
    Alert_Name: 'Battery alert',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '2.00 MPH',
    Speed_Range_Diff_Value: '2.00',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/15/2021',
    Incident_Id: '1627471',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '8',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/15/2021 05:12:17',
    GPSStatusMark: '3D',
    latitude: '20.013329554349184',
    longitude: '73.7842795997858',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: '34fbbbb2-f493-44fe-a896-93e48c1939b6',
    Asset_Id: '10394',
    Speed: '42.00 MPH',
    Speed_Value: '42.00',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/15/2021 05:12:17',
    Alert_Master_Id: '1087',
    Alert_Name: 'dfsd',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '2.00 MPH',
    Speed_Range_Diff_Value: '2.00',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/15/2021',
    Incident_Id: '1627472',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '8',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/15/2021 05:12:17',
    GPSStatusMark: '3D',
    latitude: '20.013329554349184',
    longitude: '73.7842795997858',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: '34fbbbb2-f493-44fe-a896-93e48c1939b6',
    Asset_Id: '10394',
    Speed: '42.00 MPH',
    Speed_Value: '42.00',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/15/2021 05:12:17',
    Alert_Master_Id: '1090',
    Alert_Name: 'dsf',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '2.00 MPH',
    Speed_Range_Diff_Value: '2.00',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/15/2021',
    Incident_Id: '1627473',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '5',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/14/2021 11:53:45',
    GPSStatusMark: '3D',
    latitude: '20.012931036762893',
    longitude: '73.78421790897846',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: 'c555ea5d-c7a8-4dc3-b37c-8a217c7b0717',
    Asset_Id: '10394',
    Speed: '60.00 MPH',
    Speed_Value: '60.00',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/14/2021 11:53:45',
    Alert_Master_Id: '1057',
    Alert_Name: 'Speed',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '20.00 MPH',
    Speed_Range_Diff_Value: '20.00',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/14/2021',
    Incident_Id: '1623270',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '5',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/14/2021 11:53:45',
    GPSStatusMark: '3D',
    latitude: '20.012931036762893',
    longitude: '73.78421790897846',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: 'c555ea5d-c7a8-4dc3-b37c-8a217c7b0717',
    Asset_Id: '10394',
    Speed: '60.00 MPH',
    Speed_Value: '60.00',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/14/2021 11:53:45',
    Alert_Master_Id: '1085',
    Alert_Name: 'Battery alert',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '20.00 MPH',
    Speed_Range_Diff_Value: '20.00',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/14/2021',
    Incident_Id: '1623271',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '5',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/14/2021 11:53:45',
    GPSStatusMark: '3D',
    latitude: '20.012931036762893',
    longitude: '73.78421790897846',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: 'c555ea5d-c7a8-4dc3-b37c-8a217c7b0717',
    Asset_Id: '10394',
    Speed: '60.00 MPH',
    Speed_Value: '60.00',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/14/2021 11:53:45',
    Alert_Master_Id: '1087',
    Alert_Name: 'dfsd',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '20.00 MPH',
    Speed_Range_Diff_Value: '20.00',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/14/2021',
    Incident_Id: '1623272',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '5',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/14/2021 11:53:45',
    GPSStatusMark: '3D',
    latitude: '20.012931036762893',
    longitude: '73.78421790897846',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: 'c555ea5d-c7a8-4dc3-b37c-8a217c7b0717',
    Asset_Id: '10394',
    Speed: '60.00 MPH',
    Speed_Value: '60.00',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/14/2021 11:53:45',
    Alert_Master_Id: '1090',
    Alert_Name: 'dsf',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '20.00 MPH',
    Speed_Range_Diff_Value: '20.00',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/14/2021',
    Incident_Id: '1623273',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '1',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/13/2021 06:42:18',
    GPSStatusMark: '3D',
    latitude: '20.013316970110054',
    longitude: '73.78427691757679',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: 'd554a41b-6ffb-4bdd-a594-36a7d34d0f3b',
    Asset_Id: '10394',
    Speed: '44.99 MPH',
    Speed_Value: '44.99',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/13/2021 06:42:18',
    Alert_Master_Id: '1057',
    Alert_Name: 'Speed',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '4.99 MPH',
    Speed_Range_Diff_Value: '4.99',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/13/2021',
    Incident_Id: '1622632',
    assetType: 'Dashcam',
  },
  {
    Row_Count: '1',
    Asset_Name: 'SANKET CAM',
    DeviceID: '7ee7a71c-95f7-4640-9cd5-94238c3b3cbb',
    MileageMeters: '',
    CreatedDate: '09/13/2021 06:42:18',
    GPSStatusMark: '3D',
    latitude: '20.013316970110054',
    longitude: '73.78427691757679',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    Status: 'Speed Limit Crossed',
    location_address: 'F1 Yashodeep Kaushalya Nagar Ramwadi Panchavati, Nashik, MH, India',
    CompanyName: 'DS Info Tech Pvt Ltd',
    Icon_Status: 'H',
    ExecutionId: 'd554a41b-6ffb-4bdd-a594-36a7d34d0f3b',
    Asset_Id: '10394',
    Speed: '44.99 MPH',
    Speed_Value: '44.99',
    Speed_Unit: 'MPH',
    BatteryPercentage: '0.00',
    Max_Speed_Range: '40.00 MPH',
    Min_Battery_Range: '',
    IncidentCreatedDate: '09/13/2021 06:42:18',
    Alert_Master_Id: '1085',
    Alert_Name: 'Battery alert',
    IncidentType: '2',
    Battery_Range_Diff: '0.00',
    Speed_Range_Diff: '4.99 MPH',
    Speed_Range_Diff_Value: '4.99',
    Alert_Type: 'Speed',
    TagName: '',
    GroupDate: '09/13/2021',
    Incident_Id: '1622633',
    assetType: 'Dashcam',
  },
];

export default {
  getAlertIncidentsList,
};
