const loginResponse = {
  customMessage: 'Branch Admin Logged In Successfully',
  customErrorState: '0',
  data: {
    contact_ID: 286703,
    branch_ID: 47096,
    company_ID: 37084,
    contact_LogoPath: '',
    branch_LogoPath: '',
    company_LogoPath: '',
    contact_Name: 'BLACK HORSE TRANS INC',
    branch_Name: 'BLACK HORSE TRANS INC',
    company_Name: 'BLACK HORSE TRANS INC',
    roleId: 73674,
    roleName: 'BRANCH ADMIN',
    contact_Type: 'BRANCH ADMIN',
    iS_TMS_Login: false,
    iS_HOS_Login: false,
    iS_TELEMATICS_Login: false,
    wialonToken: '',
    is_Location: false,
    account_ID: '',
    hosCount: 61,
    dashCamCount: 24,
    assetCount: 126,
  },
  token:
    'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1bmlxdWVfbmFtZSI6ImJsYWNraG9yc2V0cmFuc2luY0BnbWFpbC5jb20iLCJuYW1laWQiOiIyODY3MDMiLCJuYmYiOjE2MjIxNDQxODksImV4cCI6MTc3OTkxMDU4OSwiaWF0IjoxNjIyMTQ0MTg5LCJpc3MiOiJFTEREYXNoQ2FtTWljcm9TZXJ2aWNlIiwiYXVkIjoiQWxsIn0.Li_2uaJnbLNOqpXFEl5ry8wZwAJ_VB-_mUzdKTmZoCs',
  dashCamMasterId: '00000000-0000-0000-0000-000000000000',
  responseMsg: {
    version: { major: 1, minor: 1, build: -1, revision: -1, majorRevision: -1, minorRevision: -1 },
    content: null,
    statusCode: 200,
    reasonPhrase: 'OK',
    headers: [],
    trailingHeaders: [],
    requestMessage: null,
    isSuccessStatusCode: true,
  },
};

export default loginResponse;
