export const ROOT = process.env.REACT_APP_BASE_URL; // Dashcam Microservice
export const TMSROOT = process.env.REACT_APP_TMS_API_URL; // Asset Tracker Microservice

export const LOGIN = '/api/Login';
export const FORGOT_PASSWORD = '/api/Login/ForgotPassword';
export const ASSET_LAST_LOCATION_DATA = '/api/ATLocation/GetATLastLocationAssetData';
export const ASSET_GET_LAST_LOCATION_BY_DEVICE_ID =
  '/api/ATLocation/GetAssetLocationData_DeviceIDWise';
export const ASSET_LAST_FIVE_DAY_DATA = '/api/AssetHistory/get_at_five_days_device_data';
export const ASSET_LAST_FIVE_DAY_DATA_V2 = '/api/v2/assetHistory/getAssetFiveDaysDeviceData';
export const DASHCAM_LIVE_STREAMING = '/api/DashCamLiveStreaming';
export const ASSET_DEVICE_CONNECTION_STATUS = '/api/Asset/AssetDeviceConnectionStatus';
export const DASHCAM_MASTER_ME = '/api/DashcamMaster/Me'; // ME Api to get contact information
export const DASHCAM_ALL_VIDEOS = '/api/DashCamMaster/GetAllDashCamVideoByCompanyBranch';
export const DASHCAM_VIDEOS_BY_DASHCAMID = '/api/DashCamMaster/GetAllDashCamVideoByDashCamMasterId';
export const GET_ALL_DASHCAM_VIDEO_BY_COMPANY_BRANCH =
  '/api/DashCamMaster/GetAllDashCamVideoByCompanyBranch';
export const POST_AT_LAST_LOCATION_ASSET_DATA = '/api/ATLocation/POST_AT_LAST_LOCATION_ASSET_DATA';
export const ALL_DASHCAM = '/api/DashCamMaster/GetAllDashCam';
export const GET_DOWNLOAD_VIDEO = '/api/DashCamMaster/GetVideoDirect';
export const GET_VIDEO_SHAREABLELINK = '/api/DashCamMaster/GetVideoShareableLink';
export const GET_SEND_VIDEO_LINK_BY_EMAIL = '/api/DashCamMaster/SendVideoLinkByEmail';
export const DASHCAM_MASTER_BY_ID = '/api/DashCamMaster/GetDashCamMasterById';
export const GET_VIDEO_STREAM = '/api/DashCamMaster/GetVideoStream';
export const ASSET_TAGS_BY_DEVICE_ID = '/api/Asset/get_asset_tags';
export const ASSET_UPDATE_DATA = '/api/Asset/UpdateAssetData';
export const ASSET_TAG_UPDATE = '/api/Asset/Insert_Update_Asset_Tags';
export const AT_ALERT_ASSETS_LIST_GET = '/api/AT_Alert/AT_ALERT_ASSETS_LIST_GET';
export const AT_ALERT_ASSETS_UPDATE_STATUS = '/api/AT_Alert/AT_ALERT_ASSETS_UPDATE_STATUS';
export const AT_ALERT_TAGS_GET = '/api/AT_Alert/AT_ALERT_TAGS_GET';
export const ASSET_TAGS_DISTINCT = '/api/Asset/get_asset_tags_distinct';
export const GET_NOTIFICATION_CONTACT_LIST = '/api/GeoFenceSetting/GetNotificationContactList';
export const AT_ALERT_NOTIFICATIONS_GET = '/api/AT_Alert/AT_ALERT_NOTIFICATIONS_GET';
export const SAVE_ALERT_ASSETS_CONTACT = '/api/AT_Alert/SAVE_ALERT_ASSETS_CONTACT';
export const AT_ALERT_INSERT = '/api/AT_Alert/AT_ALERT_INSERT';
export const AT_ALERT_INSERT_V2 = '/api/v2/alert/insertAlert';
export const AT_ALERT_MASTER_GET = '/api/AT_Alert/AT_ALERT_MASTER_GET';
export const AT_ALERT_ASSETS_GET = '/api/AT_Alert/AT_ALERT_ASSETS_GET';
export const GET_ASSET_DEVICE = '/api/GeoFenceSetting/GetAssetDevice';
export const AT_ALERT_CO_ORDINATES_GET = '/api/AT_Alert/AT_ALERT_CO_ORDINATES_GET';
export const SIM_CARD_BALANCES = '/api/SimCard/SimCardBalances';
export const CHANGE_PASSWORD = '/api/Login/ChangePassword';
export const GET_SNS_CONFIRMED = '/api/Asset/GetSNSDeliveryStatusByMessageId';
export const KINESIS_VIDEO_SIGNALLING_CHANNELS = '/api/KinesisVideoStreamSignallingChannels';
export const TRAILER_IDLE_CURRENT = '/api/v2/idleTrailer/getCurrentlyIdleTrailer';
export const TRAILER_IDLE_ALL_TIME = '/api/v2/idleTrailer/getIdleTimeOfAllTrailers';
export const IDLE_ALL_TRAILER_EXPORT_PDF = '/api/v2/idleTrailer/getIdleTimeOfAllTrailersExportPDF';
export const IDLE_ALL_TRAILER_EXPORT_CSV = '/api/v2/idleTrailer/getIdleTimeOfAllTrailersExportCSV';
export const CURRENTLY_IDLE_TRAILER_EXPORT_PDF =
  '/api/v2/idleTrailer/getCurrentlyIdleTrailerExportPDF';
export const CURRENTLY_IDLE_TRAILER_EXPORT_CSV =
  '/api/v2/idleTrailer/getCurrentlyIdleTrailerExportCSV';
export const GET_DISTINCT_ASSET_TAGS = '/api/v2/asset/getAssetDistinctTags';
export const GET_ASSET_LOCATION = '/api/v2/asset/getCountryAndState';
export const GET_CITY_WITH_STATE = '/api/v2/asset/getCityListByState';
export const GET_ALL_DASHCAM_BY_ID = '/api/DashCamMaster/GetAllDashCamVideoByDashCamMasterId';
export const GET_INCIDENT_LIST = '/api/AT_Alert/get_Alert_Incidents_List';
export const GET_INCIDENT_LIST_DETAIL = '/api/AT_Alert/get_Alert_Incidents_List_Details';
export const CREATE_NEW_TRIP = '/api/v2/tripRoute/insertTripDetails';
export const EDIT_TRIP = '/api/v2/tripRoute/updateTripDetails';
export const GET_ACTIVE_TRIPS = '/api/v2/tripRoute/getActiveTripList';
export const GET_FINISHED_TRIPS = '/api/v2/tripRoute/getFinishedTripList';
export const GET_TRIP_DETAIL = '/api/v2/tripRoute/getTripDetails';
export const DELETE_ACTIVE_TRIP = '/api/v2/tripRoute/deleteTripDetails';
export const REAL_TIME_TRACKING_VALIDATION = '/api/v2/asset/getRealtimeTrackingValidation';
export const REAL_TIME_TRACKING = '/api/Asset/RealTimeTracking';
export const ASSET_GETASSETDORMANCYREPORT = '/api/Asset/GetAssetDormancyReport';
export const ASSET_GETFREQUENTCHECKINREPORT = '/api/Asset/GetFrequentCheckInReport';
export const ASSET_GETSPEEDREPORT = '/api/Asset/GetSpeedReport';
export const ASSET_GETUTILIZATIONREPORT = '/api/Asset/GetUtilizationReport';
export const REPORT_GETREPORTCOLOUR = '/api/Report/GetReportColour';
export const GET_DEVICE_CHECKIN_ID = '/api/Asset/GeDeviceCheckIn';
export const GET_ASSET_CHECKIN = '/api/v2/asset/getAssetCheckIn';
export const GET_RESELLER_COMPS = '/api/Company/getResellerCompanies';
export const IMPERSONATE_RESELLER_COMPANY = '/api/Company/impersonateResellerCompany';
export const PAGER_DUTY_INCIDENT = '/api/v2/pagerDuty/createPagerDutyIncident';
export const GET_COMPANY_BRANCH = '/api/v2/companyAdmin/getCompanyBranchesList';
export const GET_CONTACT_LIST = '/api/v2/branchAdmin/getContactsList';
export const INSERT_BRANCH = '/api/v2/companyAdmin/insertBranchDetails';
export const INSERT_CONTACT = '/api/v2/branchAdmin/insertContactDetails';
export const UPDATE_BRANCH = '/api/v2/companyAdmin/updateBranchDetails';
export const UPDATE_CONTACT = '/api/v2/branchAdmin/updateContactDetails';
export const DELETE_BRANCH = '/api/v2/companyAdmin/deleteBranch';
export const DELETE_CONTACT = '/api/v2/branchAdmin/deleteContact';
export const GET_COUNTRY = '/api/v2/entity/getCountriesList';
export const GET_STATE_LIST = '/api/v2/entity/getStatesList';
export const USER_ROLE = '/api/v2/entity/getUserRolesList';
export const UPDATE_PASSWORD = '/api/Login/ChangePasswordById';
export const REQUEST_VIDEO = '/api/DashcamCommands/TriggerVideoUploadCommand';
export const GET_ASSET_DETAIL = '/api/v2/assetHistory/getAssetDetails';
