import React from 'react';
import Loader from 'react-loader-spinner';
import colors from '../colors';

// loading component for suspense fallback
const AppLoader = () => {
  return (
    <div
      style={{
        textAlign: 'center',
        position: 'fixed',
        top: '50%',
        paddingTop: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '100%',
        width: '100%',
        // padding: '50%',
        // border: '1px solid red',
        zIndex: '998',
      }}
      data-testid="appLoader-div"
    >
      <Loader type="ThreeDots" color={colors.eldBlueColor} />
    </div>
  );
};

export default AppLoader;
