const data = [
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:44:16',
    Latitude: '39.5190862',
    Longitude: '-119.8943973',
    Speed: '15.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6567-6667 Sharlands Ave, Reno, NV 89523, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:44:16',
    SlNo: '29',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:44:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:42:16',
    Latitude: '39.5177072',
    Longitude: '-119.8774356',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1000 Backer Way, Reno, NV 89523, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:42:16',
    SlNo: '29',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:42:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:40:16',
    Latitude: '39.5268881',
    Longitude: '-119.8466828',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2705 Everett Dr, Reno, NV 89503, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:40:16',
    SlNo: '30',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:40:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:38:16',
    Latitude: '39.5363394',
    Longitude: '-119.8096395',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '469 E 9th St, Reno, NV 89512, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:38:16',
    SlNo: '31',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:38:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:36:15',
    Latitude: '39.5340121',
    Longitude: '-119.771912',
    Speed: '46.60 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2111 Victorian Ave, Sparks, NV 89431, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:36:15',
    SlNo: '32',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:36:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:34:14',
    Latitude: '39.5345414',
    Longitude: '-119.7430318',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Victorian/Stanford, Sparks, NV 89431, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:34:14',
    SlNo: '33',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:34:14 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:32:13',
    Latitude: '39.5266434',
    Longitude: '-119.7018185',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2091 Brierley Way, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:32:13',
    SlNo: '34',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:32:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:30:13',
    Latitude: '39.5104863',
    Longitude: '-119.6635211',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '485 Lockwood Rd, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:30:13',
    SlNo: '35',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:30:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:28:12',
    Latitude: '39.5217832',
    Longitude: '-119.6262561',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11998 I-80, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:28:12',
    SlNo: '36',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:28:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:26:18',
    Latitude: '39.5449919',
    Longitude: '-119.5929327',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:26:18',
    SlNo: '37',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:26:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:24:11',
    Latitude: '39.5602158',
    Longitude: '-119.5523082',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '21120 I-80, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:24:11',
    SlNo: '38',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:24:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:22:10',
    Latitude: '39.5680067',
    Longitude: '-119.5085991',
    Speed: '60.27 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '22000 I-80, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:22:10',
    SlNo: '39',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:22:10 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:20:10',
    Latitude: '39.5758059',
    Longitude: '-119.4725202',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:20:10',
    SlNo: '40',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:20:10 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:18:09',
    Latitude: '39.5892869',
    Longitude: '-119.4379737',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '26066 I-80, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:18:09',
    SlNo: '41',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:18:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:16:08',
    Latitude: '39.5893762',
    Longitude: '-119.392088',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:16:08',
    SlNo: '42',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:16:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:14:07',
    Latitude: '39.5982547',
    Longitude: '-119.3529005',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Sparks, NV 89434, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:14:07',
    SlNo: '43',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:14:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:12:07',
    Latitude: '39.614808',
    Longitude: '-119.3127022',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '495 Stampmill Dr, Wadsworth, NV 89442, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:12:07',
    SlNo: '44',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:12:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:10:07',
    Latitude: '39.6166961',
    Longitude: '-119.2679065',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '825 Commerce Center Dr, Fernley, NV 89408, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:10:07',
    SlNo: '45',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:10:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:08:06',
    Latitude: '39.6130745',
    Longitude: '-119.2215447',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '395 Jill Ct, Fernley, NV 89408, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:08:06',
    SlNo: '46',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:08:06 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:06:06',
    Latitude: '39.6347508',
    Longitude: '-119.1857983',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fernley, NV 89408, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:06:06',
    SlNo: '47',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:06:06 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:04:06',
    Latitude: '39.6599619',
    Longitude: '-119.1543259',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fernley, NV 89408, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:04:06',
    SlNo: '48',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:04:06 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:02:05',
    Latitude: '39.6839061',
    Longitude: '-119.1191914',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fernley, NV 89408, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:02:05',
    SlNo: '49',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:02:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 19:00:04',
    Latitude: '39.7129786',
    Longitude: '-119.091908',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fernley, NV 89408, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '19:00:04',
    SlNo: '50',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:00:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:58:04',
    Latitude: '39.7428053',
    Longitude: '-119.066166',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fernley, NV 89408, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:58:04',
    SlNo: '51',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:58:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:56:03',
    Latitude: '39.7722169',
    Longitude: '-119.0389016',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fernley, NV 89408, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:56:03',
    SlNo: '52',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:56:03 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:54:04',
    Latitude: '39.8015293',
    Longitude: '-119.0117185',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fallon, NV 89406, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:54:04',
    SlNo: '53',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:54:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:52:02',
    Latitude: '39.8265406',
    Longitude: '-118.9791713',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fallon, NV 89406, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:52:02',
    SlNo: '54',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:52:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:50:02',
    Latitude: '39.8457072',
    Longitude: '-118.9396305',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fallon, NV 89406, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:50:02',
    SlNo: '55',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:50:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:48:01',
    Latitude: '39.8648204',
    Longitude: '-118.8999647',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fallon, NV 89406, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:48:01',
    SlNo: '56',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:48:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:46:01',
    Latitude: '39.8869184',
    Longitude: '-118.8647913',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Fallon, NV 89406, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:46:01',
    SlNo: '57',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:46:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:44:02',
    Latitude: '39.9090785',
    Longitude: '-118.8286548',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Nevada, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:44:02',
    SlNo: '58',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:44:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:42:00',
    Latitude: '39.9270583',
    Longitude: '-118.7881434',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Nevada, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:42:00',
    SlNo: '59',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:42:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:40:03',
    Latitude: '39.9435981',
    Longitude: '-118.7463027',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Nevada, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:40:03',
    SlNo: '60',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:40:03 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '39.9606374',
    Longitude: '-118.7062633',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Nevada, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '61',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '39.9901956',
    Longitude: '-118.6829241',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '62',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.0254957',
    Longitude: '-118.6734733',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '63',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.0582994',
    Longitude: '-118.6539496',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '64',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.0742239',
    Longitude: '-118.6338625',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '65',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.0742234',
    Longitude: '-118.6338617',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '66',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.0742237',
    Longitude: '-118.6338623',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '67',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.0742233',
    Longitude: '-118.633864',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '68',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.0742209',
    Longitude: '-118.6338682',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '69',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.0905668',
    Longitude: '-118.5978989',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '70',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.1081585',
    Longitude: '-118.5572388',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '71',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:39:59',
    Latitude: '40.1299785',
    Longitude: '-118.520187',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:39:59',
    SlNo: '72',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:39:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:11:51',
    Latitude: '40.1868067',
    Longitude: '-118.465321',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1490 E Broadway, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:11:51',
    SlNo: '73',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:11:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.2044829',
    Longitude: '-118.4258845',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '74',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.2333547',
    Longitude: '-118.3981501',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '75',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.2631082',
    Longitude: '-118.3713844',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '76',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.2917288',
    Longitude: '-118.3535411',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '77',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.3169551',
    Longitude: '-118.3337566',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '78',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.3369478',
    Longitude: '-118.3178496',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '79',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.3633873',
    Longitude: '-118.3075062',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '80',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.3927523',
    Longitude: '-118.3008082',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '81',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.4283771',
    Longitude: '-118.2926225',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '82',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.4635032',
    Longitude: '-118.2855741',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '83',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.4938922',
    Longitude: '-118.2827018',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Lovelock, NV 89419, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '84',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.5283616',
    Longitude: '-118.2793948',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '85',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.564023',
    Longitude: '-118.2739562',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Frontage Rd, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '86',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.5950103',
    Longitude: '-118.2549015',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '87',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.6215502',
    Longitude: '-118.2267057',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '88',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.6431031',
    Longitude: '-118.1896953',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '89',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.6573426',
    Longitude: '-118.1461978',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '90',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.6697824',
    Longitude: '-118.1017055',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3100 Frontage Rd, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '91',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.685631',
    Longitude: '-118.0598694',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '92',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.7185642',
    Longitude: '-118.0404259',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Imlay, NV 89418, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '93',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.7517916',
    Longitude: '-118.0212662',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '94',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:51',
    Latitude: '40.7849634',
    Longitude: '-118.0021326',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:51',
    SlNo: '95',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.8177963',
    Longitude: '-117.9831965',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '96',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.8490589',
    Longitude: '-117.96029',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '97',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.8778874',
    Longitude: '-117.9340048',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '98',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.8958659',
    Longitude: '-117.8942041',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '99',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9133263',
    Longitude: '-117.8529463',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7695 McNinch Rd, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '100',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9254381',
    Longitude: '-117.8153136',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '101',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9260023',
    Longitude: '-117.8135578',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-95, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '102',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9401053',
    Longitude: '-117.7823714',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4325 W Winnemucca Blvd, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '103',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.961796',
    Longitude: '-117.7487911',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1880 W Winnemucca Blvd, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '104',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9834398',
    Longitude: '-117.7145954',
    Speed: '76.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '105',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9956609',
    Longitude: '-117.6687534',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '106',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '41.0123982',
    Longitude: '-117.6267575',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '107',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '41.0252733',
    Longitude: '-117.585026',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Winnemucca, NV 89445, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '108',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9974985',
    Longitude: '-117.5561998',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Golconda, NV 89414, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '109',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9712455',
    Longitude: '-117.5242143',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Golconda, NV 89414, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '110',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.946634',
    Longitude: '-117.4893157',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Golconda, NV 89414, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '111',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.932392',
    Longitude: '-117.4448217',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Golconda, NV 89414, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '112',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9230988',
    Longitude: '-117.4016338',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Golconda, NV 89414, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '113',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9182544',
    Longitude: '-117.3661665',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Golconda, NV 89414, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '114',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.9112182',
    Longitude: '-117.327833',
    Speed: '59.03 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Nevada, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '115',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.8964496',
    Longitude: '-117.2958065',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Nevada, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '116',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.8791869',
    Longitude: '-117.2610551',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Nevada, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '117',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 18:09:50',
    Latitude: '40.8624923',
    Longitude: '-117.2273244',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Nevada, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '18:09:50',
    SlNo: '118',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:09:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:39:27',
    Latitude: '40.8431274',
    Longitude: '-117.1934834',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Valmy, NV 89438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:39:27',
    SlNo: '119',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:39:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:37:27',
    Latitude: '40.8173342',
    Longitude: '-117.1605183',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Valmy, NV 89438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:37:27',
    SlNo: '120',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:37:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:35:26',
    Latitude: '40.791159',
    Longitude: '-117.1277217',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '216 I-80, Valmy, NV 89438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:35:26',
    SlNo: '121',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:35:26 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:33:26',
    Latitude: '40.765534',
    Longitude: '-117.0942343',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Valmy, NV 89438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:33:26',
    SlNo: '122',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:33:26 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:31:25',
    Latitude: '40.7398708',
    Longitude: '-117.0614317',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Valmy, NV 89438, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:31:25',
    SlNo: '123',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:31:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:29:24',
    Latitude: '40.7138722',
    Longitude: '-117.0283099',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:29:24',
    SlNo: '124',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:29:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:27:25',
    Latitude: '40.6882696',
    Longitude: '-116.9956858',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:27:25',
    SlNo: '125',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:27:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:25:23',
    Latitude: '40.6623792',
    Longitude: '-116.9627034',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:25:23',
    SlNo: '126',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:25:23 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:23:22',
    Latitude: '40.6319844',
    Longitude: '-116.93874',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1148 Gold Creek Ave, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:23:22',
    SlNo: '127',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:23:22 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:21:21',
    Latitude: '40.6161432',
    Longitude: '-116.8960949',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2010 E Frontage Rd, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:21:21',
    SlNo: '128',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:21:21 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:19:21',
    Latitude: '40.6124587',
    Longitude: '-116.8494733',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2239 E Frontage Rd, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:19:21',
    SlNo: '129',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:19:21 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:17:20',
    Latitude: '40.6134215',
    Longitude: '-116.8024816',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:17:20',
    SlNo: '130',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:17:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:15:20',
    Latitude: '40.639814',
    Longitude: '-116.7720538',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:15:20',
    SlNo: '131',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:15:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:13:20',
    Latitude: '40.6625417',
    Longitude: '-116.7368956',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:13:20',
    SlNo: '132',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:13:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:11:28',
    Latitude: '40.6732888',
    Longitude: '-116.6925865',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:11:28',
    SlNo: '133',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:11:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:09:19',
    Latitude: '40.673115',
    Longitude: '-116.6454625',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:09:19',
    SlNo: '134',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:09:19 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:07:20',
    Latitude: '40.684257',
    Longitude: '-116.6009077',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:07:20',
    SlNo: '135',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:07:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:05:19',
    Latitude: '40.6963965',
    Longitude: '-116.5565206',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Battle Mountain, NV 89820, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:05:19',
    SlNo: '136',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:05:19 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 16:03:20',
    Latitude: '40.6975246',
    Longitude: '-116.5104793',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Crescent Valley, NV 89821, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '16:03:20',
    SlNo: '137',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:03:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:59:20',
    Latitude: '40.6628463',
    Longitude: '-116.4266944',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:59:20',
    SlNo: '138',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:59:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:57:18',
    Latitude: '40.6501671',
    Longitude: '-116.381529',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:57:18',
    SlNo: '139',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:57:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:55:34',
    Latitude: '40.6378403',
    Longitude: '-116.3395474',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:55:34',
    SlNo: '140',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:55:34 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:53:17',
    Latitude: '40.6502208',
    Longitude: '-116.3001985',
    Speed: '59.03 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:53:17',
    SlNo: '141',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:17 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:51:17',
    Latitude: '40.659127',
    Longitude: '-116.2645435',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:51:17',
    SlNo: '142',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:51:17 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:49:17',
    Latitude: '40.6642506',
    Longitude: '-116.2344798',
    Speed: '41.01 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:49:17',
    SlNo: '143',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:49:17 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:47:17',
    Latitude: '40.6777586',
    Longitude: '-116.2155972',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:47:17',
    SlNo: '144',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:47:17 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:45:16',
    Latitude: '40.6860426',
    Longitude: '-116.1847766',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:45:16',
    SlNo: '145',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:45:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:43:15',
    Latitude: '40.7113294',
    Longitude: '-116.154244',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:43:15',
    SlNo: '146',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:43:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:41:14',
    Latitude: '40.7212197',
    Longitude: '-116.1106304',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '862 9th St, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:41:14',
    SlNo: '147',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:41:14 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:39:13',
    Latitude: '40.7259324',
    Longitude: '-116.0643498',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:39:13',
    SlNo: '148',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:39:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:37:13',
    Latitude: '40.7242786',
    Longitude: '-116.0190388',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Carlin, NV 89822, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:37:13',
    SlNo: '149',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:37:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:35:13',
    Latitude: '40.7153387',
    Longitude: '-115.9795403',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:35:13',
    SlNo: '150',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:35:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:33:12',
    Latitude: '40.7409338',
    Longitude: '-115.9478399',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:33:12',
    SlNo: '151',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:33:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:31:12',
    Latitude: '40.7674129',
    Longitude: '-115.918776',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:31:12',
    SlNo: '152',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:31:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:29:12',
    Latitude: '40.7823096',
    Longitude: '-115.8764028',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:29:12',
    SlNo: '153',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:29:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:27:12',
    Latitude: '40.8009778',
    Longitude: '-115.8367338',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:27:12',
    SlNo: '154',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:27:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:25:11',
    Latitude: '40.8213582',
    Longitude: '-115.8102001',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2882 W Airport Rd, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:25:11',
    SlNo: '155',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:25:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:23:22',
    Latitude: '40.8380342',
    Longitude: '-115.7803517',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '131 Spruce Rd, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:23:22',
    SlNo: '156',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:23:22 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:21:10',
    Latitude: '40.8518212',
    Longitude: '-115.7485231',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2075 Idaho St, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:21:10',
    SlNo: '157',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:21:10 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:19:09',
    Latitude: '40.8752454',
    Longitude: '-115.7228483',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5200 Idaho St, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:19:09',
    SlNo: '158',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:19:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:17:12',
    Latitude: '40.9062636',
    Longitude: '-115.6997808',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4810 White Rock Rd, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:17:12',
    SlNo: '159',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:17:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:15:08',
    Latitude: '40.9323404',
    Longitude: '-115.6681841',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4779 Victory Blvd, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:15:08',
    SlNo: '160',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:15:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:13:08',
    Latitude: '40.9497994',
    Longitude: '-115.6274079',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:13:08',
    SlNo: '161',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:13:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:11:07',
    Latitude: '40.9495341',
    Longitude: '-115.5801627',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8129 Frontier Dr, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:11:07',
    SlNo: '162',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:11:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:09:07',
    Latitude: '40.9495054',
    Longitude: '-115.5333473',
    Speed: '77.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:09:07',
    SlNo: '163',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:09:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:07:06',
    Latitude: '40.955842',
    Longitude: '-115.4849074',
    Speed: '55.92 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:07:06',
    SlNo: '164',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:07:06 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:05:05',
    Latitude: '40.9648524',
    Longitude: '-115.4511328',
    Speed: '53.44 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:05:05',
    SlNo: '165',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:05:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:03:04',
    Latitude: '40.9869732',
    Longitude: '-115.4333938',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:03:04',
    SlNo: '166',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:03:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 15:01:04',
    Latitude: '41.0093447',
    Longitude: '-115.4146697',
    Speed: '55.92 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '15:01:04',
    SlNo: '167',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:01:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:59:03',
    Latitude: '41.0277374',
    Longitude: '-115.3884301',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Elko, NV 89801, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:59:03',
    SlNo: '168',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:03 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:57:03',
    Latitude: '41.0452883',
    Longitude: '-115.3516776',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Deeth, NV 89823, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:57:03',
    SlNo: '169',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:57:03 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:55:02',
    Latitude: '41.0644137',
    Longitude: '-115.311144',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Deeth, NV 89823, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:55:02',
    SlNo: '170',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:55:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:53:01',
    Latitude: '41.0833025',
    Longitude: '-115.2704555',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Deeth, NV 89823, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:53:01',
    SlNo: '171',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:53:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:51:01',
    Latitude: '41.0860316',
    Longitude: '-115.2240041',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:51:01',
    SlNo: '172',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:51:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:49:01',
    Latitude: '41.0878478',
    Longitude: '-115.1760655',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:49:01',
    SlNo: '173',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:49:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:47:01',
    Latitude: '41.095697',
    Longitude: '-115.128887',
    Speed: '76.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Deeth, NV 89823, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:47:01',
    SlNo: '174',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:47:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:45:00',
    Latitude: '41.1031907',
    Longitude: '-115.08108',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:45:00',
    SlNo: '175',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:45:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:15:20',
    Latitude: '41.1206463',
    Longitude: '-101.6349064',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '637 Rd East 90, Ogallala, NE 69153, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:15:20',
    SlNo: '729',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:15:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:13:18',
    Latitude: '41.1182153',
    Longitude: '-101.5872905',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Ogallala, NE 69153, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:13:18',
    SlNo: '730',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:13:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:11:17',
    Latitude: '41.1168667',
    Longitude: '-101.5396576',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Ogallala, NE 69153, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:11:17',
    SlNo: '731',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:11:17 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:09:18',
    Latitude: '41.106939',
    Longitude: '-101.4939835',
    Speed: '60.27 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Paxton, NE 69155, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:09:18',
    SlNo: '732',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:09:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:07:15',
    Latitude: '41.1068144',
    Longitude: '-101.4554674',
    Speed: '6.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Paxton, NE 69155, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:07:15',
    SlNo: '733',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:07:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:05:15',
    Latitude: '41.1077152',
    Longitude: '-101.4515461',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Paxton, NE 69155, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:05:15',
    SlNo: '734',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:05:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:03:14',
    Latitude: '41.1087621',
    Longitude: '-101.4184769',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Paxton, NE 69155, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:03:14',
    SlNo: '735',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:03:14 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:01:14',
    Latitude: '41.1094942',
    Longitude: '-101.3713174',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Paxton, NE 69155, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:01:14',
    SlNo: '736',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:01:14 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:59:13',
    Latitude: '41.1142881',
    Longitude: '-101.3240081',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Paxton, NE 69155, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:59:13',
    SlNo: '737',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:59:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:59:13',
    Latitude: '41.1168129',
    Longitude: '-101.2767151',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Paxton, NE 69155, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:59:13',
    SlNo: '738',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:59:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:55:16',
    Latitude: '41.1289462',
    Longitude: '-101.2334409',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sutherland, NE 69165, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:55:16',
    SlNo: '739',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:55:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:53:12',
    Latitude: '41.1390039',
    Longitude: '-101.1887176',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sutherland, NE 69165, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:53:12',
    SlNo: '740',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:53:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:51:12',
    Latitude: '41.1385712',
    Longitude: '-101.1413396',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sutherland, NE 69165, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:51:12',
    SlNo: '741',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:51:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:49:14',
    Latitude: '41.1404074',
    Longitude: '-101.0937815',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sutherland, NE 69165, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:49:14',
    SlNo: '742',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:49:14 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:47:10',
    Latitude: '41.1391925',
    Longitude: '-101.0461379',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Hershey, NE 69143, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:47:10',
    SlNo: '743',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:47:10 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:45:11',
    Latitude: '41.1371632',
    Longitude: '-100.9984954',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Hershey, NE 69143, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:45:11',
    SlNo: '744',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:45:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:43:09',
    Latitude: '41.1350769',
    Longitude: '-100.9511572',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '555 N Cotner Blvd, Hershey, NE 69143, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:43:09',
    SlNo: '745',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:43:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:41:18',
    Latitude: '41.1257616',
    Longitude: '-100.9054993',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, North Platte, NE 69101, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:41:18',
    SlNo: '746',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:41:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:39:09',
    Latitude: '41.1182724',
    Longitude: '-100.8588715',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8040 W South River Rd, North Platte, NE 69101, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:39:09',
    SlNo: '747',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:39:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:37:09',
    Latitude: '41.112575',
    Longitude: '-100.8117123',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, North Platte, NE 69101, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:37:09',
    SlNo: '748',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:37:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:35:09',
    Latitude: '41.1115805',
    Longitude: '-100.7646476',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, North Platte, NE 69101, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:35:09',
    SlNo: '749',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:35:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:33:07',
    Latitude: '41.1041375',
    Longitude: '-100.7182381',
    Speed: '44.74 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, North Platte, NE 69101, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:33:07',
    SlNo: '750',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:33:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:31:06',
    Latitude: '41.0997656',
    Longitude: '-100.6900771',
    Speed: '13.67 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6547 E State Farm Rd, North Platte, NE 69101, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:31:06',
    SlNo: '751',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:31:06 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:29:05',
    Latitude: '41.097953',
    Longitude: '-100.6814958',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, North Platte, NE 69101, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:29:05',
    SlNo: '752',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:29:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:27:05',
    Latitude: '41.0859986',
    Longitude: '-100.6376371',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, North Platte, NE 69101, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:27:05',
    SlNo: '753',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:27:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:25:05',
    Latitude: '41.0732676',
    Longitude: '-100.5946392',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Maxwell, NE 69151, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:25:05',
    SlNo: '754',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:25:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:23:04',
    Latitude: '41.0578444',
    Longitude: '-100.5519399',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Maxwell, NE 69151, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:23:04',
    SlNo: '755',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:23:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:21:04',
    Latitude: '41.0487461',
    Longitude: '-100.5063655',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '21579 E Correction Line Rd, Brady, NE 69123, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:21:04',
    SlNo: '756',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:21:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:19:04',
    Latitude: '41.032659',
    Longitude: '-100.464222',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brady, NE 69123, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:19:04',
    SlNo: '757',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:19:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:17:03',
    Latitude: '41.0190736',
    Longitude: '-100.4200591',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brady, NE 69123, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:17:03',
    SlNo: '758',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:17:03 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:15:03',
    Latitude: '41.0029989',
    Longitude: '-100.3777478',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brady, NE 69123, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:15:03',
    SlNo: '759',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:15:03 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:13:02',
    Latitude: '40.9930187',
    Longitude: '-100.3323535',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gothenburg, NE 69138, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:13:02',
    SlNo: '760',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:13:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:11:02',
    Latitude: '40.9751412',
    Longitude: '-100.2936186',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gothenburg, NE 69138, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:11:02',
    SlNo: '761',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:11:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:09:01',
    Latitude: '40.9550289',
    Longitude: '-100.2551945',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gothenburg, NE 69138, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:09:01',
    SlNo: '762',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:09:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:07:01',
    Latitude: '40.9378917',
    Longitude: '-100.2136563',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gothenburg, NE 69138, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:07:01',
    SlNo: '763',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:07:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:05:08',
    Latitude: '40.9178731',
    Longitude: '-100.1748406',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gothenburg, NE 69138, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:05:08',
    SlNo: '764',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:05:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:03:00',
    Latitude: '40.9011742',
    Longitude: '-100.1326222',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gothenburg, NE 69138, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:03:00',
    SlNo: '765',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:03:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 18:01:30',
    Latitude: '40.8856484',
    Longitude: '-100.0905857',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gothenburg, NE 69138, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '18:01:30',
    SlNo: '766',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 06:01:30 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:59:04',
    Latitude: '40.8712756',
    Longitude: '-100.0482936',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Cozad, NE 69130, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:59:04',
    SlNo: '767',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:59:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:57:00',
    Latitude: '40.8539793',
    Longitude: '-100.006998',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Cozad, NE 69130, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:57:00',
    SlNo: '768',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:57:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:54:59',
    Latitude: '40.8355747',
    Longitude: '-99.9661322',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Cozad, NE 69130, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:54:59',
    SlNo: '769',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:54:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:52:58',
    Latitude: '40.8199561',
    Longitude: '-99.923307',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Cozad, NE 69130, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:52:58',
    SlNo: '770',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:52:58 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:50:57',
    Latitude: '40.8016046',
    Longitude: '-99.8826103',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Cozad, NE 69130, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:50:57',
    SlNo: '771',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:50:57 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:48:57',
    Latitude: '40.7801807',
    Longitude: '-99.8448427',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'NE-24ALink, Lexington, NE 68850, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:48:57',
    SlNo: '772',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:48:57 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:46:56',
    Latitude: '40.7615793',
    Longitude: '-99.8041793',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Lexington, NE 68850, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:46:56',
    SlNo: '773',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:46:56 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:45:02',
    Latitude: '40.7467209',
    Longitude: '-99.7614371',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '230 W River Rd, Lexington, NE 68850, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:45:02',
    SlNo: '774',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:45:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:42:55',
    Latitude: '40.7350031',
    Longitude: '-99.7166363',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '238 E River Rd, Lexington, NE 68850, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:42:55',
    SlNo: '775',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:42:55 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:40:55',
    Latitude: '40.7133074',
    Longitude: '-99.6791947',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Lexington, NE 68850, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:40:55',
    SlNo: '776',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:40:55 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:38:54',
    Latitude: '40.7021762',
    Longitude: '-99.6342246',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Overton, NE 68863, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:38:54',
    SlNo: '777',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:38:54 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:36:54',
    Latitude: '40.6959101',
    Longitude: '-99.5880267',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Overton, NE 68863, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:36:54',
    SlNo: '778',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:36:54 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:34:54',
    Latitude: '40.6911494',
    Longitude: '-99.5418519',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Overton, NE 68863, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:34:54',
    SlNo: '779',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:34:54 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:32:53',
    Latitude: '40.6936024',
    Longitude: '-99.4945636',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '74940 Rd 446, Elm Creek, NE 68836, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:32:53',
    SlNo: '780',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:32:53 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:28:53',
    Latitude: '40.6912971',
    Longitude: '-99.4018298',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Elm Creek, NE 68836, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:28:53',
    SlNo: '781',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:28:53 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:26:52',
    Latitude: '40.6910063',
    Longitude: '-99.3544794',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Elm Creek, NE 68836, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:26:52',
    SlNo: '782',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:26:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:24:52',
    Latitude: '40.6838107',
    Longitude: '-99.3086968',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Elm Creek, NE 68836, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:24:52',
    SlNo: '783',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:24:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:22:51',
    Latitude: '40.6766197',
    Longitude: '-99.2624172',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Elm Creek, NE 68836, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:22:51',
    SlNo: '784',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:22:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:20:50',
    Latitude: '40.6723844',
    Longitude: '-99.2165385',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kearney, NE 68845, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:20:50',
    SlNo: '785',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:20:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:18:50',
    Latitude: '40.6720401',
    Longitude: '-99.1694899',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kearney, NE 68845, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:18:50',
    SlNo: '786',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:18:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:16:49',
    Latitude: '40.6705075',
    Longitude: '-99.1221717',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kearney, NE 68845, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:16:49',
    SlNo: '787',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:16:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:14:48',
    Latitude: '40.6697784',
    Longitude: '-99.0749214',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1000 E 1st St, Kearney, NE 68847, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:14:48',
    SlNo: '788',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:14:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:12:48',
    Latitude: '40.6699926',
    Longitude: '-99.0278688',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kearney, NE 68847, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:12:48',
    SlNo: '789',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:12:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:10:48',
    Latitude: '40.6809141',
    Longitude: '-98.984027',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'NE-10, Kearney, NE 68847, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:10:48',
    SlNo: '790',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:10:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:08:52',
    Latitude: '40.6904695',
    Longitude: '-98.9388746',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gibbon, NE 68840, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:08:52',
    SlNo: '791',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:08:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:06:47',
    Latitude: '40.6933483',
    Longitude: '-98.892358',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gibbon, NE 68840, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:06:47',
    SlNo: '792',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:06:47 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:04:48',
    Latitude: '40.7017752',
    Longitude: '-98.8470192',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gibbon, NE 68840, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:04:48',
    SlNo: '793',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:04:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:02:48',
    Latitude: '40.7138549',
    Longitude: '-98.8040398',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Shelton, NE 68876, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:02:48',
    SlNo: '794',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:02:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 17:00:48',
    Latitude: '40.7165582',
    Longitude: '-98.7912312',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Shelton, NE 68876, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '17:00:48',
    SlNo: '795',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 05:00:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:58:45',
    Latitude: '40.7165589',
    Longitude: '-98.7912301',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Shelton, NE 68876, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:58:45',
    SlNo: '796',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:58:45 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:56:44',
    Latitude: '40.7229674',
    Longitude: '-98.7530644',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Shelton, NE 68876, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:56:44',
    SlNo: '797',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:56:44 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:54:43',
    Latitude: '40.7217223',
    Longitude: '-98.7057316',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Wood River, NE 68883, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:54:43',
    SlNo: '798',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:54:43 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:52:49',
    Latitude: '40.730546',
    Longitude: '-98.6601472',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Wood River, NE 68883, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:52:49',
    SlNo: '799',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:52:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:50:42',
    Latitude: '40.7486011',
    Longitude: '-98.6193864',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Wood River, NE 68883, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:50:42',
    SlNo: '800',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:50:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:48:41',
    Latitude: '40.7654333',
    Longitude: '-98.5795829',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Wood River, NE 68883, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:48:41',
    SlNo: '801',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:48:41 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:46:40',
    Latitude: '40.7813574',
    Longitude: '-98.5372947',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Wood River, NE 68883, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:46:40',
    SlNo: '802',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:46:40 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:44:39',
    Latitude: '40.7981943',
    Longitude: '-98.4955312',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '8033 W Holling Rd, Alda, NE 68810, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:44:39',
    SlNo: '803',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:44:39 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:42:39',
    Latitude: '40.8098956',
    Longitude: '-98.4509709',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Alda, NE 68810, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:42:39',
    SlNo: '804',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:42:39 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:40:50',
    Latitude: '40.8232488',
    Longitude: '-98.4072035',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Grand Island, NE 68803, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:40:50',
    SlNo: '805',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:40:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:38:38',
    Latitude: '40.8231909',
    Longitude: '-98.361304',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Doniphan, NE 68832, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:38:38',
    SlNo: '806',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:38:38 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:36:40',
    Latitude: '40.8217138',
    Longitude: '-98.3150168',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Doniphan, NE 68832, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:36:40',
    SlNo: '807',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:36:40 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:34:37',
    Latitude: '40.8212209',
    Longitude: '-98.2675164',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Doniphan, NE 68832, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:34:37',
    SlNo: '808',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:34:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:32:37',
    Latitude: '40.8213486',
    Longitude: '-98.2203587',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Phillips, NE 68865, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:32:37',
    SlNo: '809',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:32:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:30:37',
    Latitude: '40.8222695',
    Longitude: '-98.1733086',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Aurora, NE 68818, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:30:37',
    SlNo: '810',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:30:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:28:36',
    Latitude: '40.8221996',
    Longitude: '-98.1260144',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Aurora, NE 68818, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:28:36',
    SlNo: '811',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:28:36 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:26:35',
    Latitude: '40.8220873',
    Longitude: '-98.0785365',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Aurora, NE 68818, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:26:35',
    SlNo: '812',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:26:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:24:35',
    Latitude: '40.8220068',
    Longitude: '-98.0314149',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Aurora, NE 68818, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:24:35',
    SlNo: '813',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:24:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:22:35',
    Latitude: '40.8222383',
    Longitude: '-97.9850525',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Aurora, NE 68818, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:22:35',
    SlNo: '814',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:22:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:20:34',
    Latitude: '40.8219971',
    Longitude: '-97.9377358',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Hampton, NE 68843, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:20:34',
    SlNo: '815',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:20:34 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:18:33',
    Latitude: '40.8212168',
    Longitude: '-97.8904541',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Hampton, NE 68843, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:18:33',
    SlNo: '816',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:18:33 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:16:33',
    Latitude: '40.8212491',
    Longitude: '-97.8434867',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Henderson, NE 68371, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:16:33',
    SlNo: '817',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:16:33 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:14:33',
    Latitude: '40.8212833',
    Longitude: '-97.7965568',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Henderson, NE 68371, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:14:33',
    SlNo: '818',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:14:33 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:12:32',
    Latitude: '40.8220779',
    Longitude: '-97.7490261',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Henderson, NE 68371, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:12:32',
    SlNo: '819',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:12:32 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:10:34',
    Latitude: '40.8214859',
    Longitude: '-97.7018956',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, York, NE 68467, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:10:34',
    SlNo: '820',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:10:34 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:08:31',
    Latitude: '40.8213257',
    Longitude: '-97.655054',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, York, NE 68467, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:08:31',
    SlNo: '821',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:08:31 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:06:31',
    Latitude: '40.8213206',
    Longitude: '-97.6079132',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, York, NE 68467, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:06:31',
    SlNo: '822',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:06:31 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:04:30',
    Latitude: '40.8221518',
    Longitude: '-97.5606348',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, York, NE 68467, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:04:30',
    SlNo: '823',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:04:30 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:02:29',
    Latitude: '40.8220441',
    Longitude: '-97.5130368',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, York, NE 68467, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:02:29',
    SlNo: '824',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:02:29 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 16:00:28',
    Latitude: '40.8219644',
    Longitude: '-97.4655059',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, York, NE 68467, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '16:00:28',
    SlNo: '825',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 04:00:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:58:42',
    Latitude: '40.8214563',
    Longitude: '-97.4185717',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Waco, NE 68460, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:58:42',
    SlNo: '826',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:58:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:56:26',
    Latitude: '40.821537',
    Longitude: '-97.3714058',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Waco, NE 68460, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:56:26',
    SlNo: '827',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:56:26 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:54:25',
    Latitude: '40.8216332',
    Longitude: '-97.3280834',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4438-4478 I-80, Beaver Crossing, NE 68313, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:54:25',
    SlNo: '828',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:54:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:52:25',
    Latitude: '40.8222486',
    Longitude: '-97.2829418',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Beaver Crossing, NE 68313, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:52:25',
    SlNo: '829',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:52:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:50:24',
    Latitude: '40.8225853',
    Longitude: '-97.2361964',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Seward, NE 68434, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:50:24',
    SlNo: '830',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:50:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:48:24',
    Latitude: '40.8225462',
    Longitude: '-97.1891537',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Seward, NE 68434, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:48:24',
    SlNo: '831',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:48:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:44:24',
    Latitude: '40.8222602',
    Longitude: '-97.0974922',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Seward, NE 68434, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:44:24',
    SlNo: '832',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:44:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:42:33',
    Latitude: '40.821885',
    Longitude: '-97.0548024',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Milford, NE 68405, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:42:33',
    SlNo: '833',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:42:33 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:40:22',
    Latitude: '40.8211124',
    Longitude: '-97.0085468',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Milford, NE 68405, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:40:22',
    SlNo: '834',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:40:22 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:38:21',
    Latitude: '40.8215182',
    Longitude: '-96.9622182',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1728-1864 I-80, Pleasant Dale, NE 68423, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:38:21',
    SlNo: '835',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:38:21 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:36:20',
    Latitude: '40.8207298',
    Longitude: '-96.9158047',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Pleasant Dale, NE 68423, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:36:20',
    SlNo: '836',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:36:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:34:19',
    Latitude: '40.8204777',
    Longitude: '-96.8684294',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Lincoln, NE 68528, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:34:19',
    SlNo: '837',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:34:19 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:32:19',
    Latitude: '40.8216425',
    Longitude: '-96.8226271',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Lincoln, NE 68528, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:32:19',
    SlNo: '838',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:32:19 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:30:18',
    Latitude: '40.8168605',
    Longitude: '-96.7816758',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Lincoln, NE 68528, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:30:18',
    SlNo: '839',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:30:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:28:18',
    Latitude: '40.8278384',
    Longitude: '-96.7485742',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '383 W Lakeshore Dr, Lincoln, NE 68528, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:28:18',
    SlNo: '840',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:28:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:26:19',
    Latitude: '40.8560183',
    Longitude: '-96.722795',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4600 Innovation Dr, Lincoln, NE 68521, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:26:19',
    SlNo: '841',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:26:19 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:24:17',
    Latitude: '40.8785123',
    Longitude: '-96.6911852',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2140 Wildcat Dr, Lincoln, NE 68521, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:24:17',
    SlNo: '842',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:24:17 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:22:16',
    Latitude: '40.8970114',
    Longitude: '-96.6556179',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-77, Lincoln, NE 68514, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:22:16',
    SlNo: '843',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:22:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:20:21',
    Latitude: '40.8970925',
    Longitude: '-96.6081846',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Lincoln, NE 68517, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:20:21',
    SlNo: '844',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:20:21 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:16:13',
    Latitude: '40.9050902',
    Longitude: '-96.5168298',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9810 N 148th St, Waverly, NE 68462, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:16:13',
    SlNo: '845',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:16:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:14:13',
    Latitude: '40.9170996',
    Longitude: '-96.4735622',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Waverly, NE 68462, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:14:13',
    SlNo: '846',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:14:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:12:12',
    Latitude: '40.9366581',
    Longitude: '-96.4343779',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Greenwood, NE 68366, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:12:12',
    SlNo: '847',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:12:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:10:12',
    Latitude: '40.9626833',
    Longitude: '-96.4029655',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Greenwood, NE 68366, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:10:12',
    SlNo: '848',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:10:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:08:11',
    Latitude: '40.9866939',
    Longitude: '-96.3681356',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Greenwood, NE 68366, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:08:11',
    SlNo: '849',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:08:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:06:10',
    Latitude: '41.0022305',
    Longitude: '-96.3249596',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Ashland, NE 68003, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:06:10',
    SlNo: '850',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:06:10 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:04:09',
    Latitude: '41.0286247',
    Longitude: '-96.2955198',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I80 R And R Rd, Gretna, NE 68028, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:04:09',
    SlNo: '851',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:04:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:02:09',
    Latitude: '41.0610677',
    Longitude: '-96.2944246',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gretna, NE 68028, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:02:09',
    SlNo: '852',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:02:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 15:00:08',
    Latitude: '41.0882774',
    Longitude: '-96.2663596',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gretna, NE 68028, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '15:00:08',
    SlNo: '853',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 03:00:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:58:08',
    Latitude: '41.1062546',
    Longitude: '-96.2305693',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Gretna, NE 68028, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:58:08',
    SlNo: '854',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:58:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:54:07',
    Latitude: '41.1438504',
    Longitude: '-96.1615099',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11299 Wickersham Blvd, Gretna, NE 68028, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:54:07',
    SlNo: '855',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:54:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:52:06',
    Latitude: '41.1668236',
    Longitude: '-96.1319306',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9201 S 144th St, Omaha, NE 68138, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:52:06',
    SlNo: '856',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:52:06 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:50:05',
    Latitude: '41.1893969',
    Longitude: '-96.1030661',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, La Vista, NE 68128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:50:05',
    SlNo: '857',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:50:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:48:04',
    Latitude: '41.2195655',
    Longitude: '-96.0914098',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11550 I Street Frontage Rd, Omaha, NE 68137, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:48:04',
    SlNo: '858',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:48:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:46:04',
    Latitude: '41.2223693',
    Longitude: '-96.0548047',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '9029 Nina St, Omaha, NE 68124, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:46:04',
    SlNo: '859',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:46:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:44:03',
    Latitude: '41.2238005',
    Longitude: '-96.012032',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3805-1/2 S 66th St, Omaha, NE 68106, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:44:03',
    SlNo: '860',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:44:03 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:42:02',
    Latitude: '41.2260609',
    Longitude: '-95.9674113',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3649 Grover St, Omaha, NE 68105, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:42:02',
    SlNo: '861',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:42:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:40:01',
    Latitude: '41.2303654',
    Longitude: '-95.9277046',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3017 S 9th St, Omaha, NE 68108, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:40:01',
    SlNo: '862',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:40:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:38:00',
    Latitude: '41.2324678',
    Longitude: '-95.8830576',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Council Bluffs, IA 51501, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:38:00',
    SlNo: '863',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:38:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:35:59',
    Latitude: '41.2319869',
    Longitude: '-95.8375085',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Council Bluffs, IA 51501, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:35:59',
    SlNo: '864',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:35:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:33:58',
    Latitude: '41.2547035',
    Longitude: '-95.808178',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '401 Valley View Dr, Council Bluffs, IA 51503, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:33:58',
    SlNo: '865',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:33:58 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:31:57',
    Latitude: '41.2839735',
    Longitude: '-95.7822659',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Council Bluffs, IA 51503, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:31:57',
    SlNo: '866',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:31:57 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:29:57',
    Latitude: '41.3141437',
    Longitude: '-95.7599296',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Council Bluffs, IA 51503, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:29:57',
    SlNo: '867',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:29:57 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:27:56',
    Latitude: '41.34145',
    Longitude: '-95.7296564',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Underwood, IA 51576, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:27:56',
    SlNo: '868',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:27:56 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:25:56',
    Latitude: '41.3602213',
    Longitude: '-95.6921895',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Underwood, IA 51576, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:25:56',
    SlNo: '869',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:25:56 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:23:55',
    Latitude: '41.3907623',
    Longitude: '-95.6680354',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '27170 Mahogany Rd, Underwood, IA 51576, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:23:55',
    SlNo: '870',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:23:55 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:21:55',
    Latitude: '41.4192943',
    Longitude: '-95.6418166',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Underwood, IA 51576, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:21:55',
    SlNo: '871',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:21:55 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:19:54',
    Latitude: '41.4434552',
    Longitude: '-95.6068242',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '30297 Sycamore Rd, Neola, IA 51559, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:19:54',
    SlNo: '872',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:19:54 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:17:54',
    Latitude: '41.4743618',
    Longitude: '-95.5909882',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '31675 Thor Ln, Minden, IA 51553, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:17:54',
    SlNo: '873',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:17:54 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:15:53',
    Latitude: '41.4989607',
    Longitude: '-95.5730717',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Minden, IA 51553, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:15:53',
    SlNo: '874',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:15:53 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:13:52',
    Latitude: '41.4997141',
    Longitude: '-95.5307847',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Minden, IA 51553, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:13:52',
    SlNo: '875',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:13:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:11:52',
    Latitude: '41.4991112',
    Longitude: '-95.4871087',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:11:52',
    SlNo: '876',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:11:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:09:51',
    Latitude: '41.4996921',
    Longitude: '-95.4519701',
    Speed: '8.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Agri-Symbol Park, 12 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:09:51',
    SlNo: '877',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:09:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:07:51',
    Latitude: '41.495449',
    Longitude: '-95.4531157',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:07:51',
    SlNo: '878',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:07:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:05:51',
    Latitude: '41.4969143',
    Longitude: '-95.4527392',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:05:51',
    SlNo: '879',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:05:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:03:52',
    Latitude: '41.4969135',
    Longitude: '-95.4527377',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:03:52',
    SlNo: '880',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:03:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 14:01:50',
    Latitude: '41.4969171',
    Longitude: '-95.4527368',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '14:01:50',
    SlNo: '881',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 02:01:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:59:50',
    Latitude: '41.4969207',
    Longitude: '-95.4527084',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:59:50',
    SlNo: '882',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:59:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:57:50',
    Latitude: '41.4969231',
    Longitude: '-95.4526968',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:57:50',
    SlNo: '883',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:57:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:55:49',
    Latitude: '41.4969216',
    Longitude: '-95.4527266',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:55:49',
    SlNo: '884',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:55:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:53:49',
    Latitude: '41.4966737',
    Longitude: '-95.4527535',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:53:49',
    SlNo: '885',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:53:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:51:52',
    Latitude: '41.4966531',
    Longitude: '-95.4527583',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:51:52',
    SlNo: '886',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:51:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:49:48',
    Latitude: '41.496629',
    Longitude: '-95.4526616',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:49:48',
    SlNo: '887',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:49:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:47:48',
    Latitude: '41.4966277',
    Longitude: '-95.4526588',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:47:48',
    SlNo: '888',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:47:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:45:48',
    Latitude: '41.4966265',
    Longitude: '-95.4527042',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:45:48',
    SlNo: '889',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:45:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:43:47',
    Latitude: '41.4966038',
    Longitude: '-95.4527076',
    Speed: '6.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:43:47',
    SlNo: '890',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:43:47 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:41:46',
    Latitude: '41.4995893',
    Longitude: '-95.4511964',
    Speed: '32.93 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Agri-Symbol Park, 12 East St, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:41:46',
    SlNo: '891',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:41:46 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:39:45',
    Latitude: '41.4983802',
    Longitude: '-95.4299293',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Shelby, IA 51570, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:39:45',
    SlNo: '892',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:39:45 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:37:45',
    Latitude: '41.4977469',
    Longitude: '-95.3823767',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Avoca, IA 51521, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:37:45',
    SlNo: '893',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:37:45 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:35:44',
    Latitude: '41.4976975',
    Longitude: '-95.3345435',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Avoca, IA 51521, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:35:44',
    SlNo: '894',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:35:44 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:33:44',
    Latitude: '41.4987427',
    Longitude: '-95.2879874',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Avoca, IA 51521, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:33:44',
    SlNo: '895',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:33:44 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:31:43',
    Latitude: '41.497465',
    Longitude: '-95.2419096',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Avoca, IA 51521, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:31:43',
    SlNo: '896',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:31:43 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:29:43',
    Latitude: '41.4975091',
    Longitude: '-95.1949356',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Walnut, IA 51577, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:29:43',
    SlNo: '897',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:29:43 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:27:44',
    Latitude: '41.4984243',
    Longitude: '-95.1496526',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Marne, IA 51552, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:27:44',
    SlNo: '898',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:27:44 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:25:42',
    Latitude: '41.4980808',
    Longitude: '-95.1017151',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Marne, IA 51552, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:25:42',
    SlNo: '899',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:25:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:23:41',
    Latitude: '41.4969134',
    Longitude: '-95.0539858',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Atlantic, IA 50022, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:23:41',
    SlNo: '900',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:23:41 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:21:40',
    Latitude: '41.4974304',
    Longitude: '-95.0066378',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Atlantic, IA 50022, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:21:40',
    SlNo: '901',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:21:40 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:19:40',
    Latitude: '41.4972543',
    Longitude: '-94.9604265',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Atlantic, IA 50022, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:19:40',
    SlNo: '902',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:19:40 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:17:39',
    Latitude: '41.497304',
    Longitude: '-94.9118568',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '50506 670th St, Atlantic, IA 50022, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:17:39',
    SlNo: '903',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:17:39 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:15:39',
    Latitude: '41.4974528',
    Longitude: '-94.8668479',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '50640 690th St, Brayton, IA 50042, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:15:39',
    SlNo: '904',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:15:39 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:13:39',
    Latitude: '41.4971673',
    Longitude: '-94.8181424',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Exira, IA 50076, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:13:39',
    SlNo: '905',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:13:39 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:11:38',
    Latitude: '41.4968803',
    Longitude: '-94.7714453',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Anita, IA 50020, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:11:38',
    SlNo: '906',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:11:38 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:09:38',
    Latitude: '41.496875',
    Longitude: '-94.7234165',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Anita, IA 50020, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:09:38',
    SlNo: '907',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:09:38 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:07:42',
    Latitude: '41.4937309',
    Longitude: '-94.6777276',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Anita, IA 50020, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:07:42',
    SlNo: '908',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:07:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:05:38',
    Latitude: '41.4933021',
    Longitude: '-94.6307377',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Adair, IA 50002, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:05:38',
    SlNo: '909',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:05:38 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:03:36',
    Latitude: '41.4956644',
    Longitude: '-94.5858727',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1045-1047 Gibbon Ave, Adair, IA 50002, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:03:36',
    SlNo: '910',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:03:36 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 13:01:35',
    Latitude: '41.4965439',
    Longitude: '-94.5382554',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Casey, IA 50048, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '13:01:35',
    SlNo: '911',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 01:01:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:59:35',
    Latitude: '41.496184',
    Longitude: '-94.4918978',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Casey, IA 50048, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:59:35',
    SlNo: '912',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:59:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:57:34',
    Latitude: '41.496019',
    Longitude: '-94.4441203',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Menlo, IA 50164, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:57:34',
    SlNo: '913',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:57:34 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:55:33',
    Latitude: '41.496093',
    Longitude: '-94.3968641',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Stuart, IA 50250, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:55:33',
    SlNo: '914',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:55:33 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:53:32',
    Latitude: '41.4931272',
    Longitude: '-94.3496359',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Stuart, IA 50250, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:53:32',
    SlNo: '915',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:53:32 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:42:59',
    Latitude: '41.1110638',
    Longitude: '-115.034861',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:42:59',
    SlNo: '176',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:42:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:40:58',
    Latitude: '41.1058739',
    Longitude: '-114.9920719',
    Speed: '52.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:40:58',
    SlNo: '177',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:40:58 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:38:57',
    Latitude: '41.1004636',
    Longitude: '-114.9594255',
    Speed: '4.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1222 6th St, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:38:57',
    SlNo: '178',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:38:57 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:36:57',
    Latitude: '41.098201',
    Longitude: '-114.9593662',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:36:57',
    SlNo: '179',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:36:57 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:34:56',
    Latitude: '41.0984554',
    Longitude: '-114.9606627',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:34:56',
    SlNo: '180',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:34:56 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:32:55',
    Latitude: '41.0984551',
    Longitude: '-114.9606625',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:32:55',
    SlNo: '181',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:32:55 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:30:54',
    Latitude: '41.0984554',
    Longitude: '-114.960662',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:30:54',
    SlNo: '182',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:30:54 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:28:53',
    Latitude: '41.0984573',
    Longitude: '-114.9606642',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:28:53',
    SlNo: '183',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:28:53 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:26:52',
    Latitude: '41.0984599',
    Longitude: '-114.9607379',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:26:52',
    SlNo: '184',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:26:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:24:52',
    Latitude: '41.0984622',
    Longitude: '-114.9607375',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:24:52',
    SlNo: '185',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:24:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:22:51',
    Latitude: '41.0984353',
    Longitude: '-114.9607062',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:22:51',
    SlNo: '186',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:22:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:20:51',
    Latitude: '41.0984606',
    Longitude: '-114.9606906',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:20:51',
    SlNo: '187',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:20:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:18:51',
    Latitude: '41.0984607',
    Longitude: '-114.9606877',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:18:51',
    SlNo: '188',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:18:51 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:16:50',
    Latitude: '41.0984614',
    Longitude: '-114.9606886',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:16:50',
    SlNo: '189',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:16:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:14:49',
    Latitude: '41.0984782',
    Longitude: '-114.9607064',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:14:49',
    SlNo: '190',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:14:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:12:49',
    Latitude: '41.0984787',
    Longitude: '-114.9607054',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:12:49',
    SlNo: '191',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:12:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:10:48',
    Latitude: '41.0984769',
    Longitude: '-114.9607119',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:10:48',
    SlNo: '192',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:10:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:08:48',
    Latitude: '41.0984846',
    Longitude: '-114.9606915',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:08:48',
    SlNo: '193',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:08:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:06:48',
    Latitude: '41.0984759',
    Longitude: '-114.9607302',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:06:48',
    SlNo: '194',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:06:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:04:47',
    Latitude: '41.0984387',
    Longitude: '-114.960717',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:04:47',
    SlNo: '195',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:04:47 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:02:47',
    Latitude: '41.0984323',
    Longitude: '-114.9607082',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:02:47',
    SlNo: '196',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:02:47 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 14:00:46',
    Latitude: '41.0984621',
    Longitude: '-114.960704',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '14:00:46',
    SlNo: '197',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:00:46 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:58:46',
    Latitude: '41.0984614',
    Longitude: '-114.9607033',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:58:46',
    SlNo: '198',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:58:46 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:56:45',
    Latitude: '41.0984613',
    Longitude: '-114.9607042',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:56:45',
    SlNo: '199',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:56:45 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:54:45',
    Latitude: '41.098462',
    Longitude: '-114.9607047',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:54:45',
    SlNo: '200',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:54:45 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:52:45',
    Latitude: '41.0984618',
    Longitude: '-114.9607023',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:52:45',
    SlNo: '201',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:52:45 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:50:44',
    Latitude: '41.0984627',
    Longitude: '-114.9607027',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:50:44',
    SlNo: '202',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:50:44 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:48:43',
    Latitude: '41.0984614',
    Longitude: '-114.9607049',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:48:43',
    SlNo: '203',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:48:43 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:46:42',
    Latitude: '41.0984618',
    Longitude: '-114.9607048',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:46:42',
    SlNo: '204',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:46:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:44:41',
    Latitude: '41.098462',
    Longitude: '-114.9607041',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:44:41',
    SlNo: '205',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:44:41 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:42:40',
    Latitude: '41.098462',
    Longitude: '-114.9607045',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:42:40',
    SlNo: '206',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:42:40 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:40:39',
    Latitude: '41.0984623',
    Longitude: '-114.9607023',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:40:39',
    SlNo: '207',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:40:39 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:38:42',
    Latitude: '41.0984627',
    Longitude: '-114.9607044',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:38:42',
    SlNo: '208',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:38:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:36:37',
    Latitude: '41.0984639',
    Longitude: '-114.9607039',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:36:37',
    SlNo: '209',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:36:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:34:36',
    Latitude: '41.0984589',
    Longitude: '-114.9607042',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:34:36',
    SlNo: '210',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:34:36 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:32:35',
    Latitude: '41.0984607',
    Longitude: '-114.9607074',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1091 Cobre, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:32:35',
    SlNo: '211',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:32:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:30:35',
    Latitude: '41.0981254',
    Longitude: '-114.9589084',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '156 Great Basin Hwy, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:30:35',
    SlNo: '212',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:30:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:28:35',
    Latitude: '41.0983724',
    Longitude: '-114.9351416',
    Speed: '77.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:28:35',
    SlNo: '213',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:28:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:28:35',
    Latitude: '41.1050955',
    Longitude: '-114.886819',
    Speed: '76.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:28:35',
    SlNo: '214',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:28:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:22:34',
    Latitude: '41.112984',
    Longitude: '-114.7961365',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:22:34',
    SlNo: '215',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:22:34 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:20:34',
    Latitude: '41.1049435',
    Longitude: '-114.7536436',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:20:34',
    SlNo: '216',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:20:34 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:12:30',
    Latitude: '41.077464',
    Longitude: '-114.5814999',
    Speed: '45.98 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:12:30',
    SlNo: '217',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:12:30 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:10:29',
    Latitude: '41.0736753',
    Longitude: '-114.5524138',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:10:29',
    SlNo: '218',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:10:29 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:08:28',
    Latitude: '41.0646594',
    Longitude: '-114.5317437',
    Speed: '52.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:08:28',
    SlNo: '219',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:08:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:06:28',
    Latitude: '41.04686',
    Longitude: '-114.5083874',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Wells, NV 89835, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:06:28',
    SlNo: '220',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:06:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:04:27',
    Latitude: '41.0218678',
    Longitude: '-114.4773957',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Montello, NV 89830, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:04:27',
    SlNo: '221',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:04:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:02:27',
    Latitude: '40.9981077',
    Longitude: '-114.4422891',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Montello, NV 89830, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:02:27',
    SlNo: '222',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:02:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 13:00:26',
    Latitude: '40.974216',
    Longitude: '-114.4067224',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Montello, NV 89830, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '13:00:26',
    SlNo: '223',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:00:26 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:58:25',
    Latitude: '40.9500865',
    Longitude: '-114.3711229',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Montello, NV 89830, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:58:25',
    SlNo: '224',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:58:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:56:24',
    Latitude: '40.9287075',
    Longitude: '-114.333079',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Montello, NV 89830, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:56:24',
    SlNo: '225',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:56:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:54:24',
    Latitude: '40.9111023',
    Longitude: '-114.3118584',
    Speed: '44.12 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Montello, NV 89830, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:54:24',
    SlNo: '226',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:54:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:52:24',
    Latitude: '40.9014357',
    Longitude: '-114.2869141',
    Speed: '42.25 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, Montello, NV 89830, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:52:24',
    SlNo: '227',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:52:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:50:23',
    Latitude: '40.8938288',
    Longitude: '-114.2617489',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, West Wendover, NV 89883, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:50:23',
    SlNo: '228',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:50:23 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:48:22',
    Latitude: '40.8725231',
    Longitude: '-114.2377928',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, West Wendover, NV 89883, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:48:22',
    SlNo: '229',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:48:22 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:46:21',
    Latitude: '40.8445163',
    Longitude: '-114.2100223',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, West Wendover, NV 89883, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:46:21',
    SlNo: '230',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:46:21 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:44:21',
    Latitude: '40.8160605',
    Longitude: '-114.1816773',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, West Wendover, NV 89883, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:44:21',
    SlNo: '231',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:44:21 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:42:20',
    Latitude: '40.7871214',
    Longitude: '-114.1530506',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, West Wendover, NV 89883, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:42:20',
    SlNo: '232',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:42:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:40:21',
    Latitude: '40.7599294',
    Longitude: '-114.1227908',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, West Wendover, NV 89883, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:40:21',
    SlNo: '233',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:40:21 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:38:19',
    Latitude: '40.7449786',
    Longitude: '-114.0942462',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Purple Heart Trail, West Wendover, NV 89883, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:38:19',
    SlNo: '234',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:38:19 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:36:19',
    Latitude: '40.741233',
    Longitude: '-114.0617504',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, West Wendover, NV 89883, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:36:19',
    SlNo: '235',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:36:19 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:34:18',
    Latitude: '40.745403',
    Longitude: '-114.0202261',
    Speed: '47.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:34:18',
    SlNo: '236',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:34:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:32:17',
    Latitude: '40.7453308',
    Longitude: '-113.9896394',
    Speed: '15.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:32:17',
    SlNo: '237',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:32:17 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:30:16',
    Latitude: '40.7434259',
    Longitude: '-113.9802408',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:30:16',
    SlNo: '238',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:30:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:28:15',
    Latitude: '40.7409899',
    Longitude: '-113.9341131',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:28:15',
    SlNo: '239',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:28:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:26:14',
    Latitude: '40.7400838',
    Longitude: '-113.8866597',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:26:14',
    SlNo: '240',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:26:14 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:24:14',
    Latitude: '40.7391535',
    Longitude: '-113.839578',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:24:14',
    SlNo: '241',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:24:14 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:22:13',
    Latitude: '40.7381953',
    Longitude: '-113.7921523',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:22:13',
    SlNo: '242',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:22:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:20:12',
    Latitude: '40.7372108',
    Longitude: '-113.7446829',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:20:12',
    SlNo: '243',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:20:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:18:12',
    Latitude: '40.7362386',
    Longitude: '-113.6976514',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:18:12',
    SlNo: '244',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:18:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:16:11',
    Latitude: '40.7352137',
    Longitude: '-113.6501774',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:16:11',
    SlNo: '245',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:16:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:14:11',
    Latitude: '40.7341599',
    Longitude: '-113.6031078',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:14:11',
    SlNo: '246',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:14:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:12:11',
    Latitude: '40.733066',
    Longitude: '-113.5560741',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:12:11',
    SlNo: '247',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:12:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:10:10',
    Latitude: '40.7320009',
    Longitude: '-113.5086041',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:10:10',
    SlNo: '248',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:10:10 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:08:09',
    Latitude: '40.730931',
    Longitude: '-113.4611884',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:08:09',
    SlNo: '249',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:08:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:06:09',
    Latitude: '40.7298176',
    Longitude: '-113.4141545',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:06:09',
    SlNo: '250',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:06:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:04:09',
    Latitude: '40.7288768',
    Longitude: '-113.3671175',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:04:09',
    SlNo: '251',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:04:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:02:08',
    Latitude: '40.7283489',
    Longitude: '-113.3196753',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:02:08',
    SlNo: '252',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:02:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:00:08',
    Latitude: '40.7275913',
    Longitude: '-113.2791341',
    Speed: '4.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '12:00:08',
    SlNo: '253',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 12:00:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:58:07',
    Latitude: '40.7276009',
    Longitude: '-113.2758854',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:58:07',
    SlNo: '254',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:58:07 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:56:06',
    Latitude: '40.7270609',
    Longitude: '-113.2321253',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wendover, UT 84083, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:56:06',
    SlNo: '255',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:56:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:54:06',
    Latitude: '40.7264997',
    Longitude: '-113.1850847',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Utah, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:54:06',
    SlNo: '256',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:54:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:52:06',
    Latitude: '40.7263532',
    Longitude: '-113.1380966',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Utah, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:52:06',
    SlNo: '257',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:52:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:50:05',
    Latitude: '40.7381176',
    Longitude: '-113.0932644',
    Speed: '76.43 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:50:05',
    SlNo: '258',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:50:05 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:48:05',
    Latitude: '40.7506419',
    Longitude: '-113.0477625',
    Speed: '83.26 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:48:05',
    SlNo: '259',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:48:05 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:46:05',
    Latitude: '40.764313',
    Longitude: '-112.9978712',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:46:05',
    SlNo: '260',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:46:05 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:44:05',
    Latitude: '40.7852777',
    Longitude: '-112.9623831',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:44:05',
    SlNo: '261',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:44:05 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:42:04',
    Latitude: '40.8108324',
    Longitude: '-112.9289359',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:42:04',
    SlNo: '262',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:42:04 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:40:04',
    Latitude: '40.8211408',
    Longitude: '-112.8877285',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:40:04',
    SlNo: '263',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:40:04 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:38:04',
    Latitude: '40.8015218',
    Longitude: '-112.8517841',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:38:04',
    SlNo: '264',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:38:04 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:36:03',
    Latitude: '40.7804879',
    Longitude: '-112.813397',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:36:03',
    SlNo: '265',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:36:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:34:02',
    Latitude: '40.7595287',
    Longitude: '-112.77483',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:34:02',
    SlNo: '266',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:34:02 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:32:01',
    Latitude: '40.7536179',
    Longitude: '-112.7282801',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:32:01',
    SlNo: '267',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:32:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:30:01',
    Latitude: '40.7489723',
    Longitude: '-112.6815675',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:30:01',
    SlNo: '268',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:30:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:28:00',
    Latitude: '40.7439671',
    Longitude: '-112.6347578',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Dugway, UT 84022, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:28:00',
    SlNo: '269',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:28:00 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:26:00',
    Latitude: '40.7311509',
    Longitude: '-112.5910059',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Dugway, UT 84022, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:26:00',
    SlNo: '270',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:26:00 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:23:59',
    Latitude: '40.7173329',
    Longitude: '-112.5472299',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Dugway, UT 84022, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:23:59',
    SlNo: '271',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:23:59 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:21:59',
    Latitude: '40.7039086',
    Longitude: '-112.5042185',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:21:59',
    SlNo: '272',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:21:59 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:19:59',
    Latitude: '40.6906359',
    Longitude: '-112.4609139',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:19:59',
    SlNo: '273',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:19:59 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:17:58',
    Latitude: '40.6798301',
    Longitude: '-112.4160725',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:17:58',
    SlNo: '274',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:17:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:15:58',
    Latitude: '40.6671819',
    Longitude: '-112.372045',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Grantsville, UT 84029, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:15:58',
    SlNo: '275',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:15:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:13:58',
    Latitude: '40.6633624',
    Longitude: '-112.3259006',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Tooele, UT 84074, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:13:58',
    SlNo: '276',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:13:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:11:57',
    Latitude: '40.6776017',
    Longitude: '-112.2830648',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Tooele, UT 84074, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:11:57',
    SlNo: '277',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:11:57 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:09:56',
    Latitude: '40.705629',
    Longitude: '-112.255107',
    Speed: '59.03 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Tooele, UT 84074, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:09:56',
    SlNo: '278',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:09:56 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:07:56',
    Latitude: '40.7228232',
    Longitude: '-112.2250677',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Tooele, UT 84074, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:07:56',
    SlNo: '279',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:07:56 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:05:55',
    Latitude: '40.7276858',
    Longitude: '-112.1852768',
    Speed: '41.63 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'UT-201, Tooele, UT 84074, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:05:55',
    SlNo: '280',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:05:55 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:03:55',
    Latitude: '40.7238249',
    Longitude: '-112.1592992',
    Speed: '59.03 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2400 S, Magna, UT 84044, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:03:55',
    SlNo: '281',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:03:55 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 11:01:55',
    Latitude: '40.7165268',
    Longitude: '-112.1229904',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1278-1246 2400 S, Magna, UT 84044, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '11:01:55',
    SlNo: '282',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 11:01:55 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:59:53',
    Latitude: '40.7183938',
    Longitude: '-112.0821761',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2375 S 8000 W, Magna, UT 84044, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:59:53',
    SlNo: '283',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:59:53 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:57:53',
    Latitude: '40.7212616',
    Longitude: '-112.0489973',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6981 2100 S, West Valley City, UT 84128, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:57:53',
    SlNo: '284',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:57:53 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:55:53',
    Latitude: '40.725925',
    Longitude: '-112.0090017',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4970 2100 S, Salt Lake City, UT 84104, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:55:53',
    SlNo: '285',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:55:53 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:53:52',
    Latitude: '40.7267693',
    Longitude: '-111.9669623',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'UT-201, Salt Lake City, UT 84120, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:53:52',
    SlNo: '286',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:53:52 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:51:51',
    Latitude: '40.7243354',
    Longitude: '-111.930518',
    Speed: '45.36 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1475 2100 S, Salt Lake City, UT 84119, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:51:51',
    SlNo: '287',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:51:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:49:50',
    Latitude: '40.7186805',
    Longitude: '-111.9025537',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '355 W Bearcat Dr, South Salt Lake, UT 84115, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:49:50',
    SlNo: '288',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:49:50 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:45:49',
    Latitude: '40.71632',
    Longitude: '-111.8330817',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2540 S 2000 E, Salt Lake City, UT 84109, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:45:49',
    SlNo: '289',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:45:49 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:43:48',
    Latitude: '40.7117849',
    Longitude: '-111.8001302',
    Speed: '52.20 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Salt Lake City, UT 84109, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:43:48',
    SlNo: '290',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:43:48 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:41:48',
    Latitude: '40.7249068',
    Longitude: '-111.7719686',
    Speed: '52.82 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4551 W 800 S, Salt Lake City, UT 84108, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:41:48',
    SlNo: '291',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:41:48 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:39:47',
    Latitude: '40.7383973',
    Longitude: '-111.7432954',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Salt Lake City, UT 84108, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:39:47',
    SlNo: '292',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:39:47 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:37:46',
    Latitude: '40.7518524',
    Longitude: '-111.7134957',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Salt Lake City, UT 84108, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:37:46',
    SlNo: '293',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:37:46 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:35:45',
    Latitude: '40.7432133',
    Longitude: '-111.6793014',
    Speed: '54.06 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Salt Lake City, UT 84109, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:35:45',
    SlNo: '294',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:35:45 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:33:45',
    Latitude: '40.7467952',
    Longitude: '-111.6451935',
    Speed: '49.71 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Park City, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:33:45',
    SlNo: '295',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:33:45 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:31:45',
    Latitude: '40.7530584',
    Longitude: '-111.6145217',
    Speed: '32.93 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '185 Aspen Dr, Park City, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:31:45',
    SlNo: '296',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:31:45 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:29:45',
    Latitude: '40.7530652',
    Longitude: '-111.5936599',
    Speed: '50.33 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4275 Sunrise Dr, Park City, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:29:45',
    SlNo: '297',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:29:45 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:27:44',
    Latitude: '40.7454783',
    Longitude: '-111.5634353',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Kilby Rd & Engen Loop, Summit Park, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:27:44',
    SlNo: '298',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:27:44 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:25:46',
    Latitude: '40.724039',
    Longitude: '-111.5333353',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1035 Lincoln Ln, Park City, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:25:46',
    SlNo: '299',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:25:46 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:23:43',
    Latitude: '40.7333529',
    Longitude: '-111.494116',
    Speed: '53.44 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '7047 Pace Pl, Park City, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:23:43',
    SlNo: '300',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:23:43 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:22:01',
    Latitude: '40.7519328',
    Longitude: '-111.4707448',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Historic Union Pacific Rail Trail, Park City, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:22:01',
    SlNo: '301',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:22:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:19:42',
    Latitude: '40.775484',
    Longitude: '-111.468847',
    Speed: '50.33 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Park City, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:19:42',
    SlNo: '302',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:19:42 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:17:52',
    Latitude: '40.7944791',
    Longitude: '-111.4490377',
    Speed: '56.54 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Park City, UT 84098, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:17:52',
    SlNo: '303',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:17:52 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:15:41',
    Latitude: '40.808382',
    Longitude: '-111.4180277',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:15:41',
    SlNo: '304',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:15:41 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:13:40',
    Latitude: '40.8288751',
    Longitude: '-111.3914329',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1769 S Hoytsville Rd, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:13:40',
    SlNo: '305',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:13:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:11:39',
    Latitude: '40.8606998',
    Longitude: '-111.3877328',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:11:39',
    SlNo: '306',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:11:39 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:09:39',
    Latitude: '40.8955177',
    Longitude: '-111.3951844',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:09:39',
    SlNo: '307',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:09:39 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:07:49',
    Latitude: '40.9253578',
    Longitude: '-111.4116351',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:07:49',
    SlNo: '308',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:07:49 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:05:38',
    Latitude: '40.9520927',
    Longitude: '-111.4225248',
    Speed: '37.28 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:05:38',
    SlNo: '309',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:05:38 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:03:37',
    Latitude: '40.9659602',
    Longitude: '-111.4377531',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:03:37',
    SlNo: '310',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:03:37 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 10:01:40',
    Latitude: '40.9850355',
    Longitude: '-111.4134336',
    Speed: '15.53 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '10:01:40',
    SlNo: '311',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 10:01:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:59:35',
    Latitude: '40.9896672',
    Longitude: '-111.4057774',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:59:35',
    SlNo: '312',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:59:35 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:57:35',
    Latitude: '40.9897465',
    Longitude: '-111.4057847',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:57:35',
    SlNo: '313',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:57:35 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:55:34',
    Latitude: '40.9897547',
    Longitude: '-111.4057642',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:55:34',
    SlNo: '314',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:55:34 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:53:34',
    Latitude: '40.9897315',
    Longitude: '-111.405748',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:53:34',
    SlNo: '315',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:53:34 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:51:34',
    Latitude: '40.9897314',
    Longitude: '-111.405748',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:51:34',
    SlNo: '316',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:51:34 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:49:33',
    Latitude: '40.9897316',
    Longitude: '-111.4057487',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:49:33',
    SlNo: '317',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:49:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:47:33',
    Latitude: '40.9897326',
    Longitude: '-111.4057481',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:47:33',
    SlNo: '318',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:47:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:45:33',
    Latitude: '40.9897319',
    Longitude: '-111.4057479',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:45:33',
    SlNo: '319',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:45:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:43:33',
    Latitude: '40.989733',
    Longitude: '-111.4057479',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:43:33',
    SlNo: '320',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:43:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:41:33',
    Latitude: '40.9897346',
    Longitude: '-111.405729',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:41:33',
    SlNo: '321',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:41:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:39:32',
    Latitude: '40.9897268',
    Longitude: '-111.4057793',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:39:32',
    SlNo: '322',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:39:32 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:37:31',
    Latitude: '40.9897275',
    Longitude: '-111.4057789',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:37:31',
    SlNo: '323',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:37:31 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:35:30',
    Latitude: '40.9897263',
    Longitude: '-111.4057797',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:35:30',
    SlNo: '324',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:35:30 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:33:30',
    Latitude: '40.989727',
    Longitude: '-111.4057799',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:33:30',
    SlNo: '325',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:33:30 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:31:30',
    Latitude: '40.9897271',
    Longitude: '-111.4057795',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:31:30',
    SlNo: '326',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:31:30 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:29:43',
    Latitude: '40.9897262',
    Longitude: '-111.40578',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:29:43',
    SlNo: '327',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:29:43 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:27:29',
    Latitude: '40.9897273',
    Longitude: '-111.4057811',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:27:29',
    SlNo: '328',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:27:29 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:25:28',
    Latitude: '40.9897264',
    Longitude: '-111.4057803',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:25:28',
    SlNo: '329',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:25:28 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:23:28',
    Latitude: '40.9896921',
    Longitude: '-111.4057637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:23:28',
    SlNo: '330',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:23:28 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:21:27',
    Latitude: '40.9896927',
    Longitude: '-111.4057622',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:21:27',
    SlNo: '331',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:21:27 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:19:34',
    Latitude: '40.9896918',
    Longitude: '-111.4057638',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:19:34',
    SlNo: '332',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:19:34 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:17:27',
    Latitude: '40.9896911',
    Longitude: '-111.4057637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:17:27',
    SlNo: '333',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:17:27 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:15:25',
    Latitude: '40.9896908',
    Longitude: '-111.4057636',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:15:25',
    SlNo: '334',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:15:25 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:13:25',
    Latitude: '40.9896917',
    Longitude: '-111.4057622',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:13:25',
    SlNo: '335',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:13:25 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:11:28',
    Latitude: '40.9896938',
    Longitude: '-111.405762',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:11:28',
    SlNo: '336',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:11:28 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:09:23',
    Latitude: '40.9896935',
    Longitude: '-111.4057622',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:09:23',
    SlNo: '337',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:09:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:07:23',
    Latitude: '40.989692',
    Longitude: '-111.4057638',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:07:23',
    SlNo: '338',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:07:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:05:23',
    Latitude: '40.9896918',
    Longitude: '-111.4057627',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:05:23',
    SlNo: '339',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:05:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:03:22',
    Latitude: '40.9896923',
    Longitude: '-111.405762',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:03:22',
    SlNo: '340',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:03:22 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 09:01:22',
    Latitude: '40.9896931',
    Longitude: '-111.4057625',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '09:01:22',
    SlNo: '341',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 09:01:22 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:59:21',
    Latitude: '40.9896915',
    Longitude: '-111.4057615',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:59:21',
    SlNo: '342',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:59:21 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:57:20',
    Latitude: '40.9896929',
    Longitude: '-111.4057632',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:57:20',
    SlNo: '343',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:57:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:55:20',
    Latitude: '40.9896922',
    Longitude: '-111.4057647',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:55:20',
    SlNo: '344',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:55:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:53:20',
    Latitude: '40.9896922',
    Longitude: '-111.4057639',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:53:20',
    SlNo: '345',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:53:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:51:19',
    Latitude: '40.9896936',
    Longitude: '-111.4057636',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:51:19',
    SlNo: '346',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:51:19 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:49:18',
    Latitude: '40.9896937',
    Longitude: '-111.4057637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:49:18',
    SlNo: '347',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:49:18 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:47:17',
    Latitude: '40.989692',
    Longitude: '-111.4057624',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:47:17',
    SlNo: '348',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:47:17 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:45:20',
    Latitude: '40.9896931',
    Longitude: '-111.4057605',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:45:20',
    SlNo: '349',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:45:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:43:15',
    Latitude: '40.9896934',
    Longitude: '-111.4057604',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:43:15',
    SlNo: '350',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:43:15 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:41:14',
    Latitude: '40.9896942',
    Longitude: '-111.4057604',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:41:14',
    SlNo: '351',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:41:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:39:14',
    Latitude: '40.989694',
    Longitude: '-111.4057607',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:39:14',
    SlNo: '352',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:39:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:37:13',
    Latitude: '40.9896936',
    Longitude: '-111.4057623',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:37:13',
    SlNo: '353',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:37:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:35:13',
    Latitude: '40.9896942',
    Longitude: '-111.4057602',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:35:13',
    SlNo: '354',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:35:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:33:13',
    Latitude: '40.9896943',
    Longitude: '-111.4057637',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:33:13',
    SlNo: '355',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:33:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:31:13',
    Latitude: '40.9896969',
    Longitude: '-111.405761',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:31:13',
    SlNo: '356',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:31:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:29:13',
    Latitude: '40.9896962',
    Longitude: '-111.4057588',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:29:13',
    SlNo: '357',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:29:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:27:13',
    Latitude: '40.9896959',
    Longitude: '-111.4057482',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:27:13',
    SlNo: '358',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:27:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:51:32',
    Latitude: '41.4930683',
    Longitude: '-94.3019037',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Stuart, IA 50250, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:51:32',
    SlNo: '916',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:51:32 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:49:31',
    Latitude: '41.4931938',
    Longitude: '-94.2552996',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Dexter, IA 50070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:49:31',
    SlNo: '917',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:49:31 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:47:31',
    Latitude: '41.5005277',
    Longitude: '-94.2129127',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Dexter, IA 50070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:47:31',
    SlNo: '918',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:47:31 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:45:31',
    Latitude: '41.5180001',
    Longitude: '-94.1748657',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '15499 365th Ct, Dexter, IA 50070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:45:31',
    SlNo: '919',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:45:31 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:43:30',
    Latitude: '41.5177812',
    Longitude: '-94.127607',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Earlham, IA 50072, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:43:30',
    SlNo: '920',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:43:30 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:41:29',
    Latitude: '41.5195985',
    Longitude: '-94.0794412',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '35913 Knox Ave, Earlham, IA 50072, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:41:29',
    SlNo: '921',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:41:29 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:39:29',
    Latitude: '41.5344725',
    Longitude: '-94.0370183',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '23152 347th St, De Soto, IA 50069, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:39:29',
    SlNo: '922',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:39:29 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:37:29',
    Latitude: '41.5425443',
    Longitude: '-93.99222',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Van Meter, IA 50261, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:37:29',
    SlNo: '923',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:37:29 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:35:28',
    Latitude: '41.5501957',
    Longitude: '-93.9449243',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '27516 337th Ct, Adel, IA 50003, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:35:28',
    SlNo: '924',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:35:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:33:28',
    Latitude: '41.558468',
    Longitude: '-93.9032307',
    Speed: '9.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Adel, IA 50003, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:33:28',
    SlNo: '925',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:33:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:31:28',
    Latitude: '41.5597553',
    Longitude: '-93.897123',
    Speed: '29.83 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '33188 U Ln, Adel, IA 50003, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:31:28',
    SlNo: '926',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:31:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:29:27',
    Latitude: '41.5632992',
    Longitude: '-93.8783464',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Waukee, IA 50263, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:29:27',
    SlNo: '927',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:29:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:27:33',
    Latitude: '41.5774345',
    Longitude: '-93.8377772',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3344 Ashworth Rd, Waukee, IA 50263, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:27:33',
    SlNo: '928',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:27:33 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:25:27',
    Latitude: '41.5922393',
    Longitude: '-93.7992927',
    Speed: '40.39 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '6600 Westown Pkwy, West Des Moines, IA 50266, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:25:27',
    SlNo: '929',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:25:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:23:27',
    Latitude: '41.6024353',
    Longitude: '-93.7770141',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11428 Forest Ave, Clive, IA 50325, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:23:27',
    SlNo: '930',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:23:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:21:27',
    Latitude: '41.6311275',
    Longitude: '-93.7770833',
    Speed: '50.33 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-35, Urbandale, IA 50322, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:21:27',
    SlNo: '931',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:21:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:19:27',
    Latitude: '41.6516366',
    Longitude: '-93.7595115',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-35, Urbandale, IA 50322, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:19:27',
    SlNo: '932',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:19:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:17:26',
    Latitude: '41.652725',
    Longitude: '-93.7193703',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Winter Park, 5100 NW 72nd St, Urbandale, IA 50322, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:17:26',
    SlNo: '933',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:17:26 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:15:25',
    Latitude: '41.6511392',
    Longitude: '-93.6741757',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-35, Des Moines, IA 50310, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:15:25',
    SlNo: '934',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:15:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:13:25',
    Latitude: '41.6495578',
    Longitude: '-93.6277672',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4925 NW 6th Dr, Des Moines, IA 50313, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:13:25',
    SlNo: '935',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:13:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:11:26',
    Latitude: '41.6514305',
    Longitude: '-93.5811738',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5043 NE 22nd St, Des Moines, IA 50313, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:11:26',
    SlNo: '936',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:11:26 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:09:25',
    Latitude: '41.6573512',
    Longitude: '-93.5407503',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3857 NE 54th Pl, Des Moines, IA 50317, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:09:25',
    SlNo: '937',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:09:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:07:24',
    Latitude: '41.6620002',
    Longitude: '-93.4950972',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Altoona, IA 50009, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:07:24',
    SlNo: '938',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:07:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:05:23',
    Latitude: '41.6710979',
    Longitude: '-93.4496351',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Bondurant, IA 50035, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:05:23',
    SlNo: '939',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:05:23 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:03:24',
    Latitude: '41.6802636',
    Longitude: '-93.403314',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Mitchellville, IA 50169, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:03:24',
    SlNo: '940',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:03:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 12:01:23',
    Latitude: '41.6807484',
    Longitude: '-93.3559084',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Mitchellville, IA 50169, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '12:01:23',
    SlNo: '941',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 12:01:23 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:59:22',
    Latitude: '41.6818323',
    Longitude: '-93.3140169',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Mitchellville, IA 50169, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:59:22',
    SlNo: '942',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:59:22 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:57:21',
    Latitude: '41.6867919',
    Longitude: '-93.2741795',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '11756 Orchard Ave, Colfax, IA 50054, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:57:21',
    SlNo: '943',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:57:21 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:55:20',
    Latitude: '41.6961728',
    Longitude: '-93.2279854',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Colfax, IA 50054, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:55:20',
    SlNo: '944',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:55:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:53:20',
    Latitude: '41.692037',
    Longitude: '-93.1833108',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Colfax, IA 50054, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:53:20',
    SlNo: '945',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:53:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:51:19',
    Latitude: '41.6829239',
    Longitude: '-93.1367805',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Newton, IA 50208, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:51:19',
    SlNo: '946',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:51:19 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:49:18',
    Latitude: '41.6818882',
    Longitude: '-93.0897696',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Newton, IA 50208, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:49:18',
    SlNo: '947',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:49:18 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:47:17',
    Latitude: '41.6835441',
    Longitude: '-93.0430279',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Newton, IA 50208, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:47:17',
    SlNo: '948',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:47:17 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:45:17',
    Latitude: '41.6824883',
    Longitude: '-92.9958272',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4400 S 22nd Ave E, Newton, IA 50208, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:45:17',
    SlNo: '949',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:45:17 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:43:16',
    Latitude: '41.6803851',
    Longitude: '-92.9486994',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Newton, IA 50208, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:43:16',
    SlNo: '950',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:43:16 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:41:16',
    Latitude: '41.6827167',
    Longitude: '-92.9013777',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kellogg, IA 50135, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:41:16',
    SlNo: '951',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:41:16 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:39:15',
    Latitude: '41.695114',
    Longitude: '-92.8586284',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kellogg, IA 50135, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:39:15',
    SlNo: '952',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:39:15 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:37:15',
    Latitude: '41.6953489',
    Longitude: '-92.8100786',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Grinnell, IA 50112, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:37:15',
    SlNo: '953',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:37:15 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:35:13',
    Latitude: '41.6954712',
    Longitude: '-92.7629014',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Grinnell, IA 50112, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:35:13',
    SlNo: '954',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:35:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:33:13',
    Latitude: '41.6956118',
    Longitude: '-92.7151869',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Grinnell, IA 50112, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:33:13',
    SlNo: '955',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:33:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:31:12',
    Latitude: '41.6956921',
    Longitude: '-92.6673549',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Grinnell, IA 50112, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:31:12',
    SlNo: '956',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:31:12 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:29:12',
    Latitude: '41.6959066',
    Longitude: '-92.6194036',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Malcom, IA 50157, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:29:12',
    SlNo: '957',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:29:12 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:27:11',
    Latitude: '41.6962751',
    Longitude: '-92.5722418',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Malcom, IA 50157, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:27:11',
    SlNo: '958',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:27:11 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:25:20',
    Latitude: '41.6960654',
    Longitude: '-92.5265666',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Malcom, IA 50157, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:25:20',
    SlNo: '959',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:25:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:23:12',
    Latitude: '41.6950039',
    Longitude: '-92.4790039',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Malcom, IA 50157, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:23:12',
    SlNo: '960',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:23:12 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:21:11',
    Latitude: '41.6962104',
    Longitude: '-92.4308969',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brooklyn, IA 52211, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:21:11',
    SlNo: '961',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:21:11 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:19:09',
    Latitude: '41.696036',
    Longitude: '-92.3839311',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brooklyn, IA 52211, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:19:09',
    SlNo: '962',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:19:09 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:17:08',
    Latitude: '41.6956176',
    Longitude: '-92.3358599',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Victor, IA 52347, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:17:08',
    SlNo: '963',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:17:08 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:15:08',
    Latitude: '41.6960448',
    Longitude: '-92.2877367',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Victor, IA 52347, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:15:08',
    SlNo: '964',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:15:08 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:13:06',
    Latitude: '41.6958454',
    Longitude: '-92.2408298',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2144 D Ave, Ladora, IA 52251, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:13:06',
    SlNo: '965',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:13:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:11:06',
    Latitude: '41.69616',
    Longitude: '-92.1947808',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Ladora, IA 52251, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:11:06',
    SlNo: '966',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:11:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:09:06',
    Latitude: '41.6959356',
    Longitude: '-92.1484875',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Marengo, IA 52301, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:09:06',
    SlNo: '967',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:09:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:07:06',
    Latitude: '41.691994',
    Longitude: '-92.1015502',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Marengo, IA 52301, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:07:06',
    SlNo: '968',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:07:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:05:22',
    Latitude: '41.6879312',
    Longitude: '-92.0551948',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Williamsburg, IA 52361, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:05:22',
    SlNo: '969',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:05:22 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:03:05',
    Latitude: '41.687201',
    Longitude: '-92.0074954',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Williamsburg, IA 52361, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:03:05',
    SlNo: '970',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:03:05 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 11:01:05',
    Latitude: '41.6870463',
    Longitude: '-91.9597004',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Williamsburg, IA 52361, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '11:01:05',
    SlNo: '971',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:01:05 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:59:06',
    Latitude: '41.6877481',
    Longitude: '-91.9119771',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Williamsburg, IA 52361, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:59:06',
    SlNo: '972',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:59:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:57:04',
    Latitude: '41.687372',
    Longitude: '-91.8642809',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Homestead, IA 52236, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:57:04',
    SlNo: '973',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:57:04 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:55:03',
    Latitude: '41.6877178',
    Longitude: '-91.8178326',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Oxford, IA 52322, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:55:03',
    SlNo: '974',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:55:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:53:02',
    Latitude: '41.6892752',
    Longitude: '-91.7703922',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3399 Eagle Ave SW, Oxford, IA 52322, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:53:02',
    SlNo: '975',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:53:02 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:51:02',
    Latitude: '41.6952031',
    Longitude: '-91.7239042',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Oxford, IA 52322, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:51:02',
    SlNo: '976',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:51:02 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:49:01',
    Latitude: '41.6943049',
    Longitude: '-91.676817',
    Speed: '60.27 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Tiffin, IA 52340, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:49:01',
    SlNo: '977',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:49:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:47:01',
    Latitude: '41.6944094',
    Longitude: '-91.6378014',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Coralville, IA 52241, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:47:01',
    SlNo: '978',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:47:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:45:00',
    Latitude: '41.6931997',
    Longitude: '-91.5971371',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2266 Holiday Rd, Coralville, IA 52241, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:45:00',
    SlNo: '979',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:45:00 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:43:00',
    Latitude: '41.6856392',
    Longitude: '-91.5553513',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '963 Manitou Trail, Iowa City, IA 52245, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:43:00',
    SlNo: '980',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:43:00 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:41:00',
    Latitude: '41.6870768',
    Longitude: '-91.5103115',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Iowa City, IA 52245, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:41:00',
    SlNo: '981',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:41:00 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:38:58',
    Latitude: '41.6787527',
    Longitude: '-91.464647',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Iowa City, IA 52240, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:38:58',
    SlNo: '982',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:38:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:36:58',
    Latitude: '41.6676506',
    Longitude: '-91.4207355',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5249 Herbert Hoover Hwy NE, Iowa City, IA 52240, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:36:58',
    SlNo: '983',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:36:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:34:58',
    Latitude: '41.6677442',
    Longitude: '-91.3735555',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, West Branch, IA 52358, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:34:58',
    SlNo: '984',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:34:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:32:58',
    Latitude: '41.6641873',
    Longitude: '-91.3276486',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1915 Charles Ave, West Branch, IA 52358, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:32:58',
    SlNo: '985',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:32:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:30:57',
    Latitude: '41.6636195',
    Longitude: '-91.279695',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, West Branch, IA 52358, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:30:57',
    SlNo: '986',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:30:57 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:28:56',
    Latitude: '41.6624046',
    Longitude: '-91.2319372',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, West Branch, IA 52358, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:28:56',
    SlNo: '987',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:28:56 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:26:55',
    Latitude: '41.6536315',
    Longitude: '-91.1861952',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Atalissa, IA 52720, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:26:55',
    SlNo: '988',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:26:55 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:24:55',
    Latitude: '41.6458581',
    Longitude: '-91.1418063',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Tipton, IA 52772, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:24:55',
    SlNo: '989',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:24:55 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:22:54',
    Latitude: '41.6447174',
    Longitude: '-91.0936447',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'IA-38, Tipton, IA 52772, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:22:54',
    SlNo: '990',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:22:54 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:20:53',
    Latitude: '41.6392222',
    Longitude: '-91.0471605',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'IA-38, Wilton, IA 52778, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:20:53',
    SlNo: '991',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:20:53 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:18:53',
    Latitude: '41.6334971',
    Longitude: '-91.0027123',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Wilton, IA 52778, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:18:53',
    SlNo: '992',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:18:53 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:16:53',
    Latitude: '41.6336584',
    Longitude: '-90.9597136',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Durant, IA 52747, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:16:53',
    SlNo: '993',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:16:53 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:14:52',
    Latitude: '41.6336776',
    Longitude: '-90.9131129',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '277 I-80, Durant, IA 52747, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:14:52',
    SlNo: '994',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:14:52 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:12:52',
    Latitude: '41.633065',
    Longitude: '-90.8684226',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Durant, IA 52747, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:12:52',
    SlNo: '995',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:12:52 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:10:51',
    Latitude: '41.6288379',
    Longitude: '-90.8248184',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-6, Stockton, IA 52769, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:10:51',
    SlNo: '996',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:10:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:08:51',
    Latitude: '41.6177228',
    Longitude: '-90.7881649',
    Speed: '7.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1103 Walker St, Walcott, IA 52773, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:08:51',
    SlNo: '997',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:08:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:06:50',
    Latitude: '41.6189042',
    Longitude: '-90.7837788',
    Speed: '3.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3408 N Plainview Rd, Walcott, IA 52773, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:06:50',
    SlNo: '998',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:06:50 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:04:49',
    Latitude: '41.6194565',
    Longitude: '-90.7861952',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1102 Walker St, Walcott, IA 52773, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:04:49',
    SlNo: '999',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:04:49 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 10:02:48',
    Latitude: '41.619453',
    Longitude: '-90.7862004',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1102 Walker St, Walcott, IA 52773, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '10:02:48',
    SlNo: '1000',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:02:48 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:25:12',
    Latitude: '40.9896795',
    Longitude: '-111.4057812',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:25:12',
    SlNo: '359',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:25:12 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:23:12',
    Latitude: '40.9896813',
    Longitude: '-111.405777',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:23:12',
    SlNo: '360',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:23:12 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:21:12',
    Latitude: '40.9896689',
    Longitude: '-111.4057843',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:21:12',
    SlNo: '361',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:21:12 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:19:12',
    Latitude: '40.9896863',
    Longitude: '-111.4057841',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:19:12',
    SlNo: '362',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:19:12 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:17:11',
    Latitude: '40.989724',
    Longitude: '-111.4058353',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:17:11',
    SlNo: '363',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:17:11 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:15:11',
    Latitude: '40.9897251',
    Longitude: '-111.4058327',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:15:11',
    SlNo: '364',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:15:11 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:13:11',
    Latitude: '40.9897238',
    Longitude: '-111.4058347',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:13:11',
    SlNo: '365',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:13:11 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:11:10',
    Latitude: '40.989716',
    Longitude: '-111.4058036',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:11:10',
    SlNo: '366',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:11:10 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:09:10',
    Latitude: '40.9897171',
    Longitude: '-111.4057761',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:09:10',
    SlNo: '367',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:09:10 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:07:10',
    Latitude: '40.9897388',
    Longitude: '-111.4057788',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:07:10',
    SlNo: '368',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:07:10 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:05:09',
    Latitude: '40.9897366',
    Longitude: '-111.405779',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:05:09',
    SlNo: '369',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:05:09 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:03:08',
    Latitude: '40.9897377',
    Longitude: '-111.405778',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:03:08',
    SlNo: '370',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:03:08 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 08:01:07',
    Latitude: '40.9897541',
    Longitude: '-111.405777',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '08:01:07',
    SlNo: '371',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 08:01:07 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:59:07',
    Latitude: '40.9897546',
    Longitude: '-111.4057775',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:59:07',
    SlNo: '372',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:59:07 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:57:07',
    Latitude: '40.9897544',
    Longitude: '-111.4057763',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:57:07',
    SlNo: '373',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:57:07 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:55:06',
    Latitude: '40.9897468',
    Longitude: '-111.4057555',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:55:06',
    SlNo: '374',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:55:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:53:05',
    Latitude: '40.9897468',
    Longitude: '-111.4057568',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:53:05',
    SlNo: '375',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:53:05 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:51:04',
    Latitude: '40.989756',
    Longitude: '-111.4057754',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:51:04',
    SlNo: '376',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:51:04 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:49:03',
    Latitude: '40.9896849',
    Longitude: '-111.4058121',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:49:03',
    SlNo: '377',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:49:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:47:03',
    Latitude: '40.9896858',
    Longitude: '-111.4058109',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:47:03',
    SlNo: '378',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:47:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:45:03',
    Latitude: '40.989684',
    Longitude: '-111.4058132',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:45:03',
    SlNo: '379',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:45:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:43:03',
    Latitude: '40.989736',
    Longitude: '-111.4058035',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:43:03',
    SlNo: '380',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:43:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:41:01',
    Latitude: '40.9897349',
    Longitude: '-111.4058054',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:41:01',
    SlNo: '381',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:41:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:39:01',
    Latitude: '40.9897106',
    Longitude: '-111.4058103',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:39:01',
    SlNo: '382',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:39:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:37:01',
    Latitude: '40.9897144',
    Longitude: '-111.4058086',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:37:01',
    SlNo: '383',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:37:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:35:00',
    Latitude: '40.9897159',
    Longitude: '-111.4057699',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:35:00',
    SlNo: '384',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:35:00 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:32:59',
    Latitude: '40.9897181',
    Longitude: '-111.4057721',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:32:59',
    SlNo: '385',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:32:59 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:30:58',
    Latitude: '40.9897184',
    Longitude: '-111.4057709',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:30:58',
    SlNo: '386',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:30:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:28:58',
    Latitude: '40.9897174',
    Longitude: '-111.4057697',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:28:58',
    SlNo: '387',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:28:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:26:57',
    Latitude: '40.989718',
    Longitude: '-111.4057697',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:26:57',
    SlNo: '388',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:26:57 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:24:57',
    Latitude: '40.9897184',
    Longitude: '-111.4057697',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:24:57',
    SlNo: '389',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:24:57 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:22:57',
    Latitude: '40.9897211',
    Longitude: '-111.4057725',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:22:57',
    SlNo: '390',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:22:57 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:20:56',
    Latitude: '40.989718',
    Longitude: '-111.4057702',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:20:56',
    SlNo: '391',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:20:56 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:18:56',
    Latitude: '40.9897171',
    Longitude: '-111.4057706',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:18:56',
    SlNo: '392',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:18:56 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:16:55',
    Latitude: '40.989718',
    Longitude: '-111.4057691',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:16:55',
    SlNo: '393',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:16:55 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:14:55',
    Latitude: '40.989738',
    Longitude: '-111.4057712',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:14:55',
    SlNo: '394',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:14:55 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:12:54',
    Latitude: '40.9897407',
    Longitude: '-111.4057748',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:12:54',
    SlNo: '395',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:12:54 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:10:54',
    Latitude: '40.9897303',
    Longitude: '-111.4057731',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:10:54',
    SlNo: '396',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:10:54 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:08:54',
    Latitude: '40.9897297',
    Longitude: '-111.4057727',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:08:54',
    SlNo: '397',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:08:54 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:06:53',
    Latitude: '40.9897284',
    Longitude: '-111.4057743',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:06:53',
    SlNo: '398',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:06:53 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:04:52',
    Latitude: '40.9897305',
    Longitude: '-111.4057727',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:04:52',
    SlNo: '399',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:04:52 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:02:52',
    Latitude: '40.9897327',
    Longitude: '-111.4057719',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:02:52',
    SlNo: '400',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:02:52 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 07:00:51',
    Latitude: '40.9897304',
    Longitude: '-111.4057731',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '07:00:51',
    SlNo: '401',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 07:00:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:58:50',
    Latitude: '40.9897287',
    Longitude: '-111.405773',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:58:50',
    SlNo: '402',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:58:50 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:56:50',
    Latitude: '40.9897301',
    Longitude: '-111.4057743',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:56:50',
    SlNo: '403',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:56:50 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:54:50',
    Latitude: '40.9897437',
    Longitude: '-111.4057879',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:54:50',
    SlNo: '404',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:54:50 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:52:49',
    Latitude: '40.9897439',
    Longitude: '-111.4057883',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:52:49',
    SlNo: '405',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:52:49 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:50:48',
    Latitude: '40.9897442',
    Longitude: '-111.4057875',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:50:48',
    SlNo: '406',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:50:48 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:48:48',
    Latitude: '40.9897435',
    Longitude: '-111.4057875',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:48:48',
    SlNo: '407',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:48:48 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:46:47',
    Latitude: '40.9897441',
    Longitude: '-111.4057896',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:46:47',
    SlNo: '408',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:46:47 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:44:46',
    Latitude: '40.9897442',
    Longitude: '-111.4057879',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:44:46',
    SlNo: '409',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:44:46 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:42:46',
    Latitude: '40.9897447',
    Longitude: '-111.4057875',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:42:46',
    SlNo: '410',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:42:46 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:40:45',
    Latitude: '40.9897435',
    Longitude: '-111.4057868',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:40:45',
    SlNo: '411',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:40:45 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:38:44',
    Latitude: '40.9897462',
    Longitude: '-111.4057892',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:38:44',
    SlNo: '412',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:38:44 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:36:44',
    Latitude: '40.989747',
    Longitude: '-111.4057889',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:36:44',
    SlNo: '413',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:36:44 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:34:43',
    Latitude: '40.9897438',
    Longitude: '-111.4057882',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:34:43',
    SlNo: '414',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:34:43 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:32:42',
    Latitude: '40.9897163',
    Longitude: '-111.4057749',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:32:42',
    SlNo: '415',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:32:42 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:30:42',
    Latitude: '40.9897162',
    Longitude: '-111.4057745',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:30:42',
    SlNo: '416',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:30:42 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:28:41',
    Latitude: '40.9897178',
    Longitude: '-111.4057751',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:28:41',
    SlNo: '417',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:28:41 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:26:41',
    Latitude: '40.989717',
    Longitude: '-111.405775',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:26:41',
    SlNo: '418',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:26:41 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:24:40',
    Latitude: '40.9897241',
    Longitude: '-111.4057764',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:24:40',
    SlNo: '419',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:24:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:22:40',
    Latitude: '40.9897232',
    Longitude: '-111.4057765',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:22:40',
    SlNo: '420',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:22:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:20:40',
    Latitude: '40.9897364',
    Longitude: '-111.4057653',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:20:40',
    SlNo: '421',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:20:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:18:40',
    Latitude: '40.9897351',
    Longitude: '-111.4058184',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:18:40',
    SlNo: '422',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:18:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:16:39',
    Latitude: '40.9897347',
    Longitude: '-111.4058165',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:16:39',
    SlNo: '423',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:16:39 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:14:38',
    Latitude: '40.9897332',
    Longitude: '-111.4058165',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:14:38',
    SlNo: '424',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:14:38 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:12:38',
    Latitude: '40.9897346',
    Longitude: '-111.4058169',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:12:38',
    SlNo: '425',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:12:38 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:10:37',
    Latitude: '40.9897328',
    Longitude: '-111.4058169',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:10:37',
    SlNo: '426',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:10:37 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:08:37',
    Latitude: '40.9897352',
    Longitude: '-111.4058171',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:08:37',
    SlNo: '427',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:08:37 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:06:36',
    Latitude: '40.9897354',
    Longitude: '-111.4058158',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:06:36',
    SlNo: '428',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:06:36 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:04:35',
    Latitude: '40.9897318',
    Longitude: '-111.4058146',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:04:35',
    SlNo: '429',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:04:35 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:02:36',
    Latitude: '40.9897321',
    Longitude: '-111.4057873',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:02:36',
    SlNo: '430',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:02:36 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 06:00:35',
    Latitude: '40.989746',
    Longitude: '-111.4057949',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '06:00:35',
    SlNo: '431',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 06:00:35 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:58:34',
    Latitude: '40.9896827',
    Longitude: '-111.4057926',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:58:34',
    SlNo: '432',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:58:34 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:56:34',
    Latitude: '40.9896843',
    Longitude: '-111.4057918',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:56:34',
    SlNo: '433',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:56:34 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:54:34',
    Latitude: '40.9896841',
    Longitude: '-111.4057925',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:54:34',
    SlNo: '434',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:54:34 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:52:33',
    Latitude: '40.9896841',
    Longitude: '-111.4057924',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:52:33',
    SlNo: '435',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:52:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:50:32',
    Latitude: '40.9896827',
    Longitude: '-111.4057914',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:50:32',
    SlNo: '436',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:50:32 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:48:32',
    Latitude: '40.9896834',
    Longitude: '-111.40579',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:48:32',
    SlNo: '437',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:48:32 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:44:30',
    Latitude: '40.9896847',
    Longitude: '-111.4057915',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:44:30',
    SlNo: '438',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:44:30 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:42:30',
    Latitude: '40.9896854',
    Longitude: '-111.4057911',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:42:30',
    SlNo: '439',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:42:30 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:40:29',
    Latitude: '40.9896859',
    Longitude: '-111.4057904',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:40:29',
    SlNo: '440',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:40:29 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:38:29',
    Latitude: '40.9896826',
    Longitude: '-111.4057926',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:38:29',
    SlNo: '441',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:38:29 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:36:28',
    Latitude: '40.9896856',
    Longitude: '-111.405789',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:36:28',
    SlNo: '442',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:36:28 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:34:27',
    Latitude: '40.989685',
    Longitude: '-111.4057908',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:34:27',
    SlNo: '443',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:34:27 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:32:26',
    Latitude: '40.9896832',
    Longitude: '-111.405792',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:32:26',
    SlNo: '444',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:32:26 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:30:25',
    Latitude: '40.9896846',
    Longitude: '-111.4057911',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:30:25',
    SlNo: '445',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:30:25 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:28:24',
    Latitude: '40.9896869',
    Longitude: '-111.40579',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:28:24',
    SlNo: '446',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:28:24 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:26:23',
    Latitude: '40.9896827',
    Longitude: '-111.4057922',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:26:23',
    SlNo: '447',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:26:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:24:23',
    Latitude: '40.9896688',
    Longitude: '-111.4058063',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:24:23',
    SlNo: '448',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:24:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:22:23',
    Latitude: '40.9896727',
    Longitude: '-111.4057775',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:22:23',
    SlNo: '449',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:22:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:20:22',
    Latitude: '40.9897317',
    Longitude: '-111.4058294',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:20:22',
    SlNo: '450',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:20:22 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:18:21',
    Latitude: '40.9897312',
    Longitude: '-111.4058268',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:18:21',
    SlNo: '451',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:18:21 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:16:20',
    Latitude: '40.9897375',
    Longitude: '-111.4058362',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:16:20',
    SlNo: '452',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:16:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:14:20',
    Latitude: '40.9897354',
    Longitude: '-111.40583',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:14:20',
    SlNo: '453',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:14:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:12:19',
    Latitude: '40.9897359',
    Longitude: '-111.4058337',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:12:19',
    SlNo: '454',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:12:19 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:10:19',
    Latitude: '40.9897368',
    Longitude: '-111.4057686',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:10:19',
    SlNo: '455',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:10:19 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:08:18',
    Latitude: '40.9897353',
    Longitude: '-111.4057675',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:08:18',
    SlNo: '456',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:08:18 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:06:18',
    Latitude: '40.9897386',
    Longitude: '-111.4057692',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:06:18',
    SlNo: '457',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:06:18 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:04:17',
    Latitude: '40.9897383',
    Longitude: '-111.4057714',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:04:17',
    SlNo: '458',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:04:17 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:02:17',
    Latitude: '40.9897381',
    Longitude: '-111.4057724',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:02:17',
    SlNo: '459',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:02:17 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 05:00:16',
    Latitude: '40.9897469',
    Longitude: '-111.4058507',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '05:00:16',
    SlNo: '460',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 05:00:16 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:58:15',
    Latitude: '40.989747',
    Longitude: '-111.4058498',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:58:15',
    SlNo: '461',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:58:15 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:56:14',
    Latitude: '40.9897536',
    Longitude: '-111.405863',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:56:14',
    SlNo: '462',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:56:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:54:13',
    Latitude: '40.9897606',
    Longitude: '-111.405882',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:54:13',
    SlNo: '463',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:54:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:52:13',
    Latitude: '40.9897679',
    Longitude: '-111.4059392',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:52:13',
    SlNo: '464',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:52:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:50:12',
    Latitude: '40.9897684',
    Longitude: '-111.4058968',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:50:12',
    SlNo: '465',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:50:12 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:48:11',
    Latitude: '40.9897472',
    Longitude: '-111.4058835',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:48:11',
    SlNo: '466',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:48:11 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:44:10',
    Latitude: '40.9897447',
    Longitude: '-111.4058025',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:44:10',
    SlNo: '467',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:44:10 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:42:09',
    Latitude: '40.9897341',
    Longitude: '-111.4058252',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:42:09',
    SlNo: '468',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:42:09 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:40:08',
    Latitude: '40.9897246',
    Longitude: '-111.4058156',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:40:08',
    SlNo: '469',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:40:08 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:38:08',
    Latitude: '40.9897264',
    Longitude: '-111.4058089',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:38:08',
    SlNo: '470',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:38:08 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:36:07',
    Latitude: '40.9897347',
    Longitude: '-111.4058043',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:36:07',
    SlNo: '471',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:36:07 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:34:07',
    Latitude: '40.9897403',
    Longitude: '-111.4058023',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:34:07',
    SlNo: '472',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:34:07 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:32:07',
    Latitude: '40.989737',
    Longitude: '-111.4058045',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:32:07',
    SlNo: '473',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:32:07 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:30:06',
    Latitude: '40.9897536',
    Longitude: '-111.4057846',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:30:06',
    SlNo: '474',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:30:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:28:06',
    Latitude: '40.9897482',
    Longitude: '-111.4057878',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:28:06',
    SlNo: '475',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:28:06 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:26:05',
    Latitude: '40.989722',
    Longitude: '-111.405776',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:26:05',
    SlNo: '476',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:26:05 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:24:04',
    Latitude: '40.9897399',
    Longitude: '-111.4057607',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:24:04',
    SlNo: '477',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:24:04 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:22:03',
    Latitude: '40.9897391',
    Longitude: '-111.4057601',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:22:03',
    SlNo: '478',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:22:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:20:03',
    Latitude: '40.9897395',
    Longitude: '-111.4057596',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:20:03',
    SlNo: '479',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:20:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:18:03',
    Latitude: '40.9897396',
    Longitude: '-111.4057592',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:18:03',
    SlNo: '480',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:18:03 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:16:02',
    Latitude: '40.9897394',
    Longitude: '-111.4057598',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:16:02',
    SlNo: '481',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:16:02 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:14:01',
    Latitude: '40.9897395',
    Longitude: '-111.4057602',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:14:01',
    SlNo: '482',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:14:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:12:01',
    Latitude: '40.98974',
    Longitude: '-111.4057595',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:12:01',
    SlNo: '483',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:12:01 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:10:00',
    Latitude: '40.989739',
    Longitude: '-111.4057596',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:10:00',
    SlNo: '484',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:10:00 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:08:00',
    Latitude: '40.9897379',
    Longitude: '-111.4057621',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:08:00',
    SlNo: '485',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:08:00 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:05:59',
    Latitude: '40.9897384',
    Longitude: '-111.4057604',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:05:59',
    SlNo: '486',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:05:59 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:03:58',
    Latitude: '40.9897359',
    Longitude: '-111.4057591',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:03:58',
    SlNo: '487',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:03:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 04:01:58',
    Latitude: '40.9898737',
    Longitude: '-111.4054806',
    Speed: '36.66 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Echo, UT 84024, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '04:01:58',
    SlNo: '488',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 04:01:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:57:57',
    Latitude: '41.0212411',
    Longitude: '-111.3607607',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:57:57',
    SlNo: '489',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:57:57 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:55:58',
    Latitude: '41.0384048',
    Longitude: '-111.3285995',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Peoa, UT 84061, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:55:58',
    SlNo: '490',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:55:58 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:53:56',
    Latitude: '41.0568808',
    Longitude: '-111.2925032',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Peoa, UT 84061, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:53:56',
    SlNo: '491',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:53:56 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:51:56',
    Latitude: '41.0743929',
    Longitude: '-111.256967',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:51:56',
    SlNo: '492',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:51:56 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:49:55',
    Latitude: '41.094693',
    Longitude: '-111.2211785',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:49:55',
    SlNo: '493',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:49:55 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:47:54',
    Latitude: '41.1200766',
    Longitude: '-111.1906913',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:47:54',
    SlNo: '494',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:47:54 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:45:53',
    Latitude: '41.136286',
    Longitude: '-111.1547799',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:45:53',
    SlNo: '495',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:45:53 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:43:52',
    Latitude: '41.1684971',
    Longitude: '-111.1367788',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Coalville, UT 84017, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:43:52',
    SlNo: '496',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:43:52 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:35:51',
    Latitude: '41.2487546',
    Longitude: '-111.012806',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2017 Wasatch Rd, Evanston, WY 82930, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:35:51',
    SlNo: '497',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:35:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:35:51',
    Latitude: '41.2597999',
    Longitude: '-110.9737719',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '837 No Name St, Evanston, WY 82930, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:35:51',
    SlNo: '498',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:35:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:35:51',
    Latitude: '41.2680262',
    Longitude: '-110.9387156',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189 BUS, Evanston, WY 82930, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:35:51',
    SlNo: '499',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:35:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:35:51',
    Latitude: '41.2623363',
    Longitude: '-110.89343',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Evanston, WY 82930, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:35:51',
    SlNo: '500',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:35:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:35:51',
    Latitude: '41.2725077',
    Longitude: '-110.8538313',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-189, Evanston, WY 82930, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:35:51',
    SlNo: '501',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:35:51 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:09:43',
    Latitude: '41.3096163',
    Longitude: '-110.5182814',
    Speed: '54.68 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Fort Bridger, WY 82933, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:09:43',
    SlNo: '502',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:09:43 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:09:43',
    Latitude: '41.3202334',
    Longitude: '-110.4860504',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Fort Bridger, WY 82933, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:09:43',
    SlNo: '503',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:09:43 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:09:43',
    Latitude: '41.3291059',
    Longitude: '-110.4483372',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '570 Union Rd, Fort Bridger, WY 82933, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:09:43',
    SlNo: '504',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:09:43 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:09:43',
    Latitude: '41.3374755',
    Longitude: '-110.4087314',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Fort Bridger, WY 82933, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:09:43',
    SlNo: '505',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:09:43 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 03:01:40',
    Latitude: '41.3547309',
    Longitude: '-110.367666',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Lyman, WY 82937, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '03:01:40',
    SlNo: '506',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 03:01:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.3618198',
    Longitude: '-110.3229966',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Lyman, WY 82937, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '507',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.3701662',
    Longitude: '-110.2773458',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Lyman, WY 82937, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '508',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.3799558',
    Longitude: '-110.2320166',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Lyman, WY 82937, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '509',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.3919309',
    Longitude: '-110.1881515',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Lyman, WY 82937, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '510',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.4114086',
    Longitude: '-110.14833',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Lyman, WY 82937, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '511',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.4325039',
    Longitude: '-110.1108237',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Lyman, WY 82937, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '512',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.4520163',
    Longitude: '-110.0717886',
    Speed: '21.75 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Lyman, WY 82937, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '513',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.4584465',
    Longitude: '-110.0605135',
    Speed: '39.15 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wyoming, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '514',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.4698575',
    Longitude: '-110.0405471',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Lincoln Hwy, Wyoming, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '515',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.4951075',
    Longitude: '-110.0121396',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Granger, WY 82934, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '516',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.5130359',
    Longitude: '-109.9792026',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Granger, WY 82934, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '517',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.5314183',
    Longitude: '-109.9421628',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Granger, WY 82934, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '518',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:59:40',
    Latitude: '41.5436411',
    Longitude: '-109.8996206',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Little America, WY 82929, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:59:40',
    SlNo: '519',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:59:40 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:33:36',
    Latitude: '41.5426708',
    Longitude: '-109.8532266',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Little America, WY 82929, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:33:36',
    SlNo: '520',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:33:36 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:33:36',
    Latitude: '41.5430576',
    Longitude: '-109.806708',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Little America, WY 82929, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:33:36',
    SlNo: '521',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:33:36 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:33:36',
    Latitude: '41.5429184',
    Longitude: '-109.7595747',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:33:36',
    SlNo: '522',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:33:36 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:33:36',
    Latitude: '41.5427782',
    Longitude: '-109.7136102',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:33:36',
    SlNo: '523',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:33:36 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:25:34',
    Latitude: '41.5430327',
    Longitude: '-109.668823',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:25:34',
    SlNo: '524',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:25:34 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5454825',
    Longitude: '-109.6207092',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '525',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5573219',
    Longitude: '-109.5809387',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '526',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5631478',
    Longitude: '-109.544058',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '527',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5544794',
    Longitude: '-109.5040286',
    Speed: '59.65 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3780 WY-374, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '528',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5382244',
    Longitude: '-109.4725263',
    Speed: '55.92 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '543 Canyon Rd, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '529',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5236068',
    Longitude: '-109.4423393',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '530',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5289792',
    Longitude: '-109.4007165',
    Speed: '24.85 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Green River, WY 82935, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '531',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5332414',
    Longitude: '-109.3856584',
    Speed: '46.60 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '532',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5392479',
    Longitude: '-109.3569731',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '533',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5557162',
    Longitude: '-109.3144866',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '28B Purple Sage Rd, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '534',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5714583',
    Longitude: '-109.2767118',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1754 Interchange Rd, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '535',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.5936786',
    Longitude: '-109.2446725',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1300 Gateway Blvd, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '536',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.6089995',
    Longitude: '-109.2138068',
    Speed: '51.57 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '537',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.59178',
    Longitude: '-109.1900588',
    Speed: '61.52 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '538',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.6010438',
    Longitude: '-109.1525782',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '539',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.615482',
    Longitude: '-109.1201906',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '540',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 02:23:33',
    Latitude: '41.6294254',
    Longitude: '-109.082341',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '284 I-80 Service Rd, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '02:23:33',
    SlNo: '541',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 02:23:33 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:49:23',
    Latitude: '41.6363759',
    Longitude: '-109.0426111',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:49:23',
    SlNo: '542',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:49:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:47:23',
    Latitude: '41.6518503',
    Longitude: '-109.0061476',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:47:23',
    SlNo: '543',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:47:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:47:23',
    Latitude: '41.6666462',
    Longitude: '-108.9707314',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:47:23',
    SlNo: '544',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:47:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:47:23',
    Latitude: '41.6725687',
    Longitude: '-108.9265445',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:47:23',
    SlNo: '545',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:47:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:47:23',
    Latitude: '41.6917238',
    Longitude: '-108.8914861',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:47:23',
    SlNo: '546',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:47:23 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:37:20',
    Latitude: '41.6821455',
    Longitude: '-108.7998978',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:37:20',
    SlNo: '547',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:37:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:37:20',
    Latitude: '41.6750619',
    Longitude: '-108.7528544',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:37:20',
    SlNo: '548',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:37:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:37:20',
    Latitude: '41.656393',
    Longitude: '-108.7125701',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:37:20',
    SlNo: '549',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:37:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:37:20',
    Latitude: '41.6471586',
    Longitude: '-108.6668238',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wyoming, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:37:20',
    SlNo: '550',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:37:20 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6371782',
    Longitude: '-108.4350953',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '551',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6343044',
    Longitude: '-108.387535',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '552',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6316157',
    Longitude: '-108.3394755',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '553',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6298967',
    Longitude: '-108.2919186',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rock Springs, WY 82901, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '554',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6364766',
    Longitude: '-108.2448064',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '555',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6415779',
    Longitude: '-108.1993061',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '556',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6466364',
    Longitude: '-108.1524537',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '557',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6556208',
    Longitude: '-108.105735',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '558',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6619508',
    Longitude: '-108.0588295',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '559',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6683323',
    Longitude: '-108.0116774',
    Speed: '49.09 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '560',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:14',
    Latitude: '41.6758299',
    Longitude: '-107.9815707',
    Speed: '3.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:14',
    SlNo: '561',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:14 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6751708',
    Longitude: '-107.9834596',
    Speed: '1.86 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '562',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6755135',
    Longitude: '-107.9825154',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '563',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6755134',
    Longitude: '-107.9825154',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '564',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6755134',
    Longitude: '-107.9825155',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '565',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6755132',
    Longitude: '-107.9825157',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '566',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6755126',
    Longitude: '-107.9825161',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '567',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6755139',
    Longitude: '-107.9825155',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '568',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6756748',
    Longitude: '-107.9826481',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '569',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6757835',
    Longitude: '-107.9827616',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '570',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6757789',
    Longitude: '-107.9827627',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '571',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6757805',
    Longitude: '-107.9827647',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '572',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.675872',
    Longitude: '-107.9829343',
    Speed: '0.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '573',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.676214',
    Longitude: '-107.983215',
    Speed: '6.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '314 Kelly Rd, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '574',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6761827',
    Longitude: '-107.9786931',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '103 Co Rd 4-23, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '575',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.6919228',
    Longitude: '-107.9399792',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '576',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7047939',
    Longitude: '-107.8950328',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '577',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7055019',
    Longitude: '-107.8467841',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '578',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.712516',
    Longitude: '-107.7990544',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Wamsutter, WY 82336, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '579',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.723181',
    Longitude: '-107.7523212',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '580',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7334713',
    Longitude: '-107.7071614',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '581',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7423324',
    Longitude: '-107.6621182',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '582',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7454475',
    Longitude: '-107.6180549',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '583',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7498637',
    Longitude: '-107.5719089',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '584',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7598141',
    Longitude: '-107.5265304',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '585',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7698714',
    Longitude: '-107.4811151',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '586',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7800083',
    Longitude: '-107.4360153',
    Speed: '77.05 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'WY-789, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '587',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7893047',
    Longitude: '-107.3877579',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '588',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7786168',
    Longitude: '-107.3419721',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '589',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 01:21:13',
    Latitude: '41.7780065',
    Longitude: '-107.2980733',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '605 20 Mile Rd, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/16/2021 00:00:00',
    Time: '01:21:13',
    SlNo: '590',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/16/2021 01:21:13 AM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:58:50',
    Latitude: '41.7827808',
    Longitude: '-107.2137557',
    Speed: '57.17 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2020 Alton Ln, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:58:50',
    SlNo: '591',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:58:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:56:50',
    Latitude: '41.7854665',
    Longitude: '-107.17721',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Rawlins, WY 82301, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:56:50',
    SlNo: '592',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:56:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:50',
    Latitude: '41.7819031',
    Longitude: '-107.1351989',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Sinclair, WY 82334, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:50',
    SlNo: '593',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:50',
    Latitude: '41.7731285',
    Longitude: '-107.0968244',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Sinclair, WY 82334, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:50',
    SlNo: '594',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:50',
    Latitude: '41.7711967',
    Longitude: '-107.0569485',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Sinclair, WY 82334, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:50',
    SlNo: '595',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:50',
    Latitude: '41.7644159',
    Longitude: '-107.0180237',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Sinclair, WY 82334, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:50',
    SlNo: '596',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:50',
    Latitude: '41.7577379',
    Longitude: '-106.9796858',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Sinclair, WY 82334, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:50',
    SlNo: '597',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:50',
    Latitude: '41.7473527',
    Longitude: '-106.9345693',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Walcott, WY 82335, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:50',
    SlNo: '598',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:50',
    Latitude: '41.7437218',
    Longitude: '-106.8882344',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Walcott, WY 82335, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:50',
    SlNo: '599',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7419343',
    Longitude: '-106.8400173',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Walcott, WY 82335, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '600',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7424535',
    Longitude: '-106.7946749',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Walcott, WY 82335, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '601',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7372615',
    Longitude: '-106.7480002',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Walcott, WY 82335, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '602',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7306825',
    Longitude: '-106.7011695',
    Speed: '83.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Walcott, WY 82335, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '603',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7338379',
    Longitude: '-106.647331',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Walcott, WY 82335, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '604',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7374375',
    Longitude: '-106.6069128',
    Speed: '60.27 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '605',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7413942',
    Longitude: '-106.568421',
    Speed: '21.13 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '606',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7445552',
    Longitude: '-106.5553375',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '607',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7534011',
    Longitude: '-106.5113116',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '608',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.731041',
    Longitude: '-106.47743',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '609',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7129794',
    Longitude: '-106.4360399',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '610',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.7012681',
    Longitude: '-106.3934806',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '611',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.669394',
    Longitude: '-106.3698716',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '612',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.6501768',
    Longitude: '-106.331171',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '613',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.6364333',
    Longitude: '-106.2872533',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '614',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.6224407',
    Longitude: '-106.2441129',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Elk Mountain, WY 82324, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '615',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.5980518',
    Longitude: '-106.2152198',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Rock River, WY 82083, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '616',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:54:49',
    Latitude: '41.5846666',
    Longitude: '-106.173489',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Rock River, WY 82083, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:54:49',
    SlNo: '617',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:02:37',
    Latitude: '41.5536948',
    Longitude: '-106.0954983',
    Speed: '62.14 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:02:37',
    SlNo: '618',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:02:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 23:00:37',
    Latitude: '41.5304114',
    Longitude: '-106.0701542',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '23:00:37',
    SlNo: '619',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 11:00:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:58:36',
    Latitude: '41.5013832',
    Longitude: '-106.0500998',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:58:36',
    SlNo: '620',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:58:36 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:56:36',
    Latitude: '41.4815945',
    Longitude: '-106.0187785',
    Speed: '64.62 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:56:36',
    SlNo: '621',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:56:36 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:54:49',
    Latitude: '41.4635494',
    Longitude: '-105.9848142',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:54:49',
    SlNo: '622',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:54:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:52:44',
    Latitude: '41.4430984',
    Longitude: '-105.9568724',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:52:44',
    SlNo: '623',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:52:44 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:50:34',
    Latitude: '41.4369864',
    Longitude: '-105.917366',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:50:34',
    SlNo: '624',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:50:34 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:48:33',
    Latitude: '41.4251437',
    Longitude: '-105.8726168',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:48:33',
    SlNo: '625',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:48:33 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:46:33',
    Latitude: '41.4058898',
    Longitude: '-105.8333043',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:46:33',
    SlNo: '626',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:46:33 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:44:32',
    Latitude: '41.3788647',
    Longitude: '-105.8067258',
    Speed: '64.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:44:32',
    SlNo: '627',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:44:32 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:42:31',
    Latitude: '41.3623787',
    Longitude: '-105.7720355',
    Speed: '5.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:42:31',
    SlNo: '628',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:42:31 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:40:30',
    Latitude: '41.3614802',
    Longitude: '-105.7685557',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:40:30',
    SlNo: '629',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:40:30 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:38:29',
    Latitude: '41.3614846',
    Longitude: '-105.7685389',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:38:29',
    SlNo: '630',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:38:29 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:36:28',
    Latitude: '41.3530342',
    Longitude: '-105.7300833',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:36:28',
    SlNo: '631',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:36:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:34:27',
    Latitude: '41.3508805',
    Longitude: '-105.6815527',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:34:27',
    SlNo: '632',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:34:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:32:27',
    Latitude: '41.3493114',
    Longitude: '-105.6381706',
    Speed: '55.30 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:32:27',
    SlNo: '633',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:32:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:30:27',
    Latitude: '41.3287078',
    Longitude: '-105.6158208',
    Speed: '57.79 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1855 W Curtis St, Laramie, WY 82072, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:30:27',
    SlNo: '634',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:30:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:28:25',
    Latitude: '41.3013544',
    Longitude: '-105.6085391',
    Speed: '58.41 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Laramie, WY 82072, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:28:25',
    SlNo: '635',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:28:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:26:24',
    Latitude: '41.2920356',
    Longitude: '-105.5731916',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1618 Skyline Rd, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:26:24',
    SlNo: '636',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:26:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:24:37',
    Latitude: '41.2898161',
    Longitude: '-105.5255898',
    Speed: '66.49 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2546 Sherman Hill Rd, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:24:37',
    SlNo: '637',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:24:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:22:26',
    Latitude: '41.2719041',
    Longitude: '-105.4901492',
    Speed: '60.89 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:22:26',
    SlNo: '638',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:22:26 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:20:22',
    Latitude: '41.2594717',
    Longitude: '-105.4548637',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Laramie, WY 82070, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:20:22',
    SlNo: '639',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:20:22 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:18:23',
    Latitude: '41.228862',
    Longitude: '-105.4386125',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Buford, WY 82052, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:18:23',
    SlNo: '640',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:18:23 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:16:20',
    Latitude: '41.1963306',
    Longitude: '-105.4298056',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Buford, WY 82052, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:16:20',
    SlNo: '641',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:16:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:14:20',
    Latitude: '41.1648135',
    Longitude: '-105.409458',
    Speed: '70.84 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Buford, WY 82052, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:14:20',
    SlNo: '642',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:14:20 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:12:19',
    Latitude: '41.1443677',
    Longitude: '-105.3733412',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Buford, WY 82052, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:12:19',
    SlNo: '643',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:12:19 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:10:18',
    Latitude: '41.1280619',
    Longitude: '-105.3345515',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Buford, WY 82052, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:10:18',
    SlNo: '644',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:10:18 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:08:17',
    Latitude: '41.1215217',
    Longitude: '-105.288148',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Buford, WY 82052, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:08:17',
    SlNo: '645',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:08:17 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:06:16',
    Latitude: '41.1057021',
    Longitude: '-105.246016',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Buford, WY 82052, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:06:16',
    SlNo: '646',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:06:16 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:04:15',
    Latitude: '41.0957145',
    Longitude: '-105.2030238',
    Speed: '67.73 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Granite, WY 82059, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:04:15',
    SlNo: '647',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:04:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:02:15',
    Latitude: '41.0987207',
    Longitude: '-105.160092',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '630 I- 80 Service Rd, Granite, WY 82059, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:02:15',
    SlNo: '648',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:02:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 22:00:15',
    Latitude: '41.0982852',
    Longitude: '-105.115313',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '939 I- 80 Service Rd, Granite, WY 82059, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '22:00:15',
    SlNo: '649',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 10:00:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:58:15',
    Latitude: '41.1002013',
    Longitude: '-105.0709831',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Cheyenne, WY 82009, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:58:15',
    SlNo: '650',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:58:15 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:56:13',
    Latitude: '41.1028062',
    Longitude: '-105.0283155',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Cheyenne, WY 82009, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:56:13',
    SlNo: '651',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:56:13 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:54:12',
    Latitude: '41.1079374',
    Longitude: '-104.9868962',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Cheyenne, WY 82009, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:54:12',
    SlNo: '652',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:54:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:52:11',
    Latitude: '41.1124605',
    Longitude: '-104.9411145',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Cheyenne, WY 82009, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:52:11',
    SlNo: '653',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:52:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:50:11',
    Latitude: '41.1168694',
    Longitude: '-104.8948832',
    Speed: '65.87 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Cheyenne, WY 82009, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:50:11',
    SlNo: '654',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:50:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:48:11',
    Latitude: '41.1133732',
    Longitude: '-104.8532027',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Dwight D. Eisenhower Hwy, Cheyenne, WY 82001, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:48:11',
    SlNo: '655',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:48:11 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:46:10',
    Latitude: '41.1188367',
    Longitude: '-104.8065751',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '204 W Fox Farm Rd, Cheyenne, WY 82007, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:46:10',
    SlNo: '656',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:46:10 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:44:09',
    Latitude: '41.124277',
    Longitude: '-104.7600857',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Cheyenne, WY 82007, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:44:09',
    SlNo: '657',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:44:09 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:42:12',
    Latitude: '41.1312511',
    Longitude: '-104.7147622',
    Speed: '75.81 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Cheyenne, WY 82007, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:42:12',
    SlNo: '658',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:42:12 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:40:08',
    Latitude: '41.1510032',
    Longitude: '-104.6741957',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Cheyenne, WY 82007, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:40:08',
    SlNo: '659',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:40:08 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:38:07',
    Latitude: '41.158616',
    Longitude: '-104.6296786',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Cheyenne, WY 82009, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:38:07',
    SlNo: '660',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:38:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:36:07',
    Latitude: '41.1580706',
    Longitude: '-104.5890612',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '3698 Wadlow Ranch Rd, Cheyenne, WY 82009, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:36:07',
    SlNo: '661',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:36:07 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:34:06',
    Latitude: '41.1580393',
    Longitude: '-104.5448535',
    Speed: '68.97 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Burns, WY 82053, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:34:06',
    SlNo: '662',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:34:06 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:32:05',
    Latitude: '41.1575206',
    Longitude: '-104.5008578',
    Speed: '70.21 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Burns, WY 82053, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:32:05',
    SlNo: '663',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:32:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:30:05',
    Latitude: '41.1565527',
    Longitude: '-104.4559424',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '4342 I-80 Service Rd, Burns, WY 82053, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:30:05',
    SlNo: '664',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:30:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:28:04',
    Latitude: '41.1579161',
    Longitude: '-104.4156524',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Burns, WY 82053, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:28:04',
    SlNo: '665',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:28:04 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:26:03',
    Latitude: '41.1577781',
    Longitude: '-104.3680181',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1100 Rd 148, Burns, WY 82053, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:26:03',
    SlNo: '666',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:26:03 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:24:05',
    Latitude: '41.1588745',
    Longitude: '-104.3217775',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Burns, WY 82053, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:24:05',
    SlNo: '667',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:24:05 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:22:01',
    Latitude: '41.1587863',
    Longitude: '-104.2736787',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '5307 I-80 Service Rd, Burns, WY 82053, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:22:01',
    SlNo: '668',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:22:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:20:01',
    Latitude: '41.1584004',
    Longitude: '-104.2280543',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Pine Bluffs, WY 82082, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:20:01',
    SlNo: '669',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:20:01 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:18:00',
    Latitude: '41.1486829',
    Longitude: '-104.1820924',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Pine Bluffs, WY 82082, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:18:00',
    SlNo: '670',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:18:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:16:00',
    Latitude: '41.1491288',
    Longitude: '-104.1349833',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Pine Bluffs, WY 82082, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:16:00',
    SlNo: '671',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:16:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:14:00',
    Latitude: '41.1637705',
    Longitude: '-104.0934579',
    Speed: '63.38 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'US-30, Pine Bluffs, WY 82082, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:14:00',
    SlNo: '672',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:14:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:12:00',
    Latitude: '41.1780079',
    Longitude: '-104.0576634',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '700 E 6th St, Pine Bluffs, WY 82082, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:12:00',
    SlNo: '673',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:12:00 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:09:59',
    Latitude: '41.1881893',
    Longitude: '-104.0170124',
    Speed: '17.40 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:09:59',
    SlNo: '674',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:09:59 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:08:02',
    Latitude: '41.1901225',
    Longitude: '-104.0060563',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:08:02',
    SlNo: '675',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:08:02 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:05:58',
    Latitude: '41.1901221',
    Longitude: '-104.0060568',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:05:58',
    SlNo: '676',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:05:58 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:03:57',
    Latitude: '41.190122',
    Longitude: '-104.0060569',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:03:57',
    SlNo: '677',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:03:57 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 21:01:56',
    Latitude: '41.1901224',
    Longitude: '-104.0060564',
    Speed: '48.47 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '21:01:56',
    SlNo: '678',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 09:01:56 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:59:56',
    Latitude: '41.1926128',
    Longitude: '-103.9753387',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:59:56',
    SlNo: '679',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:59:56 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:57:55',
    Latitude: '41.1920587',
    Longitude: '-103.9276058',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:57:55',
    SlNo: '680',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:57:55 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:55:54',
    Latitude: '41.1918685',
    Longitude: '-103.8796047',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:55:54',
    SlNo: '681',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:55:54 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:53:53',
    Latitude: '41.1921752',
    Longitude: '-103.8320232',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Bushnell, NE 69128, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:53:53',
    SlNo: '682',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:53:53 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:51:52',
    Latitude: '41.1921065',
    Longitude: '-103.7841109',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kimball, NE 69145, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:51:52',
    SlNo: '683',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:51:52 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:49:57',
    Latitude: '41.2006722',
    Longitude: '-103.7380472',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kimball, NE 69145, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:49:57',
    SlNo: '684',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:49:57 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:47:50',
    Latitude: '41.2127274',
    Longitude: '-103.6932171',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kimball, NE 69145, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:47:50',
    SlNo: '685',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:47:50 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:45:49',
    Latitude: '41.2183511',
    Longitude: '-103.646426',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'NE-71, Kimball, NE 69145, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:45:49',
    SlNo: '686',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:45:49 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:43:48',
    Latitude: '41.2216011',
    Longitude: '-103.6008629',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kimball, NE 69145, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:43:48',
    SlNo: '687',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:43:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:41:48',
    Latitude: '41.221403',
    Longitude: '-103.5537722',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Kimball, NE 69145, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:41:48',
    SlNo: '688',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:41:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:39:48',
    Latitude: '41.2212003',
    Longitude: '-103.5079515',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Dix, NE 69133, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:39:48',
    SlNo: '689',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:39:48 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:37:47',
    Latitude: '41.2222494',
    Longitude: '-103.4606413',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Dix, NE 69133, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:37:47',
    SlNo: '690',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:37:47 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:35:46',
    Latitude: '41.2222958',
    Longitude: '-103.413127',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Dix, NE 69133, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:35:46',
    SlNo: '691',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:35:46 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:33:46',
    Latitude: '41.2173941',
    Longitude: '-103.3667696',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Potter, NE 69156, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:33:46',
    SlNo: '692',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:33:46 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:31:46',
    Latitude: '41.2063337',
    Longitude: '-103.3217947',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '2698 Rd 77, Potter, NE 69156, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:31:46',
    SlNo: '693',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:31:46 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:29:45',
    Latitude: '41.2015936',
    Longitude: '-103.274544',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Potter, NE 69156, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:29:45',
    SlNo: '694',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:29:45 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:27:44',
    Latitude: '41.1990113',
    Longitude: '-103.2269005',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Potter, NE 69156, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:27:44',
    SlNo: '695',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:27:44 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:25:47',
    Latitude: '41.1952877',
    Longitude: '-103.1797772',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Potter, NE 69156, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:25:47',
    SlNo: '696',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:25:47 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:23:43',
    Latitude: '41.1818146',
    Longitude: '-103.1360308',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:23:43',
    SlNo: '697',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:23:43 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:21:42',
    Latitude: '41.1613954',
    Longitude: '-103.0968644',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:21:42',
    SlNo: '698',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:21:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:19:41',
    Latitude: '41.1397273',
    Longitude: '-103.0589743',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '10342 Rd 20, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:19:41',
    SlNo: '699',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:19:41 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:17:40',
    Latitude: '41.1214696',
    Longitude: '-103.0181426',
    Speed: '71.46 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:17:40',
    SlNo: '700',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:17:40 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:15:40',
    Latitude: '41.1133434',
    Longitude: '-102.9736749',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1643 Rd 113, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:15:40',
    SlNo: '701',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:15:40 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:13:43',
    Latitude: '41.1129615',
    Longitude: '-102.9261428',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:13:43',
    SlNo: '702',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:13:43 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:11:38',
    Latitude: '41.1149927',
    Longitude: '-102.8786149',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:11:38',
    SlNo: '703',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:11:38 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:09:37',
    Latitude: '41.1130647',
    Longitude: '-102.8310287',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:09:37',
    SlNo: '704',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:09:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:07:37',
    Latitude: '41.1131063',
    Longitude: '-102.7837699',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Sidney, NE 69162, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:07:37',
    SlNo: '705',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:07:37 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:03:35',
    Latitude: '41.1134216',
    Longitude: '-102.6882325',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Lodgepole, NE 69149, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:03:35',
    SlNo: '706',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:03:35 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:01:42',
    Latitude: '41.1136741',
    Longitude: '-102.6421422',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Lodgepole, NE 69149, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:01:42',
    SlNo: '707',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:01:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:01:42',
    Latitude: '41.1135107',
    Longitude: '-102.5961363',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Chappell, NE 69129, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:01:42',
    SlNo: '708',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:01:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:01:42',
    Latitude: '41.1067363',
    Longitude: '-102.5500033',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Chappell, NE 69129, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:01:42',
    SlNo: '709',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:01:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 20:01:42',
    Latitude: '41.0891782',
    Longitude: '-102.5083859',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Chappell, NE 69129, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '20:01:42',
    SlNo: '710',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 08:01:42 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:51:32',
    Latitude: '41.0567163',
    Longitude: '-102.4255842',
    Speed: '75.19 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Chappell, NE 69129, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:51:32',
    SlNo: '711',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:51:32 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:49:31',
    Latitude: '41.0504554',
    Longitude: '-102.3784281',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Chappell, NE 69129, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:49:31',
    SlNo: '712',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:49:31 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:47:30',
    Latitude: '41.0477791',
    Longitude: '-102.331103',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Chappell, NE 69129, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:47:30',
    SlNo: '713',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:47:30 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:45:30',
    Latitude: '41.0451128',
    Longitude: '-102.2838291',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Julesburg, NE 80737, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:45:30',
    SlNo: '714',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:45:30 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:43:40',
    Latitude: '41.0400323',
    Longitude: '-102.2370349',
    Speed: '72.08 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Big Springs, NE 69122, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:43:40',
    SlNo: '715',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:43:40 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:41:28',
    Latitude: '41.0327453',
    Longitude: '-102.1922264',
    Speed: '68.35 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Big Springs, NE 69122, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:41:28',
    SlNo: '716',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:41:28 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:39:27',
    Latitude: '41.03053',
    Longitude: '-102.1486074',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Big Springs, NE 69122, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:39:27',
    SlNo: '717',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:39:27 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:37:26',
    Latitude: '41.0403515',
    Longitude: '-102.1092377',
    Speed: '69.59 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Big Springs, NE 69122, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:37:26',
    SlNo: '718',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:37:26 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:35:25',
    Latitude: '41.0501786',
    Longitude: '-102.0668773',
    Speed: '73.94 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Big Springs, NE 69122, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:35:25',
    SlNo: '719',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:35:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:33:25',
    Latitude: '41.0621458',
    Longitude: '-102.0224405',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brule, NE 69127, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:33:25',
    SlNo: '720',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:33:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:31:24',
    Latitude: '41.065973',
    Longitude: '-101.9752638',
    Speed: '72.70 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: '1398-1300 Rd West 55, Brule, NE 69127, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:31:24',
    SlNo: '721',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:31:24 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:29:23',
    Latitude: '41.0760034',
    Longitude: '-101.9306075',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brule, NE 69127, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:29:23',
    SlNo: '722',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:29:23 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:27:25',
    Latitude: '41.0793807',
    Longitude: '-101.8893963',
    Speed: '65.24 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brule, NE 69127, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:27:25',
    SlNo: '723',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:27:25 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:25:23',
    Latitude: '41.0883545',
    Longitude: '-101.8496191',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Brule, NE 69127, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:25:23',
    SlNo: '724',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:25:23 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:23:22',
    Latitude: '41.0957704',
    Longitude: '-101.8078331',
    Speed: '67.11 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'Rd West D S, Brule, NE 69127, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:23:22',
    SlNo: '725',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:23:22 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:21:22',
    Latitude: '41.1046242',
    Longitude: '-101.7665905',
    Speed: '62.76 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Ogallala, NE 69153, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:21:22',
    SlNo: '726',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:21:22 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:19:21',
    Latitude: '41.1151908',
    Longitude: '-101.7288124',
    Speed: '73.32 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Ogallala, NE 69153, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:19:21',
    SlNo: '727',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:19:21 PM',
  },
  {
    DeviceId: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/15/2021 19:17:20',
    Latitude: '41.1203538',
    Longitude: '-101.6826378',
    Speed: '74.56 MPH',
    Angle: '0',
    Hdop: '0',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: 0.0,
    Status: 'All Good',
    Location_Address: 'I-80, Ogallala, NE 69153, USA',
    Icon_Status: 'A',
    Date: '06/15/2021 00:00:00',
    Time: '19:17:20',
    SlNo: '728',
    Asset_Name: 'BH008',
    ExcludeDate: null,
    DisplayDate: '06/15/2021 07:17:20 PM',
  },
];

export default data;
