import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import loginReducer from '../features/login/loginSlice';
import loaderReducer from '../core/loader/loaderSlice';
import errorMessageReducer from '../core/popups/Error500Slice';
import incidentDetailReducer from '../features/incident/incidentDetailSlice';
import tripReducer from '../features/trips/tripsSlice';
import tripLocationReducer from '../features/trips/tripLocationSlice';
import assetDetailReducer from '../features/assets/assetDetailsSlice';
import switchCompReducer from '../features/switchCompany/switchCompSlice';
import settingRoleSlice from '../features/settingRole/settingRoleSlice';

const reducer = {
  counter: counterReducer,
  login: loginReducer,
  loader: loaderReducer,
  errorMessage: errorMessageReducer,
  incidentDetail: incidentDetailReducer,
  tripDetail: tripReducer,
  tripLocation: tripLocationReducer,
  assetDetail: assetDetailReducer,
  switchCompany: switchCompReducer,
  settingRole: settingRoleSlice,
};

export default configureStore({
  reducer,
});

export { reducer };
