import { createSlice } from '@reduxjs/toolkit';

export const errorMessageSlice = createSlice({
  name: 'errorMessage',
  initialState: {
    showing: false,
    header: '500 - Server Error',
    message:
      'Sorry, this is not working properly or the server encountered an internal error. Please try later',
  },
  reducers: {
    setErrorMessage: (state, action) => {
      state.showing = action.payload.showing;
      state.header = action.payload.header;
      state.message = action.payload.message;
    },
  },
});

export const { setErrorMessage } = errorMessageSlice.actions;
export default errorMessageSlice.reducer;
