const response = [
  {
    Asset_ID: '1050',
    Asset_Name: 'BH005',
    DeviceID: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    MileageMeters: '',
    CreatedDate: '06/15/2021 19:40:56',
    GPSStatusMark: '3D',
    latitude: '32.677345',
    longitude: '-97.122784',
    speed: '55.92 MPH',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '1390 W Interstate 20, Arlington, TX 76017, USA',
    CompanyName: 'BLACK HORSE TRANS INC',
    Status: 'All Good',
    Icon_Status: 'A',
    Display_Details: '1',
    assetType: '1',
    signature: 'Dfan-tmcoGHtBaUBtItT5g7Pshg=',
  },
  {
    Asset_ID: '1047',
    Asset_Name: 'BH008',
    DeviceID: '48e823c5-7efa-4d32-849f-d552d51874bb',
    MileageMeters: '',
    CreatedDate: '06/15/2021 19:41:28',
    GPSStatusMark: '3D',
    latitude: '39.5190862',
    longitude: '-119.8943973',
    speed: '15.00 MPH',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '6567-6667 Sharlands Ave, Reno, NV 89523, USA',
    CompanyName: 'BLACK HORSE TRANS INC',
    Status: 'All Good',
    Icon_Status: 'A',
    Display_Details: '1',
    assetType: '1',
    signature: 'e_iUhNx67J9u3B9FoKRKLBkkU4U=',
  },
  {
    Asset_ID: '1049',
    Asset_Name: 'BH004',
    DeviceID: '139408b8-a63f-488f-9354-971e45ac8661',
    MileageMeters: '',
    CreatedDate: '06/15/2021 19:41:57',
    GPSStatusMark: '3D',
    latitude: '35.0697969',
    longitude: '-106.2404062',
    Speed: '0.00 MPH',
    Angle: '0',
    Hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '31 Church St E, Edgewood, NM 87015',
    CompanyName: 'BLACK HORSE TRANS INC',
    Status: 'All Good',
    Icon_Status: 'A',
    Display_Details: '1',
    assetType: '1',
    signature: 'YP1ywkBffKOQQMTUsjj3EKO0CyQ=',
  },
  {
    Asset_ID: '1048',
    Asset_Name: 'BH006',
    DeviceID: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    MileageMeters: '',
    CreatedDate: '06/10/2021 04:53:38',
    GPSStatusMark: '3D',
    latitude: '35.0066416',
    longitude: '-117.6946824',
    speed: '37.90 MPH',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '12469 Del Oro St, Boron, CA 93516, USA',
    CompanyName: 'BLACK HORSE TRANS INC',
    Status: 'All Good',
    Icon_Status: 'A',
    Display_Details: '1',
    assetType: '1',
    signature: '0UBFUkUAiMHxJut1rYA3Mvd3D3g=',
  },
  {
    Asset_ID: '1046',
    Asset_Name: 'BH007',
    DeviceID: '07b93190-ec83-405d-8184-7c48dc3fe590',
    MileageMeters: '',
    CreatedDate: '05/06/2021 13:16:20',
    GPSStatusMark: '3D',
    latitude: '33.6793074',
    longitude: '-117.8740218',
    speed: '0.00 MPH',
    angle: '0',
    hdop: '0',
    ExternalPowerSupplyVoltage: '100V',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    AnalogInputVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '3158 Red Hill Ave #140, Costa Mesa, CA 92626, USA',
    CompanyName: 'BLACK HORSE TRANS INC',
    Status: 'All Good',
    Icon_Status: 'A',
    Display_Details: '1',
    assetType: '1',
    signature: '9ICWQ72F2EiM1P2MvInzAMG5qQI=',
  },
];
// We record the date we received this response, so that we can always offset it and show it in relevance to the current date time of the user demo'ing this
const getResponseWithOffsettedDate = () => {
  const dateReceivedResponse = response[0].CreatedDate;
  let lastLocations = response;
  const dateNow = new Date();
  lastLocations = lastLocations.map((lastLocation) => {
    const referenceDate = new Date(lastLocation.CreatedDate);
    const offsetMs = dateNow - new Date(dateReceivedResponse);
    const offsetDate = new Date(referenceDate.getTime() + offsetMs);
    const offsetDateString = `${
      offsetDate.getMonth() + 1
    }/${offsetDate.getDate()}/${offsetDate.getFullYear()} ${offsetDate.getHours()}:${offsetDate.getMinutes()}:${offsetDate.getSeconds()}`;
    return {
      ...lastLocation,
      CreatedDate: offsetDateString,
    };
  });
  return lastLocations;
};

export default {
  getResponseWithOffsettedDate,
  response,
};
