import { createSlice } from '@reduxjs/toolkit';

export const tripsSlice = createSlice({
  name: 'deleteActiveTrip',
  initialState: {
    isDeleated: false,
  },
  reducers: {
    setIsDeleated: (state, action) => {
      state.isDeleated = action.payload;
    },
  },
});

export const { setIsDeleated } = tripsSlice.actions;

export default tripsSlice.reducer;
