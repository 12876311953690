import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Modal from 'react-modal';
import './scss/switchCompanyPopUp.scss';
import * as API from '../../api/API';
import basicFetch from '../../api/basicFetch';
import { setCompanyToken, setIsSubCompany, setShowPopUp } from './switchCompSlice';
import authUtility from '../../utils/auth';
import { setLoader } from '../../core/loader/loaderSlice';

const SwitchCompanyPopUp = () => {
  const [compList, setCompList] = useState([]);
  const [selectedCompToken, setSelectedComp] = useState({});
  const [searchKey, setSearchKey] = useState('');
  const [resellerToken, setResellerToken] = useState('');
  const [filteredCompList, setFilteredCompList] = useState(compList);

  const companyInfo = authUtility.getCompanyInfo();

  const dispatch = useDispatch();
  const { showPopUp, isReseller } = useSelector((state) => state.switchCompany);
  const auth = authUtility.getLoginCredentials();

  const history = useHistory();

  const getCompanyToken = (compId) => {
    localStorage.setItem('setIsSubCompany', true);
    dispatch(setIsSubCompany(true));
    dispatch(setLoader(true));
    basicFetch(`${API.IMPERSONATE_RESELLER_COMPANY}?resellerCompanyId=${compId}`, null, null, 1)
      .then((res) => {
        if (res) {
          setSelectedComp(res);
          dispatch(setCompanyToken(res.Token));
          history.push('/Home');
          dispatch(setIsSubCompany(localStorage.getItem('setIsSubCompany')));
          dispatch(setLoader(false));
        }
      })
      .catch((e) => console.error(e));
  };

  const closeModal = () => {
    // setShowModal(false);
    dispatch(setShowPopUp(false));
  };

  const getCompanyList = () => {
    dispatch(setLoader(true));
    basicFetch(
      `${API.GET_RESELLER_COMPS}?resellerCompanyId=${companyInfo.companyId}`,
      null,
      null,
      1,
    )
      .then((res) => {
        const compData = res || [];
        setCompList(compData);
        dispatch(setLoader(false));
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (isReseller) {
      setResellerToken(auth.token);
      localStorage.setItem('resellerToken', auth.token);
      // dispatch(setResellerToken(auth.token));
    }
  }, [resellerToken]);

  useEffect(() => {
    if (selectedCompToken.Token) {
      dispatch(setCompanyToken(selectedCompToken.Token));
      authUtility.storeLoginCredentials(selectedCompToken.Token, 'New User');
      dispatch(setShowPopUp(false));
      window.location.reload(false);
    }
  }, [selectedCompToken]);

  useEffect(() => {
    const filteredList = compList.filter((comp) => {
      return comp.companyName.toLowerCase().includes(searchKey.toLowerCase());
    });
    setFilteredCompList(filteredList);
  }, [searchKey, compList]);

  useEffect(() => {
    getCompanyList();
  }, []);
  return (
    <Modal
      isOpen={showPopUp}
      onRequestClose={closeModal}
      overlayClassName="Overlay-comp"
      className="modal-comp-switch"
    >
      <div>
        <div className="btn-close">
          <i className="fas fa-times close-icon" onClick={closeModal} />
        </div>
        <div className="Title">LIST OF YOUR COMPANIES</div>
      </div>
      <div className="search-bar">
        <input
          type="text"
          className=""
          onChange={(e) => {
            setSearchKey(e.target.value);
          }}
        />
        <i className="fas fa-search" />
      </div>
      <div className="comp-list-container">
        {filteredCompList.length > 0 &&
          filteredCompList.map((comp) => {
            return (
              <div key={comp.companyName} className="d-flex justify-content-between p-2">
                <div>{comp.companyName}</div>
                <div>
                  <button
                    type="button"
                    className="btn"
                    onClick={() => {
                      getCompanyToken(comp.companyId);
                      // switchCompany(comp.companyId);
                    }}
                  >
                    {' '}
                    Switch
                  </button>
                </div>
              </div>
            );
          })}
      </div>
      {/* <div className="Message"></div> */}
    </Modal>
  );
};

export default SwitchCompanyPopUp;
