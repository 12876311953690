import getAtLastLocationAssetData from './getAtLastLocationAssetData';
import fiveDays1050 from './fiveDaysDeviceData/1050';
import fiveDays1049 from './fiveDaysDeviceData/1049';
import fiveDays1048 from './fiveDaysDeviceData/1048';
import fiveDays1047 from './fiveDaysDeviceData/1047';
import fiveDays1046 from './fiveDaysDeviceData/1046';
import dashcamMasterByIdMock from './dashcamMaster';

const transformFiveDaysDeviceDate = (fiveDaysDeviceData) => {
  let offsetDate = new Date();
  for (let i = 0; i < fiveDaysDeviceData.length; i++) {
    const date = `${
      offsetDate.getMonth() + 1
    }/${offsetDate.getDate()}/${offsetDate.getFullYear()} ${offsetDate.getHours()}:${offsetDate.getMinutes()}:${offsetDate.getSeconds()}`;
    fiveDaysDeviceData[i].CreatedDate = date;
    fiveDaysDeviceData[i].Date = date;
    fiveDaysDeviceData[i].DisplayDate = date;
    const twoMinMs = 2 * 60 * 1000;
    offsetDate = new Date(offsetDate.getTime() - twoMinMs);
  }
  return fiveDaysDeviceData;
};

const getAssetLocationDataDeviceIDWise1049 = [
  {
    DeviceID: '139408b8-a63f-488f-9354-971e45ac8661',
    CreatedDate: '06/15/2021 20:04:28',
    latitude: '35.0697969',
    longitude: '-106.2404062',
    speed: '0 MPH',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '31 Church St E, Edgewood, NM 87015',
    Date: '06/15/2021 00:00:00',
    Time: '20:04:28',
    Asset_Name: 'BH004',
    Status: 'All Good',
    Icon_Status: 'A',
    Active_Status: 'ACTIVE',
    Sim_Card_No: '89011704278605834250',
    Sim_Card_Phone_Number: '89011704278605834250',
    Asset_Description: 'BH004',
    Moving_Checkin_Id: '5',
    Stationary_Checkin_Id: '13',
    PolylineType: '0',
    WayPointsCount: '0',
    angle: '0',
    hdop: '0',
    Moving_Checkin_Name: '15 Minutes',
    Stationary_Checkin_Name: '2 Hours',
    DisplayDate: '06/15/2021 08:04:28 PM',
    currentStatus: '0',
    assetStatus: 'Moving',
    batteryThreshold: '60',
  },
];

const getAssetLocationDataDeviceIDWise1047 = [
  {
    DeviceID: '48e823c5-7efa-4d32-849f-d552d51874bb',
    CreatedDate: '06/16/2021 12:54:24',
    latitude: '39.5190862',
    longitude: '-119.8943973',
    speed: '15.00 MPH',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '6567-6667 Sharlands Ave, Reno, NV 89523, USA',
    Date: '06/16/2021 00:00:00',
    Time: '12:54:24',
    Asset_Name: 'BH008',
    Status: 'All Good',
    Icon_Status: 'A',
    Active_Status: 'ACTIVE',
    Sim_Card_No: '89011704278605834243',
    Sim_Card_Phone_Number: '89011704278605834243',
    Asset_Description: 'BH008',
    Moving_Checkin_Id: '5',
    Stationary_Checkin_Id: '13',
    PolylineType: '0',
    WayPointsCount: '0',
    angle: '0',
    hdop: '0',
    Moving_Checkin_Name: '15 Minutes',
    Stationary_Checkin_Name: '2 Hours',
    DisplayDate: '06/16/2021 12:54:24 PM',
    currentStatus: '0',
    assetStatus: 'Moving',
    batteryThreshold: '60',
  },
];

const getAssetLocationDataDeviceIDWise1050 = [
  {
    DeviceID: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
    CreatedDate: '06/16/2021 13:36:00',
    latitude: '32.677345',
    longitude: '-97.122784',
    speed: '55.92 MPH',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '1390 W Interstate 20, Arlington, TX 76017, USA',
    Date: '06/16/2021 00:00:00',
    Time: '13:36:00',
    Asset_Name: 'BH005',
    Status: 'All Good',
    Icon_Status: 'A',
    Active_Status: 'ACTIVE',
    Sim_Card_No: '89011704278605834318',
    Sim_Card_Phone_Number: '89011704278605834318',
    Asset_Description: 'BH005',
    Moving_Checkin_Id: '5',
    Stationary_Checkin_Id: '0',
    PolylineType: '0',
    WayPointsCount: '0',
    angle: '0',
    hdop: '0',
    Moving_Checkin_Name: '15 Minutes',
    Stationary_Checkin_Name: '',
    DisplayDate: '06/16/2021 01:36:00 PM',
    currentStatus: '0',
    assetStatus: 'Moving',
    batteryThreshold: '60',
  },
];

const getAssetLocationDataDeviceIDWise1048 = [
  {
    DeviceID: '879e45eb-4e13-45fc-b667-469d7d8f320b',
    CreatedDate: '06/10/2021 04:53:38',
    latitude: '35.0066416',
    longitude: '-117.6946824',
    speed: '37.90 MPH',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '12469 Del Oro St, Boron, CA 93516, USA',
    Date: '06/10/2021 00:00:00',
    Time: '04:53:38',
    Asset_Name: 'BH006',
    Status: 'All Good',
    Icon_Status: 'A',
    Active_Status: 'ACTIVE',
    Sim_Card_No: '89011704278605834300',
    Sim_Card_Phone_Number: '89011704278605834300',
    Asset_Description: 'BH006',
    Moving_Checkin_Id: '5',
    Stationary_Checkin_Id: '13',
    PolylineType: '0',
    WayPointsCount: '0',
    angle: '0',
    hdop: '0',
    Moving_Checkin_Name: '15 Minutes',
    Stationary_Checkin_Name: '2 Hours',
    DisplayDate: '06/10/2021 04:53:38 AM',
    currentStatus: '0',
    assetStatus: 'Stationary',
    batteryThreshold: '60',
  },
];

const getAssetLocationDataDeviceIDWise1046 = [
  {
    DeviceID: '07b93190-ec83-405d-8184-7c48dc3fe590',
    CreatedDate: '05/06/2021 13:16:20',
    latitude: '33.6793074',
    longitude: '-117.8740218',
    speed: '0.00 MPH',
    DeviceTemperature: '',
    DeviceBackupBatteryVoltage: '',
    DeviceBatteryPercentage: '0.00',
    location_address: '3158 Red Hill Ave #140, Costa Mesa, CA 92626, USA',
    Date: '05/06/2021 00:00:00',
    Time: '13:16:20',
    Asset_Name: 'BH007',
    Status: 'All Good',
    Icon_Status: 'A',
    Active_Status: 'ACTIVE',
    Sim_Card_No: '89011704278605834268',
    Sim_Card_Phone_Number: '89011704278605834268',
    Asset_Description: 'BH007',
    Moving_Checkin_Id: '5',
    Stationary_Checkin_Id: '13',
    PolylineType: '',
    WayPointsCount: '',
    angle: '0',
    hdop: '0',
    Moving_Checkin_Name: '15 Minutes',
    Stationary_Checkin_Name: '2 Hours',
    DisplayDate: '05/06/2021 01:16:20 PM',
    currentStatus: '0',
    assetStatus: 'Stationary',
    batteryThreshold: '60',
  },
];

const getFiveDaysDeviceData = (deviceId) => {
  if (deviceId === '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5') {
    return transformFiveDaysDeviceDate(fiveDays1050);
  }
  if (deviceId === '139408b8-a63f-488f-9354-971e45ac8661') {
    return transformFiveDaysDeviceDate(fiveDays1049);
  }
  if (deviceId === '879e45eb-4e13-45fc-b667-469d7d8f320b') {
    return transformFiveDaysDeviceDate(fiveDays1048);
  }
  if (deviceId === '48e823c5-7efa-4d32-849f-d552d51874bb') {
    return transformFiveDaysDeviceDate(fiveDays1047);
  }
  if (deviceId === '07b93190-ec83-405d-8184-7c48dc3fe590') {
    return transformFiveDaysDeviceDate(fiveDays1046);
  }
  return transformFiveDaysDeviceDate(fiveDays1050);
};

const getAssetLocationDeviceDataDeviceWise = (deviceId) => {
  const values = getAtLastLocationAssetData.getResponseWithOffsettedDate();
  const selectedLastLocationData = values.find((item) => item.DeviceID === deviceId);
  let returnValue = [];
  if (deviceId === '139408b8-a63f-488f-9354-971e45ac8661') {
    returnValue = Object.assign([], getAssetLocationDataDeviceIDWise1049);
  } else if (deviceId === '48e823c5-7efa-4d32-849f-d552d51874bb') {
    returnValue = Object.assign([], getAssetLocationDataDeviceIDWise1047);
  } else if (deviceId === '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5') {
    returnValue = Object.assign([], getAssetLocationDataDeviceIDWise1050);
  } else if (deviceId === '879e45eb-4e13-45fc-b667-469d7d8f320b') {
    returnValue = Object.assign([], getAssetLocationDataDeviceIDWise1048);
  } else if (deviceId === '07b93190-ec83-405d-8184-7c48dc3fe590') {
    returnValue = Object.assign([], getAssetLocationDataDeviceIDWise1046);
  } else {
    returnValue = Object.assign([], getAssetLocationDataDeviceIDWise1049);
  }
  returnValue[0].DisplayDate = selectedLastLocationData.CreatedDate;
  returnValue[0].CreatedDate = selectedLastLocationData.CreatedDate;
  return returnValue;
};

const getAssetDeviceConnectionStatus = (deviceId) => {
  const values = getAtLastLocationAssetData.getResponseWithOffsettedDate();
  const selectedLastLocationData = values.find((item) => item.DeviceID === deviceId);
  const returnValue = {
    deviceId,
    id: 0,
    lastGpsCreatedDate: selectedLastLocationData.CreatedDate,
    lastUpdatedDateUtc: selectedLastLocationData.CreatedDate,
    status: 1,
    tcpListenerServerId: 1,
  };
  if (deviceId === '879e45eb-4e13-45fc-b667-469d7d8f320b') {
    returnValue.status = 2;
  }
  if (deviceId === '07b93190-ec83-405d-8184-7c48dc3fe590') {
    returnValue.status = 2;
  }
  return returnValue;
};

const simCardBalance = {
  id: 496,
  deviceId: '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5',
  simPlan: 'Plan 1536MB',
  currentBalance: '1345.31',
  cumUsage: '1214.69',
  lastUpdate: new Date(),
  planBalance: null,
  planExpiryDate: null,
};

const getDashCamMasterByDeviceId = (deviceId) => {
  if (deviceId === '48e823c5-7efa-4d32-849f-d552d51874bb') {
    return dashcamMasterByIdMock.asset1047;
  }
  if (deviceId === '07b93190-ec83-405d-8184-7c48dc3fe590') {
    return dashcamMasterByIdMock.asset1046;
  }
  if (deviceId === '879e45eb-4e13-45fc-b667-469d7d8f320b') {
    return dashcamMasterByIdMock.asset1048;
  }
  if (deviceId === '139408b8-a63f-488f-9354-971e45ac8661') {
    return dashcamMasterByIdMock.asset1049;
  }
  if (deviceId === '1c6ca90b-4fe3-407a-8525-0ad2d8ffcac5') {
    return dashcamMasterByIdMock.asset1050;
  }
  return dashcamMasterByIdMock.asset1047;
};

const getAssetTagsByDeviceId = [];

export default {
  getAssetLocationDeviceDataDeviceWise,
  getFiveDaysDeviceData,
  getAssetDeviceConnectionStatus,
  simCardBalance,
  getDashCamMasterByDeviceId,
  getAssetTagsByDeviceId,
};
