const eldBlueColor = '#006496';
const eldIconColor = '#8692A3';
const outOfRangeIconColor = '#F7BF44';
const overSpeedIconColor = '#DA4655';
const insideGeofenceIconColor = '#1A2530';
const emergencyIconColor = '#006496';
const lowBatteryIconColor = '#8692A3';

export default {
  eldBlueColor,
  eldIconColor,
  outOfRangeIconColor,
  overSpeedIconColor,
  insideGeofenceIconColor,
  emergencyIconColor,
  lowBatteryIconColor,
};
