const atAlertTagsGet = [];

const atAlertAssetsListGet = [
  {
    id: '1190',
    name: 'Truck Reaches Lot',
    alert_applies: 'GeoFence Alert',
    description: 'Alerts when Trucks reach Lot',
    status: '1',
    alert_type: 'GeoFence',
    tags: '',
  },
  {
    id: '831',
    name: 'Battery Alert',
    alert_applies: 'Battery Alert',
    description: '',
    status: '0',
    alert_type: 'Battery',
    tags: '',
  },
];

const atAlertCoordinates = [
  {
    Id: '748',
    Alert_Master_ID: '1190',
    Latitude: '33.700991820030254',
    Long: '-117.95870714843717',
    Radius: '33.700991820030254,-117.95870714843717',
    RowJson:
      '[{"center":{"lat":33.700991820030254,"lng":-117.95870714843717},"radius":6335.418639801379}]',
    Name: 'Circle',
    CreatedDate: '06/21/2021 13:34:49',
  },
];

const getNotificationsContactList = [
  {
    ID: '630',
    Name: 'BLACK HORSE TRANS INC ',
    FirstName: 'BLACK HORSE TRANS INC',
    LastName: '',
    MobileNo: '',
    MobileCode: '',
    Email: 'blackhorsetransinc@gmail.com',
  },
];

const getAtAlertNotifications = [
  {
    Id: '8790',
    Alert_Master_ID: '1190',
    Notification_Contact_Id: '630',
    FirstName: 'BLACK HORSE TRANS INC',
    LastName: '',
    Email: 'blackhorsetransinc@gmail.com',
    Email_Alert: '0',
    MobileNo: '',
    MobileCode: '',
    Sms_Alert: '0',
    CreatedDate: '06/21/2021 13:34:49',
  },
];

const getAtAlertMaster = [
  {
    Id: '1190',
    Alert_Name: 'Truck Reaches Lot',
    Alert_Description: 'Alerts when Trucks reach Lot',
    Speed_Above: '0.000000',
    Is_All_Assets: 'True',
    Battery_Below: '0.00',
    Geo_Fence_Type_Id: '1',
    Alert_Type_Id: '1',
    CreatedDate: '06/21/2021 13:34:49',
    Is_Active: '1',
    Speed_Above_in_mph: '0.000000',
    Inside: 'True',
    Outside: 'False',
  },
];

export default {
  atAlertAssetsListGet,
  atAlertTagsGet,
  atAlertCoordinates,
  getNotificationsContactList,
  getAtAlertNotifications,
  getAtAlertMaster,
};
