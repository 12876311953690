/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import authUtility from '../../utils/auth';
import Layout from '../layout/Layout';

const PrivateRoute = ({ component: Component, showNavbar = true, ...rest }) => {
  const isAuthenticated = authUtility.getLoginCredentials();
  const displayComponent = (props) => {
    if (isAuthenticated && showNavbar) {
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
    }
    if (isAuthenticated) {
      return <Component {...props} />;
    }
    return <Redirect to="/Login" />;
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        return displayComponent(props);
      }}
    />
  );
};

export default connect(null, null)(PrivateRoute);
PrivateRoute.propTypes = {
  component: PropTypes.objectOf(PropTypes.any).isRequired,
  showNavbar: PropTypes.bool,
};
