const loginKey = 'login';
const companyKey = 'companyInfo';

const storeLoginCredentials = (token, userName, userId) => {
  const keyValue = {
    token,
    userName,
    userId,
  };
  localStorage.setItem(loginKey, JSON.stringify(keyValue));
};

const getLoginCredentials = () => {
  const item = localStorage.getItem(loginKey);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );

  return JSON.parse(jsonPayload);
};

const getTokenParameterFromUrlQuery = () => {
  try {
    const queryString = window.location.search;
    if (!queryString) return null;
    if (queryString.length <= 0) return null;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get('token');
    parseJwt(token); // Makes sure that the jwt token is valid and parseable.
    return token;
  } catch {
    return null;
  }
};

const storeCompanyInfo = (data) => {
  const contactData = {
    contactId: data.contact_ID,
    branchId: data.branch_ID,
    companyId: data.company_ID,
    contactLogoPath: data.contact_LogoPath,
    branchLogoPath: data.branch_LogoPath,
    companyLogoPath: data.company_LogoPath,
    contactName: data.contact_Name,
    branchName: data.branch_Name,
    companyName: data.company_Name,
    roleId: data.roleId,
    roleName: data.roleName,
    contactType: data.contact_Type,
    isTMSLogin: data.iS_TMS_Login,
    isHOSLogin: data.iS_HOS_Login,
    isTelematicsLogin: data.iS_TELEMATICS_Login,
    wialonToken: data.wialonToken,
    isLocation: data.is_Location,
    accountId: data.account_ID,
    dashcamCount: data.dashCamCount || 0,
    assetCount: data.assetCount || 0,
  };
  localStorage.setItem(companyKey, JSON.stringify(contactData));
  return contactData;
};

const getCompanyInfo = () => {
  const item = localStorage.getItem(companyKey);
  if (item) {
    return JSON.parse(item);
  }
  return null;
};

const getAndStoreTokenFromUrlQuery = () => {
  const token = getTokenParameterFromUrlQuery();
  if (!token) return;
  if (token.length <= 0) return;
  storeLoginCredentials(`Bearer ${token}`, 'User');
};

export default {
  storeLoginCredentials,
  getLoginCredentials,
  getAndStoreTokenFromUrlQuery,
  storeCompanyInfo,
  getCompanyInfo,
};
