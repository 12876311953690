// import { useSelector } from 'react-redux';
// import { useState, useEffect } from 'react';
import * as API from './API';
import authUtility from '../utils/auth';
import history from '../app/history';
import demoUtils from '../demo/utils';
import loginMock from '../demo/mocks/login';

// Automatically redirects back to Login if token is not found, or an unauthorized response comes.
// Any calling function will need to put this in a try catch. If it returns a 500 response, .json() will
// throw an error and we need to show an Error. We will leave that to the client calling, for example
// if we are calling multiple pages in a layout, it might be ok if one component just doesn't show data vs.
// throwing errors, or we can create one global error pop up, like we did loader.
// API Root: 0 === DashCam Microservice, 1 === TMS

/**
 *
 * @param {string} endpoint
 * @param {string} body
 * @param {boolean} put
 * @param {number} apiRoot
 * @returns {{isDemoAccount: boolean, response: object}}
 */
const demo = (endpoint, body = null) => {
  const returnValue = {
    isDemoAccount: false,
    response: {},
  };
  if (endpoint === API.LOGIN && demoUtils.isDemoBeforeLoggedIn(body.username)) {
    returnValue.isDemoAccount = true;
    returnValue.response = loginMock;
  } else if (endpoint === API.LOGIN) {
    return returnValue;
  }
  if (!demoUtils.isDemoAfterLoggedIn()) {
    return returnValue;
  }
  return demoUtils.handleDemoApiResponse(endpoint, body);
};

const basicFetch = async (endpoint, body = null, put = null, apiRoot = 0, patch = null) => {
  const auth = authUtility.getLoginCredentials();
  // const { isReseller, selectedCompanyToken } = useSelector((state) => state.switchCompany);

  let token = '';

  if (auth) {
    token = auth.token;
  }
  const rootURL = apiRoot === 0 ? API.ROOT : API.TMSROOT;

  const demoDetails = demo(endpoint, body, put, apiRoot);
  if (demoDetails.isDemoAccount) {
    return demoDetails.response;
  }
  let method = 'GET';
  if (put === 'delete') {
    method = 'DELETE';
  }
  if (body) {
    if (put) {
      method = 'PUT';
    } else if (patch) {
      method = 'PATCH';
    } else {
      method = 'POST';
    }
  }
  const res = await fetch(
    `${rootURL}${endpoint}`,
    {
      // eslint-disable-next-line no-nested-ternary
      method,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
        platform: 'W',
      },
      body: body ? JSON.stringify(body) : null,
    },
    500000,
  );
  if (res.status === 401) {
    history.push('/Login');
    return null;
  }
  if (res.status !== 200 && res.status !== 201) {
    // throw new Error(`Status: ${res.status}`);
    // if (res.status >= 500) {
    //   history.push('/error500');
    // }
    // return { status: res.status, response: res.text() };
    return res.text().then((text) => {
      return text ? JSON.parse(text) : {};
    });
  }

  return res.text().then((text) => {
    return text ? JSON.parse(text) : {};
  });
};

export default basicFetch;
